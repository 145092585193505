//@ts-check

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

import Server from '../server';
import Typography from '@material-ui/core/Typography';

const style = /*theme =>*/ ({
	imgLine: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 30,
	},

	imgRoot: {
		backgroundColor: 'white',
		padding: '6px 10px',
	},

	highlight: {
		fontWeight: 'bold',
	},

	img: {
		height: 150,
	},
});

class DialogTitleChange extends Component {
	state = {
		title: null,
	}

	rate(up) {
		Server.changeTitleRate(this.props.spot, up); // No waiting here
		this.props.onClose();
	}

	rateUp = () => {
		this.rate(true);
	}

	rateDown = () => {
		this.rate(false);
	}

	render() {
		const { classes, spot } = this.props;

		if (!spot || !spot.newTitle)
			return null;

		return (
			<Dialog
				open={this.props.open}
				onClose={this.props.onClose}
				maxWidth='sm'
				// fullWidth
			>
				<DialogContent>
					<Typography variant='body1' align='center' style={{ marginBottom: 10 }}>
						{'There is a voting in progress whether the spot title should change from "'}
						<span className={classes.highlight}>{`${spot.title}`}</span>
						{'" to "'}
						<span className={classes.highlight}>{`${spot.newTitle.title}`}</span>
						{'"'}
					</Typography>

					<Typography variant='body1' align='center'>
						{'Do you agree with the proposed change?'}
					</Typography>
				</DialogContent>

				<DialogActions>
					<Button variant='contained' color='primary' onClick={this.rateUp}>
						<ThumbUpIcon style={{ marginRight: 10 }} />
						{'Yes'}
					</Button>

					<Button variant='contained' color='primary' onClick={this.rateDown}>
						<ThumbDownIcon style={{ marginRight: 10 }} />
						{'No'}
					</Button>

					{/* Spacer */}
					<div style={{ flexGrow: 10 }} />

					<Button onClick={this.props.onClose} color='primary'>
						{'Close'}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

DialogTitleChange.propTypes = {
	classes: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	fullScreen: PropTypes.bool.isRequired,

	spot: PropTypes.object,
	image: PropTypes.object,
};

// @ts-ignore
export default withMobileDialog({ breakpoint: 'sm' })(withStyles(style)(DialogTitleChange));