//@ts-check
/* eslint-disable no-console */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

import Navigation from '../navigation';

import { checkKeyMods, renderBlurhash } from '../tools';

const style = /*theme => */({
	item: {
		'&:hover': {
			transform: 'scale(1.03)',
			boxShadow: '0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)',
		},

		backgroundColor: 'transparent',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		flexShrink: 0,
		cursor: 'pointer',
		position: 'relative',
		overflow: 'hidden',
		display: 'inline-block',
		transition: 'transform 0.2s ease',
	},

	canvas: {
		left: 0,
		width: '100%',
		top: 0,
		height: '100%',
		position: 'absolute',
	},

	img: {
		left: 0,
		width: '100%',
		top: 0,
		height: '100%',
		objectFit: 'cover',
		position: 'absolute',
	},

	innerText: {
		bottom: 0,
		padding: 5,
		backgroundImage: 'linear-gradient(to bottom, transparent, rgba(0,0,0,0.85))',
		position: 'absolute',
		color: 'rgba(255, 255, 255, 0.87)',
		left: 0,
		right: 0,
		pointerEvents: 'none',
		textAlign: 'center',
	},
});

var lastMouseDown = {};

class ImageWithTitle extends Component {
	componentDidMount() {
		this.update();
	}

	componentDidUpdate() {
		this.update();
	}

	update() {
		renderBlurhash(this.props.blurhash, this._canvas);
	}

	onClick = (e) => {
		if (Math.abs(e.clientX - lastMouseDown.x) + Math.abs(e.clientY - lastMouseDown.y) > 20 || Date.now() - lastMouseDown.time > 500) {
			// This wasn't a click but rather a swipe, ignore the event altogether
			e.preventDefault();
			e.stopPropagation();
			return;
		}
		if (checkKeyMods(e))
			Navigation.openTag(this.props.title, this.props.hierarchy);
	}

	onMouseDown = (e) => {
		lastMouseDown = { x: e.clientX, y: e.clientY, time: Date.now() };
	}

	render() {
		const { classes, imgClass } = this.props;

		return (
			<a href={Navigation.getTagURL(this.props.title, this.props.hierarchy)} onClick={this.onClick} onPointerDown={this.onMouseDown} style={{ position: 'relative' }}>
				<Paper
					elevation={3}
					className={classes.item + ' ' + (imgClass || 'img200f')}
				>
					{/* Background (blurhash) */}
					<canvas
						ref={r => this._canvas = r}
						className={classes.canvas}
						width={32} height={32}
					/>

					{/* Image */}
					<img
						className={classes.img}
						src={this.props.imageURL}
						alt={this.props.title}
					/>

					{/* Title */}
					<div className={classes.innerText}>
						<br />
						<br />
						{this.props.title}
					</div>
				</Paper>
			</a>
		);
	}
}

ImageWithTitle.propTypes = {
	classes: PropTypes.object.isRequired,
	title: PropTypes.string,
	hierarchy: PropTypes.string,
	imageURL: PropTypes.string,
	style: PropTypes.object,
	imgClass: PropTypes.string,
	blurhash: PropTypes.string,
};


/** @type {any} */
// @ts-ignore (style problem)
export default withStyles(style)(ImageWithTitle);