// @ts-check

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';

import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxIcon from '@material-ui/icons/RadioButtonChecked';
import CheckBoxNoIcon from '@material-ui/icons/RadioButtonUnchecked';
import OpenIcon from '@material-ui/icons/OpenInNew';
import CenterIcon from '@material-ui/icons/FullscreenExit';
import LinkIcon from '@material-ui/icons/Link';
import AdjustIcon from '@material-ui/icons/EditLocation';
import SpotBarIcon from '@material-ui/icons/Crop75';
import WeatherIcon from '@material-ui/icons/WbSunny';
import VRIcon from '@material-ui/icons/ThreeSixty';

import SpotPreview from './fragments/SpotPreview';
import SpotsBar from './fragments/SpotsBar';
import CurrentConditions from './fragments/CurrentConditions';
import VR360 from './fragments/VR360';

import Navigation from './navigation';
import Server from './server';
import PubSub from 'pubsub-js';

import { fetchMapSearch, COLORS, string2LatLong, openNewTab } from './tools';

var initMap, mapAPI, _showGeoLoc, _loading = 0;

export function showGeoLoc() {
	_showGeoLoc && _showGeoLoc();
}

const styles = /*theme*/() => ({
	root: {
		position: 'relative',
		width: '100%',
		height: '100%',
		WebkitTapHighlightColor: 'rgba(255, 255, 255, 0)',
	},

	spotBar: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
		pointerEvents: 'none',
		paddingBottom: 22,
	},

	spotBar1: {
		position: 'absolute',
		top: 0,
		width: '100%',
		transition: 'opacity 0.3s linear',
	},

	currentConditions: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
		paddingLeft: 10,
		paddingRight: 10,
		marginBottom: 22,
		backgroundColor: 'rgba(255, 255, 255, 0.85)',
	},
});

var mapInitialized = false;	// Shared by all map instances, we currently support only one shared map
var currentMap = null;
var relyMapBoundingBox;

PubSub.subscribe('MAP_BOUNDING_BOX', (msg, data) => {
	if (!initMap) { // Map not loaded yet, we have to relay the message later
		relyMapBoundingBox = relyMapBoundingBox || [];
		relyMapBoundingBox.push(data);
	}
});

function handleExpand() {
	Navigation.ToggleMap();
}

function handleDragPan(e) {
	if (currentMap)
		return currentMap.handleDragPan(e);
	else
		return true;
}

function handleChangeLayer(element) {
	PubSub.publish('SHOW_POPUP', {
		popUpID: 'mapLayersMenu',
		popUp: currentMap.renderLayersMenu(element),
	});
}

function handleChangePoint(pointID) {
	// console.log(pointID);
	if (pointID === undefined)
		Navigation.goSpot(null);
	else
		Navigation.goSpot({ id: pointID });
}

function resendMapSpotSet(msg, data) {
	if (mapInitialized) {
		PubSub.publish(msg, data);
	} else {
		setTimeout(() => {
			resendMapSpotSet(msg, data);
		}, 1000);
	}
}

// This is here to listen before the map is loaded
var tempMapSet = PubSub.subscribe('MAP_SPOT_SET', (msg, data) => { // eslint-disable-line no-unused-vars
	resendMapSpotSet(msg, data);
});

var lastBestSpots = [];

function isSmallChange(spots, lastSpots) {
	if (!currentMap.mapEl)
		return true;

	const items = currentMap.mapEl.offsetWidth / 160;
	var changes = 0;
	for (let i = 0; i < items; i++) {
		if (spots[i]) {
			const ind = lastSpots.indexOf(spots[i]);
			if (Math.abs(ind - i) > 1)
				changes++;
		}
	}

	return changes < 2;
}

var _switchingTimeout = null;
var plannedSpots = null;

function scheduleNewSpotsbar(spots) {
	if (_switchingTimeout) {
		clearTimeout(_switchingTimeout);
	}
	currentMap.setState({ oldSpots: spots }); // To start loading new spots
	const timeStart = Date.now();

	const switchBars = () => {
		currentMap.setState({ spots, oldSpots: currentMap.state.spots, firstList: !currentMap.state.firstList });
		lastBestSpots = spots;
		plannedSpots = null;
	}

	const check = () => {
		_switchingTimeout = null;
		if (Date.now() - timeStart > 700 || Server.getPreviewsLoadingCnt() === 0)
			switchBars();
		else
			_switchingTimeout = setTimeout(check, 10);
	}

	plannedSpots = spots;
	_switchingTimeout = setTimeout(check, 10);
}

function handleMoveEnd() {
	if (currentMap && mapAPI) {
		const spots = mapAPI.getVisibleSpots();
		const lastSpots = plannedSpots || lastBestSpots;
		if (spots.length !== lastSpots.length || spots.find((id, index) => id !== lastSpots[index])) { // Is there any change in the new array?
			if (isSmallChange(spots, lastSpots)) {
				if (plannedSpots) {
					currentMap.setState({ oldSpots: spots });
					plannedSpots = spots;
				} else {
					currentMap.setState({ spots });
					lastBestSpots = spots;
				}
			} else
				scheduleNewSpotsbar(spots);
		}
	}
}

class MapView extends Component {
	state = {
		spotPlaced: false,
		confirmingLocation: false,
		spots: [],
		firstList: true,
		oldSpots: null,
	}
	mapEl;
	_showWeather = false;
	_showSpotBar = false;
	_show360 = false;
	terminating = false;
	subPointMoved = '';

	initComp = async () => {
		if (process.env.REACT_APP_SERVER_SIDE || this.terminating)
			return false;

		if (!initMap && !_loading) {
			const whenLoaded = (mapOL) => {
				initMap = mapOL.initMap;
				geolocation = mapOL.Geoloc;
				_showGeoLoc = mapOL.showGeoLoc;
				_loading--;
				if (relyMapBoundingBox) {
					for (const box of relyMapBoundingBox)
						PubSub.publish('MAP_BOUNDING_BOX', box);
					relyMapBoundingBox = null;
				}
			}
			_loading++;
			// Just a quick check for a map, since sometimes map is mounted just for a while (due to SSR hack -- https://github.com/mui/material-ui/issues/21142#issuecomment-633144987)
			import(/* webpackMode: "weak" */'./MapOL')
				.then(mapOL => {
					// console.log('**Map**');
					// console.log(mapOL);
					whenLoaded(mapOL);
				})
				.catch(e => {
					// console.log('**NO MAP YET**');
					if (this.terminating) {
						// console.log('**NO longer needed**');
						_loading--;
						return;
					}
					setTimeout(() => {
						import('./MapOL').then(mapOL => {
							// console.log('**Map 2nd attempt**');
							// console.log(mapOL);
							whenLoaded(mapOL);
						})
					}, 20);
				});
		}

		if (!initMap || !this.mapEl) {
			if (this.terminating)
				return;
			setTimeout(this.initComp, 1); // Try to initialize a bit later (TODO: a hack, could be written better)
			return true;
		}

		if (!mapInitialized) {
			mapInitialized = true;
			PubSub.unsubscribe(tempMapSet);

			mapAPI = await initMap({
				onChangeLayer: handleChangeLayer,
				onChangePoint: handleChangePoint,
				onExpand: handleExpand,
				onDragPan: handleDragPan,
				onMoveEnd: handleMoveEnd,
				onDataUpdate: handleMoveEnd,
			});

			this.updateMapPoints(this.props);
			mapAPI.selectPoint(this.props.location);

			if (this.props.fixPosition)
				mapAPI.fixLocation();
		}
		this.setUpSpotClick(this.props);

		mapAPI.setElement(this.mapEl);
		mapAPI.showExpand(!this.props.largeMap);
		if (Navigation.getGeoTrack())
			mapAPI.showGeoLoc();

		mapAPI.setLightDisabled(this.props.disableLight);

		return true;
	}

	componentDidMount() {
		this.initComp();
		currentMap = this;

		this.subPointMoved = PubSub.subscribe('POINT_MOVED', () => {
			if (this.props.fixPosition) {
				this.setState({ confirmingLocation: true });
			}
		});

		if (mapAPI) {
			mapAPI.setElement(this.mapEl);

			if (this.props.fixPosition)
				mapAPI.fixLocation();

			if (this.props.location && typeof this.props.location.lat === 'number')
				mapAPI.selectPoint(this.props.location);
		}
	}

	componentWillUnmount() {
		this.terminating = true;
		PubSub.unsubscribe(this.subPointMoved);
		this.setUpSpotClick(this.props);
		if (mapAPI) {
			mapAPI.setElement(null);

			if (this.props.fixPosition)
				mapAPI.endFixLocation();
		}
		currentMap = null;
	}

	updateMapPoints(props) {
		if (props.search) {
			Server.search(props.search).then(points =>
				mapAPI.setPoints(points)
			);
		} else {
			Server.getPoints().then(points =>
				mapAPI.setPoints(points)
			);
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (Navigation.get360() && !mapInitialized)
			return true;

		if (!mapInitialized)
			return false;
		var res = false;

		if (this.props.centerLat !== nextProps.centerLat || this.props.centerLong !== nextProps.centerLong) {
			mapAPI.moveToPoint({ lat: nextProps.centerLat, long: nextProps.centerLong }, { placePoint: true });
			this.setState({ spotPlaced: true });
		}

		if (this.props.search !== nextProps.search) {
			this.updateMapPoints(nextProps);
		}

		if (this.props.fixPosition !== nextProps.fixPosition) {
			setTimeout(() => {
				this.setState({ confirmingLocation: false });
			}, 0);
			if (nextProps.fixPosition) {
				// Start fixing position
				mapAPI.fixLocation();
			} else {
				mapAPI.endFixLocation();
			}
		}

		if (this.props.fixPhoto !== nextProps.fixPhoto || this.props.newPhoto !== nextProps.newPhoto) {
			this.setUpSpotClick(nextProps);
			res = true;
		}

		if (this.props.disableLight !== nextProps.disableLight)
			mapAPI.setLightDisabled(this.props.disableLight);

		if ((nextProps.location && nextProps.location.id &&
			(!this.props.location || nextProps.location.id !== this.props.location.id || ((typeof this.props.location.lat !== 'number') && (typeof nextProps.location.lat === 'number')))) ||
			(!nextProps.location && this.props.location && this.props.location.id)) {
			mapAPI.selectPoint(nextProps.location);
			setTimeout(() => {
				this.setState({ // Reset us to the original state
					confirmingLocation: false,
				});
			}, 0);
			if (!nextProps.location || !this.props.location || nextProps.location.id !== this.props.location.id) {
				setTimeout(() => {
					this.setState({ // Reset us to the original state
						spotPlaced: false,
					});
				}, 0);
			}
		} else
			if (nextProps.location && nextProps.location.id > 0 && nextProps.location.id !== mapAPI.getSelectedPoint()) {
				mapAPI.selectPoint(nextProps.location);
			}

		return res ||
			(this.props.location !== nextProps.location ||
				this.props.newPhoto !== nextProps.newPhoto ||
				this.state !== nextState ||
				this.props.showGPS !== nextProps.showGPS) ||
			this._showSpotBar !== Navigation.getSpotBar() ||
			this._showWeather !== Navigation.getWeather() ||
			this._show360 !== Navigation.get360();
	}

	handleDragPan = (e) => {
		return this.props.largeMap || e.originalEvent.ctrlKey || e.originalEvent.pointerType === 'mouse';
	}

	setUpSpotClick(props) {
		if (!mapInitialized)
			return;

		if (props.fixPhoto)
			mapAPI.setCaptureSpotClick(this.photoFixClick);
		else if (props.newPhoto)
			mapAPI.setCaptureSpotClick(this.photoSelectClick);
		else
			mapAPI.setCaptureSpotClick(null);
	}

	photoFixClick = (idSpot) => {
		Server.movePhoto(this.props.fixPhoto, this.props.location, idSpot);
		Navigation.fixPhotoSpot(null); // Cancel fixing
	}

	photoSelectClick = (idSpot, long, lat) => {
		mapAPI.selectPoint({ id: idSpot, lat, long });
		PubSub.publish('NEW_SPOT_SELECT', { id: idSpot, lat, long });
	}

	handleMenuClose = () => {
		PubSub.publish('CLOSE_POPUP', {
			popUpID: 'mapLayersMenu',
		});
	}

	handleMapChange = (event) => {
		const map = event.currentTarget.dataset.id;

		mapAPI.showLayer(map);
		this.handleMenuClose();
		Navigation.setMapLayer(map);
	}

	handleOpenMapyCZ = () => {
		var loc = Navigation.getMapPosition();
		openNewTab(`https://mapy.cz/fotografie?x=${loc.longitude}&y=${loc.latitude}&z=${loc.zoom}`);
		this.handleMenuClose();
	}

	handleOpenMapyCZ3D = () => {
		var loc = Navigation.getMapPosition();
		openNewTab(`https://mapy.cz/fotografie?x=${loc.longitude}&y=${loc.latitude}&z=${loc.zoom}&m3d=1&height=5000&yaw=0&pitch=-40`);
		this.handleMenuClose();
	}

	handleGoogle = () => {
		var loc = Navigation.getMapPosition();
		openNewTab(`https://www.google.com/maps/@${loc.latitude},${loc.longitude},${loc.zoom}z`);
		this.handleMenuClose();
	}

	handleSearchChange = (event) => {
		this.searchValue = event.target.value;
	}

	handleSearch = () => {
		if (!this.searchValue)
			return;

		const loc = string2LatLong(this.searchValue);
		if (loc) { // It's a GPS location
			mapAPI.moveToPoint(loc);
			return;
		}

		fetchMapSearch(this.searchValue).then(data => {
			if (data && data[0]) {
				mapAPI.moveToRectangle(data[0].boundingbox);
			}
		});
	}

	onShowSpotBar = (e) => {
		Navigation.toggleSpotBar();
		this.handleMenuClose();
		if (e) {
			e.stopPropagation();
			e.preventDefault();
		}
	}

	renderLayersMenu(anchorEl) {
		const mapSelected = Navigation.getMapLayer();
		const maps = [
			{ id: 'default', title: 'Streets' },
			{ id: 'outdoor', title: 'Outdoor' },
			{ id: 'topo', title: 'Topography' },
			{ id: 'satHybrid', title: 'Satellite Hybrid' },
			{ id: 'OSM', title: 'Open Street Maps' },
			{ id: 'BingAerial', title: 'Bing Aerial' },
			// { id: 'BingRoad', title: 'Bing Road' }, // JH: Doesn't seem to be really useful
		];

		return (
			<Menu
				id='menu-layers'
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={true}
				onClose={this.handleMenuClose}
			>
				{maps.map(map =>
					<MenuItem onClick={this.handleMapChange} data-id={map.id} key={map.id}>
						<ListItemIcon>
							{map.id === mapSelected ? <CheckBoxIcon /> : <CheckBoxNoIcon />}
						</ListItemIcon>
						{map.title}
					</MenuItem>
				)}

				<Divider />

				<MenuItem onClick={this.handleGoogle}>
					<ListItemIcon><OpenIcon /></ListItemIcon>
					{'Open in Google Maps'}
				</MenuItem>
				<MenuItem onClick={this.handleOpenMapyCZ}>
					<ListItemIcon><OpenIcon /></ListItemIcon>
					{'Open in Mapy.cz'}
				</MenuItem>
				<MenuItem onClick={this.handleOpenMapyCZ3D}>
					<ListItemIcon><OpenIcon /></ListItemIcon>
					{'Open in Mapy.cz 3D'}
				</MenuItem>

				{this.props.largeMap &&
					<Divider />
				}

				{this.props.largeMap &&
					<MenuItem onClick={this.onShowSpotBar}>
						<ListItemIcon><SpotBarIcon /></ListItemIcon>
						{'SpotBar'}
						<ListItemSecondaryAction>
							<Switch
								edge='end'
								size='small'
								onChange={this.onShowSpotBar}
								checked={Navigation.getSpotBar()}
								inputProps={{ 'aria-labelledby': 'switch-list-label-bluetooth' }}
							/>
						</ListItemSecondaryAction>
					</MenuItem>
				}
			</Menu>
		);
	}

	handleSearchKeyPress = (event) => {
		if (event.key === 'Enter') { // Save the new tag
			this.handleSearch();
		}
	}

	getPointSVG() { // TODO: unite with tools.getPinSVG() (which currently returns larger SVG centered for usage by OpenLayers)
		const color = COLORS.SECONDARY_MAIN;
		const colorInner = COLORS.SECONDARY_VERY_LIGHT;

		var svg = '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32px" height="32px" viewBox="0 0 30 30" enable-background="new 0 0 30 56" xml:space="preserve">' +
			`<path fill="${color}" d="M22.906,10.438c0,4.367-6.281,14.312-7.906,17.031c-1.719-2.75-7.906-12.665-7.906-17.031S10.634,2.531,15,2.531S22.906,6.071,22.906,10.438z"/>` +
			`<circle fill="${colorInner}" cx="15" cy="10.677" r="4"/>` +
			'</svg>';

		return (
			<img src={'data:image/svg+xml,' + escape(svg)} alt='Place Spot' />
		);
	}

	handleAddPoint = () => {
		mapAPI.addNewPoint();
		this.setState({ spotPlaced: true });
		if (this.props.mobile)
			Navigation.showMap(false);
	}

	handleFixPosition = () => {
		mapAPI.endFixLocation(true /*commit*/);
		Navigation.fixMapPosition(false);
	}

	handleCancelFixPosition = () => {
		Navigation.fixMapPosition(false);
		Navigation.fixPhotoSpot(null);
	}

	onMapMouseLeave = () => {
		// To notify that mouse moved out of the map
		PubSub.publish('MAP_MOUSE_MOVE', {});
	}

	getLocString() {
		const loc = this.props.location;

		const roundStr = (i) => {
			return String(Math.round(i * 1000000) / 1000000);
		};

		if (loc && loc.lat !== undefined && loc.long !== undefined)
			return roundStr(loc.lat) + ', ' + roundStr(loc.long);
		else
			return '';
	}

	copyToClipboard = () => {
		// @ts-ignore
		const clipboard = navigator.clipboard;

		if (clipboard) {
			clipboard.writeText(this.getLocString()).then(() => {
				PubSub.publish('SHOW_SNACKBAR', {
					message: 'GPS coordinates copied to clipboard.',
				});
			});
		}
	}

	fixGPS = (event) => {
		if (!Server.checkLogin(event.currentTarget))
			return;

		Navigation.fixMapPosition(true);
	}

	hideGPS = () => {
		Navigation.showGPS(false);
	}

	toggleWeather = () => {
		Navigation.toggleWeather();
	}

	toggle360 = () => {
		Navigation.toggle360();
	}

	toggleMoon = () => {
		Navigation.toggleMoon();
		this.forceUpdate();
	}

	renderGPS() {
		return (
			<Grid container direction='row' wrap='wrap' style={{ alignItems: 'center', placeContent: 'center', justifyContent: 'center' }} >
				<span style={{ opacity: 0.8, marginRight: 12, fontSize: 14 }}>
					{'Coordinates:'}
				</span>

				<Input
					value={this.getLocString()}
					readOnly={true}
				/>

				<Tooltip title='Copy to Clipboard' enterDelay={300}>
					<IconButton onClick={this.copyToClipboard}>
						<LinkIcon />
					</IconButton>
				</Tooltip>

				<Tooltip title='Fix Coordinates' enterDelay={300}>
					<IconButton onClick={this.fixGPS}>
						<AdjustIcon />
					</IconButton>
				</Tooltip>

				<IconButton onClick={this.hideGPS}>
					<CloseIcon />
				</IconButton>
			</Grid>
		);
	}

	renderFixPosition() {
		var confText = null;
		const showGPS = this.props.showGPS && !this.props.fixPosition;

		if (this.props.fixPhoto) {
			confText = 'Click the Spot where the Photo was taken';
		} else {
			if (this.props.fixPosition && !Navigation.isImport())
				confText = this.state.confirmingLocation ? 'Confirm the new location' : 'Move the pin to the correct location';
		}

		if (!confText && !showGPS)
			return null;

		return (
			<div className='mapSubbox'>
				<Paper style={{ opacity: 0.9, padding: 10 }}>
					{showGPS && !confText ?
						this.renderGPS() :
						<Grid container direction='row' wrap='nowrap' style={{ alignItems: 'center', placeContent: 'center', justifyContent: 'center' }} >
							<Typography variant='subtitle1' style={{ marginRight: 15 }}>
								{confText}
							</Typography>

							{this.state.confirmingLocation ?
								<IconButton onClick={this.handleFixPosition}>
									<CheckIcon />
								</IconButton> : null}
							<IconButton onClick={this.handleCancelFixPosition}>
								<CloseIcon />
							</IconButton>
						</Grid>
					}
				</Paper>
			</div>
		);
	}

	renderNewSpot() {
		return (
			<div className='mapSubbox'>
				<Paper style={{ opacity: 0.9, padding: 10 }}>
					{this.props.largeMap &&
						<Typography variant='subtitle1' align='center'>
							{'Place the pin at the exact spot photo was taken.'}
						</Typography>
					}

					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Input
							placeholder={'Find a place or GPS coordinates'}
							style={{ flexGrow: 1, maxWidth: 300 }}
							onChange={this.handleSearchChange}
							onKeyPress={this.handleSearchKeyPress}
							endAdornment={
								<InputAdornment position='end'>
									<IconButton onClick={this.handleSearch}>
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							}
						/>

						<Tooltip title='Click to place the pin' enterDelay={300}>
							<IconButton style={{ padding: 1 }} onClick={this.handleAddPoint}>
								{this.getPointSVG()}
							</IconButton>
						</Tooltip>
					</div>
				</Paper>
			</div >
		);
	}

	renderVR() {
		const { largeMap, classes } = this.props;

		return (
			<div style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 100 }}>
				<Tooltip title='Close 360° View' enterDelay={300}>
					<div className='ol-unselectable ol-control' style={{ top: 8, right: 8, zIndex: 1000 }}>
						<button style={{ fill: 'white', outline: 'none' }} onClick={this.toggle360}>
							<CloseIcon />
						</button>
					</div>
				</Tooltip>

				<Fade in={!Navigation.getWeather()} mountOnEnter unmountOnExit>
					<Tooltip title='Light and Weather' enterDelay={300} style={{ position: 'absolute', bottom: 20, right: 8, width: 46, height: 46, zIndex: 1000 }}>
						<div>
							<div className='ol-unselectable ol-control' >
								<button style={{ fill: 'white', outline: 'none' }} onClick={this.toggleWeather}>
									<WeatherIcon />
								</button>
							</div>
						</div>
					</Tooltip>
				</Fade>

				{largeMap &&
					<Slide direction='up' in={Navigation.getWeather()} mountOnEnter unmountOnExit>
						<div className={classes.currentConditions} style={{ zIndex: 110 }}>
							<CurrentConditions
								location={this.props.location}
								showMoon={Navigation.getShowMoon()}
								onClickMoon={this.toggleMoon}
								onClose={this.toggleWeather}
								mobile={this.props.mobile}
								show3D={true}
							/>
						</div>
					</Slide>
				}

				<VR360
					location={this.props.location}
				/>
			</div>
		);
	}

	render() {
		const { newPhoto, largeMap, classes, location, mobile } = this.props;

		this._showWeather = Navigation.getWeather();
		this._showSpotBar = Navigation.getSpotBar();
		this._show360 = Navigation.get360();

		var locForLight = location;
		if (!locForLight && mapAPI) {
			locForLight = mapAPI.getCenter();
			locForLight.nearestId = mapAPI.getNearestSpotId();
		}

		return (
			<div
				className={classes.root}
				style={{ ...this.props.style }}
			>
				{largeMap && Navigation.get360() && location &&
					this.renderVR()
				}

				{/* Map placeholder */}
				<div
					className={this.props.className}
					ref={el => this.mapEl = el}
					style={{ width: '100%', position: 'absolute', top: 0, bottom: 0 }}
					onMouseLeave={this.onMapMouseLeave}
				/>

				{location &&
					<div className='ol-unselectable ol-control ol-360'>
						<button title='360° View' style={{ fill: 'white', outline: 'none' }} onClick={this.toggle360}>
							<VRIcon />
						</button>
					</div>
				}

				<Fade in={!Navigation.getWeather() && !Navigation.getSpotBar()}>
					<div>
						<div className='ol-unselectable ol-control' style={{ bottom: 63, right: 8 }}>
							<button title='Light and Weather' style={{ fill: 'white', outline: 'none' }} onClick={this.toggleWeather}>
								<WeatherIcon />
							</button>
						</div>

						<div className='ol-unselectable ol-control' style={{ bottom: 20, right: 8 }}>
							<button title='Spotbar' style={{ fill: 'white', outline: 'none' }} onClick={this.onShowSpotBar}>
								<SpotBarIcon />
							</button>
						</div>
					</div>
				</Fade>

				{/* SpotBar */}
				{largeMap &&
					<Slide direction='up' in={Navigation.getSpotBar()} mountOnEnter unmountOnExit>
						<div className={classes.spotBar} style={{ height: mobile ? 120 : 150 }}>
							<div className={classes.spotBar1} style={{ opacity: this.state.firstList ? 1 : 0, zIndex: this.state.firstList ? 2 : 1 }}>
								<SpotsBar
									spots={this.state.firstList ? this.state.spots : this.state.oldSpots}
									height={mobile ? 120 : 150}
									margin={5}
									singleLine={true}
									variant='outlined'
									openOnClick={true}
								/>
							</div>

							<div className='ol-unselectable ol-control' style={{ bottom: 20, right: 8, zIndex: 1000, pointerEvents: 'auto' }}>
								<button title='Close Spotbar' style={{ fill: 'white', outline: 'none' }} onClick={this.onShowSpotBar}>
									<CloseIcon />
								</button>
							</div>

							{this.state.oldSpots &&
								<div className={classes.spotBar1} style={{ opacity: this.state.firstList ? 0 : 1, zIndex: this.state.firstList ? 1 : 2 }}>
									<SpotsBar
										spots={this.state.firstList ? this.state.oldSpots : this.state.spots}
										height={mobile ? 120 : 150}
										margin={5}
										singleLine={true}
										variant='outlined'
										openOnClick={true}
									/>
								</div>
							}
						</div>
					</Slide>
				}

				{/* Light & Weather */}
				{largeMap &&
					<Slide direction='up' in={Navigation.getWeather()} mountOnEnter unmountOnExit>
						<div className={classes.currentConditions}>
							<CurrentConditions
								location={locForLight}
								showMoon={Navigation.getShowMoon()}
								onClickMoon={this.toggleMoon}
								onClose={this.toggleWeather}
								mobile={this.props.mobile}
							/>
						</div>
					</Slide>
				}

				{/* Targetting icon */}
				{newPhoto &&
					<CenterIcon
						color='secondary'
						style={{ position: 'absolute', top: 0, bottom: 0, margin: 'auto', left: 0, right: 0, pointerEvents: 'none', width: 48, height: 48 }}
					/>
				}

				{/* Upper banners */}
				{newPhoto && this.renderNewSpot()}
				{this.renderFixPosition()}

				<SpotPreview mobile={this.props.mobile} />
			</div>
		);
	}
}

MapView.propTypes = {
	classes: PropTypes.object.isRequired,
	style: PropTypes.object,
	className: PropTypes.string,
	location: PropTypes.object,
	search: PropTypes.string,
	largeMap: PropTypes.bool.isRequired,
	mobile: PropTypes.bool.isRequired,

	fixPosition: PropTypes.bool,
	fixPhoto: PropTypes.string,
	showGPS: PropTypes.bool,
	disableLight: PropTypes.bool,
	newPhoto: PropTypes.bool,

	centerLat: PropTypes.number,
	centerLong: PropTypes.number,
};

export var geolocation;

/** @type {any} */
// @ts-ignore
export default withStyles(styles)(MapView);