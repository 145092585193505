//@ts-check

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import { MD2React, COLORS } from '../tools';

const helps = {
	testshot: {
		title: 'Test Shot',
		content:
			`Test shot indicates a photograph that isn't up to the expected quality, be it because the conditions were not just right, or
the technical quality isn't up to phographer's standard (for example taken by a mobile phone).

Such photograph is usually uploaded as a placeholder, with an intention to be taken again in the future. It isn't shown in the photographer's portfolio, 
but in a separate category instead.`,
	},

	fixNorth: {
		title: 'Fix Orientation',
		content:
			`Some 360° panoramas are not properly aligned with respect to the Earth coordinates. You can calibrate individual panoramas here.

The currently designated North direction is marked by the orange cross. Please rotate the panorama so that the dashed cross points to the true North and 
is aligned with the horizon. Confirm by the check mark button then.

You can repeat this until you are satisfied with the result. Please Submit your fix then, so that it's saved for future use.`,
	},

	setView: {
		title: 'Set default view',
		content:
			`In order to open the 360° view in the direction of the most interesting scene, you can rotate and zoom before saving the result. The next
			time you open this view, it will be oriented in the desired direction.`,
	},

	photospot: {
		title: 'Photography Spot',
		content:
			'Photography Spot denotes the location where the photograph was taken.\n\n' +

			'Photographs from almost the same spot and particularly those with similar composition should always share the same Spot.',
	}
};

var dialog;

export function showHelp(id) {
	dialog.setState({ show: true, showID: id });
}

const style = /*theme =>*/ ({
});

class DialogHelp extends Component {
	state = {
		show: false,
		showID: null,
	}

	componentDidMount() {
		dialog = this;
	}

	componentWillUnmount() {
		dialog = null;
	}

	onClose = () => {
		this.setState({ show: false });
	}

	render() {
		// const { classes } = this.props;
		const data = helps[this.state.showID];

		return (
			<Dialog
				open={this.state.show}
				onClose={this.onClose}
				maxWidth='sm'
				fullWidth
			>
				<DialogContent>
					<Typography variant='h5' style={{ marginBottom: 20, color: COLORS.SECONDARY_MAIN }}>
						{data && data.title}

						<IconButton onClick={this.onClose} style={{ float: 'right', marginTop: -6 }}>
							<CloseIcon />
						</IconButton>
					</Typography>

					<Typography variant='body1'>
						{data && MD2React(data.content)}
					</Typography>
				</DialogContent>
			</Dialog>
		);
	}
}

DialogHelp.propTypes = {
	classes: PropTypes.object.isRequired,
	fullScreen: PropTypes.bool.isRequired,
};

/** @type {any} */
// @ts-ignore
export default withMobileDialog({ breakpoint: 'xs' })(withStyles(style)(DialogHelp));