// @ts-check

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';


import { checkKeyMods, renderBlurhash } from '../tools';
import Link from '@material-ui/core/Link';
import Navigation from '../navigation';
import Server from '../server';

const styles = theme => ({
	header: {
		height: '28vh',
		maxHeight: 'min(400px, 50vw)',  // For Google bot, as it has a bug that renders it (in Search Console) too tall (not respecing 'vh' units above)

		display: 'block',
		width: '100%',
		objectFit: 'cover',
	},

	dummyHeader: {
		height: 52,
		position: 'relative',
	},
});

const DEFAULT_IMG_OFFSET = 66;

class HeaderImage extends React.PureComponent {
	state = {
	}

	componentDidMount() {
		this.update();
	}

	componentDidUpdate() {
		this.update();
	}

	update() {
		renderBlurhash(this.props.blurhash, this._canvas);
	}

	render() {
		const { classes, image, imgOffset, imgDisplayName } = this.props;

		return (
			<>
				{/* Dummy header in case we don't have a usable header image */}
				{!image &&
					<div className={classes.dummyHeader} />
				}

				{/* Header */}
				{image && image.id &&
					<Link href={'/photo/' + image.id} onClick={(e) => { if (checkKeyMods(e)) Navigation.openPhoto(image.id); }} style={{ position: 'relative' }}>
						<canvas
							className={classes.header}
							ref={r => this._canvas = r}
							style={{
								width: '100%',
								position: 'relative',
								zIndex: -2,
							}}
							width={32} height={32}
						/>

						{/* Image */}
						<img
							className={classes.header}
							srcSet={
								Server.getImageThumb(image) + ' 600w, ' +
								Server.getImageUrl(image) + ' 2048w'
							}
							sizes={'(max-width: 1200px) 300px, 100vw'}
							alt='Header'
							style={{
								objectPosition: `center ${imgOffset || DEFAULT_IMG_OFFSET}%`,
								zIndex: -1,
								position: 'absolute',
								top: 0
							}}
						/>

						{/* Background at the bottom of the image */}
						<div style={{
							position: 'absolute', top: 0, right: 0, left: 0, bottom: 0,
							backgroundImage: `linear-gradient(to bottom, transparent, rgba(0,0,0,0.3))`,
						}} />

						{/* Copyright text */}
						{imgDisplayName &&
							<div style={{
								position: 'absolute', right: 0, bottom: 0, color: 'white', marginBottom: 3, marginRight: 8, textShadow: '0 0 10px rgb(0,0,0)',
								fontSize: 12, opacity: 0.86,
							}}
							>
								{'Photo by ' + imgDisplayName}
							</div>
						}
					</Link>
				}
			</>
		);
	}
}

HeaderImage.propTypes = {
	classes: PropTypes.object.isRequired,

	image: PropTypes.object,
	imgOffset: PropTypes.number,
	imgDisplayName: PropTypes.string,
	blurhash: PropTypes.string,
};

/** @type {any} */
// @ts-ignore (styles)
export default withStyles(styles)(HeaderImage);
