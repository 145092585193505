// @ts-check
/* eslint-disable react/display-name */
import React, { Component } from 'react';

import Button from '@material-ui/core/Button';

import MapIcon from '@material-ui/icons/Map';

import Server from '../server';
import Navigation from '../navigation';
import { geolocation, showGeoLoc } from '../MapView';
import MapView from '../MapView';
import SpotsList from './SpotsList';

import { checkKeyMods } from '../tools';

class Nearby extends Component {
	spots = null;
	lastScroll = 0;
	scrollHeight = 0;
	maxImages = 20;
	imgDelta = 20;
	terminating = false;
	accuracy = null;

	componentDidMount() {
		this.startGeoloc();
	}

	componentWillUnmount() {
		this.terminating = true;
	}

	geoListening = false;
	geoCallback = (coordinates, acc) => {
		this.geoLat = coordinates[1];
		this.geoLong = coordinates[0];
		this.accuracy = acc;
		this.baseSpot = { lat: this.geoLat, long: this.geoLong };
		Server.getNearbySpots(this.geoLat, this.geoLong).then(p => {
			this.spots = p;
			if (!this.terminating)
				this.forceUpdate();
		});
		this.endGeoloc();
	}


	startGeoloc() {
		if (!this.geoListening) {
			if (!geolocation) {
				setTimeout(() => this.startGeoloc(), 5);
				return;
			}
			geolocation.listenGeoLoc(this.geoCallback);
			this.geoListening = true;
		}
	}

	endGeoloc() {
		if (this.geoListening && geolocation) {
			this.geoListening = false;
			geolocation.unlistenGeoLoc(this.geoCallback);
		}
	}

	onOpenMap = (event) => {
		if (checkKeyMods(event)) {
			// var coord = null;
			// if (this.geoLat) {
			// 	coord = [this.geoLat - 0.1, this.geoLat + 0.1, this.geoLong - 0.1, this.geoLong + 0.1];
			// }
			Navigation.openMapPage(false /*tracking*/); //, coord);
			showGeoLoc();
		}
	}

	render() {
		const spots = this.spots;

		return (
			<>
				<div className='pageTitle'>
					{/* Include MapView so that geolocation code is loaded */}
					<MapView style={{ visibility: 'hidden' }} />

					{'Nearby Spots'}
					<div style={{ float: 'right' }}>
						<Button
							variant='outlined'
							size='small'
							onClick={this.onOpenMap}
							style={{ marginTop: -7, marginRight: 5 }}
						>
							<MapIcon style={{ marginRight: 10, opacity: 0.7 }} />
							{'Map'}
						</Button>
					</div>
				</div>
				<SpotsList
					spots={spots}
					baseSpot={this.baseSpot}
				/>
			</>
		);
	}
}

export default (Nearby);