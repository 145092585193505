// @ts-check
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import FirebaseAuth from '../fragments/FirebaseAuth';
import firebase from '../tools/firebase';

import Server from '../server.js';
import Navigation from '../navigation';

var dialog;
var firebaseAuth;
var uiConfig;
var FirebaseAuthentication; // for Capacitor

async function getFirebaseAuth() {
	if (!firebaseAuth) {
		firebaseAuth = await firebase.getAuth();
		if (!firebaseAuth)
			return;

		// Configure FirebaseUI.
		uiConfig = {
			'callbacks': {
				// Called when the user has been successfully signed in.
				'signInSuccessWithAuthResult': function (/*authResult, redirectUrl*/) {
					if (dialog)
						dialog.onClose();
					const user = firebaseAuth().currentUser;
					var fn = async () => {
						Server.authF(await user.getIdToken());
					};
					fn();
					return false;
				}
			},
			// Popup signin flow rather than redirect flow.
			signInFlow: process.env.REACT_APP_CORDOVA ? 'redirect' : 'popup',
			signInOptions: [
				firebaseAuth.GoogleAuthProvider.PROVIDER_ID,
				{
					provider: firebaseAuth.FacebookAuthProvider.PROVIDER_ID,
					scopes: [
						'public_profile',
						'email',
					],
				},
				firebaseAuth.TwitterAuthProvider.PROVIDER_ID,
				{
					provider: 'apple.com',
				},
				firebaseAuth.EmailAuthProvider.PROVIDER_ID,
			],
			tosUrl: '/legal/tos.html',
			privacyPolicyUrl: '/legal/privacy.html',
			'credentialHelper': 'none',
			// CLIENT_ID && CLIENT_ID != 'YOUR_OAUTH_CLIENT_ID' ?
			//       firebaseui.auth.CredentialHelper.GOOGLE_YOLO :
		};
	}
}

const style = theme => ({
	root: {
	},

	btnSocial: {
		borderRadius: 4,
		height: 40,
		width: 280,
		display: 'flex',
		alignItems: 'center',
		color: '#fff',
		cursor: 'pointer',
		marginBottom: 20,
		transition: '0.3s',
	},

	icn: {
		width: 24,
		height: 24,
		margin: 8,
		fill: '#fff',
	},

	text: {
		marginLeft: 12,
		marginRight: 24,
		fontSize: 18,
	},

	btnFacebook: {
		backgroundColor: '#4267B2',

		'&:hover': {
			backgroundColor: '#3b5998',
		}
	},

	btnGoogle: {
		backgroundColor: '#dd4b39',

		'&:hover': {
			backgroundColor: '#c23321',
		}
	},

	btnTwitter: {
		backgroundColor: '#55acee',

		'&:hover': {
			backgroundColor: '#2795e9',
		}
	},

	hyperlink: {
		color: theme.palette.primary.main,
		textDecoration: 'none',
	}
});

const signIn = async (callback) => {
	if (!FirebaseAuthentication)
		FirebaseAuthentication = (await import(/* webpackMode: "eager" */ '@capacitor-firebase/authentication')).FirebaseAuthentication;

	/** @type{any} */
	var result;
	try {
		result = await callback();
	} catch (e) {
		console.log('Login failed.');
		console.log(e);
	}

	console.log(result);
	return result && result.user;
};

class Login extends Component {
	_instrumented = false;

	componentDidMount() {
		dialog = this;
	}
	_shown = false;

	componentWillUnmount() {
		dialog = null;
	}

	onClose() {
		Navigation.closeLogin();
	}

	instrumentButtons = () => {
		if (!process.env.REACT_APP_CORDOVA)
			return;
		if (this._instrumented)
			return;

		var btn = document.querySelector('[data-provider-id="google.com"');
		if (!btn) {
			setTimeout(() => {
				this.instrumentButtons();
			}, 5);
			return;
		}
		btn.addEventListener('click', this.onGoogleClick, { capture: true });

		btn = document.querySelector('[data-provider-id="facebook.com"');
		if (btn)
			btn.addEventListener('click', this.onFacebookClick, { capture: true });

		btn = document.querySelector('[data-provider-id="twitter.com"');
		if (btn)
			btn.addEventListener('click', this.onTwitterClick, { capture: true });

		btn = document.querySelector('[data-provider-id="apple.com"');
		if (btn)
			btn.addEventListener('click', this.onAppleClick, { capture: true });
		this._instrumented = true;
	}

	onGoogleClick = (e) => {
		signIn(async () => await FirebaseAuthentication.signInWithGoogle());
		e.preventDefault();
		e.stopPropagation();
	}

	onFacebookClick = (e) => {
		signIn(async () => await FirebaseAuthentication.signInWithFacebook());
		e.preventDefault();
		e.stopPropagation();
	}

	onTwitterClick = (e) => {
		signIn(async () => await FirebaseAuthentication.signInWithTwitter({ skipNativeAuth: false }));
		e.preventDefault();
		e.stopPropagation();
	}

	onAppleClick = (e) => {
		signIn(async () => await FirebaseAuthentication.signInWithApple({ skipNativeAuth: true }));
		e.preventDefault();
		e.stopPropagation();
	}

	render() {
		if (process.env.REACT_APP_SERVER_SIDE)
			return null;

		if (Navigation.isLoginDialog()) {
			this.instrumentButtons();
		} else {
			this._instrumented = false;
			return null;
		}

		getFirebaseAuth();

		var failedOnce = false;
		const checkFirebase = () => {
			if (!firebaseAuth) {
				failedOnce = true;
				setTimeout(() => {
					checkFirebase();
				}, 10);
				return false;
			}
			if (failedOnce)
				this.forceUpdate();
			return true;
		};
		if (!checkFirebase())
			return null;

		return (
			<Dialog
				open={Navigation.isLoginDialog()}
				onClose={this.onClose}
			>
				<DialogTitle>Login / Sign up</DialogTitle>
				<DialogContent>
					<FirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth()} />
				</DialogContent>
				<DialogActions>
				</DialogActions>
			</Dialog>
		);
	}
}

Login.propTypes = {
	classes: PropTypes.object.isRequired,
};

/** @type {any} */
// @ts-ignore
export default withStyles(style)(withMobileDialog()(Login));