// @ts-check

var firebaseApp;
var firebaseAuth;
var initStarted = null;

async function initFirebase() {
	if (process.env.REACT_APP_SERVER_SIDE) // The Firebase code doesn't work at server-side, we have to load it only at clients
		return;
	if (initStarted) {
		await initStarted;
		return;
	}
	var resolve = (value) => {};
	initStarted = new Promise(res => {
		resolve = res;
	});

	firebaseApp = (await import(/* webpackMode: "eager" */ 'firebase/compat/app')).default;
	await import('firebase/compat/auth');
	firebaseAuth = firebaseApp.auth;

	if (firebaseApp.apps.length === 0) {
		firebaseApp.initializeApp(FiBaconfig);
	}
	resolve();
}

const FiBaconfig = {
	apiKey: 'AIzaSyAJQaYBaPpFBRmAsEamOM3K10S_QHcm-HQ',
	authDomain: 'phoide-211914.firebaseapp.com',
	databaseURL: 'https://phoide-211914.firebaseio.com',
	storageBucket: 'phoide-211914.appspot.com',
};

export default class Firebase {
	static async getAuth() {
    await initFirebase();
		return firebaseAuth;
	}
}