/* eslint-disable react/display-name */
import React, { useState } from 'react';

import Images from './Images';
import Server from '../server';

export default function PhotosFav() {
	const [photos, setPhotos] = useState(null);

	if (!photos) {
		Server.getFavPhotos().then(p => setPhotos(p));
	}

	return (
		<div>
			<Images
				images={photos}
				height={260}
				margins={10}
			/>
		</div>
	);
}