// @ts-check

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

import BookIcon from '@material-ui/icons/Bookmarks';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SpotlistIcon from '@material-ui/icons/PhotoLibrary';

import Server from '../server';
import { Typography } from '@material-ui/core';
import Navigation from '../navigation';

const styles = theme => ({
	header: {
		cursor: 'default',
		outline: 'none',
		minWidth: 350,

		display: 'flex',
		alignItems: 'center',
		backgroundColor: theme.palette.grey[200],
	},

	openSpotlist: {
		transition: 'opacity 0.3s',
		opacity: 0,

		':hover > &': {
			opacity: 1,
		},
	},

	itemImage: {
		backgroundSize: 'cover',
		height: 60,
		width: 60,
		marginRight: 16,
		borderRadius: 4,
	},

	itemChecked: {
		marginLeft: 6,
		marginTop: 5,
		position: 'relative',
	},

	itemUnchecked: {
		width: 42,
	},

	itemEmptyIcon: {
		height: 36,
		width: 36,
		marginRight: 16,
		borderRadius: 4,
		border: 'solid 1px #757575',
		padding: 11,
	},

	hover: {
		position: 'relative',
		opacity: 0,
		'.mi:hover  &': {
			opacity: 1,
		}
	},
	notHover: {
		position: 'absolute',
		opacity: 1,
		'.mi:hover &': {
			opacity: 0,
		}
	}
});

class ShareButton extends React.Component {
	state = {
		anchorEl: null,

		editingNew: false,
		newValue: '',

		spotlists: null,
		containing: [],
	};

	async updateSpot() {
		const containing =
			(this.props.location ? await Server.getUserSpotSpotlists(this.props.location.id) : await Server.getUserPhotoSpotlists(this.props.photo.id));
		this.setState({ containing });
	}

	async updateSpotlists() {
		const spotlists = await Server.getUserSpotlists();
		this.setState({ spotlists });
		if (this.state.anchorEl) {
			const saveAnchor = this.state.anchorEl;
			this.setState({ anchorEl: null });
			this.setState({ anchorEl: saveAnchor });
		}
	}

	handleMenuClose = (event) => {
		event.preventDefault();
		event.stopPropagation();

		this.setState({ anchorEl: null });
	};

	openPopup = (event) => {
		event.stopPropagation();
		event.preventDefault();

		if (!Server.checkLogin(event.currentTarget))
			return;

		const show = this.state.anchorEl ? null : event.currentTarget;
		this.setState({
			anchorEl: show,
			editingNew: false,
			newValue: '',
		});

		if (show) {
			if (!this.state.spotlists)
				this.updateSpotlists();
			this.updateSpot();
		}
	}

	onEditClick = () => {
		this.setState({ editingNew: true });
	}

	onNewChange = (event) => {
		this.setState({
			newValue: event.currentTarget.value,
		});
	}

	onCreateClick = async () => {
		const title = this.state.newValue;
		const spotlist = await Server.createSpotlist(title, this.props.location.id, this.getSpotImage());

		if (spotlist) {
			this.setState({
				spotlists: [spotlist, ...this.state.spotlists],
				containing: [spotlist.id, ...this.state.containing],
				editingNew: false,
				newValue: '',
			});
		}
	}

	onKeyPress = (event) => {
		if (event.key === 'Enter') {
			this.onCreateClick();
		}
	}

	getSpotImage() {
		const images = this.props.location.images;

		for (const img of images) { // eslint-disable-line no-unused-vars
			if (!Navigation.isFlickr(img))
				return img.id;
		}

		// No our image found, use Flickr linked
		if (images[0]) {
			return Server.getImageThumb(images[0]);
		}
	}

	onSpotlistClick = (event) => {
		const id = Number(event.currentTarget.dataset.id);
		const title = event.currentTarget.dataset.spotlist;
		const add = !this.isSpotInList(id);
		var containing;
		if (add) {
			if (this.props.location)
				Server.addToSpotlist(id, title, this.props.location.id, this.getSpotImage());
			else
				Server.addPhotoToSpotlist(id, title, this.props.photo.id);
			containing = [...this.state.containing, id];
		} else {
			if (this.props.location)
				Server.removeFromSpotlist(id, title, this.props.location.id);
			else
				Server.removePhotoFromSpotlist(id, title, this.props.photo.id);
			containing = this.state.containing.filter(i => i !== id);
		}
		this.setState({ containing });
	}

	isSpotInList(idSpotlist) {
		return this.state.containing.indexOf(idSpotlist) >= 0;
	}

	onOpenSpotlist = (event) => {
		event.stopPropagation();

		if (event.ctrlKey || event.altKey || event.shiftKey)
			return;

		Navigation.goSpotlist(null, event.currentTarget.dataset.spotlist);
		event.preventDefault();
	}

	preventKeys = (e) => {
		e.stopPropagation(); // To avoid menu item selection based on entered letter
	}

	renderImage(spotlist) {
		const { classes } = this.props;

		if (spotlist.image)
			return (
				<div className={classes.itemImage} style={{
					backgroundImage: `url("${Server.getThumbForID(spotlist.image)}")`,
				}} />
			);
		else
			return (
				<div className={classes.itemEmptyIcon}>
					<SpotlistIcon color='action' fontSize='large' />
				</div>
			);
	}

	renderChecked(spotlist) {
		const { classes } = this.props;
		const selected = this.isSpotInList(spotlist.id);

		if (selected)
			return (
				<div className={classes.itemChecked}>
					<CheckIcon color='secondary' fontSize='large' className={classes.notHover} />
					<CloseIcon color='secondary' fontSize='large' className={classes.hover} />
				</div>
			);
		else
			return (
				<div className={classes.itemUnchecked}>
				</div>
			);
	}

	render() {
		const { classes } = this.props;

		return (
			<React.Fragment>
				{!this.props.fullButton &&
					<Tooltip title='Add to a Gallery' enterDelay={300}>
						<IconButton aria-label='Star' onClick={this.openPopup} className={this.props.className} color='primary'>
							<BookIcon />
						</IconButton>
					</Tooltip>
				}

				{this.props.fullButton &&
					<Button
						variant='outlined'
						color='primary'
						aria-label='Add to Gallery'
						onClick={this.openPopup}
						style={{ ...this.props.style }}
					>
						<BookIcon style={{ marginRight: 8 }} />
						{'Gallery'}
					</Button>
				}


				<Menu
					id='menu-add-spotlist'
					anchorEl={this.state.anchorEl}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					open={Boolean(this.state.anchorEl)}
					onClose={this.handleMenuClose}
				>
					<ListSubheader component='div' className={classes.header}>
						<Typography variant='h6' style={{ paddingTop: 10, paddingBottom: 10, flexGrow: 1 }}>
							{'Add to Gallery'}
						</Typography>
						<IconButton onClick={this.handleMenuClose} style={{ marginRight: -6 }}><CloseIcon /></IconButton>
					</ListSubheader>

					<div style={{ marginLeft: 16, marginRight: 16, marginTop: 10 }} onKeyDown={this.preventKeys}>
						<Input
							placeholder={this.state.editingNew ? 'New Gallery Title' : 'Create a new Gallery'}
							fullWidth={true}
							value={this.state.newValue}
							onClick={this.onEditClick}
							onChange={this.onNewChange}
							onKeyPress={this.onKeyPress}
							style={{ marginBottom: 10 }}
						/>

						{this.state.editingNew &&
							<div style={{ marginBottom: 10, display: 'flex', justifyContent: 'flex-end' }}>
								<Button color='secondary' variant='contained' onClick={this.onCreateClick} disabled={!this.state.newValue}>
									{'Create'}
								</Button>
							</div>
						}
					</div>

					{this.state.editingNew &&
						<Divider />
					}

					{this.state.spotlists && this.state.spotlists.map(spotlist => {
						return (
							<MenuItem
								component='div'
								onClick={this.onSpotlistClick}
								key={spotlist.id}
								style={{ display: 'flex', height: 64 }}
								data-id={spotlist.id}
								data-spotlist={spotlist.urlTitle}
								className={'mi'}
							>
								{this.renderImage(spotlist)}

								<span style={{ flexGrow: 1, marginRight: 15 }}>
									{spotlist.title}
								</span>

								<Button
									color='primary'
									className={classes.openSpotlist}
									onClick={this.onOpenSpotlist}
									data-spotlist={spotlist.urlTitle}
									href={Navigation.hrefSpotlist(null, spotlist.urlTitle)}
								>
									{'Open'}
								</Button>

								{this.renderChecked(spotlist)}
							</MenuItem>
						);
					})}
				</Menu >
			</React.Fragment >
		);
	}
}

ShareButton.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	style: PropTypes.object,

	location: PropTypes.object,
	photo: PropTypes.object,
	fullButton: PropTypes.bool,
};

/** @type {any} */
// @ts-ignore
export default withStyles(styles)(ShareButton);
