import React from 'react';

export function getClearIcon(day, description) {
	if (day) {
		return (
			<svg aria-labelledby='Clear' role='img' viewBox="0 0 512 512" width='100%' height='100%' xmlns="http://www.w3.org/2000/svg">
				<title>{description}</title>
				<path fillRule="evenodd" clipRule="evenodd" d="M256,144c-61.75,0-112,50.25-112,112c0,61.75,50.25,112,112,112
					s112-50.25,112-112C368,194.25,317.75,144,256,144z M256,336c-44.188,0-80-35.812-80-80s35.812-80,80-80s80,35.812,80,80
					S300.188,336,256,336z M256,112c8.833,0,16-7.167,16-16V64c0-8.833-7.167-16-16-16s-16,7.167-16,16v32
					C240,104.833,247.167,112,256,112z M256,400c-8.833,0-16,7.167-16,16v32c0,8.833,7.167,16,16,16s16-7.167,16-16v-32
					C272,407.167,264.833,400,256,400z M380.438,154.167l22.625-22.625c6.25-6.25,6.25-16.375,0-22.625
					c-6.25-6.25-16.375-6.25-22.625,0l-22.625,22.625c-6.25,6.25-6.25,16.375,0,22.625
					C364.062,160.417,374.188,160.417,380.438,154.167z M131.562,357.834l-22.625,22.625c-6.25,6.249-6.25,16.374,0,22.624
					s16.375,6.25,22.625,0l22.625-22.624c6.25-6.271,6.25-16.376,0-22.625C147.938,351.583,137.812,351.562,131.562,357.834z M112,256
					c0-8.833-7.167-16-16-16H64c-8.833,0-16,7.167-16,16s7.167,16,16,16h32C104.833,272,112,264.833,112,256z M448,240h-32
					c-8.833,0-16,7.167-16,16s7.167,16,16,16h32c8.833,0,16-7.167,16-16S456.833,240,448,240z M131.541,154.167
					c6.251,6.25,16.376,6.25,22.625,0c6.251-6.25,6.251-16.375,0-22.625l-22.625-22.625c-6.25-6.25-16.374-6.25-22.625,0
					c-6.25,6.25-6.25,16.375,0,22.625L131.541,154.167z M380.459,357.812c-6.271-6.25-16.376-6.25-22.625,0
					c-6.251,6.25-6.271,16.375,0,22.625l22.625,22.625c6.249,6.25,16.374,6.25,22.624,0s6.25-16.374,0-22.625L380.459,357.812z"/>
			</svg>
		);
	} else {
		return (
			<svg aria-labelledby='Clear' role='img' viewBox="0 0 512 512" width='100%' height='100%' xmlns="http://www.w3.org/2000/svg">
				<title>{description}</title>
				<path d="M349.852,343.15c-49.876,49.916-131.083,49.916-181,0c-49.916-49.917-49.916-131.125,0-181.021
					c13.209-13.187,29.312-23.25,47.832-29.812c5.834-2.042,12.293-0.562,16.625,3.792c4.376,4.375,5.855,10.833,3.793,16.625
					c-12.542,35.375-4,73.666,22.249,99.917c26.209,26.228,64.501,34.75,99.917,22.25c5.792-2.062,12.271-0.583,16.625,3.792
					c4.376,4.333,5.834,10.812,3.771,16.625C373.143,313.838,363.06,329.941,349.852,343.15z M191.477,184.754
					c-37.438,37.438-37.438,98.354,0,135.771c40,40.021,108.125,36.417,143-8.167c-35.959,2.25-71.375-10.729-97.75-37.084
					c-26.375-26.354-39.333-61.771-37.084-97.729C196.769,179.796,194.039,182.192,191.477,184.754z"/>
			</svg>
		);
	}
}

function getFewCloudsIcon(day, description) {
	if (day) {
		return (
			<svg aria-labelledby='Few clouds' role='img' viewBox="0 0 512 512" width='100%' height='100%' xmlns="http://www.w3.org/2000/svg">
				<title>{description}</title>
				<path d="M208,64c8.833,0,16-7.167,16-16V16c0-8.833-7.167-16-16-16s-16,7.167-16,16v32
			C192,56.833,199.167,64,208,64z M332.438,106.167l22.625-22.625c6.249-6.25,6.249-16.375,0-22.625
			c-6.25-6.25-16.375-6.25-22.625,0l-22.625,22.625c-6.25,6.25-6.25,16.375,0,22.625
			C316.062,112.417,326.188,112.417,332.438,106.167z M16,224h32c8.833,0,16-7.167,16-16s-7.167-16-16-16H16
			c-8.833,0-16,7.167-16,16S7.167,224,16,224z M352,208c0,8.833,7.167,16,16,16h32c8.833,0,16-7.167,16-16s-7.167-16-16-16h-32
			C359.167,192,352,199.167,352,208z M83.541,106.167c6.251,6.25,16.376,6.25,22.625,0c6.251-6.25,6.251-16.375,0-22.625
			L83.541,60.917c-6.25-6.25-16.374-6.25-22.625,0c-6.25,6.25-6.25,16.375,0,22.625L83.541,106.167z M400,256
			c-5.312,0-10.562,0.375-15.792,1.125c-16.771-22.875-39.124-40.333-64.458-51.5C318.459,145,268.938,96,208,96
			c-61.75,0-112,50.25-112,112c0,17.438,4.334,33.75,11.5,48.438C47.875,258.875,0,307.812,0,368c0,61.75,50.25,112,112,112
			c13.688,0,27.084-2.5,39.709-7.333C180.666,497.917,217.5,512,256,512c38.542,0,75.333-14.083,104.291-39.333
			C372.916,477.5,386.312,480,400,480c61.75,0,112-50.25,112-112S461.75,256,400,256z M208,128c39.812,0,72.562,29.167,78.708,67.25
			c-10.021-2-20.249-3.25-30.708-3.25c-45.938,0-88.5,19.812-118.375,53.25C131.688,234.083,128,221.542,128,208
			C128,163.812,163.812,128,208,128z M400,448c-17.125,0-32.916-5.5-45.938-14.667C330.584,461.625,295.624,480,256,480
			c-39.625,0-74.584-18.375-98.062-46.667C144.938,442.5,129.125,448,112,448c-44.188,0-80-35.812-80-80s35.812-80,80-80
			c7.75,0,15.062,1.458,22.125,3.541c2.812,0.792,5.667,1.417,8.312,2.521c4.375-8.562,9.875-16.396,15.979-23.75
			C181.792,242.188,216.562,224,256,224c10.125,0,19.834,1.458,29.25,3.709c10.562,2.499,20.542,6.291,29.834,11.291
			c23.291,12.375,42.416,31.542,54.457,55.063C378.938,290.188,389.209,288,400,288c44.188,0,80,35.812,80,80S444.188,448,400,448z"
				/>
			</svg>
		);
	} else {
		return (
			<svg aria-labelledby='Few clouds' role='img' viewBox="0 0 512 512" width='100%' height='100%' xmlns="http://www.w3.org/2000/svg">
				<title>{description}</title>
				<path d="M435.062,262.166c4.875-3.625,9.812-7.188,14.229-11.604c13.208-13.229,23.271-29.333,29.792-47.854
	c2.083-5.792,0.604-12.292-3.772-16.625c-4.354-4.375-10.812-5.854-16.623-3.792c-35.396,12.5-73.688,4-99.896-22.25
	C332.542,133.79,324,95.52,336.542,60.145c2.042-5.813,0.583-12.271-3.792-16.646c-4.333-4.333-10.812-5.834-16.625-3.791
	C297.583,46.29,281.5,56.331,268.291,69.54c-33.541,33.5-43.979,81-32.479,123.792c-43.25,5.521-82.125,28.5-108.021,63.793
	c-5.229-0.75-10.479-1.125-15.792-1.125c-61.75,0-112,50.25-112,112.001c0,61.749,50.25,111.999,112,111.999
	c13.688,0,27.084-2.5,39.709-7.334C180.665,497.916,217.499,512,255.999,512c38.542,0,75.333-14.084,104.291-39.334
	C372.915,477.5,386.311,480,399.999,480c61.75,0,112-50.25,112-111.999C512,318.562,479.583,276.979,435.062,262.166z
	M290.916,92.166c2.542-2.583,5.271-4.979,8.146-7.229c-2.25,35.979,10.729,71.375,37.104,97.729
	c26.376,26.375,61.771,39.333,97.751,37.104c-13.792,17.584-32.792,28.521-53.126,33.188
	c-27.375-34.75-67.604-56.625-111.958-60.187C256.562,158.812,263.75,119.333,290.916,92.166z M400,448
	c-17.125,0-32.916-5.5-45.938-14.667C330.583,461.625,295.624,480,256,480c-39.625,0-74.584-18.375-98.062-46.667
	C144.938,442.5,129.126,448,112,448c-44.188,0-80-35.812-80-79.999c0-44.188,35.812-80,80-80c10.812,0,21.062,2.188,30.438,6.062
	c20.188-39.355,59.75-66.896,106.312-69.543c2.438-0.145,4.812-0.521,7.25-0.521c12.916,0,25.312,2.021,37,5.583
	c15.791,4.834,30.188,12.708,42.688,22.854c11.938,9.688,21.938,21.562,29.812,34.896c1.333,2.25,2.834,4.375,4.041,6.729
	c9.396-3.875,19.668-6.062,30.459-6.062c44.188,0,80,35.812,80,80S444.188,448,400,448z"/>
			</svg>
		);
	}
}

function getMoreCloudsIcon(description) {
	return (
		<svg aria-labelledby='More clouds' role='img' viewBox="0 0 512 512" width='100%' height='100%' xmlns="http://www.w3.org/2000/svg">
			<title>{description}</title>
			<path fillRule="evenodd" clipRule="evenodd" d="M400,160c-5.312,0-10.562,0.375-15.792,1.125
	C354.334,120.417,307.188,96,256,96s-98.312,24.417-128.208,65.125C122.562,160.375,117.312,160,112,160C50.25,160,0,210.25,0,272
	c0,61.75,50.25,112,112,112c13.688,0,27.084-2.5,39.709-7.333C180.666,401.917,217.5,416,256,416
	c38.542,0,75.333-14.083,104.291-39.333C372.916,381.5,386.312,384,400,384c61.75,0,112-50.25,112-112
	C512,210.25,461.75,160,400,160z M400,352c-17.125,0-32.916-5.5-45.938-14.667C330.584,365.624,295.624,384,256,384
	c-39.625,0-74.584-18.376-98.062-46.667C144.938,346.5,129.125,352,112,352c-44.188,0-80-35.812-80-80s35.812-80,80-80
	c10.812,0,21.062,2.208,30.438,6.083C163.667,156.667,206.291,128,256,128s92.334,28.667,113.541,70.083
	C378.938,194.208,389.209,192,400,192c44.188,0,80,35.812,80,80S444.188,352,400,352z"/>
		</svg>
	);
}

function getCloudsIcon(description) {
	return (
		<svg aria-labelledby='More clouds' role='img' viewBox="0 0 512 512" width='100%' height='100%' xmlns="http://www.w3.org/2000/svg">
			<title>{description}</title>
			<path d="M512,240.001c0-49.167-40-89.146-89.167-89.146c-2.396,0-4.771,0.104-7.146,0.291
	c-23.125-28.854-57.979-45.978-95.688-45.978c-37.688,0-72.562,17.124-95.688,45.979c-2.375-0.188-4.771-0.291-7.188-0.291
	C168,150.855,128,190.834,128,240.001c0,5.146,0.688,10.104,1.5,15.042c-0.542,0.708-1.188,1.354-1.708,2.083
	c-5.229-0.75-10.479-1.125-15.792-1.125C50.25,256.001,0,306.25,0,368c0,61.751,50.25,112,112,112c13.688,0,27.084-2.5,39.709-7.333
	C180.666,497.917,217.5,512,256,512c38.542,0,75.333-14.083,104.291-39.333C372.916,477.5,386.312,480,400,480
	c61.75,0,112-50.249,112-112c0-25.688-9.042-49.083-23.666-67.999C502.916,284.105,512,263.168,512,240.001z M400,448
	c-17.125,0-32.916-5.499-45.938-14.666C330.583,461.625,295.624,480,256,480c-39.625,0-74.584-18.375-98.062-46.666
	C144.938,442.501,129.126,448,112,448c-44.188,0-80-35.812-80-80s35.812-79.999,80-79.999c10.812,0,21.062,2.188,30.438,6.062
	c0.562-1.062,1.25-2,1.812-3.021c5.625-10.271,12.562-19.688,20.666-28.042C188,239.084,220.083,224,256,224
	c49.709,0,92.334,28.666,113.541,70.062c5.646-2.312,11.668-3.813,17.875-4.812L400,288c12.334,0,23.875,3.042,34.312,8
	c11.312,5.416,21.021,13.374,28.646,23.188c10.5,13.521,17.042,30.354,17.042,48.812C480,412.188,444.188,448,400,448z
	M465.562,277.625c-18.479-13.458-41-21.624-65.562-21.624c-5.312,0-10.562,0.375-15.792,1.125
	c-29.874-40.708-77.021-65.125-128.208-65.125c-34.125,0-66.312,11.042-92.938,30.334c7.479-22.854,28.729-39.479,54.062-39.479
	c7.75,0,15.062,1.562,21.75,4.332c15.188-29.562,45.625-50.02,81.125-50.02s65.958,20.457,81.084,50.02
	c6.729-2.77,14.083-4.332,21.749-4.332c31.584,0,57.167,25.583,57.167,57.146C480,254.48,474.438,267.542,465.562,277.625z"/>
		</svg>
	);
}

function getLightRainIcon(description) {
	return (
		<svg aria-labelledby='More clouds' role='img' viewBox="0 0 512 512" width='100%' height='100%' xmlns="http://www.w3.org/2000/svg">
			<title>{description}</title>
			<path fillRule="evenodd" clipRule="evenodd" d="M400,96c-5.312,0-10.562,0.375-15.792,1.125
			C354.334,56.417,307.188,32,256,32s-98.312,24.417-128.208,65.125C122.562,96.375,117.312,96,112,96C50.25,96,0,146.25,0,208
			c0,61.75,50.25,112,112,112c13.688,0,27.084-2.5,39.709-7.333C180.666,337.917,217.5,352,256,352
			c38.542,0,75.333-14.083,104.291-39.333C372.916,317.5,386.312,320,400,320c61.75,0,112-50.25,112-112
			C512,146.25,461.75,96,400,96z M400,288c-17.125,0-32.916-5.5-45.938-14.667C330.584,301.625,295.624,320,256,320
			c-39.625,0-74.584-18.375-98.062-46.667C144.938,282.5,129.125,288,112,288c-44.188,0-80-35.812-80-80s35.812-80,80-80
			c10.812,0,21.062,2.208,30.438,6.083C163.667,92.667,206.291,64,256,64s92.334,28.667,113.541,70.083
			C378.938,130.208,389.209,128,400,128c44.188,0,80,35.812,80,80S444.188,288,400,288z M224,448c0,17.688,14.312,32,32,32
			s32-14.312,32-32s-32-64-32-64S224,430.312,224,448z"/>
		</svg>
	);
}

function getRainIcon(description) {
	return (
		<svg aria-labelledby='More clouds' role='img' viewBox="0 0 512 512" width='100%' height='100%' xmlns="http://www.w3.org/2000/svg">
			<title>{description}</title>
			<path fillRule="evenodd" clipRule="evenodd" d="M400,64c-5.312,0-10.562,0.375-15.792,1.125
			C354.334,24.417,307.188,0,256,0s-98.312,24.417-128.208,65.125C122.562,64.375,117.312,64,112,64C50.25,64,0,114.25,0,176
			s50.25,112,112,112c13.688,0,27.084-2.5,39.709-7.333C180.666,305.917,217.5,320,256,320c38.542,0,75.333-14.083,104.291-39.333
			C372.916,285.5,386.312,288,400,288c61.75,0,112-50.25,112-112S461.75,64,400,64z M400,256c-17.125,0-32.916-5.5-45.938-14.667
			C330.584,269.625,295.624,288,256,288c-39.625,0-74.584-18.375-98.062-46.667C144.938,250.5,129.125,256,112,256
			c-44.188,0-80-35.812-80-80s35.812-80,80-80c10.812,0,21.062,2.208,30.438,6.083C163.667,60.667,206.291,32,256,32
			s92.334,28.667,113.541,70.083C378.938,98.208,389.209,96,400,96c44.188,0,80,35.812,80,80S444.188,256,400,256z M225,480
			c0,17.688,14.312,32,32,32s32-14.312,32-32s-32-64-32-64S225,462.312,225,480z M352,448c0,17.688,14.312,32,32,32s32-14.312,32-32
			s-32-64-32-64S352,430.312,352,448z M96,384c0,17.688,14.312,32,32,32s32-14.312,32-32s-32-64-32-64S96,366.312,96,384z"/>

		</svg>
	);
}

function getLightSnowIcon(description) {
	return (
		<svg aria-labelledby='More clouds' role='img' viewBox="0 0 512 512" width='100%' height='100%' xmlns="http://www.w3.org/2000/svg">
			<title>{description}</title>
			<path fillRule="evenodd" clipRule="evenodd" d="M326.042,443.688l-18-10.376
			c0.542-3.124,0.958-6.311,0.958-9.624c0-3.312-0.416-6.5-1-9.625l18.042-10.396c8.417-4.917,11.291-15.729,6.458-24.104
			c-4.876-8.479-15.667-11.375-24.125-6.5l-18.188,10.5c-4.876-4.146-10.375-7.396-16.5-9.604v-20.896
			c0-9.771-7.938-17.688-17.688-17.688s-17.666,7.917-17.666,17.688v20.875c-6.126,2.188-11.646,5.479-16.521,9.625l-18.146-10.5
			c-8.479-4.875-19.292-2-24.167,6.479c-4.875,8.417-1.938,19.25,6.5,24.126l17.959,10.375c-0.584,3.146-0.959,6.334-0.959,9.646
			c0,3.313,0.375,6.5,0.959,9.624L186,443.688c-8.459,4.875-11.375,15.75-6.5,24.188s15.688,11.312,24.125,6.438l18.167-10.438
			c4.874,4.125,10.396,7.375,16.542,9.562v20.938c0,9.749,7.916,17.624,17.666,17.624s17.688-7.875,17.688-17.624v-20.938
			c6.125-2.188,11.688-5.438,16.521-9.625l18.167,10.5c8.458,4.875,19.249,2,24.125-6.438
			C337.375,459.438,334.5,448.625,326.042,443.688z M256,441.375c-9.75,0-17.688-7.938-17.688-17.688s7.938-17.646,17.688-17.646
			s17.667,7.896,17.667,17.646S265.75,441.375,256,441.375z M400,64c-5.312,0-10.562,0.375-15.792,1.125
			C354.334,24.417,307.188,0,256,0s-98.312,24.417-128.208,65.125C122.562,64.375,117.312,64,112,64C50.25,64,0,114.25,0,176
			s50.25,112,112,112c13.688,0,27.084-2.5,39.709-7.333C180.666,305.917,217.5,320,256,320c38.542,0,75.333-14.083,104.291-39.333
			C372.916,285.5,386.312,288,400,288c61.75,0,112-50.25,112-112S461.75,64,400,64z M400,256c-17.125,0-32.916-5.5-45.938-14.667
			C330.584,269.625,295.624,288,256,288c-39.625,0-74.584-18.375-98.062-46.667C144.938,250.5,129.125,256,112,256
			c-44.188,0-80-35.812-80-80s35.812-80,80-80c10.812,0,21.062,2.208,30.438,6.083C163.667,60.667,206.291,32,256,32
			s92.334,28.667,113.541,70.083C378.938,98.208,389.209,96,400,96c44.188,0,80,35.812,80,80S444.188,256,400,256z"/>
		</svg>
	);
}

function getSnowIcon(description) {
	return (
		<svg aria-labelledby='More clouds' role='img' viewBox="0 0 512 512" width='100%' height='100%' xmlns="http://www.w3.org/2000/svg">
			<title>{description}</title>
			<path fillRule="evenodd" clipRule="evenodd" d="M326.042,443.688l-18-10.376
			c0.542-3.124,0.958-6.311,0.958-9.624c0-3.312-0.416-6.5-1-9.625l18.042-10.396c8.417-4.917,11.291-15.729,6.458-24.104
			c-4.876-8.479-15.667-11.375-24.125-6.5l-18.188,10.5c-4.876-4.146-10.375-7.396-16.5-9.604v-20.896
			c0-9.771-7.938-17.688-17.688-17.688s-17.666,7.917-17.666,17.688v20.875c-6.126,2.188-11.646,5.479-16.521,9.625l-18.146-10.5
			c-8.479-4.875-19.292-2-24.167,6.479c-4.875,8.417-1.938,19.25,6.5,24.126l17.959,10.375c-0.584,3.146-0.959,6.334-0.959,9.646
			c0,3.313,0.375,6.5,0.959,9.624L186,443.688c-8.459,4.875-11.375,15.75-6.5,24.188s15.688,11.312,24.125,6.438l18.167-10.438
			c4.874,4.125,10.396,7.375,16.542,9.562v20.938c0,9.749,7.916,17.624,17.666,17.624s17.688-7.875,17.688-17.624v-20.938
			c6.125-2.188,11.688-5.438,16.521-9.625l18.167,10.5c8.458,4.875,19.249,2,24.125-6.438
			C337.375,459.438,334.5,448.625,326.042,443.688z M256,441.375c-9.75,0-17.688-7.938-17.688-17.688s7.938-17.646,17.688-17.646
			s17.667,7.896,17.667,17.646S265.75,441.375,256,441.375z M474.166,396.25l-12.083-3.208c-0.291-3.833-1.208-7.479-2.896-10.979
			l8.771-8.771c4.125-4.125,4.104-10.792,0-14.854c-4.083-4.104-10.708-4.125-14.833,0l-8.791,8.75
			c-3.459-1.625-7.146-2.562-10.959-2.875l-3.209-12.062c-1.499-5.583-7.25-8.938-12.874-7.438
			c-5.604,1.521-8.875,7.271-7.417,12.875l3.209,11.896c-1.584,1.084-3.084,2.292-4.5,3.667c-1.375,1.417-2.542,2.916-3.626,4.459
			l-11.896-3.209c-5.604-1.499-11.396,1.876-12.896,7.438c-1.499,5.625,1.876,11.354,7.417,12.875l12,3.229
			c0.334,3.771,1.292,7.458,2.979,10.959l-8.812,8.812c-4.083,4.104-4.062,10.729,0.042,14.812
			c4.083,4.083,10.708,4.125,14.792,0.042l8.832-8.833c3.459,1.707,7.168,2.666,11.001,2.957l3.166,12.021
			c1.542,5.604,7.25,8.938,12.876,7.438c5.583-1.5,8.957-7.249,7.458-12.917l-3.209-11.896c1.5-1.062,3.042-2.25,4.459-3.625
			c1.375-1.396,2.542-2.938,3.624-4.479l11.917,3.209c5.604,1.5,11.375-1.854,12.854-7.417
			C483.062,403.541,479.75,397.792,474.166,396.25z M438.312,402.938c-4.125,4.125-10.771,4.104-14.875,0
			c-4.062-4.062-4.104-10.729,0-14.854c4.104-4.083,10.771-4.083,14.875,0C442.375,392.188,442.375,398.875,438.312,402.938z
			M118.938,342.5l-11.875,3.188c-1.104-1.5-2.25-3.021-3.646-4.438c-1.416-1.375-2.916-2.562-4.479-3.625l3.188-11.938
			c1.5-5.604-1.834-11.375-7.375-12.854c-5.625-1.5-11.375,1.834-12.875,7.417l-3.25,12.062c-3.812,0.312-7.458,1.25-10.938,2.896
			l-8.812-8.771c-4.125-4.125-10.75-4.104-14.834,0c-4.104,4.104-4.104,10.75,0,14.854l8.771,8.771
			c-1.646,3.5-2.604,7.188-2.896,10.979l-12.042,3.208c-5.625,1.542-8.959,7.25-7.458,12.875c1.521,5.583,7.271,8.875,12.896,7.417
			l11.875-3.23c1.062,1.604,2.25,3.105,3.688,4.501c1.375,1.375,2.875,2.604,4.438,3.625l-3.188,11.896
			c-1.5,5.625,1.834,11.417,7.416,12.917c5.626,1.5,11.334-1.833,12.834-7.438l3.25-12c3.812-0.312,7.5-1.271,11-2.938l8.791,8.792
			c4.084,4.124,10.709,4.041,14.834-0.042c4.062-4.062,4.125-10.708,0-14.812l-8.812-8.812c1.688-3.46,2.688-7.188,2.938-11.001
			l12.062-3.188c5.562-1.521,8.896-7.25,7.396-12.875C130.334,344.376,124.584,341.042,118.938,342.5z M88.562,370.958
			c-4.104,4.104-10.75,4.104-14.875,0c-4.062-4.083-4.062-10.771,0-14.833c4.125-4.083,10.771-4.083,14.875,0
			S92.625,366.875,88.562,370.958z M512,176c0-61.75-50.25-112-112-112c-5.312,0-10.562,0.375-15.792,1.125
			C354.334,24.417,307.188,0,256,0s-98.312,24.417-128.208,65.125C122.562,64.375,117.312,64,112,64C50.25,64,0,114.25,0,176
			s50.25,112,112,112c13.688,0,27.084-2.5,39.709-7.333C180.666,305.917,217.5,320,256,320c38.542,0,75.333-14.083,104.291-39.333
			C372.916,285.5,386.312,288,400,288C461.75,288,512,237.75,512,176z M354.062,241.333C330.584,269.625,295.624,288,256,288
			c-39.625,0-74.584-18.375-98.062-46.667C144.938,250.5,129.125,256,112,256c-44.188,0-80-35.812-80-80s35.812-80,80-80
			c10.812,0,21.062,2.208,30.438,6.083C163.667,60.667,206.291,32,256,32s92.334,28.667,113.541,70.083
			C378.938,98.208,389.209,96,400,96c44.188,0,80,35.812,80,80s-35.812,80-80,80C382.875,256,367.084,250.5,354.062,241.333z"/>
		</svg>
	);
}

function getThunderIcon(description) {
	return (
		<svg aria-labelledby='More clouds' role='img' viewBox="0 0 512 512" width='100%' height='100%' xmlns="http://www.w3.org/2000/svg">
			<title>{description}</title>
			<path d="M512,134.833c0-49.146-40-89.124-89.167-89.124c-2.396,0-4.771,0.083-7.146,0.27
	C392.562,17.146,357.709,0,320,0c-37.688,0-72.562,17.146-95.688,45.979c-2.375-0.187-4.771-0.27-7.188-0.27
	C168,45.709,128,85.688,128,134.833c0,5.146,0.688,10.125,1.5,15.042c-0.542,0.708-1.188,1.354-1.708,2.083
	c-5.229-0.75-10.479-1.125-15.792-1.125c-61.75,0-112,50.25-112,112c0,61.751,50.25,112,112,112c13.688,0,27.084-2.499,39.709-7.333
	c18.354,16,40.104,26.938,63.207,33.208L192,416l32,32l-32,64l96-64l-32-32l4.812-9.583c36.75-1.125,71.75-14.729,99.479-38.917
	c12.625,4.834,26.021,7.333,39.709,7.333c61.75,0,112-50.249,112-112c0-25.666-9.042-49.083-23.666-68
	C502.916,178.958,512,158.021,512,134.833z M400,342.833c-17.125,0-32.916-5.5-45.938-14.646c-19,22.896-45.75,38.729-76.229,44.125
	l-23.771,2.312C215.25,374,181,356,157.938,328.188c-13,9.146-28.812,14.646-45.938,14.646c-44.188,0-80-35.791-80-80
	c0-44.167,35.812-80,80-80c10.812,0,21.062,2.209,30.438,6.083c0.562-1.083,1.25-2,1.812-3.042
	c5.625-10.271,12.562-19.667,20.666-28.042c23.084-23.896,55.167-39,91.084-39c49.709,0,92.334,28.667,113.541,70.083
	c5.646-2.333,11.668-3.833,17.875-4.833l12.584-1.25c12.334,0,23.875,3.042,34.312,8c11.312,5.417,21.021,13.375,28.646,23.208
	c10.5,13.521,17.042,30.334,17.042,48.792C480,307.042,444.188,342.833,400,342.833z M465.562,172.458
	c-18.479-13.438-41-21.625-65.562-21.625c-5.312,0-10.562,0.375-15.792,1.125C354.334,111.271,307.188,86.833,256,86.833
	c-34.125,0-66.312,11.062-92.938,30.354c7.479-22.853,28.729-39.478,54.062-39.478c7.75,0,15.062,1.562,21.75,4.333
	C254.062,52.458,284.5,32,320,32s65.958,20.458,81.084,50.042c6.729-2.771,14.083-4.333,21.749-4.333
	c31.584,0,57.167,25.583,57.167,57.124C480,149.333,474.438,162.396,465.562,172.458z"/>
		</svg>
	);
}

export function getSunriseIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="24 24 52 52">
			<g>
				<path fillRule="evenodd" clipRule="evenodd" d="M38.688,41.859l-2.828-2.828c-0.781-0.78-2.047-0.78-2.828,0
			c-0.781,0.781-0.781,2.047,0,2.828l2.828,2.828c0.781,0.781,2.047,0.781,2.828,0C39.469,43.906,39.469,42.641,38.688,41.859z
			M71.997,54h-3.999c-1.104,0-1.999,0.896-1.999,2s0.895,2,1.999,2h3.999c1.105,0,2-0.896,2-2S73.103,54,71.997,54z M32.003,54h-4
			c-1.104,0-2,0.896-2,2s0.896,2,2,2h4c1.104,0,2-0.896,2-2S33.106,54,32.003,54z M59.999,63.999H40.001
			c-1.104,0-1.999,0.896-1.999,2s0.896,1.999,1.999,1.999h19.998c1.104,0,2-0.895,2-1.999S61.104,63.999,59.999,63.999z
			M66.969,39.031c-0.78-0.78-2.048-0.78-2.828,0l-2.828,2.828c-0.78,0.781-0.78,2.047,0,2.828c0.781,0.781,2.048,0.781,2.828,0
			l2.828-2.828C67.749,41.078,67.749,39.812,66.969,39.031z M50.001,40.002c1.104,0,1.999-0.896,1.999-2v-3.999
			c0-1.104-0.896-2-1.999-2c-1.105,0-2,0.896-2,2v3.999C48.001,39.106,48.896,40.002,50.001,40.002z M50.001,44.002
			c-6.627,0-11.999,5.371-11.999,11.998c0,1.404,0.254,2.747,0.697,3.999h4.381c-0.683-1.177-1.079-2.54-1.079-3.999
			c0-4.418,3.582-7.999,8-7.999c4.417,0,7.998,3.581,7.998,7.999c0,1.459-0.396,2.822-1.078,3.999h4.381
			c0.443-1.252,0.697-2.595,0.697-3.999C61.999,49.373,56.627,44.002,50.001,44.002z M50.001,60.249c0.552,0,0.999-0.447,0.999-1
			v-3.827l2.536,2.535c0.39,0.391,1.023,0.391,1.414,0c0.39-0.391,0.39-1.023,0-1.414l-4.242-4.242
			c-0.391-0.391-1.024-0.391-1.414,0l-4.242,4.242c-0.391,0.391-0.391,1.023,0,1.414s1.023,0.391,1.414,0l2.535-2.535v3.827
			C49.001,59.802,49.448,60.249,50.001,60.249z"/>
			</g>
		</svg>
	);
}

export function getWeatherIcon(code, description, day) {
	if (code >= 300 && code < 400)
		code = 300;  // All Drizzle
	if (code === 520)
		code = 500;  // TODO: light shower
	if (code > 520 && code < 600)
		code = 501;  // All rain
	if (code === 620)
		code = 600;  // Light snow
	if (code >= 601 && code < 700)
		code = 601;  // All snow
	if (code >= 200 && code < 300)
		code = 200;  // All thunderstorms

	switch (code) {
		case 801: return getFewCloudsIcon(day, description);
		case 802: return getMoreCloudsIcon(description);
		case 803:
		case 804: 
			return getCloudsIcon(description);

		case 300:
		case 500:
			return getLightRainIcon(description);

		case 501:
			return getRainIcon(description);
		
		case 600:
			return getLightSnowIcon(description);
		case 601:
			return getSnowIcon(description);

		case 200:
			return getThunderIcon(description);

		case 800: 
		default:
			return getClearIcon(day, description);			
	}
}