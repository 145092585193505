// @ts-check

import PubSub from 'pubsub-js';

import { fetchJson, cacheInvalidateSpot, cacheInvalidateSpots, cacheInvalidatePhoto, cacheInvalidateAll, cacheInvalidate, cacheAddToPoints, saveToCache } from './tools';
import Navigation from './navigation';
import firebase from './tools/firebase';

// cacheInvalidateAll();

var auth;
var currUser;
const avatarCache = {};

const CDN_MINI = 'https://tcdn.phoide.com/';
const CDN_IMAGES = 'https://cdn.phoide.com/';

var downloadPreviews = [];
var previewPromise = null;
var previewsInProgress = 0;

class Server {
	static fetchJson = (path, options) => {
		// Prepare Options
		options = options || {};
		if (auth) { // Send authenticated requests
			options.headers = options.headers || new Headers({});
			options.headers.append('Authorization', auth);
		}

		return fetchJson('/api' + path, options);
	}

	static postJson = (path, json, options) => {
		options = options || {};
		options.method = options.method || 'POST';
		options.headers = new Headers({
			'Content-Type': 'application/json'
		});
		options.body = JSON.stringify(json);
		return Server.fetchJson(path, options);
	}

	static deleteJson = (path, json, options) => {
		options = options || {};
		options.method = options.method || 'DELETE';
		return Server.postJson(path, json, options);
	}

	// Login
	static setAuth = (anAuth) => {
		auth = anAuth;
		if (!auth) {
			currUser = null;
			PubSub.publishSync('USER_CHANGE', currUser);
		}
	}

	static async authF(idToken) {
		const res = await Server.postJson('/authF', { token: idToken });
		if (res && res.auth) {
			auth = res.auth;
			currUser = res.user;
			PubSub.publish('USER_CHANGE', currUser);
		}
		return res;
	}

	static logout() {
		Server.postJson('/logout', {});
		Server.setAuth(null);
		firebase.getAuth().then(auth => {
			auth().signOut();
		});
	}

	static getUser = (idUser) => {
		return new Promise((resolve, reject) => {
			// if (!idUser && !auth)
			// 	return resolve(null);
			// return reject('Not a logged user'); // The user isn't logged in, no need to access the server

			Server.fetchJson(`/user/${idUser ? idUser : ''}`, { cache: Boolean(idUser) || false }).then(user => {
				if (!idUser) {
					currUser = user;
					PubSub.publish('USER_CHANGE', currUser);
				}
				resolve(user);
			}).catch(err => {
				reject(err);
			});
		});
	}

	static refreshLogin() {
		Server.getUser().then(user => {
			if (!user) { // User isn't logged in to Phoide
				firebase.getAuth().then(auth => { // Check out whether Firebase still has an active login
					auth().onAuthStateChanged(user => {
						user && user.getIdToken().then(idToken => {
							if (!Server.getLoggedUser()) // User still isn't logged in to Phoide, let's authenticate him using Firebase token
								Server.authF(idToken);
						});
					});
				});
			}
		}); // To force retrieval of the user object
	}

	static getUserByName = (username) => {
		return Server.fetchJson(`/username/${username}`);
	}

	static isUserLogged = (idUser) => {
		return (currUser && currUser.id === idUser);
	}

	static getLoggedUser() {
		return currUser;
	}

	static setLoggegUser(user) {
		currUser = user;
	}

	static async remoteAuth(provider, data) {
		const res = await Server.postJson('/remAuth', {
			provider,
			...data
		});

		if (res.token) {
			// Successful login
			Server.setAuth(res.token);
			PubSub.publish('USER_CHANGE', res.user);
			currUser = res.user;
			return true;
		}

		return false;
	}

	static getAvatarURL = (user) => {
		return new Promise((resolve, reject) => {
			if (!user)
				return reject();
			if (user.picture)
				return resolve(user.picture);

			// TODO: The following code shouldn't be needed soon, as we get all the pictures URLs directly from our server
			switch (user.providerType) {
				case 2: // Facebook
					// TODO: This should need access token soon, according to https://developers.facebook.com/docs/graph-api/reference/user/picture
					// But since we go through Firebase, there's probably no need to update it here
					resolve(`https://graph.facebook.com/${user.providerID}/picture?type=square`);
					break;
				case 3: // Google
					if (avatarCache[user.id])
						return resolve(avatarCache[user.id]);

					// This version uses Google People API (previously Google+ API is being deprecated)
					fetch(`https://people.googleapis.com/v1/people/${user.providerID}?personFields=photos&key=AIzaSyDwZS9e30HAMp8APy5WaxLQSzlJyUrzijE`).then(result => {
						try {
							if (!result.ok)
								reject(`Error: ${result.status}: ${result.statusText}`);
							else
								return result.json();
						} catch (e) {
							reject(e);
						}
					}).then(json => {
						if (json && json.photos && json.photos[0] && json.photos[0].url) {
							avatarCache[user.id] = json.photos[0].url;
							resolve(json.photos[0].url);
						} else
							reject();
					});

					// Picasa based version (no need for our API key) -- it's deprecated now
					// fetch(`https://picasaweb.google.com/data/entry/api/user/${user.providerID}?alt=json`).then(result => {
					// 	try {
					// 		if (!result.ok)
					// 			reject(`Error: ${result.status}: ${result.statusText}`);
					// 		else
					// 			return result.json();
					// 	} catch (e) {
					// 		reject(e);
					// 	}
					// }).then(json => {
					// 	if (json && json.entry && json.entry.gphoto$thumbnail && json.entry.gphoto$thumbnail.$t) {
					// 		const res = json.entry.gphoto$thumbnail.$t;
					// 		avatarCache[user.id] = res;
					// 		resolve(res);
					// 	} else
					// 		reject();
					// });

					break;
				case 4: // Twitter
					resolve(`https://avatars.io/twitter/${user.providerID}/small`);
					break;
				default:
					reject('Not implemented.');
			}
		});
	}

	static checkLogin = (element, reason) => {
		if (!currUser) {
			PubSub.publish('USER_NOT_LOGGED', { element: element, reason: reason });
			return false;
		}

		return true;
	}

	static saveProfile = (user) => {
		cacheInvalidate(`/user/${user.id}`);
		cacheInvalidate('/user/');

		return Server.postJson('/user', {
			id: user.id,
			displayName: user.displayName,
			about: user.about,
			emailNews: user.emailNews,
			username: user.username,
		});
	}

	static getUserStats = (idUser) => {
		return Server.fetchJson(`/user/${idUser}/stats`);
	}

	static getUsernameStats = (username) => {
		return Server.fetchJson(`/username/${username}/stats`);
	}

	// API
	static getPoints = () => {
		return new Promise((resolve, reject) => {
			// Server.fetchJson('/points', { cache: true }).then(points => {
			Server.fetchJson('/pspots/1/1/1', { cache: true }).then(points => {
				resolve(points);
			}).catch(err => {
				reject(err);
			});
		});
	}

	static getSpots = (z, x, y) => {
		return new Promise((resolve, reject) => {
			// Server.fetchJson('/points', { cache: true }).then(points => {
			Server.fetchJson(`/pspots/${z}/${x}/${y}`, { cache: true }).then(points => {
				resolve(points);
			}).catch(err => {
				reject(err);
			});
		});
	}

	static getNearbySpots(lat, long) {
		return Server.fetchJson(`/near/${long}/${lat}`);
	}

	static getRange(lat, long, dLat, dLong) {
		return Server.fetchJson(`/range/${long}/${lat}/${dLong}/${dLat}`, { cache: true });
	}

	static getLatestPhotos() {
		return Server.fetchJson('/photos/latest', { cache: true });
	}

	static getRecentPhotos() {
		return Server.fetchJson('/photos/recentAll', { cache: true });
	}

	static getFavPhotos() {
		return Server.fetchJson('/photos/fav', { cache: true });
	}

	static getPopularSpots() {
		return Server.fetchJson('/pspots/popular', { cache: true });
	}

	static getPhoto(photoID) {
		return Server.fetchJson(`/photo/${photoID}`, { cache: true });
	}

	static async deletePhoto(idPhoto, idSpot) {
		const res = await Server.deleteJson(`/photo/${idPhoto}`);
		if (res.ok) {
			cacheInvalidateSpot(idSpot);
			if (currUser)
				cacheInvalidate(`/user/${currUser.id}`);
			PubSub.publish('SHOW_SNACKBAR', {
				message: 'The photograph was successfully deleted.',
				autoHide: 5000,
			});
			return true;
		} else {
			console.log('Delete image failure.');
			return false;
		}
	}

	static async fetchMiniThumb(id) {
		try {
			const result = await fetch(`${CDN_MINI}miniThumb/${id}.webp`);
			if (!result.ok)
				// reject(`Error: ${result.status}: ${result.statusText}`);
				return;
			else {
				const arr = await result.arrayBuffer();
				return 'data:image/svg+xml;base64,' + btoa('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="60px" height="60px" viewBox="7.5 2.8 15 15" xml:space="preserve">' +
					'<clipPath id="clipCircle">' +
					'<circle cx="15" cy="10.3" r="7.2"/>' +
					'</clipPath>' +
					`<image href="data:image/webp;base64,${btoa(String.fromCharCode(...new Uint8Array(arr)))}"` +
					'	height="14.5" width="14.5" x="7.8" y="3.12" clip-path="url(#clipCircle)"' +
					'	style="opacity: 1"/>' +
					'</svg>');
			}
		} catch (e) {
			console.log(`Error getting mini thumb.`);
			console.log(e);
			return;
		}
	}

	static search = (term) => {
		return Server.fetchJson(`/search/${term}`, { cache: true });
	}

	static getPoint = (id) => {
		return new Promise((resolve, reject) => {
			if (id === 0) {
				// It's a temporary point for a new location
				resolve({
					id: 0,
					title: 'New Spot',
					images: [],
					descriptions: [{
						id: -1, // A dummy description to start its editing
						rating: 0,
					}]
				});
			} else {
				Server.fetchJson(`/point/${id}`, { cache: true }).then(point => {
					PubSub.publish('POINT_UPDATED', { point: point });
					resolve(point);
				}).catch(err => {
					reject(err);
				});
			}
		});
	}

	static _lastWeatherLoading = null;

	static async getPointWeather(idSpot) {
		if (Server._lastWeatherLoading !== idSpot) {
			Server._lastWeatherLoading = idSpot;
			const weather = await Server.fetchJson(`/point/${idSpot}/weather`, { cache: true });
			PubSub.publish('WEATHER_RECEIVED', { idSpot, weather });
		}
	}

	static getPreviewsLoadingCnt() {
		return previewsInProgress;
	}

	static async getPointPreview(id, canGroup) {
		if (id <= 0)
			return null;

		const cachedSpot = await Server.fetchJson(`/point/${id}`, { cachedOnly: true });
		if (cachedSpot && (cachedSpot.image || cachedSpot.images[0])) {
			return {
				id,
				title: cachedSpot.title,
				image: cachedSpot.image || cachedSpot.images[0],
				lat: cachedSpot.lat,
				long: cachedSpot.long,
			};
		}

		if (canGroup) {
			if (downloadPreviews.indexOf(id) < 0) {
				downloadPreviews.push(id);
				previewsInProgress++;
			}

			if (!previewPromise) {
				previewPromise = new Promise((resolve) => {
					setTimeout(async () => {
						const usePreviews = downloadPreviews;
						downloadPreviews = []; // Clean to let it accumulate new requests
						previewPromise = null;
						const previews = await Server.postJson(`/pointPreviews`, usePreviews);
						previewsInProgress -= usePreviews.length;
						for (const preview of previews) {
							saveToCache(`/point/${preview.id}`, preview);
						}
						resolve(null);
					}, 20); // Accumulate all requests for 20 milliseconds
				});
			}
			await previewPromise;
			return await Server.getPointPreview(id); // now is in the cache
		} else
			return await Server.fetchJson(`/pointPreview/${id}`, { cache: true });
	}

	static movePoint = (point, longitude, latitude) => {
		cacheInvalidateSpot(point.id);

		point.newLocation = {
			lat: latitude,
			long: longitude,
		};
		PubSub.publish('POINT_UPDATED', { point: point });

		Server.postJson(`/movePoint/${point.id}`, {
			lat: latitude,
			long: longitude,
		});

		PubSub.publish('SHOW_SNACKBAR', {
			message: 'The request to move the location was recorded',
			autoHide: 5000,
		});
	}

	static async _voteGeneric(url, idSpot, element) {
		if (!Server.checkLogin(element, 'vote'))
			return false;

		cacheInvalidateSpot(idSpot);

		const res = await Server.postJson(url);
		PubSub.publish('SHOW_THANK_VOTE', null);

		return res && res.resolved;
	}

	static moveRateUp = (point, element) => {
		return Server._voteGeneric(`/point/${point.id}/moveRateUp`, point.id, element);
	}

	static moveRateDown = (point, element) => {
		return Server._voteGeneric(`/point/${point.id}/moveRateDown`, point.id, element);
	}

	static movePhoto(idPhoto, spot, newIdSpot) {
		Server.postJson(`/movePhoto/${idPhoto}`, {
			newIdSpot
		});

		cacheInvalidateSpot(spot.id);

		const updatedSpot = { ...spot };
		const photo = updatedSpot.images.find(p => String(p.id) === String(idPhoto));
		if (photo) {
			photo.newSpot = { idSpot: newIdSpot }; // To indicate that it's about to be moved
			PubSub.publish('POINT_UPDATED', { point: updatedSpot });
		}
	}

	static async movePhotoRate(idImage, spot, newIdSpot, element, up) {
		const res = await Server._voteGeneric(`/movePhoto/${idImage}/${up ? 'rateUp' : 'rateDown'}`, spot.id, element);
		if (res) {
			if (newIdSpot)
				cacheInvalidateSpot(newIdSpot);

			// To refresh the current spot
			Server.getPoint(spot.id);
		}

		return res;
	}

	static rateDescUp = (point, descID, element) => {
		if (!Server.checkLogin(element, 'vote'))
			return;

		cacheInvalidateSpot(point.id);

		Server.postJson(`/point/${point.id}/desc/${descID}/rateUp`)
			.then(json => {
				if (json.rating != null) {
					point.descriptions.forEach(desc => {
						if (desc.id === descID)
							desc.rating = json.rating;
					});
					PubSub.publish('POINT_UPDATED', { point: { ...point } });
				}
			});
		point.descriptions.forEach(desc => {
			if (desc.id === descID)
				desc.rating++;
		});
		PubSub.publish('POINT_UPDATED', { point: { ...point } });
		PubSub.publish('SHOW_THANK_VOTE', null);
	}

	static rateDescDown = (point, descID, element) => {
		if (!Server.checkLogin(element, 'vote'))
			return;

		cacheInvalidateSpot(point.id);

		Server.postJson(`/point/${point.id}/desc/${descID}/rateDown`)
			.then(json => {
				if (json.rating != null) {
					point.descriptions.forEach(desc => {
						if (desc.id === descID)
							desc.rating = json.rating;
					});
					PubSub.publish('POINT_UPDATED', { point: { ...point } });
				}
			});
		point.descriptions.forEach(desc => {
			if (desc.id === descID)
				desc.rating--;
		});
		PubSub.publish('POINT_UPDATED', { point: { ...point } });
		PubSub.publish('SHOW_THANK_VOTE', null);
	}

	static rateImgUp = (point, image, element) => {
		if (!Server.checkLogin(element, 'vote'))
			return;

		const idSpot = point ? point.id : image.idLoc;
		cacheInvalidateSpot(idSpot);
		cacheInvalidatePhoto(image.id);

		Server.postJson(`/point/${idSpot}/img/${image.id}/rateUp`)
			.then(json => {
				if (json.rating != null) {
					image.rating = json.rating;
					const update = { image: image };
					if (point)
						update.point = { ...point };
					PubSub.publish('POINT_UPDATED', update);
				}
			});

		image.rating++;
		if (point)
			PubSub.publish('POINT_UPDATED', { point: { ...point } });
		PubSub.publish('SHOW_THANK_VOTE', null);
	}

	static rateImgDown = (point, image, element) => {
		if (!Server.checkLogin(element, 'vote'))
			return;

		const idSpot = point ? point.id : image.idLoc;
		cacheInvalidateSpot(idSpot);
		cacheInvalidatePhoto(image.id);

		Server.postJson(`/point/${point ? point.id : image.idLoc}/img/${image.id}/rateDown`)
			.then(json => {
				if (json.rating != null) {
					image.rating = json.rating;
					const update = { image: image };
					if (point)
						update.point = { ...point };
					PubSub.publish('POINT_UPDATED', update);
				}
			});

		image.rating--;
		if (point)
			PubSub.publish('POINT_UPDATED', { point: { ...point } });
		PubSub.publish('SHOW_THANK_VOTE', null);
	}

	static editDescription = (point, descID, newText) => {
		cacheInvalidateSpot(point.id);

		const desc = point.descriptions.find(desc => desc.id === descID);

		if (desc) {
			if (desc.id < 0 || desc.flickr) {
				Server.addUserToNewItem(desc);
			}

			desc.flickr = undefined;
			desc.text = newText;
			if (desc.id < 0) {
				desc.id = null; // A new description
			}

			// Update or insert the description
			Server.postJson(`/point/${point.id}/desc${desc.id ? '/' + desc.id : ''}`, desc).then(json => {
				if (!desc.id)
					desc.id = json.id;
				PubSub.publish('POINT_UPDATED', { point: point });
			});

			if (!desc.id) { // A temporary not-null id (until a real ID is created by the server)
				desc.id = 0;
			}

			PubSub.publish('SHOW_SNACKBAR', {
				message: 'Thank you for the new description of this location!',
				autoHide: 5000,
			});
		}
	}

	// *** Images ***
	static uploadImage = (file) => {
		return new Promise((resolve, reject) => {
			var formData = new FormData();
			formData.append('photo', file);

			Server.fetchJson('/photo', {
				method: 'POST',
				body: formData,
			}).then(json => {
				if (json.imageID)
					resolve(json.imageID);
				else
					reject(json);
			}).catch(e => {
				reject(e);
			});
		});
	}

	static newImage = async (spot, image) => {
		cacheInvalidateSpot(spot.id);

		var newImage = {
			id: image.id,
			title: image.title,
			description: image.description,
			rating: 0,
			idSpot: spot.id,
			lat: spot.lat,
			long: spot.long,
			spotTitle: spot.title,
			spotTags: spot.tags,
			tagsDone: (spot.tags || [])._OSM && (spot.tags || [])._Overpass,
			exif: image.exif,
			imgType: image.imgType,
			author: image.author, // For linked images
			linkURL: image.linkURL, // For linked images
			linkImgURL: image.linkImgURL, // For linked images
			license: image.license,
			authorUrl: image.authorUrl,
		};

		PubSub.publish('POINT_UPDATED', { point: spot });

		PubSub.publish('SHOW_SNACKBAR', {
			message: 'Thank you for the new photograph!',
			autoHide: 5000,
		});

		const res = await Server.postJson('/img', newImage);
		if (res && res.spot) {
			if (!spot.id) {
				PubSub.publishSync('ADD_SPOT_TO_MAP', res.spot); // New spot -> add a feature to the map
				cacheAddToPoints(res.spot); // So that it's properly cached locally
			}
			PubSub.publishSync('POINT_UPDATED', { point: null }); // To force spot re-fetch from the server by then next line
			Navigation.goSpot(res.spot);
		}
	}

	static updateImage = (image, idSpot) => {
		// TODO: spot's images array should be updated to reflect the changes here
		cacheInvalidateSpot(idSpot);
		cacheInvalidatePhoto(image.id);
		// location.images = [newImage, ...location.images];

		PubSub.publish('IMAGE_UPDATED', {
			image
		});

		PubSub.publish('SHOW_SNACKBAR', {
			message: 'Changes were saved.',
			autoHide: 5000,
		});

		// Send to the server, but not for new locations, they are sent only later, when the whole Location is submitted.
		return Server.postJson(`/img/${image.id}`, image);
	}

	static getImageUrlFromID = (id) => {
		return `${CDN_IMAGES}Images/${id}.jpg`;
	}

	static getImageUrl = (image) => {
		if (image.tempURL)
			return image.tempURL;
		else if (image.linkImg)
			return Server.getImageThumb(image); // We only have thumbs for linked images
		else if (image.farm)
			return null; // Flickr
		else
			return Server.getImageUrlFromID(image.id); // Our server
	}

	static getThumbForID = (idImage) => {
		if (typeof idImage === 'string' && idImage.slice(0, 4) === 'http')
			return idImage; // It already is an URL (in case of Flickr linked thumbs for Spotlist covers)
		else if (idImage)
			return `${CDN_IMAGES}Thumbs/${idImage}.jpg`;
		else
			return undefined;
	}

	static getImageThumb = (image) => {
		if (typeof image === 'string' && image.slice(0, 4) === 'http')
			return image; // It already is an URL (in case of Flickr linked thumbs for Spotlist covers)
		else if (image.tempURL)
			return image.tempURL;
		else if (image.linkImgURL) // Temporary, until the server thumbnail is created
			return image.linkImgURL;
		else if (image.farm && image.flickr)
			return `https://farm${image.farm}.staticflickr.com/${image.server}/${image.id}_${image.secret}_n.jpg`; // Flickr
		else
			return Server.getThumbForID(image.id);
	}

	static addUserToNewItem(item) {
		item.user = currUser;
	}

	static newLocation = (location) => {
		cacheInvalidateAll();

		PubSub.publish('SHOW_SNACKBAR', {
			message: 'Thank you for the new spot!',
			autoHide: 5000,
		});

		var loc = { ...location };

		loc.images.forEach(image => {
			image.tempURL = undefined;
			Server.addUserToNewItem(image);
		});

		loc.descriptions.forEach(desc => {
			desc.id = null;
			Server.addUserToNewItem(desc);
		});

		return Server.postJson('/point', location);
	}

	// *** Tags ***
	static addTag(location, tagName) {
		cacheInvalidateSpot(location.id);
		location.tags = location.tags || [];

		if (location.tags.find(tag => tag.tag.toLowerCase() === tagName.toLowerCase()))
			return; // The tag already exists

		location.tags.push({
			tag: tagName,
			isNew: true,
		});

		PubSub.publish('POINT_UPDATED', { point: location });
	}

	static deleteTag(location, tag) {
		cacheInvalidateSpot(location.id);

		if (tag.isNew)
			location.tags = location.tags.filter(t2 => t2.tag !== tag.tag);
		else {
			if (tag.toDelete)
				delete tag.toDelete;
			else {
				tag.toDelete = true;
			}
		}

		PubSub.publish('POINT_UPDATED', { point: location });

		if (tag.toDelete) {
			PubSub.publish('SHOW_SNACKBAR', {
				message: 'Tag was marked to be deleted (subject to approval by other photographers)',
				autoHide: 10000,
				action: {
					title: 'Undo',
					fn: () => {
						Server.deleteTag(location, tag); // To undo the deletion
					}
				}
			});
		}
	}

	static async submitTags(location) {
		cacheInvalidateSpot(location.id);

		const changes = [];

		for (const tag of (location.tags || [])) { // eslint-disable-line no-unused-vars
			if (tag.isNew) {
				changes.push(tag);
			} else {
				if (tag.toDelete)
					changes.push(tag);
			}
		}

		if (changes.length > 0) {
			const newTags = await Server.postJson(`/point/${location.id}/editTags`, changes);
			if (Array.isArray(newTags)) {
				location.tags = newTags; // eslint-disable-line require-atomic-updates
				PubSub.publish('POINT_UPDATED', { point: location });
			}
		}
	}

	static async rateTag(location, tagName, value) {
		cacheInvalidateSpot(location.id);

		Server.postJson(`/point/${location.id}/rateTag`, {
			tag: tagName,
			value: value,
		});

		PubSub.publish('SHOW_THANK_VOTE', null);
	}

	static async getTag(tagName) {
		return Server.fetchJson(`/tag/${tagName}`, { cache: true });
	}

	static async getTagSpots(idTag) {
		return Server.fetchJson(`/tagSpots/${idTag}`, { cache: true });
	}

	static async getTagPhotos(idTag) {
		return Server.fetchJson(`/tagPhotos/${idTag}`, { cache: true });
	}

	static async updateTag(idTag, tagName, newData) {
		cacheInvalidate(`/tag/${tagName}`);
		return Server.postJson(`/tag/update`, { idTag, newData });
	}

	static async updateTagMapRect(idTag, tagName, rect) {
		cacheInvalidate(`/tag/${tagName}`);
		return Server.postJson(`/tag/updateMapRect`, { idTag, rect });
	}

	static async deleteTagAdmin(tagName) {
		cacheInvalidate(`/tag/${tagName}`);
		return Server.deleteJson(`/tag/${tagName}`);
	}

	// *** Guides ***

	static async getGuide(guideName) {
		return Server.fetchJson(`/guide/${guideName}`, { cache: true });
	}

	static async saveGuide(guide) {
		PubSub.publish('GUIDE_UPDATE', { guide: guide }); // To perform the update even before the DB commmit
		const newGuide = await Server.postJson(`/guide/`, guide);

		if (newGuide) {
			cacheInvalidate(`/tag/${guide.tag}`);
			cacheInvalidate(`/guide/${guide.perma}`);
			PubSub.publish('GUIDE_UPDATE', { guide: newGuide }); // To refresh based on DB data

			if (guide.perma !== newGuide.perma) {
				Navigation.openGuide(newGuide.perma);
			}
		}

		return newGuide;
	}

	static async rateGuide(guide, up, element, tag) {
		if (!Server.checkLogin(element, 'vote'))
			return false;

		cacheInvalidate(`/tag/${tag.name}`);

		const inc = up ? 1 : -1;
		guide.rating += inc;
		PubSub.publish('GUIDE_UPDATE', { guide: { ...guide } }); // To update rating immediatelly

		const res = await Server.postJson(`/guide/${guide.id}/rate/${inc}`);
		if (res && res.rating >= 0) {
			guide.rating = res.rating; // eslint-disable-line require-atomic-updates
			PubSub.publish('GUIDE_UPDATE', { guide: { ...guide } }); // To update rating immediatelly
		}

		return res;
	}


	// *** Spotlists ***
	static async getSpotlist(username, spotlist) {
		return Server.fetchJson(`/spotlist/${spotlist}/${username}`, { cache: true });
	}

	static async getUserSpotlists(user) {
		if (!user)
			user = currUser;

		return Server.fetchJson(`/spotlist/${user.username}`, { cache: true });
	}

	static async createSpotlist(title, addIdSpot, idImg) {
		cacheInvalidate(`/spotlist/${currUser.username}`);

		return await Server.postJson('/spotlist/new', {
			title: title,
			addIdSpot,
			idImg
		});
	}

	static async addToSpotlist(idSpotlist, spotlist, idSpot, idImg) {
		cacheInvalidate(`/spotlist/${spotlist}/${currUser.username}`);
		cacheInvalidate(`/spotlist/${currUser.username}`); // To reload spotlist title images, if changed

		return Server.postJson('/spotlist', {
			idSpotlist,
			idSpot,
			idImg,
		});
	}

	static async addPhotoToSpotlist(idSpotlist, spotlist, idPhoto) {
		cacheInvalidate(`/spotlist/${spotlist}/${currUser.username}`);
		cacheInvalidate(`/spotlist/${currUser.username}`); // To reload spotlist title images, if changed

		return Server.postJson('/spotlistPhoto', {
			idSpotlist,
			idPhoto
		});
	}

	static async removeFromSpotlist(idSpotlist, spotlist, idSpot) {
		cacheInvalidate(`/spotlist/${spotlist}/${currUser.username}`);

		return Server.deleteJson('/spotlist', {
			idSpotlist,
			idSpot,
		});
	}

	static async removePhotoFromSpotlist(idSpotlist, spotlist, idPhoto) {
		cacheInvalidate(`/spotlist/${spotlist}/${currUser.username}`);

		return Server.deleteJson('/spotlistPhoto', {
			idSpotlist,
			idPhoto,
		});
	}

	static async getUserSpotSpotlists(idSpot) {
		return Server.fetchJson(`/spotLists/${idSpot}`);
	}

	static async getUserPhotoSpotlists(idPhoto) {
		return Server.fetchJson(`/spotListsPhoto/${idPhoto}`);
	}

	static async saveSpotlist(spotlist) {
		cacheInvalidate(`/spotlist/${currUser.username}`);
		cacheInvalidate(`/spotlist/${spotlist.urlTitle}/${currUser.username}`);

		return Server.postJson('/spotlist/update', spotlist);
	}

	static async deleteSpotlist(idSpotlist) {
		cacheInvalidate(`/spotlist/${currUser.username}`);

		return Server.postJson('/spotlist/update', {
			id: idSpotlist,
			delete: true,
		});
	}

	// *** Title Edit ***
	static async editTitle(spot, newTitle) {
		PubSub.publish('POINT_UPDATED', { point: { ...spot, newTitle: { title: newTitle } } });
		cacheInvalidateSpot(spot.id);
		const res = await Server.postJson(`/editTitle/${spot.id}`, { newTitle });
		if (res.ok) { // The spot was renamed immediately
			// To refresh the current spot
			const newSpot = { ...spot };
			newSpot.title = res.newSpot.title;
			delete newSpot.newTitle;
			PubSub.publish('POINT_UPDATED', { point: newSpot });
		}
	}

	static async changeTitleRate(spot, up) {
		const res = await Server.postJson(`/editTitle/${spot.id}/${up ? 'rateUp' : 'rateDown'}`);
		if (res.newTitle) {
			cacheInvalidateSpot(spot.id);

			// To refresh the current spot
			const newSpot = { ...spot };
			newSpot.title = res.newTitle;
			delete newSpot.newTitle;
			PubSub.publish('POINT_UPDATED', { point: newSpot });

			return true;
		} else
			return false;
	}

	// http images can't be loaded to our https page, use a proxy instead
	static fixHTTPImage(url) {
		// console.log(url);
		if (url.startsWith('http:') || url.indexOf('instagram') >= 0) {
			return 'https://images.weserv.nl/?url=' + encodeURIComponent(url);
		}
		return url;
	}

	static fixHTTPImages(images) {
		if (images) {
			for (var img of images) {
				img.url = Server.fixHTTPImage(img.url);
			}
		}
		return images;
	}

	// *** Flickr import ***
	static getImport() {
		return Server.fetchJson('/flickrImage');
	}

	static getImports() {
		return Server.fetchJson('/flickrImages');
	}

	static importSkip() {
		return Server.postJson('/flickrImage', { skip: true });
	}

	static importIgnore() {
		return Server.postJson('/flickrImage', { ignore: true });
	}

	static importIgnoreAll(preserve) {
		return Server.postJson('/flickrImage', { ignoreAll: true, preserve: preserve });
	}

	static importAsSpot(spot, idSpot) {
		if (idSpot)
			return Server.postJson('/flickrImage', { import: idSpot, tags: spot.tags });
		else
			return Server.postJson('/flickrImage', { importNew: true, tags: spot.tags, title: spot.title, lat: spot.lat, long: spot.long });
	}

	// 3D stuff
	static submit3DAdjust(lat, lng, headingAdj, pitchAdj) {
		Server.postJson('/adjust3D', {
			lat, lng, headingAdj, pitchAdj,
		});

		PubSub.publish('SHOW_SNACKBAR', {
			message: 'Thank you for the correction!',
			autoHide: 5000,
		});
	}

	static async get3DAdjust(lat, lng) {
		return await Server.fetchJson(`/adjust3D/${lat}/${lng}`);
	}

	static set3DView(spot, lat, lng, heading, pitch, fov) {
		Server.postJson('/set3DView', {
			idSpot: spot.id, lat, lng, heading, pitch, fov
		});

		cacheInvalidateSpot(spot.id);

		PubSub.publish('SHOW_SNACKBAR', {
			message: 'Thank you for the submission!',
			autoHide: 5000,
		});
	}

	static fixElevation(spot, elevation) {
		spot.elev = elevation;
		Server.postJson(`/elevation/${spot.id}`, { elevation });

		cacheInvalidateSpot(spot.id);
		cacheInvalidateSpots(spot.nearby); // To reload this spot's elevation when going to nearby spots

		PubSub.publish('SHOW_SNACKBAR', {
			message: 'Thank you for the submission!',
			autoHide: 5000,
		});
	}

	// *** Tools ***
	static async getURLImages(url) {
		return Server.postJson('/tools/urlImages', { url });
	}
}

export default Server;