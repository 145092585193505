import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Route } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Logo from '../logo';
import { mainNav, subNav } from '../navUI.js';
import Navigation from '../navigation';

const styles = theme => ({
	drawerPermanent: {
		position: 'relative',
		width: 0,
		height: '100%',
		transition: '0.3s ease',
	},
	drawerPaper: {
		position: 'inherit',
	},
	list: {
		width: 250,
	},
	logoItem: {
		opacity: 0.65,
		width: 100,
		marginLeft: 'auto',
		marginRight: 'auto',
		marginBottom: -6,
	},

	topLevel: {
		textTransform: 'uppercase',
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
	highlight: {
		color: theme.palette.secondary.main,
	}
});

class MainDrawer extends React.Component {
	state = {
	};

	sectionClick = (e) => {
		const section = 'open' + e.currentTarget.dataset.id;
		const newState = {};
		newState[section] = !this.state[section];
		this.setState(newState);
	}

	goHome = () => {
		Navigation.goHome();
		this.props.onClose();
	}

	goMenu = (e) => {
		// if (checkKeyMods(e)) {
		const data = e.currentTarget.dataset;
		Navigation.goPath(data.url || `/${data.pageid}/${data.id}`);
		this.props.onClose();
		// }
	}

	render() {
		const { classes } = this.props;

		return (
			<Route path='/:page?/:subpage?' render={({ match }) => (
				<Drawer
					open={this.props.open}
					onClose={this.props.onClose}
					variant='temporary'
				>
					<List
						component='nav'
						className={classes.list}
					>
						<ListItem button onClick={this.goHome}>
							<div className={classes.logoItem}>
								<Logo />
							</div>
						</ListItem>
						{mainNav.map(item => {
							const nav = subNav[item.id];
							const open = this.state['open' + item.id];
							var page = match.params.page;
							var subpage = match.params.subpage;
							if (page === 'map') { // Hardcoded map page selection
								page = 'explore';
								subpage = 'map';
							}
							return (
								<React.Fragment key={item.id}>
									<ListItem button key={item.id} onClick={this.sectionClick} data-id={item.id} className={classes.topLevel}									>
										<ListItemText primary={item.title} className={page === item.id ? classes.highlight : ''} />
										{open ? <ExpandLess /> : <ExpandMore />}
									</ListItem>
									<Collapse in={open} timeout='auto' unmountOnExit>
										{nav && nav.map((subitem, index) => {
											if (subitem.id) {
												return (
													<ListItem
														button
														key={subitem.id}
														selected={subpage === subitem.id}
														data-id={subitem.id}
														data-pageid={item.id}
														data-url={subitem.url}
														onClick={this.goMenu}
														className={classes.nested}
													// component='a'
													// href={`/${page}/${item.id}`}
													>
														<ListItemText primary={subitem.title} />
													</ListItem>
												);
											}
											else
												return (
													<Divider key={'d' + index} />
												);
										})}
									</Collapse>
								</React.Fragment>
							);
						})
						}
					</List>
				</Drawer>
			)} />
		);
	}
}

MainDrawer.propTypes = {
	classes: PropTypes.object.isRequired,
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
};

/** @type {any} */
export default withStyles(styles)(MainDrawer);
