// @ts-check

import React from 'react';
import PropTypes from 'prop-types';

import { withCookies } from 'react-cookie';
import Navigation from './navigation';
import Server from './server.js';

import PubSub from 'pubsub-js';
import { shallowEqual } from './tools';

var initDataUsed = false;

class NavRoute extends React.Component {
	state = {
		/** @type{any} */
		point: null,
		/** @type{any} */
		tagData: null,
		/** @type{any} */
		photoData: null,
		/** @type{any} */
		guideData: null,
		range: null,
		filter: null,

		fixPosition: false,
		fixPhoto: null,
		searchResults: null,
		currentUser: null,
	}
	/** @type{any} */
	_point = null;
	/** @type{string} */
	_onPointUpdated;
	/** @type{string} */
	_onWeatherUpdated;
	/** @type{string} */
	_onImageUpdated;
	/** @type{string} */
	_onUserChange;
	/** @type{string} */
	_onGuideUpdate;

	constructor(props) {
		super(props);
		Navigation.init(this.props.location, this.props.history, this.props.cookies);

		// @ts-ignore (__INITIAL__DATA__ is our own object created by the server-side rendering)
		const initData = window.__INITIAL__DATA__;

		if (initData.currentUser)
			Server.setLoggegUser(initData.currentUser);

		Object.assign(this.state, this.state, initData);
		// if (!initData.currentUser)
		Server.refreshLogin();
	}

	componentDidMount() {
		this.update();

		this._onPointUpdated = PubSub.subscribe('POINT_UPDATED', (mgs, data) => {
			const newData = data.point;
			this._point = newData;
			this.setState({ point: newData });
			if (data.image && this.state.photoData && data.image.id === this.state.photoData.id)
				this.setState({ photoData: { ...data.image } }); // force photoData refresh, as there might be some changes
		});

		this._onWeatherUpdated = PubSub.subscribe('WEATHER_RECEIVED', (mgs, data) => {
			const spot = this.state.point;
			if (spot && spot.id === data.idSpot) {
				this.setState({ weather: { idSpot: spot.id, ...data.weather } });
			}
		});

		this._onImageUpdated = PubSub.subscribe('IMAGE_UPDATED', (mgs, data) => {
			if (data.image && this.state.photoData && data.image.id === this.state.photoData.id)
				this.setState({ photoData: { ...data.image } }); // force photoData refresh, as there might be some changes
		});

		this._onUserChange = PubSub.subscribe('USER_CHANGE', (mgs, data) => {
			this.setState({ currentUser: data });
		});

		this._onGuideUpdate = PubSub.subscribe('GUIDE_UPDATE', (mgs, data) => {
			this.updateGuide(data.guide);
		});

		this._onRangeChange = PubSub.subscribe('RANGE_CHANGE', (mgs, data) => {
			this.setState({ range: data });
		});

		this.setState({
			range: Navigation.getRange(),
		});
	}

	componentWillUnmount() {
		PubSub.unsubscribe(this._onPointUpdated);
		PubSub.unsubscribe(this._onWeatherUpdated);
		PubSub.unsubscribe(this._onImageUpdated);
		PubSub.unsubscribe(this._onUserChange);
		PubSub.unsubscribe(this._onGuideUpdate);
	}

	updateGuide(guide) {
		this.setState({ guideData: guide });
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) { // This compares URL
			Navigation.update(this.props.location, this.props.history, this.props.cookies);
			this.update();
		}
	}

	async updatePoint(pointID) {
		// console.log('NavRoute: Update Point');
		var point;
		try {
			point = await Server.getPoint(pointID);
		} catch (e) {
			console.log(`Error getting point: ${e}`); // eslint-disable-line
			Navigation.goHome();
		}

		this.setState({ point: point });
		this._point = point;
	}

	async update() {
		// Spot
		const pointID = Navigation.getPointID();
		if (pointID === null) {
			if (this.state.point !== null) {
				this._point = null;
				this.setState({ point: null });
			}
		} else {
			if (!this._point || Number(this._point.id) !== Number(pointID)) {
				// @ts-ignore (__INITIAL__DATA__ is our own object created by the server-side rendering)
				const initData = window.__INITIAL__DATA__;
				if (initData.point && Number(initData.point.id) === Number(pointID) && !initDataUsed) {
					// If we already got the point data from server (and aren't old)
					initDataUsed = true;
					this.setState({ point: initData.point });
					this._point = initData.point;
					return;
				}

				this._point = {
					id: pointID,
				};
				this.setState({
					point: this._point
				});

				this.updatePoint(pointID);
			}
		}

		// Others
		this.setState({
			fixPosition: Navigation.getFixMapPosition(),
			fixPhoto: Navigation.getFixPhotoSpot(),
			showGPS: Navigation.getShowGPS(),
			currentUser: Server.getLoggedUser(),
		});

		// Search
		const searchTerm = Navigation.getSearch();
		if (searchTerm) {
			this.setState({
				searchString: searchTerm,
				searchResults: null,
			});
			Server.search(searchTerm).then(searchRes => this.setState({ searchResults: searchRes }));
		} else {
			this.setState({
				searchString: null,
				searchResults: null
			});
		}

		// Tag
		var tagName = Navigation.getCurrentTag();
		if ((tagName || '').replace(/_/g, ' ') !== ((this.state.tagData || {}).name || '')) {
			if (tagName) {
				tagName = tagName.replace(/_/g, ' ');
				const tagData = await Server.getTag(tagName);
				this.setState({ tagData });
			} else {
				this.setState({ tagData: null });
			}
		}

		// Photo
		const photoID = Navigation.getPhotoID();
		if (photoID) {
			if (!this.state.photoData || this.state.photoData.photoID !== photoID) {
				// Set photoID, so that it can start loading
				this.setState({ photoData: { id: photoID } });
				// ... and get other info from the server on the background
				const photoData = await Server.getPhoto(photoID);
				this.setState({ photoData });
			}
		} else
			this.setState({ photoData: null });

		// Guide
		const guide = Navigation.getCurrentGuide();
		if (guide) {
			if (guide.name) {
				this.setState({ guideData: { title: guide.name.replace(/_/g, ' ') } });
				const guideData = await Server.getGuide(guide.name);
				this.setState({ guideData });
			} else
				this.setState({ guideData: { id: -1, tag: guide.tag } });
		} else {
			this.setState({ guideData: null });
		}

		if (!shallowEqual(Navigation.getFilter(), this.state.filter)) {
			this.setState({ filter: Navigation.getFilter() });
		}
	}

	getSpot() {
		var res = this.state.point;
		const pointID = Navigation.getPointID();
		if (!res && pointID) {
			res = {
				id: pointID,
			};
		}

		// console.log('Nav route');
		// console.log(res);

		const weather = this.state.weather;
		if (weather && res && weather.idSpot === res.id)
			return { ...res, weather };
		else
			return res;
	}

	render() {
		const s = this.state;

		// @ts-ignore
		return React.cloneElement(this.props.children, {
			...this.props,
			point: this.getSpot(),
			region: s.range,
			editDescID: Navigation.getEditDescID(),
			editGuideID: Navigation.getEditGuideID(),
			fixPosition: s.fixPosition || Navigation.isImport(),
			fixPhoto: s.fixPhoto,
			showGPS: s.showGPS,
			searchResults: s.searchResults,
			searchString: s.searchString,
			largeMap: Navigation.isLargeMap(),
			filter: s.filter,
			user: s.currentUser,
			tagData: s.tagData,
			photoData: s.photoData,
			guideData: s.guideData,
		});
	}
}

NavRoute.propTypes = {
	children: PropTypes.element.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	cookies: PropTypes.object,
};

function Exp() {
	if (!process.env.REACT_APP_SERVER_SIDE)
		return withCookies(NavRoute);
	else
		return NavRoute;
}

export default Exp();