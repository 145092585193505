import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

import Navigation from '../navigation';
import { checkKeyMods } from '../tools';

const styles = theme => ({
	edittag: {
		margin: theme.spacing(0.25),

		'&:focus': {
			backgroundColor: theme.palette.primary.main,
		}
	},
	tag: {
		// extend: theme.typography.body2, // Todo: Use this, when we can use 'extend' (see https://github.com/mui-org/material-ui/issues/9342)
		marginRight: theme.spacing(1.5),
		cursor: 'pointer',
		color: theme.palette.primary.main,

		fontSize: 12,
		fontWeight: 400,
		lineHeight: 2,
		transition: 'all 0.3s ease',

		whiteSpace: 'nowrap',
		display: 'inline-block',
		textIndent: 0,

		'&:hover': {
			color: theme.palette.primary.dark,
			textDecoration: 'underline',
		},
	},
	tagDelete: {
		color: theme.palette.secondary.light,
	},
	tagAdd: {
		color: theme.palette.primary.light,
	},

	tagToDelete: {
		backgroundColor: theme.palette.secondary.light,
		'&:focus': {
			backgroundColor: theme.palette.secondary.light,
		}
	},

	tagNew: {
		backgroundColor: theme.palette.primary.light,
		'&:focus': {
			backgroundColor: theme.palette.primary.light,
		}
	},

	tagAuto: {
		backgroundColor: theme.palette.grey['400'],
		'&:focus': {
			backgroundColor: theme.palette.grey['600'],
		}
	}
});

class TagItem extends Component {
	handleDelete = () => {
		this.props.onDelete(this.props.tag);
	}

	handleTagConfirm = (event) => {
		const tagName = event.currentTarget.dataset.id;
		this.props.onRating(tagName, 1);
	}

	handleTagReject = (event) => {
		const tagName = event.currentTarget.dataset.id;
		this.props.onRating(tagName, -1);
	}

	handleClick = (event) => {
		if (checkKeyMods(event))
			Navigation.openTag(event.currentTarget.dataset.id);
	}

	render() {
		const { tag, classes } = this.props;

		const reqAdd = (tag.request === 'add');
		const reqDelete = (tag.request === 'delete');
		const req = reqAdd || reqDelete;

		if (this.props.edit) {
			const colorClass = (tag.auto ? { colorPrimary: classes.tagAuto } :
				(tag.toDelete || reqDelete) ? { colorPrimary: classes.tagToDelete } :
					(tag.isNew || reqAdd) ? { colorPrimary: classes.tagNew } : {});

			return (
				<React.Fragment>
					{req &&
						<Tooltip title={reqAdd ? 'Confirm this tag' : 'Delete this tag'} enterDelay={300}>
							<IconButton aria-label='Thumb Up' onClick={this.handleTagConfirm} data-id={tag.tag} style={{ marginLeft: 0, marginRight: -7 }}>
								<ThumbUpIcon fontSize='small' />
							</IconButton>
						</Tooltip>
					}

					<Chip
						label={tag.tag}
						color='primary'
						classes={colorClass}
						className={classes.edittag}
						onDelete={tag.request ? null : this.handleDelete}
					// TODO: Custom deleteIcon to support Up/Down rating of tags?
					// deleteIcon={
					// 	<span>
					// 		<SvgIcon><UpIcon/></SvgIcon>
					// 		<span>{'1'}</span>
					// 		<SvgIcon><DownIcon/></SvgIcon>
					// 	</span>
					// }
					/>

					{req &&
						<Tooltip title={reqAdd ? 'Reject this tag' : 'Don\'t delete this tag'} enterDelay={300}>
							<IconButton aria-label='Thumb Down' onClick={this.handleTagReject} data-id={tag.tag} style={{ marginLeft: -7, marginRight: 0 }}>
								<ThumbDownIcon fontSize='small' />
							</IconButton>
						</Tooltip>
					}
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					<a
						className={classes.tag + (reqAdd ? ' ' + classes.tagAdd : '') + (reqDelete ? ' ' + classes.tagDelete : '')}
						href={Navigation.getTagURL(tag.tag)}
						onClick={this.handleClick}
						data-id={tag.tag}
					>
						{'#' + tag.tag}
					</a>
				</React.Fragment>
			);
		}
	}
}

TagItem.propTypes = {
	classes: PropTypes.object.isRequired,
	tag: PropTypes.object.isRequired,
	edit: PropTypes.bool,
	onDelete: PropTypes.func.isRequired,
	onRating: PropTypes.func.isRequired,
};

export default withStyles(styles)(TagItem);