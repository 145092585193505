/* eslint-disable react/display-name */
import React from 'react';

import ImageWithTitle from './ImageWithTitle';

import Server from '../server';

// select tag, imgSquare, locHier, img.blurhash
// from tags left outer join img on tags.imgSquare = img.id
// where tag in ('Prague', 'Iceland', 'California', 'Lofoten', 'San Francisco', 'Paris', 'New York', 'Los Angeles', 
// 'France', 'London', 'Rome', 'United Arab Emirates', 'Venice',
// 'Bavaria', 'Canada', 'Oregon', 'Switzerland', 'Catalonia', 'Portugal',
// 'India', 'Galicia', 'Ireland', 'Amsterdam', 'Lisbon', 'Chicago',
// 'Andalusia', 'Greece', 'New Zealand', 'Utah', 'Washington', 'Scotland', 'United States'
// ) and not isnull(locHier)
// order by pop desc

export const tags = 
[
	{
		"tag" : "Prague",
		"photo" : "d481573e-6c6f-4035-b45e-ac11ba66e962",
		"hier" : "Czechia",
		"blurhash" : "M8CYm^EU5E-.Nf1B-TxCNIWB5Ixat3M}s,"
	},
	{
		"tag" : "Iceland",
		"photo" : "dfd56396-6260-40e7-9968-c32d5fea8279",
		"hier" : "",
		"blurhash" : "L7DmzBjI~ps+NEjEtStS00aJ9aWE"
	},
	{
		"tag" : "San Francisco",
		"photo" : "d50c498b-76f5-46ba-b3ec-d98a8e19f92a",
		"hier" : "United States\\California",
		"blurhash" : "LIBLP22aI:|[EhxFsoNuIURjoejZ"
	},
	{
		"tag" : "Lofoten",
		"photo" : "04fa7251-6186-4533-bb5a-1e79b6a5688f",
		"hier" : "Norway\\Nordland",
		"blurhash" : "LAB{QA-9xt#j}_V?NGnh0LEgELXT"
	},
	{
		"tag" : "California",
		"photo" : "b2364744-1c5d-4ea3-89cc-28f81f96fc92",
		"hier" : "United States",
		"blurhash" : "LIAAp{s.DhIp.TWCa0af9aNH%Mxt"
	},
	{
		"tag" : "Scotland",
		"photo" : "aa77d3bd-f23c-490a-9b6e-1510addd3bab",
		"hier" : "United Kingdom",
		"blurhash" : "LKGat5t6-TW=.T=wxZof_2niR-s."
	},
	{
		"tag" : "Paris",
		"photo" : "085ad69d-7230-4321-9bf9-1bc7c63a7202",
		"hier" : "France",
		"blurhash" : "T%BNKxozbITOofWVkEfls,M{WBWB"
	},
	{
		"tag" : "New York",
		"photo" : "c0de7dfa-f5e2-4539-a228-58a29b628e6b",
		"hier" : "United States",
		"blurhash" : "L384xkijyZob00.Q4Ti_NBDi-,WG"
	},
	{
		"tag" : "Los Angeles",
		"photo" : "1272e3b1-7189-447f-a072-0f8865de4ebb",
		"hier" : "United States\\California",
		"blurhash" : "Ma4z2radaxayjYXrj?axjtjsaca}jrf6ay"
	},
	{
		"tag" : "France",
		"photo" : "a1003b0c-a4b9-4189-b098-e9c1c135a753",
		"hier" : "",
		"blurhash" : "LA68EyskD~NH%Qj]RhWBRjfloIj?"
	},
	{
		"tag" : "London",
		"photo" : "4b1c3bb2-0502-4f8b-a1a5-a9a7a26f61a0",
		"hier" : "United Kingdom",
		"blurhash" : "LSCY@BR%Ngs;F*w[a#NdM}NHs*s."
	},
	{
		"tag" : "United Arab Emirates",
		"photo" : "b75517b6-e95c-4c1e-96e0-17454d04d4b7",
		"hier" : "",
		"blurhash" : "MVF$t*9bRj%1Ip?wIpRkt6ayNNjYRkj[of"
	},
	{
		"tag" : "Portugal",
		"photo" : "6eae11cc-3bf1-4d3e-8c8a-42816ff6d64f",
		"hier" : "",
		"blurhash" : "LXH-}h-iW?%L}:s,nhn$IUkWRjRj"
	},
	{
		"tag" : "Oregon",
		"photo" : "4b7d2ca0-d14e-4c2d-b36b-49793f63ac0a",
		"hier" : "United States",
		"blurhash" : "L7DILZE^0yK6LNtS,pnhyY-qfRR%"
	},
	{
		"tag" : "Utah",
		"photo" : "f83fa0fa-95ae-40e6-bad8-c68e4bd7fe37",
		"hier" : "United States",
		"blurhash" : "LOAv@EI:ofjs?ws9bIjsTKslkCfQ"
	},
	{
		"tag" : "Bavaria",
		"photo" : "8c34fcc8-0458-491e-a7fe-6f4eafe2b32b",
		"hier" : "Germany",
		"blurhash" : "LBCiT}02JV$~}?9wWBxa00?ZnhRl"
	},
	{
		"tag" : "Ireland",
		"photo" : "5276700b-27dd-45ca-868b-c626bee3f5a4",
		"hier" : "",
		"blurhash" : "T5AB5$x@00DjRk?b00S0?u?@x@8{"
	},
	{
		"tag" : "Lisbon",
		"photo" : "be47f64b-404b-4b37-a31c-3be6ab27d5f0",
		"hier" : "Portugal",
		"blurhash" : "LEFhIs0gJRxGOF$%EMt60N}?WYR+"
	},
	{
		"tag" : "Chicago",
		"photo" : "1413c534-1264-4380-9c73-a861b14c5e7f",
		"hier" : "United States\\Illinois",
		"blurhash" : "L3C#lG=_-:}=0F9uRP57V=of$PEk"
	},
	{
		"tag" : "Canada",
		"photo" : "e2f67e23-07a8-4f3f-b60e-3e43d9da1596",
		"hier" : "",
		"blurhash" : "LXDlsxM^xbRi?1bHaeogtBt9R%oM"
	},
	{
		"tag" : "Rome",
		"photo" : "6e30af11-3a41-4392-9a3c-0ed9a7067985",
		"hier" : "Italy",
		"blurhash" : "TpB;U$$~xuT#kBf8NhR+V[V?kDWA"
	},
	{
		"tag" : "Washington",
		"photo" : "43e959e5-38cb-4269-839b-764fbfb80e29",
		"hier" : "United States",
		"blurhash" : "LlDl]GoejYax_4oIjubIb|bIjsjs"
	},
	{
		"tag" : "Catalonia",
		"photo" : "f8760500-7cac-476a-866a-9a3ddb800377",
		"hier" : "Spain",
		"blurhash" : "L6B3A@Os00Mw0pxG$1Rj00Rj~qxv"
	},
	{
		"tag" : "Venice",
		"photo" : "c95feab4-7024-4027-b729-88461ccd0235",
		"hier" : "Italy\\Veneto",
		"blurhash" : "Lq6^70obbbW=pykCbbbIT1a|fijs"
	},
	{
		"tag" : "India",
		"photo" : "1dab8cbc-c12d-4f40-9f1d-36d87881058c",
		"hier" : "",
		"blurhash" : "LgIr5BRktSjZ?dflkDazb|R+aej["
	},
	{
		"tag" : "Amsterdam",
		"photo" : "d135ee25-f093-400f-9914-4af5882dd33c",
		"hier" : "Netherlands",
		"blurhash" : "L19?L60$0M}=1M=bw^Nv00$%}?EN"
	},
	{
		"tag" : "Andalusia",
		"photo" : "f3187164-49d0-4ffb-a849-f45fae787141",
		"hier" : "Spain",
		"blurhash" : "LZGIl#NHW?s._4oJWEay9at5aza#"
	},
	{
		"tag" : "Switzerland",
		"photo" : "1a7f1e7f-3b47-4b9e-bff3-d84e1a8fba71",
		"hier" : "",
		"blurhash" : "Le6wBPX;WBsRcIjEflWYMwZ~kDW?"
	},
	{
		"tag" : "New Zealand",
		"photo" : "f384818a-6947-49d2-91c8-b7915ae1df28",
		"hier" : "",
		"blurhash" : "UYBEUSIpt8WWyZR-ofWXo$a#jYa#XAWBjEay"
	},
	{
		"tag" : "Greece",
		"photo" : "ad6700d7-bfd8-4f2e-82f7-d3aac3bd6a54",
		"hier" : "",
		"blurhash" : "LlL:i?5AjExZ~AR:XAV@xp$fR.S6"
	},
	{
		"tag" : "Galicia",
		"photo" : "5a1e4eae-4615-495e-9ec5-a55712687ac7",
		"hier" : "Spain",
		"blurhash" : "L_D^i[Rkj[j]t:WWj[axbba}jZa}"
	},
	{
		"tag" : "United States",
		"photo" : "28f88d02-c305-49e8-87f2-72ff9978c79e",
		"hier" : "",
		"blurhash" : "LRDlA,J9M{WAx?$%I;Nb~ANbWBsm"
	},
];

// [
// 	{ tag: 'Prague', photo: 'd481573e-6c6f-4035-b45e-ac11ba66e962', hier: 'Czechia' },
// 	{ tag: 'Iceland', photo: 'dfd56396-6260-40e7-9968-c32d5fea8279' },  // 65333bc3-414c-427f-a743-bbf1d630ba4c
// 	{ tag: 'California', photo: 'bd94c7e5-9ba8-41d8-b1ac-28148aca3cf4', hier: 'United States' },
// 	{ tag: 'Lofoten', photo: '04fa7251-6186-4533-bb5a-1e79b6a5688f', hier: 'Norway\\Nordland' },
// 	{ tag: 'San Francisco', photo: 'd50c498b-76f5-46ba-b3ec-d98a8e19f92a', hier: 'United States\\California' },  // c534c092-0b81-42cb-a71c-7d26f7bf77df
// 	{ tag: 'Paris', photo: '5ef14deb-d6ef-4a1e-802d-eb9e50ef568a', hier: 'France' },
// 	{ tag: 'New York', photo: 'c0de7dfa-f5e2-4539-a228-58a29b628e6b', hier: 'United States' }, // e6d889a2-6c34-4a86-ad5e-18e85bb28bb0, 71bbe870-2d72-4fd1-92d2-9fc11c5dc319, 3e635db4-fd46-4df6-87f9-e65565dc3fe2
// 	{ tag: 'Los Angeles', photo: '9a92e36a-c5fb-4671-bd96-fe62c9feac47', hier: 'United States\\California' },
// 	{ tag: 'France', photo: 'a1003b0c-a4b9-4189-b098-e9c1c135a753' },
// 	{ tag: 'London', photo: '4b1c3bb2-0502-4f8b-a1a5-a9a7a26f61a0', hier: 'United Kingdom' }, // c6966189-b240-41d6-a3e3-ea5276d60dfc, '9ffd2415-2436-4efc-b8e3-eea1b986121c'
// 	{ tag: 'Rome', photo: 'ff7a91fb-6890-4e92-89f6-807fb0def24b', hier: 'Italy' }, //6e30af11-3a41-4392-9a3c-0ed9a7067985
// 	{ tag: 'United Arab Emirates', photo: 'b75517b6-e95c-4c1e-96e0-17454d04d4b7' },
// 	{ tag: 'Venice', photo: 'c95feab4-7024-4027-b729-88461ccd0235', hier: 'Italy\\Veneto' },
// 	{ tag: 'Bavaria', photo: '8c34fcc8-0458-491e-a7fe-6f4eafe2b32b', hier: 'Germany' },
// 	{ tag: 'Canada', photo: 'e2f67e23-07a8-4f3f-b60e-3e43d9da1596' },
// 	{ tag: 'Oregon', photo: '9c3e2ac2-4407-4f9f-a31e-09572ff33560', hier: 'United States' },
// 	{ tag: 'Switzerland', photo: '1a7f1e7f-3b47-4b9e-bff3-d84e1a8fba71' },
// 	{ tag: 'Catalonia', photo: 'b06ed82a-45ed-4ffa-b638-f7a4f3981ec1', hier: 'Spain' },
// 	{ tag: 'Portugal', photo: 'cee15863-1f2f-4496-93e0-733b8b3caf95' },
// 	{ tag: 'India', photo: '1dab8cbc-c12d-4f40-9f1d-36d87881058c' },
// 	{ tag: 'Galicia', photo: '5a1e4eae-4615-495e-9ec5-a55712687ac7', hier: 'Spain' },
// 	{ tag: 'Ireland', photo: '86936cc6-4453-46af-aeea-12d4decfe269' },
// 	{ tag: 'Amsterdam', photo: 'd135ee25-f093-400f-9914-4af5882dd33c', hier: 'Netherlands' },
// 	{ tag: 'Lisbon', photo: '16dde913-c973-4237-bc04-9be8903025d4', hier: 'Portugal' },
// 	{ tag: 'Chicago', photo: '1413c534-1264-4380-9c73-a861b14c5e7f', hier: 'United States\\Illinois' },
// 	{ tag: 'Andalusia', photo: 'f3187164-49d0-4ffb-a849-f45fae787141', hier: 'Spain' },
// 	{ tag: 'Greece', photo: 'ad6700d7-bfd8-4f2e-82f7-d3aac3bd6a54' },
// 	{ tag: 'New Zealand', photo: 'acdf1ee2-a212-4547-b9ca-309f205457d4' },
// 	{ tag: 'Utah', photo: 'dd29c19d-82e7-4509-920f-fe1601fe416e', hier: 'United States' },
// 	{ tag: 'Washington', photo: 'fbae2e12-760e-4104-884c-353cf1a501d9', hier: 'United States' },
// 	{ tag: 'Scotland', photo: 'aa77d3bd-f23c-490a-9b6e-1510addd3bab', hier: 'United Kingdom' },
// 	{ tag: 'United States', photo: 'f83fa0fa-95ae-40e6-bad8-c68e4bd7fe37' },
// ];

export default function Tags() {
	return (
		<div>
			{tags.map((tag, index) => {
				if (tag.photo)
					return (
						<ImageWithTitle
							title={tag.tag}
							hierarchy={tag.hier || ''}
							imageURL={Server.getImageThumb({ id: tag.photo })}
							style={{ marginRight: (index + 1 === tags.length) ? 0 : 15 }}
							key={tag.tag}
							imgClass='img200'
						/>
					);
				else
					return null;
			})}
		</div>
	);
}