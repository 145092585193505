/* eslint-disable react/display-name */
import React from 'react';

export default function footer() {
	return (
		<footer>
			<div>
				<div style={{ float: 'left' }}>
					© 2019-2024 Phoide
				</div>
				<div style={{ float: 'right' }}>
					<a href={'/about/privacy'} data-inlink>Privacy Policy</a>
				</div>
			</div>
		</footer>
	);
}