import React from 'react';
import LazyLoad from 'vanilla-lazyload';

// Only initialize it one time for the entire application
if (!process.env.REACT_APP_SERVER_SIDE && !document.lazyLoadInstance) {
	document.lazyLoadInstance = new LazyLoad();
}

export class LazyImage extends React.Component {
	// Update lazyLoad after first rendering of every image
	componentDidMount() {
		if (!process.env.REACT_APP_SERVER_SIDE)
			document.lazyLoadInstance.update();
	}

	// Update lazyLoad after rerendering of every image
	componentDidUpdate() {
		if (!process.env.REACT_APP_SERVER_SIDE)
			document.lazyLoadInstance.update();
	}

	// Just render the image with data-src
	render() {
		const { src, srcset, sizes, important, ...props } = this.props;
		if (important) {
			return (
				// eslint-disable-next-line jsx-a11y/alt-text
				<img
					src={src}
					srcSet={srcset}
					sizes={sizes}
					{...props}
				/>
			);
		} else {
			return (
				// eslint-disable-next-line jsx-a11y/alt-text
				<img
					className='lazy'
					data-src={src}
					data-srcset={srcset}
					data-sizes={sizes}
					{...props}
				/>
			);
		}
	}
}

export default LazyImage;
