import React, { useState } from 'react';

import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';

import { parseImageCode/*, imgToCode*/ } from '../../tools';
import { TextField, Divider } from '@material-ui/core';
// import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import AlignLeft from '@material-ui/icons/FormatAlignLeft';
import AlignCenter from '@material-ui/icons/FormatAlignCenter';
import AlignRight from '@material-ui/icons/FormatAlignRight';

import Navigation from '../../navigation';
import Server from '../../server';

var menuAnchor, setMenuAnchor;
var img, setImg;
var ctx;
var onChange;
var onClose;

export function openPhotoPopup(anchorEl, aCtx, aOnChange, aOnClose) {
	ctx = aCtx;
	setMenuAnchor(anchorEl, aCtx);
	const { node } = ctx;
	setImg(parseImageCode((node && node.attrs.value) || ''))
	onChange = aOnChange;
	onClose = aOnClose;
}

var changeCounter = 0;

export default function PhotoPopup(props) {
	// const { node, view, getPos } = ctx || {};
	[menuAnchor, setMenuAnchor] = useState(null);
	[img, setImg] = useState({});
	const [rect, setRect] = useState(null);

	if (!rect && menuAnchor) {
		setRect(menuAnchor.getBoundingClientRect());
	}
	const nowRect = rect || { left: 0, top: 0 };

	const url = (img.id ? (img.id.indexOf(':') >= 0 ? img.id : Navigation.getPhotoURL({ id: img.id })) : '');
	var size = (img.height || 1) - 1;
	if (size < 0) {
		size = -1 / (size + 1) + 1;
	}

	const menuClose = () => {
		setMenuAnchor(null);
		ctx = null;
		setRect(null);
		onChange = null;
		if (onClose) {
			onClose();
			onClose = null;
		}
	}

	const changeValue = (newValue) => {
		const newObj = Object.assign({}, img, newValue);
		setImg(newObj);
		if (onChange)
			onChange(newObj);
	}

	const setAlign = (e) => {
		changeValue({ align: e.currentTarget.dataset.align });
	}

	const changeComment = (e) => {
		changeValue({ comment: e.target.value });
	}

	const changeUrl = (e) => {
		const url = e.target.value;
		const m = url.match(/photo=([0-9a-fA-F-]+)/) || url.match(/photo\/([0-9a-fA-F-]+)/) || url.match(/Thumbs\/([0-9a-fA-F-]+)/);
		const id = m && m[1];
		changeValue({ id: id || url });
		const changeID = ++changeCounter;
		if (id) {
			Server.getPhoto(id).then((photo) => {
				if (photo && changeID === changeCounter) {
					changeValue({
						author: photo.author || (photo.user && photo.user.displayName),
						username: photo.authorUrl || (photo.author ? null : photo.user && photo.user.username),
						idLocation: photo.idLoc,
						locTitle: photo.locTitle,
						title: photo.title,
					});
				}
			});
		}
	}

	const changeSize = (e, value) => {
		const size = value;
		changeValue({ height: size >= 0 ? size + 1 : -1 / (size - 1) });
	}

	const style = { position: 'relative' };
	if (img.height)
		style.width = Math.round(img.height * 400);
	return (
		<Popover
			open={Boolean(menuAnchor)}
			onClose={menuClose}
			anchorReference={'anchorPosition'}
			anchorPosition={{ left: nowRect.left, top: nowRect.top }}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
		>
			{/* Url */}
			<TextField
				label='Photograph URL'
				variant='outlined'
				size='small'
				style={{ margin: 10, width: 350, maxWidth: 'calc(100vw - 50px)' }}
				value={url}
				onChange={changeUrl}
			/> <br />

			{/* Comment */}
			<TextField
				label='Comment'
				variant='outlined'
				size='small'
				multiline
				maxRows={6}
				style={{ margin: 10, width: 350, maxWidth: 'calc(100vw - 50px)' }}
				value={img.comment}
				onChange={changeComment}
			/>

			<Divider />
			{/* Alignment */}
			<MenuItem disabled style={{ textAlign: 'center' }}>Alignment</MenuItem>
			<div style={{ display: 'flex', padding: 10, paddingTop: 0 }}>
				<ToggleButtonGroup value={img.align || 'left'}>
					<ToggleButton size='small' color='default' style={{ padding: 8 }} value={'left'} onClick={setAlign} data-align='left'>
						<AlignLeft />
					</ToggleButton>
					<ToggleButton size='small' color='default' style={{ padding: 8 }} value={'center'} onClick={setAlign} data-align='center'>
						<AlignCenter />
					</ToggleButton>
					<ToggleButton size='small' color='default' style={{ padding: 8 }} value={'right'} onClick={setAlign} data-align='right'>
						<AlignRight />
					</ToggleButton>
				</ToggleButtonGroup>
			</div>

			<Divider />
			{/* Size */}
			<MenuItem disabled style={{ textAlign: 'center' }}>Size</MenuItem>
			<div style={{ padding: 10, paddingTop: 0 }}>
				<Slider value={size} min={-2} max={2} step={0.01} onChange={changeSize} />
			</div>
		</Popover>
	);
}
