import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import PubSub from 'pubsub-js';
import Navigation from '../navigation';

const styles = theme => ({
	root: {
		margin: theme.spacing(2),
	},
	hyperlink: {
		cursor: 'pointer',
		opacity: 0.7,
	}
});

class SignupPopup extends Component {
	state = {
		isOpen: false,
		anchorEl: null,
		reason: null,
	}

	componentDidMount() {
		this._onUserNotLogged = PubSub.subscribe('USER_NOT_LOGGED', (msg, data) => { // eslint-disable-line no-unused-vars
			this.setState({
				isOpen: true,
				anchorEl: data.element,
				reason: data.reason,
			});
		});
	}

	componentWillUnmount() {
		PubSub.unsubscribe(this._onUserNotLogged);
	}

	handleClose = () => {
		this.setState({
			isOpen: false,
			anchorEl: null,
		});
	}

	handleLogin = () => {
		Navigation.login();
		this.handleClose();
	}

	render() {
		const { classes } = this.props;

		if (this.state.isOpen) {
			return (
				<Popover
					open={this.state.isOpen}
					anchorEl={this.state.anchorEl}
					onClose={this.handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<Typography className={classes.root}>
						{'Please '}
						<span className={classes.hyperlink} onClick={this.handleLogin}>{'login'}</span>
						{' or '}
						<span className={classes.hyperlink} onClick={this.handleLogin}>{'sign up'}</span>
						{' first in order to '}
						{this.state.reason === 'vote' ? 'vote.' : 'participate.'}
					</Typography>
				</Popover>
			);
		} else
			return null;
	}
}

SignupPopup.propTypes = {
	classes: PropTypes.object.isRequired,
};

/** @type {any} */
export default withStyles(styles)(SignupPopup);