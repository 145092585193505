import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';

import Server from '../server';
import PubSub from 'pubsub-js';

const PREVIEW_HEIGHT = 180;
const PREVIEW_WIDTH = 250;

const styles = {
	root: {
		position: 'fixed',
		pointerEvents: 'none',
		backgroundColor: 'rgba(0, 0, 0, 0.8)',

		display: 'flex',
		flexDirection: 'column',
		padding: '6px 10px',
		zIndex: 1000,
	},

	img: {
		flexGrow: 1,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},

	text: {
		marginTop: 3,
		color: 'rgba(255, 255, 255, 0.9)',
		textAlign: 'center',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		fontStyle: 'italic',
	},

	moreSpots: {
		fontSize: 11,
		marginTop: -1,
		textAlign: 'right',
		color: 'rgba(255, 255, 255, 0.5)',
	}
};

class SpotPreview extends React.Component {
	state = {
		pixel: null,
		idSpot: null,
		wasShown: false,

		title: null,
		image: null,
	};
	_terminating = false;
	_timer = null;

	componentDidMount() {
		this._subscrMouse = PubSub.subscribe('MAP_MOUSE_MOVE', (msg, data) => {
			if (data.idSpot && !this.props.mobile) {
				if (data.idSpot !== this.state.idSpot || data.spotPixel[0] !== this.state.pixel[0]  || data.spotPixel[1] !== this.state.pixel[1])
					this.startTimeout(data.idSpot, data.spotPixel, data.featCount);
			} else {
				this.closePreview();
			}
		});

		this._subscrPointUpdated = PubSub.subscribe('POINT_UPDATED', () => {
			this.closePreview();
		});
	}

	componentWillUnmount() {
		PubSub.unsubscribe(this._subscrMouse);
		PubSub.unsubscribe(this._subscrPointUpdated);
		this._terminating = true;
	}

	closePreview() {
		this.setState({
			idSpot: null,
		});
		this.stopTimeout();
	}

	stopTimeout() {
		if (this._timer) {
			clearTimeout(this._timer);
			this._timer = null;
		}
	}

	startTimeout(idSpot, pixel, featCount) {
		this.stopTimeout();

		this._timer = setTimeout(() => {
			if (this._terminating)
				return;

			this.setState({
				pixel,
				idSpot,
				featCount,
				wasShown: true,
				image: null,
				title: null,
			});
			this.fetchSpot(idSpot);
		}, 300);
	}

	async fetchSpot(id) {
		const preview = await Server.getPointPreview(id);

		if (this._terminating)
			return;

		if (preview) {
			this.setState({
				title: preview.title,
				image: preview.image,
			});
		}
	}

	render() {
		var { idSpot, pixel, wasShown, featCount, image, title } = this.state;

		// // Testing data
		// idSpot = 1122;
		// pixel = [300, 300];
		// wasShown = true;
		// featCount = 100;
		// image = { id: '6e8077ef-39b9-42c2-a1bc-483d8f16fa6b' };
		// title = 'Test title';

		if (!wasShown)
			return null;

		if (!pixel) {
			pixel = [0, 0];
		}

		var left = Math.max(0, pixel[0] - PREVIEW_WIDTH / 2);
		var top = pixel[1] - PREVIEW_HEIGHT - 50;
		if (top < 0)
			top = pixel[1] + 3;

		const { classes } = this.props;
		const backImage = image ? `url(${Server.getImageThumb(image)})` : '';

		return (
			<Fade in={Boolean(idSpot)} timeout={300}>
				<Paper className={classes.root} style={{ top: top, height: PREVIEW_HEIGHT, left: left, width: PREVIEW_WIDTH }}>
					<div className={classes.img} style={{ backgroundImage: backImage }} />

					<Typography className={classes.text} style={{}} variant='body1'>
						{title}
					</Typography>

					{featCount > 1 &&
						<Typography className={classes.moreSpots} style={{ textAligh: 'right' }} variant='body1'>
							{`${featCount} spots`}
						</Typography>
					}
				</Paper>
			</Fade>
		);
	}
}

SpotPreview.propTypes = {
	classes: PropTypes.object.isRequired,
	style: PropTypes.object,
	mobile: PropTypes.bool,
};

/** @type {any} */
export default withStyles(styles)(SpotPreview);
