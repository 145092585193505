// @ts-check
/* eslint-disable no-console */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import SpotItem from './SpotItem';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation as SwiperNavigation, Scrollbar as SwiperScrollbar, FreeMode as SwiperFreeMode } from 'swiper';
import './Swiper/swiper-bundle.css';

// Install modules
SwiperCore.use([SwiperNavigation, SwiperScrollbar, SwiperFreeMode]);

const styles = /*theme =>*/ ({
	spotSwiper: {
		'& .swiper-scrollbar': {
			height: 3,
		}
	},

	spotSwiperScrollbar: {
		paddingBottom: 12,  // Enough place for the scrollbar, etc.
	},

	spotCard: {
		display: 'inline-block',
		verticalAlign: 'middle',
	},

	spotCardWrap: {
		width: 'auto',
	},

	text: {
		display: '-webkit-box',
		WebkitLineClamp: 2,
		WebkitBoxOrient: 'vertical',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		fontWeight: 'bold',
		fontSize: 14,
	},

	textSingle: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		fontSize: 14,
	},

	distance: {
		float: 'right',
		opacity: 0.5,
		display: 'inline-flex',
		alignItems: 'center',
		marginTop: 4,
		fontSize: 12,
	},
});

class SpotsBar extends Component {
	state = {
		maxImages: 5,
	}
	_swiper;
	_needsImages = 5;

	componentWillUnmount() {
		this._swiper = null;
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.spots !== this.props.spots) {
			this.setState({ maxImages: this._needsImages });
			if (this._swiper) {
				this._swiper.slideTo(0);
			}
			return true;
		}

		if (nextState.maxImages !== this.state.maxImages) {
			return true;
		}

		if (nextState.mobile !== this.state.mobile)
			return true;

		return false;
	}

	adjustSize(width) {
		const images = Math.trunc(width / ((this.props.height || 240) - 50 + (this.props.margin || 20)) + 1);
		this._needsImages = images + 2; // Load enough in advance
		if (this.state.maxImages < images)
			this.setState({ maxImages: images });
	}

	initSwiper = (swiper) => {
		this._swiper = swiper;
		if (swiper) {
			this.adjustSize(swiper.el.offsetWidth);
		}
	}

	onSlideChange = (swiper) => {
		if (swiper) {
			swiper.updateSlides();
			// console.log(`${swiper.activeIndex} + ${this._needsImages}, Max: ${this.state.maxImages}`);
			if (swiper.activeIndex + this._needsImages > this.state.maxImages) {
				this.setState({ maxImages: swiper.activeIndex + this._needsImages });
			}
		}
	}

	render() {
		const { classes, spots } = this.props;

		let addDummies = spots ? spots.length - this.state.maxImages : this._needsImages;
		if (addDummies && addDummies < this._needsImages)
			addDummies = this._needsImages;
		// console.log(`Spots: ${spots.length}, showing: ${spotlist.length}, needs: ${this._needsImages}, dummies: ${addDummies}`);

		return (
			<Swiper
				spaceBetween={1}
				slidesPerView={'auto'}
				slidesPerGroup={1}
				slidesPerGroupAuto={true}
				navigation={{}}
				scrollbar={{ draggable: true, hide: true }}
				className={'swiper-container swiper-container-horizontal ' + classes.spotSwiper + ' ' + (this.props.overlayScrollbar ? '' : classes.spotSwiperScrollbar)}
				style={{ width: '100%', ...this.props.style }}
				onInit={this.initSwiper}
				onResize={this.initSwiper}
				onSlideChange={this.onSlideChange}
			>
				{(spots || []).map((spot, index) =>
					<SwiperSlide key={(spot ? (spot.id ? spot.id : spot) : 'i' + index)} className={classes.spotCardWrap}>
						<SpotItem
							spot={index < this.state.maxImages ? spot : null}
							height={this.props.height}
							margin={this.props.margin}
							variant={this.props.variant}
							singleLine={this.props.singleLine}
							baseSpot={this.props.baseSpot}
							openOnClick={this.props.openOnClick}
							onClick={this.props.onClick}
						/>
					</SwiperSlide>
				)
				}
			</Swiper>
		);
	}
}

SpotsBar.propTypes = {
	classes: PropTypes.object.isRequired,
	style: PropTypes.object,

	height: PropTypes.number,
	margin: PropTypes.number,
	variant: PropTypes.string, // elevation or outlined
	singleLine: PropTypes.bool, // single or double line title

	spots: PropTypes.array, // Spots or list of spot IDs, data will be downloaded automatically
	baseSpot: PropTypes.object,
	openOnClick: PropTypes.bool,
	overlayScrollbar: PropTypes.bool,

	onClick: PropTypes.func,
};

/** @type {any} */
// @ts-ignore (some silly style check?)
export default withStyles(styles)(SpotsBar);
