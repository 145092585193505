// @ts-check
/* eslint-disable no-console */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { completeLocalURL, completeURL } from '../tools.js';
// import { withStyles } from '@material-ui/core/styles';

var canPlayWEBM = false;
if (!process.env.REACT_APP_SERVER_SIDE) {
	if ('MediaSource' in window &&
		MediaSource.isTypeSupported('video/webm; codecs="vp9"') &&
		window.navigator.userAgent.indexOf('Edg/') < 0 /* Edge doesn't seem to work with our webm */) {
		canPlayWEBM = true;
	}
}

class VideoPlayer extends Component {
	state = {
		playing: false,
		stopped: true,
	}

	onPlay = () => {
		if (!this._video)
			return;

		const newState = !this.state.playing;
		if (newState) {
			this._video.play();
		} else {
			this._video.pause();
		}
		this.setState({ playing: newState, stopped: false });
	}

	onEnd = () => {
		this.setState({ playing: false, stopped: true });
	}

	onBackClick = () => {
		if (this._video) {
			this._video.pause();
			this._video.currentTime = 0;
			this.onEnd();
		}
	}

	render() {
		const { playing, stopped } = this.state;
		const { video, webm, alt } = this.props;

		const clsPlaying = (!stopped ? ' playing' : ' ');

		return (
			<>
				<div className={'videoWrap' + clsPlaying}>
					<video
						className={'video ' + (!stopped ? 'playing' : '')}
						ref={r => this._video = r}
						onEnded={this.onEnd}
						style={{ position: 'absolute', left: 0 }}
						poster={completeLocalURL(`/images/${video}.webp`)}
					>
						{webm && canPlayWEBM &&
							<source src={completeURL(`/images/${video}.webm`)} type='video/webm' />
						}
						<source src={completeURL(`/images/${video}.mp4`)} type='video/mp4' />
					</video>
					<picture>
						<source srcSet={completeLocalURL(`/images/${video}.webp`)} type='image/webp' />
						<source srcSet={completeLocalURL(`/images/${video}.jpg`)} type='image/jpeg' />
						<img className={'videopic '+clsPlaying} src={completeLocalURL(`/images/${video}.jpg`)} alt={alt} />
					</picture>
					<button className='btnPlay' onClick={this.onPlay}>
						<div className={'icnPlay ' + (playing ? '' : 'visible')} />  {/* image placeholder */}
						<div className={'icnPause ' + (playing ? 'visible' : '')} />  {/* image placeholder */}
					</button>
				</div>
				<div className={'videoBack' + clsPlaying} onClick={this.onBackClick}></div>
			</>
		);
	}
}

VideoPlayer.propTypes = {
	// classes: PropTypes.object.isRequired,
	video: PropTypes.string.isRequired,
	webm: PropTypes.bool,
	alt: PropTypes.string.isRequired,
};

// @ts-ignore (some silly style check?)
export default /*withStyles(styles)*/(VideoPlayer);