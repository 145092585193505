// @ts-check
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@material-ui/core/Switch';

import ArrowUp from '@material-ui/icons/ArrowUpward';
import ArrowDown from '@material-ui/icons/ArrowDownward';
import YearIcon from '@material-ui/icons/Event';
import CloseIcon from '@material-ui/icons/Close';
import ShadowIcon from '@material-ui/icons/NavigationTwoTone';
import EnlargeIcon from '@material-ui/icons/ZoomIn';
import PathIcon from '@material-ui/icons/Redo';
import MoreIcon from '@material-ui/icons/MoreVert';

import Collapse from '@material-ui/core/Collapse';

import suncalc from '../tools/suncalc';

import { getWeatherIcon, getClearIcon } from '../toolsWeather';
import { browserLocale, COLORS, getLocTZOffset, shallowCompare, getBlankTarget } from '../tools';
import Navigation from '../navigation';
import Server from '../server';

import PubSub from 'pubsub-js';

const SEC_PER_DAY = 60 * 60 * 24;
const MS_PER_DAY = 1000 * SEC_PER_DAY;

const styles = theme => ({
	// Slider
	dayTrack: {
		background: 'none',
	},
	dayThumb: {
		width: 14,
		height: 14,
		color: theme.palette.secondary.main,
	},
	dayContainer: {
		width: '100%',
		margin: 0,
		padding: 0,
		overflow: 'initial',
	},
	dayRail: {
		display: 'none',
	},

	dayHeader: {
		textAlign: 'center',
		marginTop: 5,
		marginBottom: -5,
	},

	upDownIcon: {
		position: 'absolute',
		width: 13,
		height: 13,
		marginTop: -11,
	},

	weather: {
		marginRight: -10,
		paddingRight: 10,
		maxHeight: 115,
		overflowX: 'hidden',
		overflowY: 'scroll',
		'&::-webkit-scrollbar': {
			width: 4,
		},
		'&::-webkit-scrollbar-thumb': {
			width: 4,
			background: '#aeaeae',
			borderRadius: 2,
		},
	}
});

function isValidDate(date) {
	return Boolean(date) && Boolean(date.valueOf());
}

function timeToFrac(spot, time) {
	if (!isValidDate(time))
		return 0; // Probably better than 'null' to avoid math issues? 

	const fract = (time.valueOf() + getLocTZOffset(spot, time)) % MS_PER_DAY;
	return fract / MS_PER_DAY;
}

function timeToPerc(spot, time) {
	return 100 * timeToFrac(spot, time);
}

function hourFormat(spot, hour) {
	return (
		<Typography key={'hour' + hour} variant='caption' style={{ width: 40, textAlign: 'center' }}>
			{timeFormat(spot, new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth()+1, new Date().getDate(), hour)))}
		</Typography>
	);
}

function timeFormat(spot, time, options) {
	options = Object.assign({ hour: 'numeric', minute: 'numeric', timeZone: 'UTC', hour12: false }, options);

	if (isValidDate(time)) {
		const adjTime = new Date(time.valueOf() + getLocTZOffset(spot, time));
		try {
			return adjTime.toLocaleTimeString(browserLocale(), options);
		} catch (e) {
			// This is here just as a workaround for a Google Search Fetch tool error, which fails on {timeZone: 'UTC'} for some reason
			return adjTime.toLocaleTimeString(browserLocale(), { hour: 'numeric', minute: 'numeric' });
		}
	} else
		return '';
}

function dateFormat(date) {
	if (date)
		return date.toLocaleDateString(browserLocale(), { weekday: 'long', month: 'numeric', day: 'numeric' });
	else
		return '';
}


class Timeline extends React.PureComponent {
	render() {
		const offset = getLocTZOffset(this.props.location) / 1000 / 60 / 60;
		return (
			<div style={{ display: 'flex', placeContent: 'space-between', justifyContent: 'space-between' }}>
				{[0, 3, 6, 9, 12, 15, 18, 21, 24].map(t =>
					hourFormat(this.props.location, t - offset)
				)}
			</div>
		);
	}
}

Timeline.propTypes = {
	location: PropTypes.object,
};

class Events extends Component {
	shouldComponentUpdate(nextProps) {
		const newDate = (nextProps.forceDay && nextProps.forceDay.valueOf()) || 0;
		const date = (this.props.forceDay && this.props.forceDay.valueOf()) || 0;
		return (newDate !== date) ||
			(nextProps.location !== this.props.location) ||
			(nextProps.showMoon !== this.props.showMoon);
	}

	getMoonTooltip(action, time) {
		const illum = suncalc.getMoonIllumination(time);
		var phase;
		switch (Math.floor(illum.phase * 4)) {
			case 0: phase = 'Waxing Crescent'; break;
			case 1: phase = 'Waxing Gibbous'; break;
			case 2: phase = 'Waning Gibbous'; break;
			default: phase = 'Waning Crescent';
		}

		return (
			<>
				{`${action} at ${timeFormat(this.props.location, time)}`}<br />
				{`Illuminated: ${Math.round(illum.fraction * 1000) / 10}%`}<br />
				{phase}
			</>
		);
	}

	render() {
		const { location, classes, showMoon } = this.props;
		const times = this.props;
		const events = [];

		if (isValidDate(times.sunrise))
			events.push({
				e: 'sunrise',
				t: times.sunrise,
				p: timeToPerc(location, times.sunrise),
				tooltip: (
					<>
						{isValidDate(times.dawn) && <>{`Dawn from: ${timeFormat(location, times.dawn)}`}<br /></>}
						{isValidDate(times.sunrise) && <>{`Sunrise: ${timeFormat(location, times.sunrise)}`}<br /></>}
						{isValidDate(times.goldenHourEnd) && `Golden hour until: ${timeFormat(location, times.goldenHourEnd)}`}
					</>),
			});
		if (isValidDate(times.sunset))
			events.push({
				e: 'sunset',
				t: times.sunset,
				p: timeToPerc(location, times.sunset),
				tooltip: (
					<>
						{isValidDate(times.goldenHour) && <>{`Golden hour from: ${timeFormat(location, times.goldenHour)}`}<br /></>}
						{isValidDate(times.sunset) && <>{`Sunset: ${timeFormat(location, times.sunset)}`}<br /></>}
						{isValidDate(times.dusk) && `Dusk until: ${timeFormat(location, times.dusk)}`}
					</>),
			});
		if (showMoon && isValidDate(times.moonRise))
			events.push({
				e: 'moonrise',
				t: times.moonRise,
				p: timeToPerc(location, times.moonRise),
				tooltip: this.getMoonTooltip('Moonrise', times.moonRise),
			});
		if (showMoon && isValidDate(times.moonSet))
			events.push({
				e: 'moonset',
				t: times.moonSet,
				p: timeToPerc(location, times.moonSet),
				tooltip: this.getMoonTooltip('Moonset', times.moonSet),
			});

		events.sort((e1, e2) => e1.p - e2.p);
		var prevPerc = 0;

		return (
			<>
				<Typography variant='caption' style={{ display: 'flex', marginBottom: 5 }}>
					{events.map((e, index) => {
						const perc = prevPerc;
						prevPerc = e.p;// + 1.7;
						const isBorder = index === 0 || index === events.length - 1;
						return (
							<React.Fragment key={e.e}>
								<div style={{ flexBasis: `calc(${e.p - perc}vw - ${isBorder ? 23 : 46}px)`, flexGrow: 1 }}></div>
								<Tooltip title={e.tooltip} enterDelay={300}>
									<div style={{ height: 20, display: 'flex', alignItems: 'center' }}>
										<div style={{ width: 20, marginBottom: -4 }}>
											{getClearIcon(e.e === 'sunrise' || e.e === 'sunset')}
										</div>
										{timeFormat(location, e.t)}
									</div>
								</Tooltip>
							</React.Fragment>
						);
					})}

					{events.length &&
						<div style={{ flexBasis: `calc(${100 - prevPerc}vw - 23px)`, flexGrow: 1 }}></div>
					}
				</Typography>

				<div style={{ position: 'relative', top: 3 }}>
					{events.map((e, index) => {
						var color = COLORS.SUNRISE;
						if (e.e === 'sunset')
							color = COLORS.SUNSET;
						if (e.e === 'moonset' || e.e === 'moonrise')
							color = COLORS.MOON;
						if (e.e === 'sunset' || e.e === 'moonset')
							return (
								<ArrowDown key={'arrow' + index} className={classes.upDownIcon} style={{ left: `calc(${e.p}% - 6px)`, color: color }} />
							);
						else
							return (
								<ArrowUp key={'arrow' + index} className={classes.upDownIcon} style={{ left: `calc(${e.p}% - 6px)`, color: color }} />
							);
					})}
				</div>
			</>
		);
	}
}

Events.propTypes = {
	classes: PropTypes.object.isRequired,
	style: PropTypes.object,
	location: PropTypes.object,

	forceDay: PropTypes.object,
	sunrise: PropTypes.object,
	sunset: PropTypes.object,
	dawn: PropTypes.object,
	dusk: PropTypes.object,
	goldenHour: PropTypes.object,
	goldenHourEnd: PropTypes.object,
	moonSet: PropTypes.object,
	moonRise: PropTypes.object,
	showMoon: PropTypes.bool,
};

class Weather extends React.PureComponent {
	renderWeatherItem(item, time, sunrise, sunset) {
		var desc;
		if (item)
			desc = `${item.descr}\n${Math.round((item.temp - 273.15) * 10) / 10}°C\n${item.clouds}% clouds\n` +
				`wind ${item.wndSpeed}m/s from ${item.wndDeg}°\n${(item.rain ? 'rain: ' + item.rain + 'mm/3h\n' : '')}${(item.snow ? 'snow: ' + item.snow + 'mm/3h' : '')}`;

		return (
			<div style={{ width: 32 }} key={time}>
				{item &&
					getWeatherIcon(item.code, desc, (time > sunrise) && (time < sunset))
				}
			</div>
		);
	}

	renderWeatherDay(dt, index, sunrise, sunset) {
		const { classes } = this.props;
		const loc = this.props.location;
		const offset = Math.round(getLocTZOffset(loc, dt) / 1000 / 60 / 60 / 3) * 3 * 60 * 60;
		const items = loc.weather.list;

		return (
			<div key={'day' + index}>
				{index === 1 &&
					<Typography variant='caption' display='block' className={classes.dayHeader}>{'Tomorrow'}</Typography>
				}

				{index > 1 &&
					<Typography variant='caption' display='block' className={classes.dayHeader}>{dateFormat(new Date(dt * 1000))}</Typography>
				}

				<div style={{ display: 'flex', placeContent: 'space-between', justifyContent: 'space-between' }}>
					{[0, 3, 6, 9, 12, 15, 18, 21, 24].map(t =>
						this.renderWeatherItem(items.find(w => w.dt === dt + t * 60 * 60 - offset), t, sunrise, sunset)
					)}
				</div>
			</div>
		);
	}

	render() {
		const { location, sunrise, sunset } = this.props;
		if (!location.weather || !location.weather.list)
			return null;

		const now = new Date();
		const date = new Date(now.getFullYear(), now.getMonth(), now.getDate()).valueOf() / 1000 - (new Date().getTimezoneOffset() * 60);
		const dtSunrise = sunrise ? ((sunrise.valueOf() + getLocTZOffset(location, date)) % MS_PER_DAY) / 1000 / 60 / 60 : null;
		const dtSunset = sunset ? ((sunset.valueOf() + getLocTZOffset(location, date)) % MS_PER_DAY) / 1000 / 60 / 60 : null;

		return (
			<React.Fragment>
				{[0, 1, 2, 3, 4].map(i => this.renderWeatherDay(date + i * SEC_PER_DAY, i, dtSunrise, dtSunset))}
			</React.Fragment>
		);
	}
}

Weather.propTypes = {
	classes: PropTypes.object.isRequired,
	style: PropTypes.object,
	location: PropTypes.object,
	sunrise: PropTypes.object,
	sunset: PropTypes.object,
};

var updateCounter = 0;
var lasttz = '';

class CurrentConditions extends React.Component {
	state = {
		showDate: false, // Show date pop-up
		showTime: false,
		showDateSlider: false,
		showWeather: false,
		menuEl: null,
	}
	/** @type{any} */
	forceDay = null; // Date with time = 0:00 UTC
	/** @type{any} */
	forceTime = null; // ms from midnight, adjusted (+/-) to the local timezone
	_times = {};

	constructor(props) {
		super(props);
		this.recalc();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.location !== this.props.location ||
			(nextProps.location && this.props.location && nextProps.location.lat !== this.props.location.lat)) {
			this.recalc(nextProps);
			return true;
		}

		return shallowCompare(this, nextProps, nextState);
	}

	recalc(props) {
		const _props = props || this.props;
		const { location } = _props;
		updateCounter++;
		if (!location)
			return null;

		if (!location.tz && location.nearestId) {
			const cnt = updateCounter;
			location.tz = lasttz;
			Server.getPointPreview(location.nearestId).then(preview => {
				if (cnt === updateCounter && preview) {
					location.tz = preview.tz;
					lasttz = preview.tz;
					if (location.tz)
						this.recalc(props);
				}
			});
		}

		if (this.state.showWeather)
			this.checkWeather(location);

		const forceDay = this.forceDay || new Date(Math.trunc((Date.now() + getLocTZOffset(location)) / MS_PER_DAY) * MS_PER_DAY);
		const dayOfYear = Math.trunc((forceDay.valueOf() - Date.UTC(forceDay.getFullYear(), 0)) / MS_PER_DAY);
		const partOfDay = ((this.forceTime || Date.now()) + getLocTZOffset(location, forceDay)) % (MS_PER_DAY + 1);
		const localTime = new Date(forceDay.valueOf() + partOfDay - getLocTZOffset(location, forceDay));

		const times = suncalc.getTimes(forceDay.valueOf() - getLocTZOffset(location, forceDay) + 10000000 /* To get somewhat into the day, otherwise sometimes the previous day values are returned */, location.lat, location.long);
		const moonTimes = suncalc.getMoonTimes(forceDay.valueOf() - getLocTZOffset(location, forceDay), location.lat, location.long);
		const sunPosition = suncalc.getPosition(localTime, location.lat, location.long);
		const aboveHorizon = Math.round(sunPosition.altitude / Math.PI * 180);

		const res = {
			forceDay,
			dayOfYear,
			partOfDay,
			localTime,
			aboveHorizon,
			forceTime: this.forceTime,

			sunrise: times.sunrise,
			goldenHour: times.goldenHour,
			dawn: times.dawn,
			goldenHourEnd: times.goldenHourEnd,
			sunset: times.sunset,
			dusk: times.dusk,

			moonRise: moonTimes.rise,
			moonSet: moonTimes.set,

			sunriseDef: times.sunrise,
			sunsetDef: times.sunset,
		};

		if (!res.forceDay || !this._times.forceDay || res.forceDay.valueOf() !== this._times.forceDay.valueOf()) {
			res.forceDayText = dateFormat(res.forceDay);
		} else
			res.forceDayText = this._times.forceDayText;

		if (!this.forceDay) {
			res.sunriseDef = res.sunrise; // Sunset time used for weather icons (so that it doesn't change when a date does)
			res.sunsetDef = res.sunset;
		}

		if (this.forceTime === null) {
			this.forceTime = res.partOfDay;
		}

		this._times = res;
	}

	timeToPerc(time, def) {
		if (!isValidDate(time))
			return def;
		else
			return timeToPerc(this.props.location, time);
	}

	renderGradient() {
		// TODO: This still doesn't look perfect for some high latitudes, e.g. when Sun sets at 1:00 and rises later at 3:00. Probably better to sort the values according to their occurence during a day?
		const times = this._times;

		var lastPerc = 0;
		var lastColor = 'black';
		var res = 'linear-gradient(to right, black 0%, ';
		var lastSet = false;

		var isToday = (time) => {
			const diff = time.valueOf() + getLocTZOffset(this.props.location, time) - times.forceDay.valueOf();
			// console.log(`${time}, ${times.forceDay}, ${diff}, TZ: ${getLocTZOffset(this.props.location)} `);			
			return (diff >= 0 && diff < MS_PER_DAY);
		};

		var addColor = (color, time, last) => {
			if (isValidDate(time) && isToday(time)) {
				const perc = this.timeToPerc(time);
				res += `${lastColor} ${lastPerc}%, ${lastColor} ${perc}%, `;
				lastPerc = perc;
				lastColor = color;
			} else {
				if (!last || !lastSet) {
					lastColor = color;
					if (last)
						lastSet = true;
				}
			}
		};

		addColor(COLORS.DAWN, times.dawn);
		addColor(COLORS.SUNRISE, times.sunrise);
		addColor(COLORS.DAY, times.goldenHourEnd);
		addColor(COLORS.SUNSET, times.goldenHour, true);
		addColor(COLORS.DUSK, times.sunset, true);
		addColor('black', times.dusk, true);
		if (lastPerc > 0 && lastPerc < 100) {
			const color = lastSet ? lastColor : 'black';
			res += `${color} ${lastPerc}%, ${color} 100%`;
		}
		res += ')';

		return res;
	}

	notifyChange(day, time) {
		PubSub.publish('FORCE_TIME', {
			newDay: day,
			newTime: time
		});
	}

	setForceTime(newTime) {
		this.forceTime = newTime;
		this.recalc();
		this.forceUpdate();
	}

	setForceDate(newDate) {
		this.forceDay = newDate;
		this.recalc();
		this.forceUpdate();
	}

	sliderChange = (event, value) => {
		const newTime = value - getLocTZOffset(this.props.location, this.forceDay);
		this.setForceTime(newTime);
		this.notifyChange(this.forceDay, newTime);
	}

	dateSliderChange = (event, value) => {
		const currDate = this.forceDay || new Date();
		const newDate = new Date(Date.UTC(currDate.getFullYear(), 0) + value * MS_PER_DAY);
		this.setForceDate(newDate);
		this.notifyChange(newDate, this.forceTime);
	}

	onCloseDatePopup = () => {
		this.setState({ showDate: false });
	}

	onToggleDatePopup = (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		this.setState({ showDate: !this.state.showDate });
	}

	onCloseTimePopup = () => {
		this.setState({ showTime: false });
	}

	onToggleTimePopup = (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		this.setState({ showTime: !this.state.showTime });
	}

	onToggleDateSlider = () => {
		this.setState({ showDateSlider: !this.state.showDateSlider });
	}

	onDatePickerChange = (newDate) => {
		this.setForceDate(newDate);
		this.notifyChange(newDate, this.forceTime);
	}

	onTimePickerChange = (value) => {
		const newTime = value - this.forceDay;//getLocTZOffset(this.props.location, this.forceDay);
		this.setForceTime(newTime);
		this.notifyChange(this.forceDay, newTime);
	}

	onToggleMenu = (e) => {
		if (this.state.menuEl)
			this.setState({ menuEl: null });
		else {
			this.setState({ menuEl: e.currentTarget });
		}
	}

	isInRect(lat, long, lat1, long1, lat2, long2) {
		return (long > long1 && long < long2 && lat > lat1 && lat < lat2);
	}

	checkWeather(location) {
		if (!location.weather && location.id && !process.env.REACT_APP_SERVER_SIDE) {
			Server.getPointWeather(location.id);
		}
	}

	toggleWeather = () => {
		const newState = !this.state.showWeather;
		this.setState({ showWeather: newState });
		if (newState)
			this.checkWeather(this.props.location);
	}

	toggleShadows = () => {
		Navigation.toggleShadows();
		this.forceUpdate();
	}

	toggleEnlarge = () => {
		Navigation.toggleEnlarge();
		this.forceUpdate();
	}

	togglePaths = () => {
		Navigation.togglePaths();
		this.forceUpdate();
	}

	// renderLocalLinks(lat, long) {
	// 	var href = null;
	// 	if (this.isInRect(lat, long, 48.3408249, 11.7604687, 51.210434, 18.8238697))
	// 		href = `http://portal.chmi.cz/files/portal/docs/meteo/sat/data_jsmsgview.html?prod=vis-ir&refer=msgcz-1160x800&lon=${long}&lat=${lat}`;
	// 	else {
	// 		if (this.isInRect(lat, long, 45.5351388, 4.8146906, 52.3785299, 24.2055542))
	// 			href = `http://portal.chmi.cz/files/portal/docs/meteo/sat/data_jsmsgview.html?prod=vis-ir&refer=msgce-1160x800&lon=${long}&lat=${lat}`;
	// 		else {
	// 			if (this.isInRect(lat, long, 32.0891535, -30.5559706, 59.1733278, 25.3173009))
	// 				href = `http://portal.chmi.cz/files/portal/docs/meteo/sat/data_jsmsgview.html?prod=vis-ir&refer=msgeu-1160x800&lon=${long}&lat=${lat}`;
	// 		}
	// 	}

	// 	if (!href)
	// 		return null;

	// 	return (
	// 		<>
	// 			<a
	// 				target='_blank'
	// 				rel='noopener noreferrer'
	// 				href={href}
	// 			>
	// 				{'Satellite'}
	// 			</a>
	// 			{'; '}
	// 		</>
	// 	);
	// }

	renderMenu() {
		return (
			<Menu
				anchorEl={this.state.menuEl}
				// transformOrigin={{
				// 	vertical: 'bottom',
				// 	horizontal: 'left',
				// }}
				open={this.state.menuEl !== null}
				onClose={this.onToggleMenu}
				variant='menu'
			>
				{this.props.show3D &&
					<MenuItem onClick={this.togglePaths}>
						<ListItemIcon><PathIcon /></ListItemIcon>
						{'Sun and Moon paths'}
						<ListItemSecondaryAction><Switch edge='end' size='small' onChange={this.togglePaths} checked={Navigation.getPaths()} /></ListItemSecondaryAction>
					</MenuItem>
				}

				{this.props.show3D &&
					<MenuItem onClick={this.toggleEnlarge}>
						<ListItemIcon><EnlargeIcon /></ListItemIcon>
						{'Larger Sun and Moon'}
						<ListItemSecondaryAction><Switch edge='end' size='small' onChange={this.toggleEnlarge} checked={Navigation.getEnlarge()} /></ListItemSecondaryAction>
					</MenuItem>
				}

				<MenuItem onClick={this.toggleShadows}>
					<ListItemIcon><ShadowIcon /></ListItemIcon>
					{'Shadows'}
					<ListItemSecondaryAction><Switch edge='end' size='small' onChange={this.toggleShadows} checked={Navigation.getShadows()} /></ListItemSecondaryAction>
				</MenuItem>

				<MenuItem onClick={this.props.onClickMoon}>
					<ListItemIcon><div style={{ width: 24, height: 24, fill: COLORS.PRIMARY_MAIN }}>{getWeatherIcon(800, '', false)}</div></ListItemIcon>
					{'Moon'}
					<ListItemSecondaryAction><Switch edge='end' size='small' onChange={this.props.onClickMoon} checked={Navigation.getShowMoon()} /></ListItemSecondaryAction>
				</MenuItem>

				<MenuItem onClick={this.toggleWeather}>
					<ListItemIcon><div style={{ width: 24, height: 24, fill: COLORS.PRIMARY_MAIN }}>{getWeatherIcon(801, '', true)}</div></ListItemIcon>
					{'Weather'}
					<ListItemSecondaryAction><Switch edge='end' size='small' onChange={this.toggleWeather} checked={this.state.showWeather} /></ListItemSecondaryAction>
				</MenuItem>

				<MenuItem onClick={this.onToggleDateSlider}>
					<ListItemIcon><YearIcon /></ListItemIcon>
					{'Day selection'}
					<ListItemSecondaryAction><Switch edge='end' size='small' onChange={this.onToggleDateSlider} checked={this.state.showDateSlider} /></ListItemSecondaryAction>
				</MenuItem>
			</Menu>
		);
	}

	render() {
		const { classes, location, mobile, show3D } = this.props;
		if (!location || location.lat === undefined)
			return (<div />);

		return (
			<div style={{ ...this.props.style }} className={this.props.className}>
				{/* Header line */}
				<div style={{ marginBottom: -8, marginTop: -3 }}>
					<div style={{ float: 'right', marginBottom: -17, marginTop: -5, paddingTop: 1 }}>
						{!mobile &&
							<>
								{show3D &&
									<>
										<Tooltip title='Sun and Moon paths' enterDelay={300}>
											<IconButton size='medium' onClick={this.togglePaths}>
												<div style={{ width: 16, height: 16, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
													<PathIcon color={Navigation.getPaths() ? 'secondary' : 'primary'} style={{ width: 18, height: 18 }} />
												</div>
											</IconButton>
										</Tooltip>

										<Tooltip title='Larger Sun and Moon' enterDelay={300}>
											<IconButton size='medium' onClick={this.toggleEnlarge}>
												<div style={{ width: 16, height: 16, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
													<EnlargeIcon color={Navigation.getEnlarge() ? 'secondary' : 'primary'} style={{ width: 18, height: 18 }} />
												</div>
											</IconButton>
										</Tooltip>
									</>
								}

								<Tooltip title='Shadows' enterDelay={300}>
									<IconButton size='medium' onClick={this.toggleShadows}>
										<div style={{ width: 16, height: 16, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
											<ShadowIcon color={Navigation.getShadows() ? 'secondary' : 'primary'} style={{ width: 18, height: 18 }} />
										</div>
									</IconButton>
								</Tooltip>

								<Tooltip title='Moon' enterDelay={300}>
									<IconButton size='medium' onClick={this.props.onClickMoon}>
										<div style={{ width: 24, height: 24, margin: -4, display: 'inline-flex', fill: this.props.showMoon ? COLORS.SECONDARY_MAIN : COLORS.PRIMARY_MAIN }}>
											{getWeatherIcon(800, '', false)}
										</div>
									</IconButton>
								</Tooltip>

								<Tooltip title='Weather' enterDelay={300}>
									<IconButton size='medium' onClick={this.toggleWeather}>
										<div style={{ width: 16, height: 16, display: 'inline-flex', fill: this.state.showWeather ? COLORS.SECONDARY_MAIN : COLORS.PRIMARY_MAIN }}>
											{getWeatherIcon(801, '', true)}
										</div>
									</IconButton>
								</Tooltip>

								<Tooltip title='Day selection' enterDelay={300}>
									<IconButton size='medium' onClick={this.onToggleDateSlider}>
										<YearIcon color={this.state.showDateSlider ? 'secondary' : 'primary'} style={{ width: 18, height: 18 }} />
									</IconButton>
								</Tooltip>
							</>
						}

						{mobile &&
							<IconButton size='medium' onClick={this.onToggleMenu}>
								<div style={{ width: 16, height: 16, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<MoreIcon />
								</div>
							</IconButton>
						}

						<IconButton size='medium' style={{}} onClick={this.props.onClose}>
							<div style={{ width: 16, height: 16, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<CloseIcon />
							</div>
						</IconButton>
					</div>

					<Typography variant='caption' display='block' style={{ marginTop: 5, marginLeft: 16 }}>
						{/* <IconButton size='small' onClick={this.onToggleDateSlider}>
							{this.state.showDateSlider ? <CollapseIcon /> : <ExpandIcon />}
						</IconButton> */}

						{this._times.forceDayText &&
							<a onClick={this.onToggleDatePopup} style={{ cursor: 'pointer' }} href={Navigation.getLocationURL(this.props.location)}> {/* TODO: href should get some real target */}
								{this._times.forceDayText}
							</a>
						}
						<DatePicker
							open={this.state.showDate}
							onClose={this.onToggleDatePopup}
							style={{ width: 0 }}   // Invisible, we use it just as a pop-up  (could be written better in the 4.x version of the component)
							variant='inline'
							value={this._times.forceDay}
							onChange={this.onDatePickerChange}
							views={['month', 'date']}
						/>
						<TimePicker
							open={this.state.showTime}
							onClose={this.onToggleTimePopup}
							style={{ width: 0 }}   // Invisible, we use it just as a pop-up  (could be written better in the 4.x version of the component)
							variant='inline'
							value={this._times.forceTime}
							onChange={this.onTimePickerChange}
							views={['hours', 'minutes']}
						/>
						{' at '}
						<a onClick={this.onToggleTimePopup} style={{ cursor: 'pointer' }} href={Navigation.getLocationURL(this.props.location)}> {/* TODO: href should get some real target */}
							{timeFormat(location, this._times.localTime)}
						</a>
						{`, \u2600 is ${Math.abs(this._times.aboveHorizon)}° ${this._times.aboveHorizon < 0 ? '\u2193' : '\u2191'}`}
					</Typography>


				</div>

				{/* Date selection pop-up */}
				<Collapse in={this.state.showDateSlider} mountOnEnter={true} unmountOnExit={true}>
					<div style={{ marginBottom: -10, marginTop: 14 }}>
						<div style={{ marginLeft: 15, marginRight: 15, marginBottom: -12 }}>
							{/* Slider */}
							<Slider
								style={{
									background: 'black',
									height: 4,
									marginBottom: 10,
								}}
								classes={{
									root: classes.dayContainer,
									track: classes.dayTrack,
									thumb: classes.dayThumb,
									rail: classes.dayRail,
								}}
								min={0}
								max={364}
								step={1}
								value={this._times.dayOfYear}
								onChange={this.dateSliderChange}
							/>
						</div>

						<Typography variant='caption' style={{ display: 'flex', placeContent: 'space-between', justifyContent: 'space-between', marginBottom: 10, marginLeft: '5%', marginRight: '5%' }}>
							{['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII'].map(m =>
								(<span key={m}>{m}</span>)
							)}
						</Typography>
					</div>
				</Collapse>

				<div style={{ marginLeft: 15, marginRight: 15, marginTop: 3, marginBottom: -12 }}>
					{/* Sun & Moon events */}
					<Events
						location={location}
						forceDay={this._times.forceDay}
						dawn={this._times.dawn}
						dusk={this._times.dusk}
						sunrise={this._times.sunrise}
						sunset={this._times.sunset}
						goldenHour={this._times.goldenHour}
						goldenHourEnd={this._times.goldenHourEnd}
						moonRise={this._times.moonRise}
						moonSet={this._times.moonSet}
						classes={classes}
						showMoon={this.props.showMoon}
					/>

					{/* Slider */}
					<div>
						<Slider
							style={{
								background: this.renderGradient(),
								height: 4,
								marginBottom: 10,
								display: 'relative',
							}}
							classes={{
								root: classes.dayContainer,
								track: classes.dayTrack,
								thumb: classes.dayThumb,
								rail: classes.dayRail,
							}}
							min={0}
							max={MS_PER_DAY}
							value={this._times.partOfDay}
							onChange={this.sliderChange}
						/>
					</div>
				</div>

				{/* Timeline */}
				<Timeline
					location={location}
				/>

				{/* Weather */}
				<Collapse in={this.state.showWeather} mountOnEnter={true} unmountOnExit={true}>
					<div className={classes.weather}>
						{/* TODO: move to a separate component, in order to not re-render it everytime */}
						<Weather location={location} sunrise={this._times.sunriseDef} sunset={this._times.sunsetDef} classes={classes} />

						<Typography variant='body1' style={{ textAlign: 'right', marginTop: 10, fontSize: '85%', opacity: 0.87 }}>
							<a
								target={getBlankTarget()}
								rel='noopener noreferrer'
								href={`https://www.windy.com/${location.lat}/${location.long}?radar,${location.lat},${location.long},7`}
							>
								{'Radar'}
							</a>
							{', '}
							<a
								target={getBlankTarget()}
								rel='noopener noreferrer'
								href={`https://www.windy.com/${location.lat}/${location.long}?satellite,${location.lat},${location.long},7`}
							>
								{'Satellite'}
							</a>
							{', '}
							<a
								target={getBlankTarget()}
								rel='noopener noreferrer'
								href={`https://www.windy.com/${location.lat}/${location.long}/meteogram?clouds,${location.lat},${location.long},7,m:e2Dagzc`}
							>
								{'Forecast @ Windy.com'}
							</a>
						</Typography>
					</div>
				</Collapse>

				{this.renderMenu()}
			</div >
		);
	}
}

CurrentConditions.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	style: PropTypes.object,
	location: PropTypes.object,
	mobile: PropTypes.bool,
	show3D: PropTypes.bool,

	showMoon: PropTypes.bool,
	onClickMoon: PropTypes.func,
	onClose: PropTypes.func.isRequired,
};

/** @type {any} */
// @ts-ignore
export default withStyles(styles)(CurrentConditions);