// @ts-check

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AddDescriptionIcon from '@material-ui/icons/PostAdd';
import LocationIcon from '@material-ui/icons/LocationOn';
import MapIcon from '@material-ui/icons/Map';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import ShareIcon from '@material-ui/icons/Share';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import DialogTitleChange from '../dialogs/TitleChange';

import TagItems from './TagItems';
import Description from './Description';
import LocationChange from './LocationChange';
import SpotsBar from './SpotsBar';
import ShareButton from './ShareButton';
// import MapView from '../MapView';
import AddToCollection from './AddToCollection';
import TagHierarchy from './TagHierarchy';

import Webcams from './Webcams';

import PubSub from 'pubsub-js';
import Server from '../server';
import Navigation from '../navigation';
import { MapOutlineLoader } from './MapOutline';
import Link from '@material-ui/core/Link';
import { checkKeyMods } from '../tools';

const styles = /*theme => */({
	floatRight: {
		float: 'right',
	},

	title: {
		fontSize: 18,
		lineHeight: '42px',
	},

	headerButtons: {
		marginTop: -3,
		marginBottom: '0.35em',  // For multi-line headers
	},

	mapBase: {
		height: 220,
		width: 350,
		flexGrow: 0,
		flexBasis: 350,
		flexShrink: 0,
		marginLeft: 10,

		'@media (max-width: 1166px)': {
			marginRight: -12,
		}
	},

	panelRoot: {
		backgroundColor: 'inherit',
		boxShadow: 'none',

		'&::before': {
			backgroundColor: 'inherit',
		}
	},

	panelSummaryContent: {
		padding: 0,
		marginTop: 2,
		marginBottom: 0,
	},

	panelSummaryExpanded: {
		marginBottom: '2px !important',
		marginTop: '2px !important',
		minHeight: '48px !important',
	},

	panelDetailsRoot: {
		padding: 0,
	}
});

class LocationSidebar extends Component {
	state = {
		titleValue: null,
		editTitle: false,
		showTitleChangeDialog: false,

		titleMissing: false,
		imageMissing: false,
		mapMissing: false,

		menuAnchor: null,
		shareAnchorEl: null,

		nearbySpotsExpanded: Navigation.getExpandedNearbySpots(true),
		nearbyWebcamsExpanded: Navigation.getExpandedNearbyWebcams(false),
		mapExpanded: Navigation.getExpandedMap(false),
	}

	getDescrWeight(i) {
		var res = i.image ? i.rating : i.rating * 3 + 4;
		if (i.user && (i.author === undefined || i.author === null || i.license === 'unsplash'))
			res += 0.5;
		return res;
	}

	updateData() {
		const spot = this.props.location || {};

		const descs = (spot.descriptions || []).filter(img => !img.flickr);
		const imgs = (spot.images || []).map(img => Object.assign({}, img, { image: true }));
		if (this.descriptionsID === spot.id) {
			// Don't resort spots that we've already sorted (to avoid jumping of items on rating change)
			const oldDescs = [...(this.descriptions || [])];
			const newDescs = descs.concat(imgs).reverse();
			oldDescs.forEach(itm => itm.newSort = false);
			newDescs.forEach(itm => itm.newSort = true);
			for (var desc of newDescs) {
				const index = oldDescs.findIndex(d => d.id === desc.id); // eslint-disable-line no-loop-func
				if (index >= 0)
					oldDescs[index] = desc;
				else
					oldDescs.splice(0, 0, desc);
			}

			this.descriptions = oldDescs.filter(itm => { const n = itm.newSort; delete itm.newSort; return n; });
		} else {
			// TODO: This branch doesn't seem to be called
			this.descriptionsID = spot.id;
			this.descriptions = []
				.concat(descs, imgs)
				.sort((i1, i2) => this.getDescrWeight(i2) - this.getDescrWeight(i1));
		}
	}

	handleAddDescription = (event) => {
		event.preventDefault();
		event.stopPropagation();
		this.onMenuClose();

		if (!Server.checkLogin(event.currentTarget))
			return;

		const loc = { ...this.props.location };
		loc.descriptions = [{
			id: -1,
			rating: 0,
		}, ...loc.descriptions];
		PubSub.publish('POINT_UPDATED', { point: loc });
		Navigation.editDescription(true, -1);
	}

	handleCoordinates = () => {
		this.onMenuClose();
		Navigation.showGPS(!this.props.showGPS);
		if (this.props.mobile)
			Navigation.showMap(true);
	}

	showAddDescButton = (location) => {
		const { user } = this.props;
		if (!user)
			return true;

		const hasDesc = location.descriptions.find(desc => desc.user && user && (desc.user.id === user.id));
		return !hasDesc;
	}

	getNearbyImages = () => {
		const images = [];

		if (this.props.location && this.props.location.nearby) {
			for (const loc of this.props.location.nearby) { // eslint-disable-line no-unused-vars
				if (loc.images[0])
					images.push({
						...loc.images[0],
						location: loc,
					});
			}
		}

		return images;
	}

	handleNewTag = (tagname) => {
		Server.addTag(this.props.location, tagname);
	}

	handleDeleteTag = (tag) => {
		Server.deleteTag(this.props.location, tag);
	}

	handleRateTag = (tagName, value) => {
		Server.rateTag(this.props.location, tagName, value);
	}

	titleChange = (event) => {
		this.setState({ titleValue: event.target.value });
	}

	nearbySpotsExpanded = (event, expanded) => {
		this.setState({ nearbySpotsExpanded: expanded });
		Navigation.setExpandedNearbySpots(expanded);
	}

	renderNearbySpots(nearbyImages) {
		const { classes } = this.props;
		const location = this.props.location || {};

		if (nearbyImages.length <= 0)
			return null;

		return (
			<Accordion
				classes={{ root: classes.panelRoot }}
				expanded={this.state.nearbySpotsExpanded}
				onChange={this.nearbySpotsExpanded}
				TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.panelSummaryContent, expanded: classes.panelSummaryExpanded }}>
					<Typography className={classes.title} variant='h5' gutterBottom noWrap={true}>
						{'Nearby Spots'}
					</Typography>
				</AccordionSummary>
				<AccordionDetails classes={{ root: classes.panelDetailsRoot }}>
					<SpotsBar
						spots={location.nearby}
						openOnClick={true}
						baseSpot={location}
						height={200}
						margin={6}
						style={{ marginTop: 10 }}
					/>
				</AccordionDetails>
			</Accordion>
		);
	}

	nearbyWebcamsExpanded = (event, expanded) => {
		this.setState({ nearbyWebcamsExpanded: expanded });
		Navigation.setExpandedNearbyWebcams(expanded);
	}

	renderWebcams() {
		const { classes } = this.props;
		const location = this.props.location || {};
		if (!location.id || !location.lat)
			return null;

		return (
			<Accordion
				classes={{ root: classes.panelRoot }}
				expanded={this.state.nearbyWebcamsExpanded}
				onChange={this.nearbyWebcamsExpanded}
				TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.panelSummaryContent, expanded: classes.panelSummaryExpanded }}>
					<Typography className={classes.title} variant='h5' gutterBottom noWrap={true}>
						{'Webcams around'}
					</Typography>
				</AccordionSummary>
				<AccordionDetails classes={{ root: classes.panelDetailsRoot }}>
					<Webcams
						location={location}
						style={{ width: '100%' }}
					/>
				</AccordionDetails>
			</Accordion>
		);
	}

	handleMapExpand = (event) => {
		event.stopPropagation();
		event.preventDefault();
		Navigation.ToggleMap();
	}

	onShowMore = (event) => {
		this.setState({ menuAnchor: event.currentTarget });
	}

	onMenuClose = () => {
		this.setState({ menuAnchor: null });
	}

	onShareOpen = (event) => {
		this.onMenuClose();
		this.setState({ shareAnchorEl: event.currentTarget });
	}

	onCloseSharePopup = () => {
		this.setState({ shareAnchorEl: null });
	}

	handleFixTitle = (event) => {
		if (!Server.checkLogin(event.currentTarget))
			return false;

		this.onMenuClose();
		this.setState({
			editTitle: true,
			titleValue: (this.props.location || {}).title,
		});
	}

	onOKEditTitle = () => {
		this.setState({ editTitle: false });

		Server.editTitle(this.props.location, this.state.titleValue);
	}

	onCancelEditTitle = () => {
		this.setState({ editTitle: false });
	}

	onTitleKeyPress = (event) => {
		if (event.key === 'Enter') {
			this.onOKEditTitle();
			event.preventDefault();
			event.stopPropagation();
		}
	}

	onTitleKeyDown = (event) => {
		if (event.key === 'Escape') {
			this.onCancelEditTitle();
			event.preventDefault();
			event.stopPropagation();
		}
	}

	onShowTitleChangeDialog = (event) => {
		if (!Server.checkLogin(event.currentTarget, 'vote'))
			return false;

		this.setState({ showTitleChangeDialog: true });
	}

	onTitleChangeClose = () => {
		this.setState({ showTitleChangeDialog: false });
	}

	openMap = (e) => {
		if (checkKeyMods(e)) {
			Navigation.showMap(true);
		}
	}

	renderMenu() {
		return (
			<>
				<Menu
					id='menu-more'
					anchorEl={this.state.menuAnchor}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					open={this.state.menuAnchor !== null}
					onClose={this.onMenuClose}
				>
					{this.props.mobile &&
						<MenuItem onClick={this.onShareOpen}>
							<ListItemIcon>
								<ShareIcon />
							</ListItemIcon>
							{'Share'}
						</MenuItem>
					}

					{this.props.mobile &&
						<Divider />
					}

					<MenuItem onClick={this.handleAddDescription}>
						<ListItemIcon>
							<AddDescriptionIcon />
						</ListItemIcon>
						{'Add a Description'}
					</MenuItem>

					<MenuItem onClick={this.handleFixTitle}>
						<ListItemIcon>
							<EditIcon />
						</ListItemIcon>
						{'Edit Title'}
					</MenuItem>

					<Divider />

					<MenuItem onClick={this.handleCoordinates}>
						<ListItemIcon>
							<LocationIcon />
						</ListItemIcon>
						{'Coordinates'}
					</MenuItem>
				</Menu>

				<ShareButton
					popupOnly={true}
					anchorEl={this.state.shareAnchorEl}
					onClose={this.onCloseSharePopup}
					location={this.props.location}
				/>
			</>
		);
	}

	renderTitlePart(oneColumn) {
		const location = this.props.location || {};

		return (
			<div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 12, paddingRight: 12, flexGrow: 10, marginTop: oneColumn ? 0 : 10, }}>
				{!this.state.editTitle &&
					<div style={{ textAlign: 'center', paddingTop: 7, paddingBottom: 12 }}>
						{/* Hierarchy */}
						<div style={{ marginTop: oneColumn ? 7 : 0 }}>
							<TagHierarchy hier={location.hier} />
						</div>

						{/* Title */}
						<h2 style={{ marginTop: 5, marginBottom: 5 }}>
							{location.title || <Skeleton animation='wave' />}
						</h2>

						{location.newTitle &&
							<Tooltip title='Vote on proposed Title change' enterDelay={300}>
								<IconButton onClick={this.onShowTitleChangeDialog} color='secondary' style={{ margin: '-14px 0px -8px 10px' }}>
									<EditIcon />
								</IconButton>
							</Tooltip>
						}
					</div>
				}

				{this.state.editTitle &&
					<div style={{ display: 'flex', alignItems: 'start' }}>
						<Input
							placeholder={'Spot title'}
							className={this.props.classes.input}
							style={{ fontSize: '1.5rem', marginBottom: 15, marginRight: 20, marginTop: 5, flexGrow: 1 }}
							onChange={this.titleChange}
							value={this.state.titleValue}
							onKeyPress={this.onTitleKeyPress}
							onKeyDown={this.onTitleKeyDown}
						/>
						<IconButton onClick={this.onOKEditTitle} color='secondary'>
							<CheckIcon />
						</IconButton>
						<IconButton onClick={this.onCancelEditTitle} color='primary'>
							<CloseIcon />
						</IconButton>
					</div>
				}

				< div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
					{this.props.mobile &&
						<Button
							variant='outlined'
							color='primary'
							aria-label='Map'
							onClick={this.handleMapExpand}
						>
							<MapIcon style={{ marginRight: 8 }} />
							{'Map'}
						</Button>
					}

					{!this.props.mobile &&
						<ShareButton
							className={this.props.classes.headerButtons}
							location={location}
							fullButton={true}
							style={{ marginLeft: 10 }}
						/>
					}

					<AddToCollection
						className={this.props.classes.headerButtons}
						location={this.props.location}
						fullButton={true}
						style={{ marginLeft: 10 }}
					/>

					<Button
						variant='outlined'
						color='primary'
						aria-label='More'
						style={{ marginLeft: 10, minWidth: 32, paddingLeft: 10, paddingRight: 10 }}
						onClick={this.onShowMore}
					>
						<MoreIcon />
					</Button>
				</div>


				{/* Tags */}
				{location.tags ?
					<TagItems
						tags={location.tags}
						location={location}
						onNewTag={this.handleNewTag}
						onDeleteTag={this.handleDeleteTag}
						onRateTag={this.handleRateTag}
						isNew={false}
						editable={true}
					/>
					:
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
						<Skeleton animation='wave' style={{ flexGrow: 1, marginRight: 10, maxWidth: 150 }} />
						<Skeleton animation='wave' style={{ flexGrow: 1, marginRight: 10, maxWidth: 150 }} />
						<Skeleton animation='wave' style={{ flexGrow: 1, maxWidth: 150 }} />
					</div>
				}

				{
					!this.props.mobile &&
					<LocationChange location={location} />
				}
			</div >
		);
	}

	renderSkeletonDescription() {
		return (
			<Paper style={{
				width: 'calc(100% - 20px)',
				padding: 10,
				display: 'flex',
			}}>
				<div style={{ flexGrow: 5 }}>
					<Skeleton variant={'text'} animation='wave' />
					<Skeleton variant={'text'} animation='wave' />
					<Skeleton variant={'text'} animation='wave' />
					<Skeleton variant={'text'} animation='wave' />
					<Skeleton variant={'text'} animation='wave' />
					<Skeleton variant={'text'} animation='wave' style={{ width: '80%' }} />
				</div>
				<Skeleton variant={'rect'} animation='wave' height={200} style={{ flexGrow: 4, marginLeft: 10 }} />
			</Paper>
		);
	}

	renderDescriptions(spot, oneColumn, mobile) {
		const sortedDescriptions = this.descriptions || [];

		if (!sortedDescriptions.length) {
			if (oneColumn) {
				return (
					<>
						{this.renderSkeletonDescription()}
						<div style={{ height: 20 }}></div>
						{this.renderSkeletonDescription()}
					</>
				);
			} else {
				return (
					<div style={{ display: 'flex' }}>
						{this.renderSkeletonDescription()}
						<div style={{ width: 30 }}></div>
						{this.renderSkeletonDescription()}
					</div>
				);
			}
		}

		if (oneColumn) {
			const indImg = sortedDescriptions.findIndex(itm => itm.image);
			var items;
			if (indImg >= 0)
				items = [].concat(sortedDescriptions[indImg], sortedDescriptions.slice(0, indImg), sortedDescriptions.slice(indImg + 1));
			else
				items = sortedDescriptions;

			// One column version
			return (
				<>
					{
						items.map((description, index) => {
							return (
								<Description
									location={spot}
									description={description}
									key={'Desc' + description.id}
									editDescID={!spot || spot.id === 0 ? -1 : this.props.editDescID}
									mobile={mobile}
									important={mobile ? index < 2 : index < 3}
								/>
							);
						})
					}
				</>
			);
		} else {
			// Two columns version
			return (
				<div style={{ display: 'flex', marginTop: 10 }}>
					{/* Left column */}
					<div style={{ flex: '1 1 100px', maxWidth: 'calc(50% - 15px)' }}>
						{sortedDescriptions.filter((item, index) => (index & 1) === 0).map((description, index) => {
							return (
								<Description
									location={spot}
									description={description}
									key={'Desc' + index}
									editDescID={!spot || spot.id === 0 ? -1 : this.props.editDescID}
									mobile={mobile}
									important={mobile ? index < 2 : index < 3}
								/>
							);
						})}
					</div>

					{/* Spacer */}
					<div style={{ flex: '0 0 30px' }}></div>

					{/* Right column */}
					<div style={{ flex: '1 1 100px', maxWidth: 'calc(50% - 15px)' }}>
						{sortedDescriptions.filter((item, index) => (index & 1) === 1).map((description, index) => {
							return (
								<Description
									location={spot}
									description={description}
									key={'Desc' + index}
									editDescID={!spot || spot.id === 0 ? -1 : this.props.editDescID}
									mobile={mobile}
									important={mobile ? index < 2 : index < 3}
								/>
							);
						})}
					</div>
				</div>
			);
		}
	}

	render() {
		this.updateData(); // TODO: There's probably a better time to do this
		const { classes, mobile } = this.props;
		const location = this.props.location || {};
		const nearbyImages = this.getNearbyImages();
		const largeMap = !this.props.large || this.props.fixPosition;
		const hasSmallMap = !this.props.mobile && !largeMap;
		const sortedDescriptions = this.descriptions || [];
		const oneColumnTitle = (this.props.mobile || !this.props.large);
		const oneColumn = oneColumnTitle || sortedDescriptions.length === 1;

		return (
			<>
				{/* Title part */}
				<div style={{
					display: 'flex',
					marginBottom: 20,
					paddingLeft: (hasSmallMap) ? 12 : 0,
					paddingRight: (hasSmallMap) ? 12 : 0,
				}}>

					{this.renderTitlePart(oneColumnTitle)}

					{/* Small map */}
					{hasSmallMap &&
						<Paper
							elevation={7}
							className={(classes.mapBase) + (this.state.mapMissing ? ' invalid' : '')}
						>
							{/* <MapView
								style={{ height: '100%', width: '100%' }}
								location={location}
								fixPosition={this.props.fixPosition}
								showGPS={this.props.showGPS}
								largeMap={false}
								mobile={this.props.mobile}
							/> */}
							<Link href={`${Navigation.getLocationURL(location)}?map`} onClick={(e) => this.openMap(e)}>
								<MapOutlineLoader
									mapId={'minimap'}
									id={location.id}
									style={{ height: '100%' }}
									loading={!location.id || typeof location.long !== 'number'}
									data={{
										// showRect: typeof location.long === 'number' && [[location.long - 0.05, location.lat - 0.05], [location.long + 0.05, location.lat + 0.05]],
										points: typeof location.long === 'number' ? [[location.long, location.lat]] : [],
										view: location.D3 && location.D3.head && { head: location.D3.head, fov: location.D3.fov },
										tags: [(location.hier || '').replaceAll('\\', '$')],
									}}
									mobile={this.props.mobile}
								/>
							</Link>
						</Paper>
					}
				</div>

				<div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 12, paddingRight: 12 }}>
					<div style={{ display: 'flex', flexDirection: oneColumn ? 'column' : 'row' }}>
						<div style={{ flexGrow: 10, flexBasis: 100, maxWidth: '100%' }}>
							{/* Descriptions and Images */}
							{this.renderDescriptions(location, oneColumn, mobile)}
						</div>
					</div>

					{this.renderNearbySpots(nearbyImages)}

					{this.renderWebcams()}

					{this.renderMenu()}

					{
						// @ts-ignore
						<DialogTitleChange
							open={this.state.showTitleChangeDialog}
							onClose={this.onTitleChangeClose}
							image={this.state.image}
							spot={this.props.location}
						/>
					}
				</div>
			</>
		);
	}
}

LocationSidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	location: PropTypes.object,
	editDescID: PropTypes.number,
	large: PropTypes.bool.isRequired,
	landscape: PropTypes.bool.isRequired,

	fixPosition: PropTypes.bool,
	fixPhoto: PropTypes.string,
	showGPS: PropTypes.bool,
	mobile: PropTypes.bool,

	user: PropTypes.object,
};

/** @type {any} */
// @ts-ignore
export default withStyles(styles)(LocationSidebar);
