import React from 'react';

export default function Logo() {
	return (
		<svg width="100%" height="100%" version="1.1" viewBox="0 0 27.919 27.919">
			<path d="m13.875 25.954s-0.4144-0.5997-0.4144-0.5997c-0.3117-0.4516-7.6413-11.111-7.6413-15.39 0-4.4416 3.614-8.0556 8.0557-8.0556 4.4416 0 8.0556 3.614 8.0556 8.0556 0 4.2791-7.3296 14.939-7.6413 15.39 0 0-0.4143 0.5997-0.4143 0.5997m0-23.039c-3.8869 0-7.0487 3.1618-7.0487 7.0486 0 3.4171 5.4803 11.859 7.0487 14.205 1.5683-2.3457 7.0486-10.788 7.0486-14.205 0-3.8868-3.1618-7.0486-7.0486-7.0486" stroke="#000" strokeWidth=".175" />
			<g strokeWidth=".0748">
				<path d="m15.625 9.2405l1.9255-3.2697c-0.9659-0.8993-2.2506-1.4603-3.6754-1.4603-0.3376 0-0.6648 0.0403-0.9852 0.0992l2.7351 4.6308" />
				<path d="m15.212 11.277h3.8848c0.1127-0.4348 0.1919-0.8827 0.1919-1.3533 0-1.3104-0.4839-2.496-1.2597-3.4325l-2.817 4.7858" />
				<path d="m14.044 7.8939l-1.8297-3.0965c-1.5226 0.4937-2.7538 1.6234-3.3509 3.0965h5.1806" />
				<path d="m13.751 11.954l1.824 3.0846c1.5052-0.5021 2.7199-1.6243 3.312-3.0846h-5.136" />
				<path d="m12.557 8.5706h-3.9041c-0.113 0.4348-0.1915 0.8827-0.1915 1.3534 0 1.3164 0.4889 2.5071 1.2708 3.447l2.8248-4.8004" />
				<path d="m12.156 10.587s-1.9434 3.3016-1.9434 3.3016c0.9653 0.8908 2.2449 1.4484 3.6628 1.4484 0.3523 0 0.6953-0.0396 1.0289-0.1032 0 0-2.7483-4.6468-2.7483-4.6468" />
			</g>
		</svg>
	);
}