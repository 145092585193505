// @ts-check
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Images from './Images';

import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

import Navigation from '../navigation';

const styles = /*theme =>*/ ({
	embedRoot: {
		maxWidth: 800,
		maxHeight: 600,
		pointerEvents: 'auto',
	}
});

class Webcams extends Component {
	state = {
		images: [],
		daytime: Navigation.getDaylightWebcams(false),
		/** @type{string | undefined} */
		openWebcamURL: undefined,
	}

	componentDidMount() {
		if (!process.env.REACT_APP_SERVER_SIDE)
			this.updateData();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.location.lat !== prevProps.location.lat || this.props.location.long !== prevProps.location.long)
			this.updateData();

		if (this.state.daytime !== prevState.daytime)
			this.setData(this.state.images);
	}

	updateData() {
		const { location } = this.props;
		if (!location || !location.lat)
			return;

		this.fetchData().then(data => {
			const webcams = data && data.webcams;
			if (!webcams)
				return;

			const images = [];
			for (const webcam of webcams) {
				images.push({
					id: webcam.webcamId,
					location: {
						lat: webcam.location.latitude,
						long: webcam.location.longitude,
						title: webcam.title,
					},
					dayUrl: webcam.images.daylight && webcam.images.daylight.preview,
					curUrl: webcam.images.current.preview,
					embedUrl: webcam.player.day,
				});
			}

			this.setData(images);
		})
		.catch(e => {
			console.log(e);
		});
	}

	setData(images) {
		if (!images || this.unmounted)
			return;

		for (const img of images) {
			img.tempURL = this.state.daytime ? img.dayUrl : img.curUrl;
		}
		this.setState({ images: [...images] });
	}

	fetchData() {
		const { location } = this.props;

		return new Promise((resolve, reject) => {
			const options = {};
			options.headers = new Headers({});
			options.headers.append('X-WINDY-API-KEY', 'rN417imTY5x8i6gALUkUOlzRPq8GiH0j');

			fetch(`https://api.windy.com/webcams/api/v3/webcams?nearby=${location.lat},${location.long},25&lang=en&include=images,location,player,urls`, options)
				.then((result) => {
					try {
						if (!result.ok)
							reject(`Error: ${result.status}: ${result.statusText}`);
						else
							return result.json();
					} catch (e) {
						reject(e);
					}
				}).then((json) => {
					resolve(json);
				}).catch(err => {
					reject(err);
				});
		});
	}

	onDaytimeWebcams = (event, checked) => {
		this.setState({ daytime: checked });
		Navigation.setDaylightWebcams(checked);
	}

	onWebcamClose = () => {
		this.setState({ openWebcamURL: null });
	}

	onImageOpen = (event, image) => {
		this.setState({ openWebcamURL: image.embedUrl });
		event.preventDefault();
		event.stopPropagation();
	}

	render() {
		const { location, classes } = this.props;
		if (!location)
			return null;

		// Daylight switch positition is hacked below, because when placed directly in the header, there were problems with click event processing
		return (
			<div style={{ width: '100%', marginTop: -58 }}>
				<div style={{ display: 'flex', flexDirection: 'row', placeContent: 'flex-end', justifyContent: 'flex-end', alignItems: 'center', marginRight: 45 }}>
					<Typography>
						Daylight
					</Typography>
					<Switch
						onChange={this.onDaytimeWebcams}
						checked={this.state.daytime}
						color='primary'
					/>
				</div>
				<Images
					location={this.props.location}
					images={this.state.images}
					style={{ ...this.props.style }}
					onClick={this.onImageOpen}
					openLocation
				/>

				{/* @ts-ignore */}
				<Modal
					open={Boolean(this.state.openWebcamURL)}
					onClose={this.onWebcamClose}
				>
					<div style={{ display: 'flex', placeContent: 'center', alignItems: 'center', height: '100%', pointerEvents: 'none' }}>
						<iframe
							src={this.state.openWebcamURL}
							title={'Webcam player'}
							className={'aspect43 ' + classes.embedRoot}
						/>
					</div>
				</Modal>
			</div >
		);
	}
}

Webcams.propTypes = {
	classes: PropTypes.object.isRequired,
	style: PropTypes.object,
	location: PropTypes.object,
};

/** @type {any} */
// @ts-ignore
export default withStyles(styles)(Webcams);