// @ts-check

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import WrongSpotIcon from '@material-ui/icons/EditLocation';
import LocationIcon from '@material-ui/icons/LocationOn';

import DialogPhotoMove from '../dialogs/PhotoMove';

import Editor from './Editor';

import ResizeObserver from 'resize-observer-polyfill';

import PubSub from 'pubsub-js';
import Server from '../server';
import Navigation from '../navigation';
import { description2React, MD2React, getServerFromURL, checkKeyMods, getBlankTarget, renderBlurhash } from '../tools';
import { showHelp } from '../dialogs/Help';
import LazyImage from './LazyImage';

const PADDING = 12;

const styles = theme => ({
	description: {
		marginTop: theme.spacing(1),
	},
	leftIcons: {
		width: 'auto',
		marginRight: theme.spacing(1),
		float: 'left',
	},
	descRatingValue: {
		textAlign: 'center',
		fontSize: theme.spacing(2.5),
	},
	descText: {
		minHeight: 70,
		marginBottom: -10,
		marginTop: 13,
	},
	editLink: {
		cursor: 'pointer',
		opacity: 0.7,
	},
	comments: {
		paddingTop: theme.spacing(2),
		clear: 'both',
		fontSize: '90%',
	},
	commentRating: {
		width: theme.spacing(3),
		flexShrink: 0,
		flexGrow: 0,
	},

	hyperButton: {
		opacity: 0.6,
		cursor: 'pointer',
		marginRight: theme.spacing(1),
	},

	descDivider: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
	},

	baseFrame: {
		backgroundColor: 'inherit',
		overflow: 'hidden',
		marginBottom: 30,
		position: 'relative',
		transition: 'max-height 300ms',
		padding: PADDING,
		paddingBottom: 0,
		width: `calc(100% - ${2 * PADDING}px)`,
		marginLeft: 'auto',
		marginRight: 'auto',
	},

	mobileFrame: {
		width: 'calc(100% + 24px)',
		marginLeft: -12,
		marginRight: -12,
	},

	imgLeft: { overflow: 'hidden', float: 'right', marginTop: -PADDING, marginRight: -PADDING, marginLeft: 10, fontSize: 0 },
	imgCenter: { overflow: 'hidden', display: 'flex', justifyContent: 'center', margin: -PADDING, marginBottom: 10 },
	belowImage: { marginBottom: 10 },

	ratingBar: {
		alignItems: 'center',
		width: 'auto',
		marginRight: 10,
	}
});

const COLLAPSE_BAR_HEIGHT = 28;

const MAXOURHEIGHT = 400;
const MAXOTHERHEIGHT = 200;
const MAX_DESCR_HEIGHT = 320;
const MAX_IMGTEXT_VERT = 440;
const FRAME_WIDTHS = [500, 550];

function getImgCls(width) {
	const need = width + 200;
	if (need <= FRAME_WIDTHS[FRAME_WIDTHS.length - 1]) {
		return FRAME_WIDTHS.find(v => v >= need);
	}
}

class Description extends PureComponent {
	state = {
		// forceHeight: DEFAULT_HEIGHT,
		showReadMore: false,
		showReadLess: false,

		showPhotoMove: false,
		menuAnchor: null,

		imgCenter: false,
		belowImage: false,

		forceHeight: 0,
	}
	/** @type{any} */
	_subscrEdit;
	/** @type{any} */
	ro;
	/** @type{any} */
	_img;

	isEdit() {
		return (this.props.editDescID !== undefined);
	}

	dynamicUpdate = () => {
		const { description } = this.props;
		const image = description.image ? description : undefined;
		let MAXHEIGHT = MAX_DESCR_HEIGHT;
		let floats;
		let imgHeight = 0;
		if (image) {
			const AR = (image && image.ar) || 1.5;
			imgHeight = (Navigation.isOurImg(image) ? MAXOURHEIGHT : MAXOTHERHEIGHT);
			const imgWidth = imgHeight * AR;
			const cls = getImgCls(imgWidth);
			if (cls) {
				floats = this._frame && this._frame.offsetWidth >= cls;
				MAXHEIGHT = floats ? MAX_IMGTEXT_VERT : 150;
			} else
				MAXHEIGHT = 150;
		}
		const text = this._text;
		if (text) {
			// @ts-ignore
			const h = floats ? text.parentElement.scrollHeight : text.scrollHeight;
			if (h > MAXHEIGHT) {
				if (h - MAXHEIGHT < 50)
					this.setState({ showReadMore: false, forceHeight: h });
				else
					this.setState({ showReadMore: true, totalHeight: h });
			} else {
				this.setState({ forceHeight: 0, showReadMore: false });
			}
			this.setState({ floats, processed: true });

			if (this.state.showReadLess) {
				this.setState({ forceHeight: h });
			}
		}
	}

	componentDidMount() {
		this.dynamicUpdate();
		this._subscrEdit = PubSub.subscribe('EDIT_DESCRIPTION', (msg, data) => { // eslint-disable-line no-unused-vars
			if (this.props.description.id === data.id)
				this.editDescriptionID(data.id);
		});

		// update canvas
		const desc = this.props.description;
		if (desc && desc.image && desc.blurhash)
			renderBlurhash(desc.blurhash, this._canvas);
	}

	componentWillUnmount() {
		PubSub.unsubscribe(this._subscrEdit);

		if (this._frame)
			this.ro.unobserve(this._frame);
		this.ro = undefined;
	}

	componentDidUpdate() {
		this.dynamicUpdate();
	}

	renderComment(comment, index) {
		const { classes } = this.props;

		return (
			<React.Fragment key={'Comment' + index}>
				<Divider />
				<Grid container direction='row' wrap='nowrap'>
					<div className={classes.commentRating}>
						{comment.rating}
					</div>
					<div>
						{comment.text}
					</div>
				</Grid>
			</React.Fragment>
		);
	}

	handleDescRatingUp = (event) => {
		const descID = Number(event.currentTarget.dataset.id);
		Server.rateDescUp(this.props.location, descID, event.currentTarget);
	}

	handleDescRatingDown = (event) => {
		const descID = Number(event.currentTarget.dataset.id);
		Server.rateDescDown(this.props.location, descID, event.currentTarget);
	}

	editDescriptionID = (ID) => {
		Navigation.editDescription(true, ID);
	}

	editDescription = (event) => {
		if (!Server.checkLogin(event.currentTarget))
			return;

		this.editDescriptionID(Number(event.currentTarget.dataset.id));
	}

	photoClick = (event) => {
		if (checkKeyMods(event)) {
			const image = this.props.description;
			Navigation.openImage(image);
		}
	}

	frameRef = (ref) => {
		this._frame = ref;

		if (ref && !this.ro) {
			this.ro = new ResizeObserver(() => {
				this.dynamicUpdate();
			});

			this.ro.observe(this._frame);
		}
	}

	onExpand = () => {
		this.setState({
			showReadLess: true,
			showReadMore: false,
			forceHeight: this.state.totalHeight,
		});
	}

	onCollapse = () => {
		this.setState({
			showReadLess: false,
			showReadMore: true,
			forceHeight: 0,
		});
	}

	getImageByID(idImg) {
		const images = this.props.location.images;
		if (images)
			return images.find(img => img.id === idImg);
	}

	handleImgRatingUp = (event) => {
		Server.rateImgUp(this.props.location, this.getImageByID(event.currentTarget.dataset.id), event.currentTarget);
	}

	handleImgRatingDown = (event) => {
		Server.rateImgDown(this.props.location, this.getImageByID(event.currentTarget.dataset.id), event.currentTarget);
	}

	showMoreMenu = (event) => {
		this.setState({
			menuAnchor: event.currentTarget,
			idImage: event.currentTarget.dataset.id,
		});

		event.preventDefault();
		event.stopPropagation();
	}

	closeMoreMenu = () => {
		this.setState({ menuAnchor: null });
	}

	onMovePhoto = (event) => {
		this.closeMoreMenu();

		if (!Server.checkLogin(event.currentTarget))
			return;

		Navigation.fixPhotoSpot(this.state.idImage);
	}

	showPhotoMove = (event) => {
		const image = this.getImageByID(event.currentTarget.dataset.id);

		this.setState({
			showPhotoMove: true,
			image: image,
		});

		event.preventDefault();
		event.stopPropagation();
	}

	onPhotoMoveClose = () => {
		this.setState({
			showPhotoMove: false,
			image: null,
		});
	}

	onTestShotHelp = () => {
		showHelp('testshot');
	}

	/**
	 * Shorten external description, so that we can show it (and not infringe copyright)
	 * @param {string} desc
	 */
	shortenExtDescription(desc) {
		if (desc && desc.length > 150) {
			const m = desc
				.replace(/\[(.*?)\]\(.*?\)/g, '$1')  // Remove hyperlinks
				.slice(0, 150) // Shorten
				.match(/((?:.|\n)*)[\s,]+/); // End at a word end
			if (m)
				return m[1] + '...';
		} else
			return desc;
	}

	renderRatingBar(description, float) {
		const { classes } = this.props;

		return (
			<div
				style={{
					float: float ? 'left' : 'none',
					clear: float ? 'left' : 'none',
					marginRight: 5,
					display: 'inline',
					whiteSpace: 'nowrap',
					marginTop: description.image && description.description ? 25 : 5
				}}
			>
				{/* <Tooltip title='This description is useful' enterDelay={300}> */}
				<IconButton aria-label='Thumb Up' onClick={description.image ? this.handleImgRatingUp : this.handleDescRatingUp} data-id={description.id}>
					<ThumbUpIcon />
				</IconButton>
				{/* </Tooltip> */}
				<Typography variant='subtitle1' className={classes.descRatingValue} style={{ display: 'inline' }}>
					{description.rating}
				</Typography>
				{/* <Tooltip title='This describtion is not useful' enterDelay={300}> */}
				<IconButton aria-label='Thumb Down' onClick={description.image ? this.handleImgRatingDown : this.handleDescRatingDown} data-id={description.id}>
					<ThumbDownIcon />
				</IconButton>
				{/* </Tooltip> */}

				{description.image && !description.newSpot &&
					<IconButton aria-label='Image menu more' onClick={this.showMoreMenu} data-id={description.id}>
						<MoreIcon />
					</IconButton>
				}

				{description.image && description.newSpot &&
					<IconButton
						color='secondary'
						onClick={this.showPhotoMove}
						aria-label='wrong spot'
						data-id={description.id}
					>
						<WrongSpotIcon />
					</IconButton>
				}
			</div>
		);
	}

	onLocationClick = (e) => {
		if (checkKeyMods(e))
			Navigation.goSpot(this.props.description.location);
	}

	renderDescriptionBody(description) {
		const { classes, mobile, showLocation, important } = this.props;
		const imgCenter = true;

		const image = description.image ? description : null;
		var descriptionText = description.description ? description.description : description.text || '';
		if (Navigation.isFlickr(description))
			descriptionText = this.shortenExtDescription(descriptionText);
		const linkURL = description.linkURL || (Navigation.isFlickr(description) ? Navigation.getPhotoURL(image) : null);

		const AR = (image && image.ar) || 1.5;
		const ARcss = Math.round(AR * 100) + ' / 100';
		const imgWidth = Math.round((Navigation.isOurImg(image) ? MAXOURHEIGHT : MAXOTHERHEIGHT) * AR);

		const cls = image && getImgCls(imgWidth);
		const clsW = (cls ? ('need' + cls) : '');

		let frameStyle = {};
		let textStyle = {};
		if (!clsW || (this.state.processed && !this.state.floats))
			textStyle = { maxHeight: this.state.forceHeight || (image ? 150 : MAX_DESCR_HEIGHT) };
		if (clsW && this.state.forceHeight && (!this.state.processed || this.state.floats)) {
			frameStyle = {
				maxHeight: this.state.forceHeight || MAX_IMGTEXT_VERT,
			};
		}

		const floatRating = !image || imgCenter;

		if (description.flickr) {
			return null;
			// return (

			// 	<div className={classes.descText} style={{ marginTop: 30 /* for a dumb vertical centering of the text below */ }}>
			// 		<p>
			// 			{'This spot doesn\'t have any description yet. If you know this location, please add a '}
			// 			<span className={classes.editLink} onClick={this.editDescription} data-id={description.id}>{'description'}</span>
			// 			{' or your own '}
			// 			<span className={classes.editLink} onClick={this.handleAddImage} >{'photographs'}</span>
			// 			{'.'}
			// 		</p>
			// 	</div>
			// );
		} else {
			return (
				<div
					ref={this.frameRef}
					className={mobile ? ' ' + classes.mobileFrame : ''}
					style={{
						// @ts-ignore
						containerName: clsW,
						containerType: 'inline-size',
					}}
				>
					<Paper
						elevation={3}
						className={'defText ' + classes.baseFrame}
						style={frameStyle}
					>
						{/* Photograph */}
						{image &&
							<span className={(imgCenter ? classes.imgCenter : classes.imgLeft) + ' ' + (this.state.belowImage ? classes.belowImage : '') + ' descImg'}>
								<a href={Navigation.getPhotoURL(image)} data-photo={image.id} onClick={this.photoClick} style={{ position: 'relative', display: 'block', width: imgWidth, aspectRatio: ARcss }}>
									<canvas
										ref={r => this._canvas = r}
										style={{
											position: 'absolute',
											width: '100%',
											height: '100%',
											top: 0,
										}}
										width={32} height={32}
									/>
									<LazyImage
										src={Server.getImageThumb(image)}
										style={{
											position: 'absolute',
											width: '100%',
											top: 0,
										}}
										alt={image.title}
										ref={i => this._img = i}
										important={important}
									/>
								</a>
							</span>
						}

						<div style={textStyle} ref={t => this._text = t} className={'descText ' + clsW}>

							{/* Title and author for Tag page*/}
							{showLocation && image && description.location &&
								<span className='uimgauthor' style={{ display: imgCenter ? 'block' : 'inline' }}>
									<a className={classes.locationLink} href={Navigation.getLocationURL(description.location)} onClick={this.onLocationClick} style={{ fontWeight: 'bold' }}>
										<LocationIcon color='action' fontSize='small' className={classes.locationHover} style={{ marginRight: 2, marginBottom: -3, marginLeft: imgCenter ? -10 : -4 }} />
										{(description.location.title || '')}
										<br />
									</a>
									{description.author &&
										<>
											{description.authorUrl ?
												(<>{' by '} <a href={description.authorUrl} target={getBlankTarget()} rel='noopener noreferrer'>{description.author}</a></>) :
												' by ' + description.author
											}
											<br />
										</>
									}
									{description.user && !(description.author || description.author === '') &&
										<>
											{' by '}
											<a href={Navigation.getUserURL(description.user.username)}>{description.user.displayName}</a>
											<br />
										</>
									}
									{!imgCenter && showLocation && <br />}
								</span>
							}

							{/* Title and author for Location page*/}
							{!showLocation && image &&
								<span className='uimgauthor' style={{ display: imgCenter ? 'block' : 'inline' }}>
									<span style={{ fontWeight: 'bold' }}>
										{(description.title || '')}
									</span>
									{description.author &&
										(description.authorUrl ?
											(<>{' by '} <a href={description.authorUrl} target={getBlankTarget()} rel='noopener noreferrer'>{description.author}</a></>) :
											(' by ' + description.author)
										)
									}
									{description.user && !(description.author || description.author === '') &&
										<>
											{' by '}
											<a href={Navigation.getUserURL(description.user.username)}>{description.user.displayName}</a>
										</>
									}
									{image && image.imgType === 1 &&
										<span style={{ paddingLeft: 30, whiteSpace: 'nowrap', cursor: 'help', opacity: 0.6 }} onClick={this.onTestShotHelp}>
											{'TEST SHOT'}
										</span>
									}
									<br />
									{this.renderRatingBar(description, floatRating)}
								</span>
							}

							<div style={{ wordBreak: 'break-word', marginBottom: descriptionText ? '-1em' : 0 /* to substract the bottom margin of the last </p> from the text */ }}>
								{/* Rating bar */}
								{!image && this.renderRatingBar(description, true)}
								{image ? MD2React(descriptionText) : description2React(descriptionText)}
							</div>

							{linkURL &&
								<span style={{ fontSize: '90%', opacity: 0.8 }}>
									{/* <br /> */}
									<a target={getBlankTarget()} rel='noopener noreferrer' href={linkURL} style={{}}>{getServerFromURL(linkURL)}</a>
								</span>
							}


							{/* Edit description */}
							{!image && description.user && Server.isUserLogged(description.user.id) &&
								<span className={classes.hyperButton} style={{ float: 'right', paddingTop: '1em' }} onClick={this.editDescription} data-id={description.id}>
									{'edit'}
								</span>
							}

							{!image &&
								<div style={{ clear: 'left', paddingTop: '1em', paddingBottom: PADDING }}>
									<span className='uimgauthor' style={{ borderTop: 'solid rgba(0, 0, 0, 0.2) 1px', paddingTop: 4, marginTop: -5 }}>
										{description.user &&
											<>
												{' by '}
												<a href={Navigation.getUserURL(description.user.username)}>{description.user.displayName}</a>
											</>
										}
									</span>
								</div>
							}

							<div style={{ clear: 'both' }}></div>

							{this.state.showReadMore && !this.state.showReadLess &&
								<div style={{
									position: 'absolute', left: 0, right: 0, bottom: 0, height: 65,
									backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 90%, rgb(255, 255, 255) 100%)',
									display: 'flex', justifyContent: 'center', alignItems: 'flex-end',
									pointerEvents: 'none',
								}}>
									<IconButton size='small' onClick={this.onExpand} style={{ pointerEvents: 'all' }} aria-label='Show more'>
										<ExpandMoreIcon fontSize='large' />
									</IconButton>
								</div>
							}

							{(this.state.showReadMore || this.state.showReadLess) &&
								<div style={{
									height: COLLAPSE_BAR_HEIGHT,
									display: 'flex', justifyContent: 'center', alignItems: 'flex-end',
								}}>
									{this.state.showReadLess &&
										<IconButton size='small' onClick={this.onCollapse} aria-label='Show less'>
											<ExpandLessIcon fontSize='large' />
										</IconButton>
									}
								</div>
							}
						</div>
					</Paper>

					{/* Comments to be enabled, when implemented
					{!commentsExist ?
						<span className={classes.hyperButton} style={{ float: 'right' }}>{'comment'}</span> :
						null
					} */}
				</div>
			);
		}
	}

	currentMarkdown = '';
	currentEditDescID = null;

	handleSaveDescEdit = () => {
		Server.editDescription(
			this.props.location,
			this.currentEditDescID,
			this.currentMarkdown);
		Navigation.editDescription(false);
	}

	handleCancelDescEdit = () => {
		if (this.props.description.id < 0) {
			// A temporary description, to be removed
			this.props.location.descriptions = this.props.location.descriptions.slice(1);
		}
		Navigation.editDescription(false);
		PubSub.publish('POINT_UPDATED', { point: { ...this.props.location } });
	}

	handleEditorChange = (newMarkdown) => {
		this.currentMarkdown = newMarkdown;
		const location = this.props.location;

		if (location.id === 0 && newMarkdown !== this.getInitDesc()) {
			this.props.description.text = this.currentMarkdown; // Update it continually
			PubSub.publish('POINT_UPDATED', { point: { ...this.props.location } });
		}

		// We currently don't support this, title is edited separately
		// if (this.props.location.id === 0) {
		// 	// It's a new location, update it's title based on the current edit
		// 	this.props.location.title = description2Title(newMarkdown);
		// 	PubSub.publish('POINT_UPDATED', { point: this.props.location });
		// }
	}

	getInitDesc = () => {
		const description = this.props.description;
		// const isNew = this.props.location.id === 0;

		const defaultBody = 'Describe the location here\n\n**Photo tips:** Suggested lens, time of day, etc.\n\n**Getting there:** Where to park, opening hours, etc.';

		if (description.flickr)
			description.text = undefined; // To start with our default

		return description.text || defaultBody;
	}

	render() {
		const description = this.props.description;
		// const { classes } = this.props;
		// const commentsExist = description.comments && description.comments.length > 0;
		const isNew = this.props.location.id === 0;

		if (this.props.editDescID === description.id) {
			// === Editor ===
			this.currentEditDescID = description.id;
			if (!this.currentMarkdown)
				this.currentMarkdown = this.getInitDesc();

			return (
				<React.Fragment>
					<Editor
						initialValue={this.currentMarkdown}
						onChange={this.handleEditorChange}
						className='smallEditor' />

					{!isNew &&
						<div style={{ marginBottom: 20, marginTop: 15, display: 'flex', justifyContent: 'flex-end', paddingBottom: 6, paddingRight: 8 }}>
							<Button style={{ marginRight: 5, paddingLeft: '1em', paddingRight: '1em' }} onClick={this.handleCancelDescEdit}>
								{'Cancel'}
							</Button>
							<Button variant='contained' color='secondary' style={{ paddingLeft: '1.5em', paddingRight: '1.5em' }} onClick={this.handleSaveDescEdit}>
								{'Save'}
							</Button>
						</div>
					}
				</React.Fragment>
			);

		} else {

			// === Description ===
			return (
				<React.Fragment>
					{/* Description body */}
					{this.renderDescriptionBody(description)}

					<Menu
						id='menu-more'
						anchorEl={this.state.menuAnchor}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
						open={this.state.menuAnchor !== null}
						onClose={this.closeMoreMenu}
					>
						<MenuItem onClick={this.onMovePhoto}>
							<ListItemIcon>
								<WrongSpotIcon />
							</ListItemIcon>
							{'Change Photo Location...'}
						</MenuItem>
					</Menu>

					{
						// @ts-ignore
						<DialogPhotoMove
							open={this.state.showPhotoMove}
							onClose={this.onPhotoMoveClose}
							image={this.state.image}
							spot={this.props.location}
						/>
					}

					{/* Comments */}
					{/* {commentsExist ?
						<React.Fragment>
							<div className={classes.comments}>
								{description.comments.map((comment, index) => {
									return this.renderComment(comment, index);
								})}
							</div>
							<Divider />
						</React.Fragment>
						: null
					}

					{commentsExist ?
						<span className={classes.hyperButton} style={{ float: 'right' }}>comment</span> :
						null
					} */}
				</React.Fragment>
			);
		}
	}
}

Description.propTypes = {
	classes: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	description: PropTypes.object.isRequired,
	editDescID: PropTypes.number,
	mobile: PropTypes.bool,
	showLocation: PropTypes.bool,
	important: PropTypes.bool,
};

/** @type {any} */
// @ts-ignore
export default withStyles(styles)(Description);
