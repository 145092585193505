// @ts-check
/* eslint-disable no-console */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import Server from '../server';
import Navigation from '../navigation';
import PubSub from 'pubsub-js';

import IconDist from '@material-ui/icons/ArrowUpward';
import IconLocation from '@material-ui/icons/LocationOn';
import IconMenu from '@material-ui/icons/MoreHoriz';

import { formatDistance, bearingTo, checkKeyMods } from '../tools';
import 'justifiedGallery/dist/css/justifiedGallery.css'; // We need this CSS right away in order to not show images until they are loaded
import ResizeObserver from 'resize-observer-polyfill';

var jGalleryFn, jGalleryPromise;

function justifiedGallery(element, command, imagesClass) {
	if (process.env.REACT_APP_SERVER_SIDE)
		return;

	if (jGalleryFn)
		return jGalleryFn(element, command);
	else {
		if (!jGalleryPromise) {
			jGalleryPromise = import(/* webpackMode: "eager" */ './justifiedGallery');
		}

		jGalleryPromise.then(jg => {
			jGalleryFn = jg.justifiedGallery;
			if (!imagesClass._destroying || command === 'destroy') { // Don't continue with object creation if we are about to be destroyed
				jGalleryFn(element, command);
			}
		});
	}
}

const styles = theme => ({
	imgbox: {
		cursor: 'pointer',
		position: 'relative',
		display: 'inline-block',
	},
	img: {
		height: '100%',
	},

	overlay: {
		position: 'absolute',
		left: 0,
		right: 0,
		color: 'white',
		opacity: 0,
		pointerEvents: 'none',

		fontFamily: theme.typography.body1.fontFamily,
		fontSize: 15,

		transition: theme.transitions.create('opacity'),
		':hover > &': {
			opacity: 1,
		},
	},

	caption: {
		bottom: 0,
		padding: 5,
		backgroundImage: 'linear-gradient(to bottom, transparent, rgba(0,0,0,0.85))',
	},

	overTop: {
		top: 0,
		paddingLeft: 5,
		paddingRight: 5,
		backgroundImage: 'linear-gradient(to top, transparent, rgba(0,0,0,0.7))',
	},

	spot: {
		color: 'white',
		opacity: 0.7,
		transition: '0.3s ease',
		pointerEvents: 'auto',

		'&:hover': {
			opacity: 1,
			color: theme.palette.secondary.main,
		},
	},

	menuIcon: {
		color: 'white',
		pointerEvents: 'all',
		marginTop: 3,

		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.18)',
		},
	},

	authorLink: {
		color: 'white',
		transition: '0.1s ease',
		pointerEvents: 'auto',

		'&:hover': {
			color: theme.palette.primary.light,
		}
	}
});

class Images extends Component {
	state = {
		maxImages: 6,
	}
	imgDelta = 6;
	scrollHeight = 0;
	idImages = Navigation.getUID();
	/** @type{any} */
	_elRef = null;
	_destroying = false;
	/** @type{any} */
	ro;
	/** @type{any} */
	jg = null;
	/** @type{any} */
	scrollSubscr;

	componentDidMount() {
		if (this.props.images)
			this.updateGallery();

		this.scrollSubscr = PubSub.subscribe('MAIN_SCROLL', (msg, data) => {
			this.onScroll(data.scrollTop, data.scrollHeight, data.scrollerHeight);
		});
	}

	componentWillUnmount() {
		if (this._elRef)
			this.ro.unobserve(this._elRef);
		this.ro = undefined;
		this._elRef = null;
		PubSub.unsubscribe(this.scrollSubscr);
		this._destroying = true;

		if (this.jg) {
			try {
				justifiedGallery('.justified-gallery' + this.idImages, 'destroy', this);
			} catch (e) {
				console.log(`JG issue: ${e}`);
			}
			this.jg = undefined;
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.images !== this.props.images || prevState.maxImages !== this.state.maxImages)
			this.updateGallery(prevProps.images);
	}

	setRef = (ref) => {
		this._elRef = ref;
		if (ref) {
			if (!this.ro) {
				this.ro = new ResizeObserver(() => {
					this.dynamicUpdate();
				});

				this.ro.observe(this._elRef);
			}
		}
	}

	dynamicUpdate() {
		if (this._elRef) {
			const maxImages = Math.max(8, 1.2 * this._elRef.clientWidth / this.getHeight() * window.innerHeight / this.getHeight());
			if (maxImages > this.state.maxImages) {
				this.setState({ maxImages });
			}
			this.imgDelta = maxImages / 2;
		}
	}

	lastScroll = 0;

	onScroll = (scrollTop, scrollHeight, scrollerHeight) => {
		if (scrollTop + (1.5 * scrollerHeight) > scrollHeight &&
			scrollHeight > this.scrollHeight &&
			Date.now() - this.lastScroll > 250 /*don't load too often*/) {
			this.scrollHeight = scrollHeight;
			this.setState({
				maxImages: this.state.maxImages + this.imgDelta,
			});
			this.lastScroll = Date.now();
		}
	}

	getHeight() {
		return (this.props.height / Math.min(2, window.devicePixelRatio)) || 150;
	}

	updateGallery(oldImages) {
		if (oldImages) {
			for (var i = 0; i < oldImages.length; i++) {
				if (this.getObjImageURL(oldImages[i]) !== this.getImageURL(i)) {
					this.jg = false; // To force redraw of all images
					this.scrollHeight = 0;
					this.setState({ maxImages: this.imgDelta });
					break;
				}
			}
		}

		if (this.jg) {
			justifiedGallery('.justified-gallery' + this.idImages, 'norewind', this);
		} else {
			this.jg = true;
			justifiedGallery('.justified-gallery' + this.idImages, {
				rowHeight: this.getHeight(),
				lastRow: this.props.lastRow || 'nojustify',
				margins: this.props.margins || 1,
				border: 0,
				captions: false,
				maxRowHeight: '140%',
				refreshSensitivity: 20, // TODO: Remove this later and find a proper fix to images flickering with/without scrollbar (e.g. http://localhost:3000/?loc=7171)
			}, this);
		}
	}

	getObjImageURL = (image) => {
		if (!image)
			return null;

		if (image.url)
			return image.url;
		else {
			if (this.props.openLocation && image.location)
				return Navigation.getLocationURL(image.location);
			else {
				return Navigation.getPhotoURL(image);
			}
		}
	}

	getImageURL = (index) => {
		const images = this.getImages();
		if (!images)
			return null;
		else
			return this.getObjImageURL(images[index]);
	}

	imgClick = (event) => {
		if (event.ctrlKey || event.altKey || event.shiftKey)
			return;

		const index = event.currentTarget.dataset.index;
		const image = this.getImages()[index];

		if (this.props.onClick) {
			this.props.onClick(event, image, index);
			return;
		}

		if (this.props.openLocation) {
			// Open the location
			if (!image.location) {
				console.error('image location expected!');
				return;
			}

			Navigation.goSpot(image.location);
		} else {
			Navigation.openImage(image);
		}

		event.preventDefault();
		event.stopPropagation();
	}

	getImages = () => {
		return this.props.images;
	}

	onUserClick = (event) => {
		if (checkKeyMods(event))
			Navigation.showProfile(event.currentTarget.dataset.id);
	}

	onMouseOver = (event) => {
		const index = event.currentTarget.dataset.index;
		const image = this.getImages()[index];
		const { location } = image;

		if (location)
			PubSub.publish('HIGHLIGHT_COORDS', { long: location.long, lat: location.lat });
	}

	onMouseOut = () => {
		// To cancel the hightlight
		PubSub.publish('HIGHLIGHT_COORDS', {});
	}

	openLocation = (event) => {
		if (checkKeyMods(event)) {
			const index = event.currentTarget.dataset.id;
			Navigation.goSpot(this.getImages()[index].location);
		}
	}

	openImageMenu = (e) => {
		this.props.onOverflowClick(e, Number(e.currentTarget.dataset.id));
	}

	renderImage = (img, index) => {
		const { classes } = this.props;
		const title = this.props.openLocation && img.location ? img.location.title : img.title;
		var byUser;
		if (img.user) {
			byUser = img.user.displayName || (img.user.firstName + ' ' + img.user.lastName);
		}

		const showDist = this.props.openLocation && img.location && this.props.location;
		const showLoc = !this.props.openLocation && img.location;

		return (
			<div
				className={classes.imgbox}
				key={img.id}
			>
				<a
					data-index={index}
					href={this.getImageURL(index)}
					onClick={this.imgClick}
					onMouseEnter={this.onMouseOver}
					onMouseLeave={this.onMouseOut}
				>
					<img
						src={Server.getImageThumb(img)}
						className={classes.img}
						data-index={index}
						alt={title}
						loading='lazy'
					/>
				</a>

				{/* Title overlay */}
				<div className={classes.overlay + ' ' + classes.caption}>
					<br /><br /><br /><br />  {/* To make the overlay background smoother */}
					{title}
					{byUser &&
						<React.Fragment>
							<br />
							{' by '}
							<a href={Navigation.getUserURL(img.user.username)} className={classes.authorLink} onClick={this.onUserClick} data-id={img.user.username}>
								{byUser}
							</a>
						</React.Fragment>
					}
				</div>

				{(showDist || showLoc || this.props.onOverflowClick) &&
					<div className={classes.overlay + ' ' + classes.overTop}>
						{this.props.onOverflowClick &&
							<div style={{ float: 'left' }}>
								<IconButton classes={{ root: classes.menuIcon }} color='primary' size='small' onClick={this.openImageMenu} data-id={index}>
									<IconMenu />
								</IconButton>
							</div>
						}

						{showLoc &&
							<div style={{ float: 'right' }}>
								<a
									href={Navigation.getLocationURL(img.location)}
									onClick={this.openLocation}
									data-id={index}
								>
									<Tooltip title='Open the Spot' enterDelay={300}>
										<IconLocation style={{ marginTop: 5 }} className={classes.spot} />
									</Tooltip>
								</a>
							</div>
						}

						{showDist &&
							<div style={{ float: 'right' }}>
								<IconDist style={{ marginRight: 5, transform: `rotate(${bearingTo(this.props.location, img.location)}deg)` }} />
								<span>{formatDistance(this.props.location, img.location)}</span>
							</div>
						}
					</div>
				}
			</div>
		);
	}

	render() {
		const images = this.getImages();

		return (
			<div className={'justified-gallery justified-gallery' + this.idImages} style={{ ...this.props.style }} ref={this.setRef}>
				{images && images.slice(0, this.state.maxImages).map((img, index) =>
					this.renderImage(img, index)
				)}
			</div>
		);
	}
}

Images.propTypes = {
	classes: PropTypes.object.isRequired,
	style: PropTypes.object,
	height: PropTypes.number,
	margins: PropTypes.number,
	location: PropTypes.object,
	images: PropTypes.array,
	openLocation: PropTypes.bool, // Open the location instead of the image
	onClick: PropTypes.func,
	emptyImage: PropTypes.element,
	lastRow: PropTypes.string,
	onOverflowClick: PropTypes.func,
};

/** @type {any} */
// @ts-ignore (some silly style check?)
export default withStyles(styles)(Images);
