//@ts-check
/* eslint-disable no-console */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import SearchBar from 'material-ui-search-bar';

import AppHeader from './AppHeader';
import Images from './Images';
import Typography from '@material-ui/core/Typography';
import ImageWithTitle from './ImageWithTitle';
import VideoPlayer from './VideoPlayer';
import Footer from './Footer';

import MapIcon from '@material-ui/icons/Map';
import NearbyIcon from '@material-ui/icons/MyLocation';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

import Server from '../server';
import PubSub from 'pubsub-js';
import Navigation from '../navigation';

import { checkKeyMods/*, getBlankTarget*/ } from '../tools';
import Logo from '../icons/Logo';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation as SwiperNavigation, Scrollbar as SwiperScrollbar, FreeMode as SwiperFreeMode } from 'swiper';
import './Swiper/swiper-bundle.css';

import { tags } from './PopularLocations';

// Install modules
SwiperCore.use([SwiperNavigation, SwiperScrollbar, SwiperFreeMode]);

const style = theme => ({
	header: {
		height: 460,
		minHeight: '30vh',
		maxHeight: '55vh',
		backgroundColor: theme.palette.primary.light,
		backgroundSize: 'cover',
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		willChange: 'transform',
		overflow: 'hidden',
	},

	headerButton: {
		width: 'calc(50% - 10px)',
		height: 38,
		backgroundColor: 'rgba(255, 255, 255, 0.8)',
		whiteSpace: 'nowrap',
	},

	content: {
		backgroundColor: theme.palette.background.default,
		zIndex: 10,
		position: 'relative',
	},

	panelRoot: {
		backgroundColor: 'inherit',
		boxShadow: 'none',

		'&::before': {
			backgroundColor: 'inherit',
		}
	},

	searchBarButton: {
		padding: 8,
	},

	panelSummaryRoot: {
		padding: 0,
		marginTop: -10,
	},
	panelSummaryContent: {
		alignItems: 'center',
	},

	tags: {
		paddingTop: 10,
		paddingBottom: 30,
		paddingLeft: 10,
		paddingRight: 10,
		overflow: 'hidden',
		width: 'calc(100% - 10px)',
		marginLeft: -10,
		marginRight: -10,
		marginTop: -6,
		fontSize: 19,

		'& .swiper-scrollbar': {
			bottom: -2,
			height: 3,
		}
	},
});

const titleImages = [
	{
		id: 'd0b34042-6658-40ae-8835-bfc91044ece7',
		title: 'Charles Bridge',
		user: {
			username: 'AdelheidSmitt',
			displayName: 'Adelheid Smitt',
		}
	},
	{
		id: '0b582761-97f5-41fe-bca4-40b8a68ba003',
		title: 'Prague from above',
		vCenter: 30,
		blur: 0.4,
		user: {
			username: 'JirkaHajek',
			displayName: 'Jiri Hajek',
		}
	},
	{
		id: 'e28bd2b8-5efc-431f-ab88-f78903e8d7b1',
		title: 'To the Castle',
		user: {
			username: 'JirkaHajek',
			displayName: 'Jiri Hajek',
		}
	},
	{
		id: 'd50c498b-76f5-46ba-b3ec-d98a8e19f92a',
		title: 'Golden San Francisco',
		vCenter: 40,
		user: {
			username: 'JirkaHajek',
			displayName: 'Jiri Hajek',
		}
	},
	{
		id: 'c534c092-0b81-42cb-a71c-7d26f7bf77df',
		title: 'Shining San Francisco',
		blur: 0.1,
		user: {
			username: 'JirkaHajek',
			displayName: 'Jiri Hajek',
		}
	},
	{
		id: 'e56acb98-98dc-4131-acc8-a870d4656f3a',
		title: 'Fiery San Francisco',
		blur: 0.1,
		user: {
			username: 'JirkaHajek',
			displayName: 'Jiri Hajek',
		}
	},
	{
		id: '674acce9-c1e9-4021-90ac-d0aeae8d54ad',
		title: 'Golden Gate at Dusk',
		vCenter: 40,
		blur: 0.2,
		user: {
			username: 'JirkaHajek',
			displayName: 'Jiri Hajek',
		}
	},
	{
		id: '6e8077ef-39b9-42c2-a1bc-483d8f16fa6b',
		title: 'Borobudur Sunrise',
		vCenter: 35,
		blur: 0.2,
		user: {
			username: 'JirkaHajek',
			displayName: 'Jiri Hajek',
		},
	},
	{
		id: '91e73e1f-2156-4762-b7c7-2c6676035745',
		title: 'Barbara Sunset',
		blur: 0.1,
		user: {
			username: 'JirkaHajek',
			displayName: 'Jiri Hajek',
		}
	},
	{
		id: '1ddd2333-503a-44d5-b2f0-13ce71cb838d',
		title: 'Paris depuis le Jardin des Tuileries',
		vCenter: 20,
		blur: 0.2,
		user: {
			username: 'agphotographe',
			displayName: 'AG PHOTOGRAPHE',
		}
	},
	{
		id: 'c1da7963-d608-4c22-9571-0952f0fee346',
		title: 'Houten Blue Hour',
		vCenter: 50,
		user: {
			username: 'mariocalma',
			displayName: 'Mario Calma',
		}
	},
	{
		id: '8a42cf05-f43e-48b5-80b6-cacf3f8845a2',
		title: 'Lacs des Chéserys',
		vCenter: 40,
		user: {
			username: 'philibertbarelli',
			displayName: 'Philibert Barelli',
		}
	},
	{
		id: 'c0de7dfa-f5e2-4539-a228-58a29b628e6b',
		title: 'The Two Bridges',
		blur: 0.1,
		vCenter: 76,
		user: {
			username: 'jakubslovacek',
			displayName: 'Jakub Slováček',
		}
	},
	{
		id: '1806b873-1f82-4eee-a6f8-b0de380decaa',
		title: 'Bramante Staircase',
		vCenter: 70,
		user: {
			username: 'jakubslovacek',
			displayName: 'Jakub Slováček',
		}
	},
	{
		id: '2b6b9add-be88-4526-8933-5f6b6b0ffdd4',
		title: 'Prague panorama',
		vCenter: 56,
		blur: 0.2,
		user: {
			username: 'zbynekhrubos',
			displayName: 'Zbyněk Hruboš',
		}
	}
];

class HomePage extends Component {
	currentMarkdown = null;
	state = {
		scroller: null,
		photos: null,
		search: '',
	}
	scrollSubsr = '';

	constructor(props) {
		super(props);

		this.titleImage = titleImages[Math.trunc(new Date().valueOf() / (1000 * 60 * 60)) % titleImages.length]; // Cycle every hour
	}

	updateTitle() {
		document.title = 'Phoide — The Photographer\'s Guide';
	}

	componentDidMount() {
		this.getContent();
		this.updateTitle();

		this.scrollSubsr = PubSub.subscribe('MAIN_SCROLL', (msg, data) => {
			this.updateScrollTop(data.scrollTop);
		});
	}

	componentWillUnmount() {
		PubSub.unsubscribe(this.scrollSubsr);
	}

	componentDidUpdate() {
		this.updateTitle();
	}

	getContent() {
		Server.getLatestPhotos().then((rawPhotos) => {
			// reorder Photos
			rawPhotos.sort((p1, p2) => (p1.rating) - (p2.rating));
			const queue = [];
			var reordered = [];
			var last, next;

			while (rawPhotos.length || queue.length) {
				next = queue.shift();
				if (next) { // Try queued items
					if (last && last.idUser !== next.idUser) {
						last = next;
						reordered.push(next);
						continue;
					}
					queue.unshift(next);
				}

				next = rawPhotos.pop(); // Try next item
				if (!next) {
					reordered = reordered.concat(queue);
					break;
				}

				if (!last || last.idUser !== next.idUser) {
					reordered.push(next);
					last = next;
					continue;
				}

				queue.push(next);
			}
			rawPhotos = reordered;

			if (rawPhotos) {
				const photos = rawPhotos.map(photo => ({
					id: photo.id,
					rating: photo.rating,
					created: photo.created,
					title: photo.title,
					location: JSON.parse(photo.jsonstring),
					user: {
						id: photo.idUser,
						firstName: photo.firstName,
						lastName: photo.lastName,
						displayName: photo.displayName,
						username: photo.username,
					}
				}));

				this.setState({ photos: photos });
			}
		});
	}

	handleSearch = (value) => {
		Navigation.search(value);
	}

	handleSearchChange = (value) => {
		this.setState({ search: value });
		if (!value) {
			if (!this.state.showSearch)
				this.setState({ showSearch: true });
			Navigation.search(value);
		}
	}

	handleCancelSearch = () => {
		this.handleSearchChange('');
	}

	headerPhotoShow = (event) => {
		if (checkKeyMods(event))
			Navigation.openPhoto(this.titleImage.id);
	}

	onLogin = () => {
		Navigation.login();
	}

	updateScrollTop(scrollTop) {
		// if (!this.props.mobile) { // Mobile browsers (Chrome) don't support scrolling well yet (event sent _after_ scroll is performed)
		// 	if (this.tickingScrollTop === undefined) {
		// 		window.requestAnimationFrame(() => {
		// 			if (this.header)
		// 				this.header.style.transform = `translateY(${Math.round(scrollTop / 2)}px)`;
		// 			this.tickingScrollTop = undefined;
		// 		});
		// 	}
		// 	this.tickingScrollTop = scrollTop;
		// }
	}

	renderTags(style) {
		const { classes } = this.props;

		return (
			// Padding is needed here to leave some space for larger images on hover
			<div className={classes.tags} style={{ paddingTop: 10, paddingBottom: 30, overflow: 'hidden', width: 'calc(100% - 10px)', marginLeft: -10, marginRight: -10, marginTop: -6, paddingLeft: 10, paddingRight: 10, ...style }}>
				<Swiper
					className='swiper-container swiper-container-horizontal'
					slidesPerView={'auto'}
					slidesPerGroup={1}
					slidesPerGroupAuto={true}
					updateOnImagesReady={true}
					preloadImages={true}
					freeMode={true}
					navigation={{}}
					scrollbar={{ draggable: true, hide: true }}
					style={{ width: '100%', overflow: 'visible' }}
				>
					{tags.map((tag, index) => {
						if (tag.photo)
							return (
								<SwiperSlide key={tag.tag}>
									<ImageWithTitle
										title={tag.tag}
										hierarchy={tag.hier || ''}
										imageURL={Server.getImageThumb({ id: tag.photo })}
										blurhash={tag.blurhash}
										style={{ marginRight: (index + 1 === tags.length) ? 0 : 15 }}
									/>
								</SwiperSlide>
							);
						else
							return null;
					})}
				</Swiper>
			</div>
		);
	}

	renderPromo() {
		// const { classes } = this.props;

		return (
			<div>
				{/* Header bar */}
				<AppHeader
					user={this.props.user}
				// noSearch={true}
				/>

				<div style={{ textAlign: 'center', maxWidth: 1200, marginRight: 'auto', marginLeft: 'auto' }}>

					<picture>
						<source srcSet='/images/desk.webp' type='image/webp' />
						<source srcSet='/images/desk.png' type='image/png' />
						<img src='/images/desk.png' alt='Phoide running on a notebook' style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20, boxSizing: 'border-box', height: 'calc(calc(100vw * 0.568) - 40px)', maxHeight: 540 }} />
					</picture>

					<h2 className='main-header'>PHOIDE</h2>

					<h3 className='sub-header'>Explore photographic locations all around our beautiful Earth</h3>

					<h5 className='subsub'>
						Navigate through tens of thousand photographic locations and get inspired by beautiful photographs.
					</h5>

					{/* Tags */}
					{/* <Typography variant='h6' style={{ marginTop: 10 }}>
						{'Popular Locations'}
					</Typography> */}
					{this.renderTags({ marginTop: 50, marginBottom: -35, marginLeft: 'calc( max(-200px, calc(-1 * calc(calc(100vw - 100%) / 2))) - 10px)', width: '100vw', maxWidth: 1600 })}

					{/* <svg
						width="90.879"
						height="90.879"
						version="1.1"
						viewBox="0 0 16.112 24.045">
						<path
							d="m 8.0557,24.045 -0.414,-0.6 C 7.3297,22.994 0,12.334 0,8.0556 0,3.614 3.614,0 8.0557,0 12.498,0 16.112,3.614 16.112,8.0556 16.112,12.334 8.7817,22.994 8.4697,23.445 l -0.414,0.6 M 6.3367,8.6783 c -0.648,1.101 -1.295,2.2007 -1.943,3.3017 1.233,1.159 3.029,1.691 4.691,1.345 -0.916,-1.549 -1.832,-3.098 -2.748,-4.6467 z m 0.401,-2.0164 c -1.301,0 -2.6027,0 -3.9041,0 -0.48,1.6504 -0.028,3.4991 1.0793,4.8001 C 4.8547,9.8623 5.7957,8.2623 6.7377,6.6619 Z m 1.194,3.3831 c 0.608,1.028 1.216,2.057 1.824,3.085 1.4813,-0.485 2.7333,-1.635 3.3123,-3.085 -1.712,0 -3.4243,0 -5.1363,0 z M 8.2247,5.9852 C 7.6147,4.953 7.0047,3.9209 6.3947,2.8887 4.8967,3.3655 3.6281,4.5226 3.0441,5.9852 c 1.7266,0 3.4536,0 5.1806,0 z m 1.168,3.3831 c 1.2953,0 2.5903,0 3.8853,0 C 13.756,7.7238 13.311,5.8811 12.21,4.5825 11.271,6.1778 10.332,7.773 9.3927,9.3683 Z M 9.8057,7.3318 C 10.448,6.2419 11.089,5.152 11.731,4.0621 10.51,2.905 8.7247,2.3618 7.0707,2.7008 c 0.912,1.5436 1.823,3.0872 2.735,4.6308"
							style={{ fill: '#e24313' }} />
					</svg> */}

					{/* <h5 className='subsub'>
						Explore about 50,000 photographic locations all around our Earth and get inspired by beautiful photographs. 
					</h5> */}

					<div className='feature right'>
						<div className='featImg'>
							<VideoPlayer video='map' webm alt='Video showing Map usage' />
						</div>
						<div className='featText'>
							<h3>Browse</h3>
							<p>Use Map to find new places, check out different points of view and beautiful photographs. Save favorites or add new places to the map to plan your future trips.</p>
						</div>
					</div>

					<div className='feature'>
						<div className='featImg'>
							<VideoPlayer video='charles' webm alt='Video showing photography guide' />
						</div>
						<div className='featText'>
							<h3>Explore</h3>
							<p>Study existing location guides, explore various points to photograph from, find inspiration in photographs made by fellow photographers.</p>
						</div>
					</div>

					<div className='feature right'>
						<div className='featImg'>
							<VideoPlayer video='neist' webm alt='Video showing 3D view navigation' />
						</div>
						<div className='featText'>
							<h3>Visualize</h3>
							<p>Preview the location in 360° panorama with Augmented Reality elements that show nearby locations, Sun and Moon positions thorough the day and year and much more.</p>
						</div>
					</div>

					<div className='feature'>
						<div className='featImg'>
							<VideoPlayer video='plan' webm alt='Video showing how to plan which photography spots to visit' />
						</div>
						<div className='featText'>
							<h3>Plan</h3>
							<p>Prepare a list of locations to visit, plan the best time due to sun position, sunrise and sunset times. Check out local weather forecast and nearby webcams.</p>
						</div>
					</div>

					<div className='feature right'>
						<div className='featImg'>
							<VideoPlayer video='publish' webm alt='New photograph upload' />
						</div>
						<div className='featText'>
							<h3>Publish</h3>
							<p>Upload photographs to share your work with others, popularize your photography, get in touch with fellow photographers sharing the same passion.</p>
						</div>
					</div>


					<h5 className='subsub' style={{ marginTop: 66, marginBottom: 56 }}>
						Not convinced yet? You should be by now ;-) ... Check out the complete feature set of Phoide <a href='/about/features'>here</a> to find more about all the goodies!
					</h5>

					<div><Button variant='contained' color='secondary' size='large' classes={{ label: 'joinLabel' }} onClick={this.onLogin}>Join Phoide Now</Button></div>
					<h5 className='subsub' style={{ marginTop: 8 }}>It's quick, easy and free. You can start to use all of its features right away!</h5>

					<div className='thx' style={{ justifyContent: 'space-evenly', marginBottom: 35 }}>
						<div style={{ height: 145 }}>
							<Logo />
						</div>
					</div>

					{/* Temporarily disabled Android app */}
					{/* <div className='feature' style={{ maxWidth: 830 }}>
						<div className='featImg' style={{ justifyContent: 'flex-start', maxWidth: '40%' }}>
							<picture>
								<source srcSet='/images/phone.webp' type='image/webp' />
								<source srcSet='/images/phone.png' type='image/png' />
								<img src='/images/phone.png' alt='Phoide running on a mobile phone' style={{ width: 250, maxWidth: '100%' }} />
							</picture>
						</div>
						<div className='featText' style={{ textAlign: 'center' }}>
							<p className='mobSmall mobMTop'>
								All the Phoide features are also available in a cool mobile app. Navigate to nearby locations, check out weather or upload your photographs and comments.
							</p>

							<p>
								<a href='https://play.google.com/store/apps/details?id=com.phoide' target={getBlankTarget()} rel='noopener noreferrer'>
									<img src="/images/playstore.svg" style={{ width: 160, cursor: 'pointer', margin: 10, maxWidth: '35vw' }} alt='Get it on Google Play' />
								</a>
							</p>

						</div>
					</div> */}
				</div>

				<Footer />
			</div>
		);
	}

	render() {
		const { classes, user } = this.props;

		if (!user && !process.env.REACT_APP_CORDOVA)
			return this.renderPromo();

		return (
			<div
				style={{ width: '100%', overflowY: 'auto' }}
				ref={el => !this.state.scroller && this.setState({ scroller: el })}
			>
				{/* Header bar */}
				<AppHeader
					user={this.props.user}
					noSearch={true}
				/>

				{/* Header image and controls */}
				{this.state.backImageURL !== '-' &&
					<div
						ref={el => this.header = el}
						className={classes.header}
						style={{
							backgroundImage: `linear-gradient(to bottom, transparent, rgba(0,0,0,0.3)), url(${Server.getImageUrl(this.titleImage)})`,
							backgroundPosition: `center ${this.titleImage.vCenter || 66}%`,
						}}
					>
						{/* Header text and buttons */}
						<div style={{ width: 400, position: 'relative', marginTop: '2vh', padding: 20 }}>
							{/* Blurring element */}
							<div style={{ position: 'absolute', left: -100, right: -100, top: -100, bottom: -100, backgroundColor: `rgba(0, 0, 0, ${this.titleImage.blur || 0.5})`, filter: 'blur(60px)', zIndex: -1 }} />

							<Typography align='center' variant='h5' style={{ color: 'rgb(245, 245, 245)', marginBottom: 10 }}>
								{'Discover great photographic spots'}
							</Typography>
							<Typography align='center' variant='h6' style={{ color: 'rgb(245, 245, 245)', marginBottom: 30 }}>
								{'and share your beautiful photographs!'}
							</Typography>

							{/* @ts-ignore */}
							<SearchBar
								onRequestSearch={this.handleSearch}
								onChange={this.handleSearchChange}
								onCancelSearch={this.handleCancelSearch}
								placeholder='Search a photographic location'
								value={this.state.search}
								style={{
									width: '100%',
									backgroundColor: 'rgba(255, 255, 255, 0.8)',
									boxShadow: 'none',
									flex: 15,
									height: 40,
								}}
								classes={{
									iconButton: classes.searchBarButton,
									searchIconButton: classes.searchButton,
								}}
								searchIcon={<SearchIcon />}
								closeIcon={<ClearIcon />}
							/>

							<div style={{ marginTop: 20 }}>
								<Button
									variant='contained'
									classes={{ root: classes.headerButton, outlined: classes.headerButton }}
									className={classes.headerButton}
									href='/explore/nearby'
									onClick={(e) => { if (checkKeyMods(e)) Navigation.goPath('/explore/nearby') }}
								>
									<NearbyIcon style={{ marginRight: 10, opacity: 0.7 }} />
									{this.props.mobile ? 'Nearby' : 'Nearby Spots'}
								</Button>

								<Button
									variant='contained'
									style={{ marginLeft: 20 }}
									className={classes.headerButton}
									href='/map?map'
									onClick={(e) => { if (checkKeyMods(e)) Navigation.openMapPage(false /*tracking*/) }}
								>
									<MapIcon style={{ marginRight: 10, opacity: 0.7 }} />
									{'World Map'}
								</Button>
							</div>
						</div>

						<a
							style={{
								position: 'absolute', bottom: 7, right: 15, color: 'white', opacity: 0.80, cursor: 'pointer', fontSize: this.props.mobile ? 10 : 14
							}}
							href={Navigation.getPhotoURL(this.titleImage)}
							onClick={this.headerPhotoShow}
						>
							{this.titleImage.title}
							{' by '}
							{this.titleImage.user.displayName}
						</a>
					</div>
				}

				<div className={classes.content}>
					<div style={{ padding: this.props.mobile ? 7 : 20 }}>
						{/* Tags */}
						<Typography variant='h6' style={{ marginTop: 10 }}>
							{'Popular Locations'}
						</Typography>
						{this.renderTags()}

						{/* Latest Photos */}
						<Typography variant='h6'>
							{'Recent Uploads'}
						</Typography>
						<div style={{ marginTop: 4 }}>
							<Images
								images={this.state.photos}
								height={260}
								margins={10}
							/>
						</div>
					</div>
				</div>
			</div >
		);
	}
}

HomePage.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object,
	mobile: PropTypes.bool,
};

/** @type {any} */
// @ts-ignore (style problem)
export default withStyles(style)(HomePage);