//@ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SearchBar from './SearchBar';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

// import { ReactComponent as PhoideIcon } from '../logo.svg';
import Logo from '../logo';
import LoginIcon from './LoginIcon';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import BackIcon from '@material-ui/icons/ArrowBack';
import AddPhotoIcon from '@material-ui/icons/AddAPhoto';
import PersonIcon from '@material-ui/icons/Person';
import SpotlistIcon from '@material-ui/icons/PhotoLibrary';

import Navigation from '../navigation';
import Server from '../server';
import { COLORS, checkKeyMods, toggleDrawer } from '../tools';
import { mainNav, subNav } from '../navUI';

const styles = theme => ({
	main: {
		flex: 1,
	},
	phoideButton: {
		paddingRight: 10,
		paddingBottom: 2,
	},
	phoideSmallButton: {
		minWidth: 48,
		padding: 0,
	},
	phoideIcon: {
		height: 32,
		width: 100,
		opacity: 0.65,
	},
	phoideSmallIcon: {
		height: 40,
		width: 40,
		opacity: 0.85,
	},
	menuItem: {
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'none',
		}
	},
	selItem: {
		color: theme.palette.secondary.main,
	},
	leftToolButton: {
		marginLeft: theme.spacing(-1.5),
		marginRight: theme.spacing(1.5),
	},
	menuButton: {
		marginLeft: 0,
		marginRight: theme.spacing(-1.5),
	},
	toolbarItem: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},

	searchButton: {
		marginRight: -40,
	},
	searchBarButton: {
		padding: 8,
	},

	title: {
		display: 'inline',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		flexShrink: 10
	},

	hideSmall: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	hideLarge: {
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	hide: {
		display: 'none',
	},

	chip: {
		marginLeft: 10,
		maxWidth: 'calc(100% - 10px)',
	},
});

class AppHeader extends React.Component {
	state = {
		search: '', // Search string
		showSearch: false, // To handle Search Bar visibility on smaller screens
		drawerOpen: false,
		elMenu: null,
		/** @type {any} */
		menuItems: [],
	};

	componentDidMount() {
		if (Navigation.getSearch())
			this.setState({ search: Navigation.getSearch() });
	}

	handleViewChange = (data) => {
		this.setState({
			view: data.view,
			viewProps: data.props,
		});
	}

	handleSearch = (value) => {
		Navigation.search(value);
	}

	handleSearchChange = (value) => {
		this.setState({ search: value });
		if (!value) {
			if (!this.state.showSearch)
				this.setState({ showSearch: true });
			Navigation.search(value);
		}
	}

	handleCancelSearch = () => {
		this.handleSearchChange('');
	}

	handleSearchButton = () => {
		this.setState({ showSearch: true });
	}

	handleClearSearch = () => {
		this.setState({
			showSearch: false,
			search: null,
		});
		Navigation.cancelSearch();
	}

	handleAddPhoto = (event) => {
		if (!Server.checkLogin(event.currentTarget))
			return;

		Navigation.newPhoto();
	}

	onPhoideClick = (event) => {
		if (checkKeyMods(event))
			Navigation.goHome();
	}

	goMenu = (e) => {
		const id = e.currentTarget.dataset.id;
		const items = subNav[id];
		if (items) {
			this.setState({
				elMenu: e.currentTarget,
				menuItems: items,
				menuId: id,
			});
		}
	}

	handleMenuClose = () => {
		this.setState({ elMenu: null });
	}

	onSubMenu = (e) => {
		const data = e.currentTarget.dataset;
		Navigation.goPath(data.url || `/${this.state.menuId}/${data.id}`);
		this.handleMenuClose();
	}

	removeFilter = () => {
		Navigation.clearFilters();
		Navigation.go(true);
	}

	onFilterClick = () => {
		const { filter } = this.props;
		if (filter.ft)
			Navigation.openTag(filter.ft);
		if (filter.fu)
			Navigation.showProfile(filter.fu);
		if (filter.fs)
			Navigation.search(filter.fs);
		if (filter.fsl)
			Navigation.goSpotlist(filter.fsu, filter.fsl);
	}

	renderFilter(filter) {
		if (!filter)
			return null;
		const { classes } = this.props;

		if (filter.ft) {
			return (
				<Chip
					color='primary'
					label={filter.ft}
					onClick={this.onFilterClick}
					onDelete={this.removeFilter}
					className={classes.chip}
				/>
			);
		}

		if (filter.fu) {
			return (
				<Chip
					avatar={<Avatar style={{ backgroundColor: COLORS.PRIMARY_MAIN }}><PersonIcon /></Avatar>}
					color='primary'
					label={filter.fu}
					onClick={this.onFilterClick}
					onDelete={this.removeFilter}
					className={classes.chip}
				/>
			);
		}

		if (filter.fs) {
			return (
				<Chip
					avatar={<Avatar style={{ backgroundColor: COLORS.PRIMARY_MAIN }}><SearchIcon /></Avatar>}
					color='primary'
					label={filter.fs}
					onClick={this.onFilterClick}
					onDelete={this.removeFilter}
					className={classes.chip}
				/>
			);
		}

		if (filter.fsl) {
			return (
				<Chip
					avatar={<Avatar style={{ backgroundColor: COLORS.PRIMARY_MAIN }}> <SpotlistIcon /></Avatar >}
					color='primary'
					label={filter.fsl}
					onClick={this.onFilterClick}
					onDelete={this.removeFilter}
					className={classes.chip}
				/>
			);
		}
	}

	onDrawer = (e) => {
		toggleDrawer();
		if (e) {
			e.stopPropagation();
			e.preventDefault();
		}
	}

	render() {
		const { classes, newPhoto, subMenuItem } = this.props;
		const showSearchBar = this.state.showSearch || Navigation.getSearch();

		return (
			<div style={{ width: '100%', zIndex: 10, ...this.props.style }} >
				<AppBar position='static' style={{ backgroundColor: 'rgba(230, 230, 230, 0.87)' }}>
					<Toolbar style={{ minHeight: 56, paddingLeft: 10 }}>
						{/* Left sidebar button */}
						{/* Home button */}
						<a href='/' aria-label='Homepage'>
							<Tooltip title='Phoide Homepage' enterDelay={300}>
								<Button className={classes.phoideButton + ' fullMainMenu' + (showSearchBar ? ' ' + classes.hideSmall : '')} variant='text' aria-label='Phoide Homepage' onClick={this.onPhoideClick}>
									<Logo className={classes.phoideIcon} />
								</Button>
							</Tooltip>
						</a>

						{/* Main menu button (for smaller screens) */}
						{this.props.filter ? /* When filter applied, show smaller main menu button (so that there's more space) */
							<a href='/' aria-label='Main Menu'>
								<Tooltip title='Phoide Main Menu' enterDelay={300}>
									<Button className={classes.phoideSmallButton + ' shortMainMenu' + (showSearchBar ? ' ' + classes.hideSmall : '')} variant='text' aria-label='Phoide Main Menu' onClick={this.onDrawer}>
										<Logo className={classes.phoideSmallIcon} small />
									</Button>
								</Tooltip>
							</a>
							:
							<a href='/' aria-label='Main Menu'>
								<Tooltip title='Phoide Main Menu' enterDelay={300}>
									<Button className={classes.phoideButton + ' shortMainMenu' + (showSearchBar ? ' ' + classes.hideSmall : '')} variant='text' aria-label='Phoide Main Menu' onClick={this.onDrawer}>
										<Logo className={classes.phoideIcon} />
									</Button>
								</Tooltip>
							</a>
						}

						{/* Main menu */}
						<div className={'fullMainMenu'}>
							{mainNav.map(item => {
								return (
									<Button
										variant='text'
										color={this.props.menuItem === item.id ? 'secondary' : 'default'}
										disableRipple={this.props.menuItem === item.id}
										data-id={item.id}
										onClick={this.goMenu}
										key={item.id}
										aria-label={item.title}
									>
										{item.title}
									</Button>
								);
							})}
						</div>

						{/* Filters */}
						{this.props.filter &&
							<div className={(showSearchBar ? classes.hideSmall : '')} style={{ minWidth: 0 /* To support shrinking */ }}>
								{this.renderFilter(this.props.filter)}
							</div>
						}

						{/* Clear Search button */}
						<IconButton className={classes.leftToolButton + ' ' + (showSearchBar ? classes.hideLarge : classes.hide)} aria-label='Hide Search' onClick={this.handleClearSearch}>
							<BackIcon />
						</IconButton>

						{/* Placeholder */}
						<div style={{ flexGrow: 1 }} />

						{/* Search bar */}
						{!this.props.noSearch &&
							<span className={(showSearchBar ? '' : classes.hideSmall)}>
								<SearchBar
									onRequestSearch={this.handleSearch}
									onChange={this.handleSearchChange}
									onCancelSearch={this.handleCancelSearch}
									value={this.state.search}
									style={{
										marginRight: 8,
										marginLeft: 8,
										maxWidth: 300,
										backgroundColor: 'rgba(255, 255, 255, 0.6)',
										boxShadow: 'none',
										flex: 15,
										height: 40,
									}}
									classes={{
										iconButton: classes.searchBarButton,
										searchIconButton: classes.searchButton,
									}}
									searchIcon={<SearchIcon />}
									closeIcon={<ClearIcon />}
								/>
							</span>
						}

						{/* Search button */}
						{!this.props.noSearch &&
							<IconButton aria-label='Search' onClick={this.handleSearchButton} className={showSearchBar ? classes.hide : classes.hideLarge}>
								<SearchIcon />
							</IconButton>
						}

						{!newPhoto &&
							<Tooltip title='Upload a new Photograph' enterDelay={300} className={showSearchBar ? classes.hideSmall : ''}>
								<IconButton aria-label='Add new Spot' onClick={this.handleAddPhoto}>
									<AddPhotoIcon />
								</IconButton>
							</Tooltip>
						}

						{/* Login icon */}
						<LoginIcon
							user={this.props.user}
							style={{ marginLeft: 10 }}
						/>
					</Toolbar>
				</AppBar>

				<Menu
					anchorEl={this.state.elMenu}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					open={Boolean(this.state.elMenu)}
					onClose={this.handleMenuClose}
					getContentAnchorEl={null}
				>
					{this.state.menuItems.map((item, index) => {
						if (item.id)
							return (
								<MenuItem onClick={this.onSubMenu} data-id={item.id} data-url={item.url} key={item.id} selected={item.id === subMenuItem}>
									{item.title}
								</MenuItem>
							);
						else
							return (
								<Divider key={index} />
							);
					})}
				</Menu>
			</div>
		);
	}
}

AppHeader.propTypes = {
	classes: PropTypes.object.isRequired,
	style: PropTypes.object,
	tag: PropTypes.string,
	filter: PropTypes.object,
	location: PropTypes.object,
	user: PropTypes.object,
	noSearch: PropTypes.bool,
	newPhoto: PropTypes.bool,
	menuItem: PropTypes.string,
	subMenuItem: PropTypes.string,
};

function getClass() {
	//@ts-ignore
	var res = withStyles(styles)(AppHeader);
	return res;
}

/** @type {any} */
export default getClass();
