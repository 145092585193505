// @ts-check

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import MapView from '../MapView';

import AppHeader from './AppHeader';
import LocationSidebar from './LocationSidebar';
import NewPhotoUpload from './NewPhotoUpload';
import Region from './Region';
import Footer from './Footer';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';

import ImagesSwiped from './ImagesSwiped';

import Navigation from '../navigation.js';
import { Beforeunload } from 'react-beforeunload';
import { Prompt } from 'react-router';

import IconExpand from '@material-ui/icons/ExpandLess';
import IconClose from '@material-ui/icons/Close';

// import DeviceOrientation from '../react-screen-orientation';
import { IconButton } from '@material-ui/core';
import PubSub from 'pubsub-js';
import { setPageTitle } from '../tools';

const styles = (theme) => ({
	scrollRoot: {
		overflow: 'hidden',
		width: '100%',
		height: '100%',
		// backgroundColor: theme.palette.background.default,
	},
	root: {
		maxWidth: 1166,
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingBottom: 30,
		boxSizing: 'border-box',
		'.rightSidebarLarge &': {
			minHeight: 'calc(100vh - 104px)',
		},
	},

	footer: {
		display: 'none',
		'.rightSidebarLarge &': {
			display: 'block',
		},
	},

	mapArea: {
		flexGrow: 100,
		position: 'relative',
	},
	map: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},

	miniSpot: {
		position: 'relative',
		backgroundColor: theme.palette.grey[50],
		borderTop: 'solid 1px rgba(0, 0, 0, 0.3)',
	}
});

// const location = {
// 	title: 'Uluru from South',
// 	descriptions: [{
// 		text: '<p>This spot just south of the Uluru rock offers interesting foreground, nice grass that can help to create a beautiful composition.</p>' +
// 			'<p><b>Photo advice: </b>This is definitely a sunrise spot, since the early morning light helps to beautifully render the scene. Bring a wide-angle lens in order to capture not only the Uluru rock, but also the foreground grass.</p>' +
// 			'<p><b>Getting there: </b>It\'s a short, about 300m walk from the campground, just follow the signs to the \'Sunrise point\'.</p>',
// 		rating: 25,
// 		createdBy: {
// 			userName: 'Jiri Hajek',
// 			avatarText: 'JH',
// 			avatarColor: 'red',
// 			avatarURL: 'avatar1.svg',
// 		},
// 		comments: [{
// 			text: 'There\'s a lot of flies, come prepared!',
// 			rating: 11,
// 			createdBy: {
// 				userName: 'Local Photographer',
// 			}
// 		}, {
// 			text: 'Avoid weekends at all costs, as the site gets really crowded on sunrises.',
// 			rating: 4,
// 			createdBy: {
// 				userName: 'Pete Smith',
// 			}
// 		}],
// 	},
// 	{
// 		text: '<p><b>Photo advice: </b>Come at Spring and you\'ll enjoy wildflowers!</p>',
// 		rating: 10,
// 		createdBy: {
// 			userName: 'John Dundee',
// 			avatarText: 'JD',
// 			avatarColor: 'blue',
// 		},
// 	},
// 	{
// 		text: '<p>A very silly comment that\'ll hopefully be soon voted out of this great site!</p>',
// 		rating: -5,
// 		createdBy: {
// 			userName: 'Dumb Ass',
// 			avatarText: 'DA',
// 			avatarColor: 'brown',
// 		},
// 	}
// 	],
// 	images: [
// 		{ url: 'https://www.outbacktourservices.com.au/wp-content/uploads/2017/09/Uluru-One-Day-Experience_Uluru-sunset-clouds-e1505891455366.jpg', rating: 37 },
// 		{ url: 'https://www.limelightmagazine.com.au/wp-content/uploads/2016/09/Uluru%20Credit%20Xavier%20Lambrecht.jpg', rating: 21 },
// 		{ url: 'https://cdn.cnn.com/cnnnext/dam/assets/170217101722-uluru.jpg', rating: 10 },
// 		{ url: 'https://www.telegraph.co.uk/content/dam/travel/Spark/collette/uluru-australia-sunset-xlarge.jpg', rating: 3 },
// 	],
// 	tags: [
// 		{ tag: 'landscape' },
// 		{ tag: 'sunrise' },
// 		{ tag: 'autumn' },
// 	],
// 	starred: false,
// };

class Location extends Component {
	state = {
		sidebarLarge: false,
		photoID: null, // ID of the image to be shown by lightbox
		uploadDone: false,
	}
	scroller;

	updateTitle() {
		if (process.env.REACT_APP_SERVER_SIDE || this.props.newPhoto)
			return;

		const { point } = this.props;

		if (point) {
			if (!point.title)
				return; // Don't update the title yet, we're waiting for a response from the server
			if (window.document)
				setPageTitle(point.title);
		} else {
			if (window.document)
				window.document.title = 'Phoide — Photographer\'s guide';
		}
	}

	isLargeMap() {
		return this.props.largeMap || this.props.fixPosition || this.props.fixPhoto;
	}

	componentDidMount() {
		this.updateTitle();
		this.adjustMap(this.isLargeMap());
	}

	componentWillUnmount() {
		this.adjustMap(false);
	}

	componentDidUpdate(prevProps) {
		this.updateTitle();

		if (prevProps.largeMap !== this.props.largeMap || prevProps.fixPosition !== this.props.fixPosition || prevProps.fixPhoto !== this.props.fixPhoto)
			this.adjustMap(this.isLargeMap());

		if (prevProps.region !== this.props.region) {
			if (this.scroller)
				this.scroller.scrollTop = 0;
		}
	}

	adjustMap(largeMap) {
		const body = document.querySelector('body');
		const root = document.querySelector('#root');
		if (largeMap) {
			// @ts-ignore
			body.classList.add('staticBody');
			// @ts-ignore
			root.classList.add('staticRoot');
		} else {
			// @ts-ignore
			body.classList.remove('staticBody');
			// @ts-ignore
			root.classList.remove('staticRoot');
		}
	}

	handleExpandSidebar = () => {
		Navigation.showMap(false);
	}

	handleCollapseSidebar = () => {
		Navigation.showMap(true);
	}

	getSidebarClass = (landscape) => {
		const largeMap = this.isLargeMap();

		if (this.props.point || this.props.newPhoto || this.props.region)
			return (landscape ?
				(!largeMap ? 'rightSidebarLarge' : 'rightSidebarSmall') :
				(!largeMap ? 'bottomSidebarLarge' : 'bottomSidebarSmall'));
		else
			return (landscape ? 'rightSidebarHidden' : 'bottomSidebarHidden');
	}

	preventNavigation() {
		return this.props.newPhoto && !this.state.uploadDone;
	}

	onUploadDone = (value) => {
		this.setState({ uploadDone: value });
	}

	handleUnload = (event) => {
		if (this.preventNavigation())
			event.preventDefault();
	}

	onCloseSpot = (e) => {
		e.preventDefault();
		e.stopPropagation();

		Navigation.goSpot(null);
	}

	promptChange = (location) => {
		return Navigation.getPath().startsWith('/newPhoto') && !location.pathname.startsWith('/newPhoto') ?
			'Changes you made are not saved. Do you want to leave?' :
			true;
	}

	onScroll = (e) => {
		const el = e.currentTarget;
		PubSub.publishSync('MAIN_SCROLL', {
			scrollTop: el.scrollTop,
			scrollHeight: el.scrollHeight,
			scrollerHeight: el.clientHeight,
		});
	}

	renderMobile() {
		const { classes, newPhoto, region, mobile } = this.props;
		const spot = newPhoto ? null : this.props.point;
		const largeMap = this.isLargeMap();

		return (
			<div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', position: 'absolute', zIndex: 10 }}>
				{/* Appbar -- TODO: Probably remove it and replace by floating buttons */}
				<AppHeader
					filter={this.props.filter}
					location={this.props.point}
					user={this.props.user}
					newPhoto={newPhoto}
					style={{ position: 'absolute' }}
				/>

				<div style={{ flexGrow: 1 }} className='mapOffset'>
					{/* Map */}
					<MapView
						className={classes.map}
						location={spot}
						search={this.props.search}
						fixPosition={this.props.fixPosition}
						fixPhoto={this.props.fixPhoto}
						showGPS={this.props.showGPS}
						largeMap={true}
						mobile={this.props.mobile}
						newPhoto={newPhoto}
					/>
				</div>

				{((spot && spot.id) || region) &&
					<div className={classes.miniSpot} onClick={this.handleExpandSidebar}>
						{spot &&
							<>
								<Button color='primary' style={{ position: 'absolute', width: '100%', padding: 0, paddingBottom: 24 }}>
									<IconExpand />
								</Button>

								<div style={{ float: 'right' }}>
									<IconButton size='medium' style={{ float: 'right', display: 'inline-flex', marginLeft: 0 }} onClick={this.onCloseSpot}>
										<IconClose />
									</IconButton>
								</div>

								<Typography variant='subtitle2' noWrap style={{ marginTop: 21, paddingLeft: 3 }}>
									{spot.title === undefined ?
										<Skeleton animation='wave' /> :
										spot.title
									}
								</Typography>

								{/* Images */}
								{spot &&
									<ImagesSwiped
										location={spot}
										images={(spot && spot.images) ? spot.images : []}
										height={120}
										style={{ minHeight: 120 }} // To prevent size changes on location change
										spacing={5}
									/>
								}
							</>
						}

						{region &&
							<Region
								region={region}
								mobile={mobile}
								large={!largeMap}
							/>
						}
					</div>
				}
			</div>
		);
	}

	renderDataContent() {
		const largeMap = this.isLargeMap();
		const { user, mobile, region, showGPS, point } = this.props;

		if (this.props.newPhoto) {
			return (
				<NewPhotoUpload
					location={point}
					large={!largeMap}
					mobile={mobile}
					user={user}
					onDone={this.onUploadDone}
				/>
			);
		} else {
			if (region)
				return (
					<Region
						region={region}
						mobile={mobile}
						large={!largeMap}
						showGPS={showGPS}
					/>
				);
			else
				return (
					<LocationSidebar
						location={point}
						editDescID={this.props.editDescID}
						large={!largeMap}
						landscape={true}
						fixPosition={this.props.fixPosition}
						fixPhoto={this.props.fixPhoto}
						showGPS={showGPS}
						mobile={mobile}
						user={user}
					/>
				);
		}
	}

	renderMainBody() {
		const { classes, newPhoto } = this.props;
		const largeMap = this.isLargeMap();

		return (
			<div className={'vertRoot'} ref={(ref) => { this._vertRoot = ref; }} style={{ display: this.props.mobile && largeMap ? 'none' : '' }}>
				<Beforeunload onBeforeunload={this.handleUnload} />
				{this.preventNavigation() &&
					<Prompt
						message={this.promptChange}
					/>
				}

				<AppHeader
					filter={this.props.filter}
					location={this.props.point}
					user={this.props.user}
					newPhoto={newPhoto}
				/>

				{/* Sidebar for portrait - REMOVED, no longer base the UI on this */}
				{/* <DeviceOrientation orientation='portrait' alwaysRender={false}>
					<div className={'rightSidebar ' + this.getSidebarClass(false)}>
						{this.renderDataContent()}
					</div>
				</DeviceOrientation> */}

				<div className={'horzRoot'}>
					{/* Map */}
					<div className={classes.mapArea}>
						{!this.props.mobile && largeMap &&
							<MapView
								className={classes.map}
								location={this.props.point}
								search={this.props.search}
								fixPosition={this.props.fixPosition}
								fixPhoto={this.props.fixPhoto}
								showGPS={this.props.showGPS}
								largeMap={true}
								mobile={this.props.mobile}
								newPhoto={newPhoto}
							/>
						}
					</div>

					{/* Sidebar for landscape */}
					{/* <DeviceOrientation orientation='landscape' alwaysRender={false}> */}
					<div className={'rightSidebar ' + this.getSidebarClass(true)}>
						<div className={classes.scrollRoot} style={{ overflowY: largeMap ? 'auto' : 'hidden' }} onScroll={this.onScroll} ref={(ref) => this.scroller = ref} data-scroll>
							<div className={classes.root}>
								{this.renderDataContent()}
							</div>

							<div className={classes.footer}>
								<Footer />
							</div>
						</div>
					</div>
					{/* </DeviceOrientation> */}
				</div>
			</div>
		);
	}

	render() {
		const largeMap = this.isLargeMap();

		if (this.props.mobile)
			return (
				<div>
					{largeMap &&
						this.renderMobile()
					}

					{this.renderMainBody()}
				</div>
			);
		else
			return this.renderMainBody();
	}
}

Location.propTypes = {
	classes: PropTypes.object.isRequired,
	editDescID: PropTypes.number,
	search: PropTypes.string,

	point: PropTypes.object,
	region: PropTypes.object,
	fixPosition: PropTypes.bool,
	fixPhoto: PropTypes.string,
	showGPS: PropTypes.bool,
	largeMap: PropTypes.bool,
	mobile: PropTypes.bool,
	filter: PropTypes.object,
	newPhoto: PropTypes.bool,

	user: PropTypes.object,
};

/** @type {any} */
// @ts-ignore
export default withStyles(styles)(Location);