import React from 'react';
import PropTypes from 'prop-types';

export default function Logo(props) {
	if (props.small)
		return (
			<svg width="100%" height="100%" viewBox="0 0 19.494 27.919" className={props.className}>
				<g transform="translate(-31.291,-269.12)" style={{ opacity: 0.7 }}>
					<g transform="matrix(1.0287,0,0,1.0287,-52.523,98.671)">
						<g transform="matrix(0.48943,0,0,0.48943,79.086,167.49)">
							<path
								d="M 24,47.759 23.177,46.568 C 22.558,45.671 8,24.499 8,16 8,7.178 15.178,0 24,0 32.822,0 40,7.178 40,16 40,24.499 25.442,45.671 24.823,46.568 Z M 24,2 C 16.28,2 10,8.28 10,16 10,22.787 20.885,39.555 24,44.214 27.115,39.555 38,22.787 38,16 38,8.28 31.72,2 24,2 Z"
								style={{ stroke: '#000000', strokeWidth: 0.92912 }} />
						</g>
						<g transform="matrix(0.3289,0,0,0.3289,85.57,170.02)" style={{ fill: '#ff3d00' }}>
							<path d="M 21.172,13.98 26.863,4.316 C 24.008,1.658 20.211,0 16,0 15.002,0 14.035,0.119 13.088,0.293 Z" />
							<path d="M 19.951,20 H 31.433 C 31.766,18.715 32,17.391 32,16 32,12.127 30.57,8.623 28.277,5.855 Z" />
							<path d="M 16.5,10 11.092,0.848 C 6.592,2.307 2.953,5.646 1.188,10 Z" />
							<path d="m 15.633,22 5.391,9.117 C 25.473,29.633 29.063,26.316 30.813,22 Z" />
							<path d="M 12.105,12 H 0.566 C 0.232,13.285 0,14.609 0,16 c 0,3.891 1.445,7.41 3.756,10.188 z" />
							<path d="M 10.918,17.961 5.174,27.719 C 8.027,30.352 11.809,32 16,32 c 1.041,0 2.055,-0.117 3.041,-0.305 z" />
						</g>
					</g>
				</g>
			</svg>
		);
	else
		return (
			<svg width="100%" height="100%" className={props.className} version="1.1" viewBox="0 0 85.645932 27.918769" xmlns="http://www.w3.org/2000/svg">
				<g transform="translate(0,-269.08)" opacity="1">
					<path d="m5.021 282.34v7.5h-1.4883v-18.05h6.226q2.8403 0 4.3903 1.45 1.563 1.45 1.563 3.82 0 2.4-1.563 3.84-1.55 1.44-4.3903 1.44zm0-1.27h4.7377q2.2323 0 3.3483-1.12 1.117-1.14 1.117-2.87t-1.117-2.88q-1.116-1.15-3.3483-1.15h-4.7377z" />
					<path d="m30.778 289.84h-1.488v-8.31h-10.257v8.31h-1.489v-18.06h1.489v8.49h10.257v-8.49h1.488z" />
					<path d="m52.656 289.84h-1.488v-18.06h1.488z" />
					<path d="m55.407 289.84v-18.06h5.507q3.311 0 5.469 2.21 2.17 2.21 2.17 5.67v2.32q0 3.46-2.17 5.66-2.158 2.2-5.469 2.2zm1.488-16.79v15.54h4.019q2.679 0 4.415-1.88 1.736-1.87 1.736-4.73v-2.36q0-2.83-1.748-4.7-1.737-1.87-4.403-1.87z" />
					<path d="m80.623 281.1h-8.099v7.49h9.364v1.25h-10.852v-18.05h10.79v1.26h-9.302v6.78h8.099z" />
					<g transform="matrix(1.0287,0,0,1.0287,-52.523,98.671)">
						<g transform="matrix(.48943 0 0 .48943 79.086 167.49)">
							<path d="m24 47.759-0.823-1.191c-0.619-0.897-15.177-22.069-15.177-30.568 0-8.822 7.178-16 16-16s16 7.178 16 16c0 8.499-14.558 29.671-15.177 30.568zm0-45.759c-7.72 0-14 6.28-14 14 0 6.787 10.885 23.555 14 28.214 3.115-4.659 14-21.427 14-28.214 0-7.72-6.28-14-14-14z" stroke="#000" strokeWidth=".92912" />
						</g>
						<g transform="matrix(.3289 0 0 .3289 85.57 170.02)" fill="#ff3d00">
							<path d="M 21.172,13.98 26.863,4.316 C 24.008,1.658 20.211,0 16,0 15.002,0 14.035,0.119 13.088,0.293 Z" />
							<path d="m19.951 20h11.482c0.333-1.285 0.567-2.609 0.567-4 0-3.873-1.43-7.377-3.723-10.145z" />
							<path d="m16.5 10-5.408-9.152c-4.5 1.459-8.139 4.798-9.904 9.152z" />
							<path d="m15.633 22 5.391 9.117c4.449-1.484 8.039-4.801 9.789-9.117z" />
							<path d="m12.105 12h-11.539c-0.334 1.285-0.566 2.609-0.566 4 0 3.891 1.445 7.41 3.756 10.188z" />
							<path d="m10.918 17.961-5.744 9.758c2.853 2.633 6.635 4.281 10.826 4.281 1.041 0 2.055-0.117 3.041-0.305z" />
						</g>
					</g>
				</g>
			</svg>
		);
}

Logo.propTypes = {
	className: PropTypes.string,
	small: PropTypes.bool,
};
