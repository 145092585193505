/* eslint-disable react/display-name */
import React from 'react';

export default function privacy() {
	return (
		<div className='textContent'>
			<h1>Privacy Policy</h1>

			<h3>Information collected by Phoide.com</h3>

			<p>
				We only collect personal information that is relevant to the purpose of our website. We collect the following types of information from our users:
			</p>

			<h3>Information you provide to us</h3>

			<p>
				Upon registration to the site, we collect your Name and E-mail address.
				You have the right to edit, update, access or to delete your personal information if you are terminating your registration with Phoide.com.
				Please submit your request via email at team@phoide.com.
			</p>

			<h3>Automatic information</h3>

			<p>
				We receive and record information from your browser or mobile device when you visit Phoide.com, such as your Internet Protocol (IP) address
				or unique device identifier. Cookies and data about which pages you visit on our Network allow us to operate and optimize the Products
				and Services we provide to you.
			</p>

			<h3>Analytics information</h3>

			<p>
				Phoide.com may use services like Google Analytics to help analyze how our site and mobile applications are used,
				so that we can make the site more interesting to you. These tools capture data about how individual users are using our site
				and mobile applications. This includes event logs, device type and device configuration settings such as language, crash data
				and other data but never personal information.
			</p>

			<h3>Cookies</h3>

			<p>
				In order to provide the Phoide.com services, we may place cookies on you as well as partner with third parties including advertising partners,
				who may use cookies or other similar technologies to provide you with advertising based on your browsing activities and interests.
			</p>

			<h3>Data security</h3>

			<p>
				Your encrypted personal information is stored on servers provided by a third-party cloud-based service provider that protects and
				stores your data in a manner that is consistent with this Policy. We never share your personal data with third parties,
				except for cases explicitly specified in this Policy.
			</p>
		</div>
	);
}