// @ts-check

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ShareIcon from '@material-ui/icons/Share';
import MailIcon from '@material-ui/icons/MailOutline';
import LinkIcon from '@material-ui/icons/Link';

import PubSub from 'pubsub-js';
import Server from '../server';
import Navigation from '../navigation';

import { getBlankTarget } from '../tools.js';

const styles = {
	btnSocial: {
		borderWidth: '0em',
		height: 12,
		width: 12,
		padding: 0,
		borderRadius: '.25rem',
	},
	btnFacebook: {
		padding: 6,
		backgroundColor: '#4267B2',
		fill: '#fff',
		borderColor: 'rgba(0,0,0,.2)',
	},
	btnTwitter: {
		padding: 6,
		backgroundColor: '#55acee',
		fill: '#fff',
		borderColor: 'rgba(0,0,0,.2)',
	},
	btnPinterest: {
		padding: 6,
		backgroundColor: '#BD081C',
		fill: '#fff',
		borderColor: 'rgba(0,0,0,.2)',
	},
};

class ShareButton extends React.Component {
	state = {
		auth: true,
		anchorEl: null,
		notImpDialog: false,
		userLogged: false,
		avatarUrl: null,
		user: null,
	};

	shouldComponentUpdate(nextProps) {
		if (nextProps.anchorEl && !this.props.anchorEl && this.hasNativeShare()) {
			if (this.props.onClose)
				this.props.onClose();

			this.nativeShare();

			return false;
		}

		return true; // TODO: Better check when update is really needed?
	}

	hasNativeShare() {
		return !process.env.REACT_APP_SERVER_SIDE && (process.env.REACT_APP_CORDOVA || (navigator && navigator.share));
	}

	nativeShare() {
		if (navigator && navigator.share) {
			navigator.share({
				text: `Check out "${this.getTitle()}" at Phoide.com`,
				title: `${this.getTitle()} @ phoide.com`,
				url: this.getShareURL(),
				// files: can be here, if needed
			});
		} else {
			// @ts-ignore
			window.plugins.socialsharing.shareWithOptions({
				message: `Check out "${this.getTitle()}" at Phoide.com`,
				subject: `${this.getTitle()} @ phoide.com`,
				url: this.getShareURL(),
			});
		}
	}

	handleMenu = event => {
		event.preventDefault(event);
		event.stopPropagation(event);

		if (this.hasNativeShare()) {
			this.nativeShare();
		} else
			this.setState({ anchorEl: event.currentTarget });
	};

	handleMenuCloseEvt = event => {
		event.preventDefault(event);
		event.stopPropagation(event);

		this.handleMenuClose();
	}

	handleMenuClose = event => {
		if (this.props.onClose)
			this.props.onClose(event);

		this.setState({ anchorEl: null });
	};

	getShareURL() {
		if (this.props.spotlist)
			return 'https://phoide.com' + Navigation.hrefSpotlist(this.props.spotlist.username, this.props.spotlist.urlTitle);
		else {
			if (this.props.image)
				return `https://phoide.com/photo/${this.props.image.id}`;
			else
				return `https://phoide.com/i/${this.props.location.id}`;
		}
	}

	getShareURLEnc() {
		return encodeURIComponent(this.getShareURL());
	}

	getFirstImgURL() {
		if (this.props.spotlist)
			return Server.getThumbForID(this.props.spotlist.image);
		else {
			if (this.props.image)
				return Server.getImageUrl(this.props.image);
			else {
				for (const img in this.props.location.images) {
					const url = Server.getImageUrl(img);
					if (url)
						return url;
				}
				return '';
			}
		}
	}

	getTitle() {
		if (this.props.spotlist)
			return this.props.spotlist.title;
		else {
			if (this.props.image)
				return this.props.image.title || 'image';
			else
				return this.props.location.title || 'location';
		}
	}

	getFBURL = () => {
		return `https://www.facebook.com/dialog/share?app_id=372019109952392&display=popup&href=${this.getShareURLEnc()}`;
	}

	getTwitterURL = () => {
		return `https://twitter.com/share?text=${encodeURIComponent(this.getTitle())}%20at%20Phoide.com%3A&url=${this.getShareURLEnc()}`;
	}

	getPinterestURL = () => {
		return `https://www.pinterest.com/pin/create/button/?url=${this.getShareURLEnc()}&media=${encodeURIComponent(this.getFirstImgURL())}&description=${encodeURIComponent(this.getTitle())}`;
	}

	getEmailURL = () => {
		const text = encodeURIComponent(`Check out "${this.getTitle()}" at Phoide.com`);

		return `mailto:?subject=${text}&body=${text}%3A%20${this.getShareURLEnc()}`;
	}

	handleCopyLink = () => {
		// @ts-ignore
		const clipboard = navigator.clipboard;

		if (clipboard) {
			clipboard.writeText(this.getShareURL()).then(() => {
				PubSub.publish('SHOW_SNACKBAR', {
					message: 'Link to this page has been copied to clipboard.',
				});
			});
		}

		this.handleMenuClose();
	}

	render() {
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl) || Boolean(this.props.anchorEl);
		const { classes } = this.props;
		const ready = (this.props.location && this.props.location.images) || this.props.image || this.props.spotlist;
		const showit = !this.props.popupOnly && ready;

		return (
			<React.Fragment>
				{showit && !this.props.fullButton &&
					<Tooltip title='Share' enterDelay={300}>
						<IconButton
							aria-label='Share'
							aria-owns={open ? 'menu-appbar' : null}
							aria-haspopup='true'
							onClick={this.handleMenu}
							className={this.props.className}
							color='primary'
							// color={this.props.image ? 'inherit' : 'default'}
							style={{ ...this.props.style }}
						>
							<ShareIcon />
						</IconButton>
					</Tooltip>
				}

				{showit && this.props.fullButton &&
					<Button
						variant='outlined'
						color='primary'
						aria-label='Share'
						onClick={this.handleMenu}
						style={{ ...this.props.style }}
					>
						<ShareIcon style={{ marginRight: 8 }} />
						{'Share'}
					</Button>
				}

				{ready && !this.hasNativeShare() &&
					<Menu
						id='menu-share'
						anchorEl={this.props.anchorEl || anchorEl}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={open}
						onClose={this.handleMenuCloseEvt}
						style={{ zIndex: 1320 }} // To be above Dialogs and 2nd Lightbox
					>
						<MenuItem component='a' target={getBlankTarget()} href={this.getFBURL()} onClick={this.handleMenuClose}>
							<ListItemIcon>
								<svg className={classes.btnSocial + ' ' + classes.btnFacebook} aria-labelledby='facebook-icon' role='img' viewBox="0 0 24 24" width='100%' height='100%' xmlns="http://www.w3.org/2000/svg">
									<title id="facebook-icon">Facebook</title>
									<path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
								</svg>
							</ListItemIcon>
							{'Share on Facebook'}
						</MenuItem>

						<MenuItem component='a' target={getBlankTarget()} href={this.getTwitterURL()} onClick={this.handleMenuClose}>
							<ListItemIcon>
								<svg className={classes.btnSocial + ' ' + classes.btnTwitter} aria-labelledby='twitter-icon' viewBox="0 0 24 24" width='100%' height='100%' xmlns="http://www.w3.org/2000/svg">
									<title id="twitter-icon">Twitter</title>
									<path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
								</svg>
							</ListItemIcon>
							{'Share on Twitter'}
						</MenuItem>

						<MenuItem component='a' target={getBlankTarget()} href={this.getPinterestURL()} onClick={this.handleMenuClose}>
							<ListItemIcon>
								<svg className={classes.btnSocial + ' ' + classes.btnPinterest} aria-labelledby='pinterest-icon' viewBox="0 0 24 24" width='100%' height='100%' xmlns="http://www.w3.org/2000/svg">
									<title id="twitter-icon">Twitter</title>
									<path d="M12 0c-6.627 0-12 5.372-12 12 0 5.084 3.163 9.426 7.627 11.174-.105-.949-.2-2.405.042-3.441.218-.937 1.407-5.965 1.407-5.965s-.359-.719-.359-1.782c0-1.668.967-2.914 2.171-2.914 1.023 0 1.518.769 1.518 1.69 0 1.029-.655 2.568-.994 3.995-.283 1.194.599 2.169 1.777 2.169 2.133 0 3.772-2.249 3.772-5.495 0-2.873-2.064-4.882-5.012-4.882-3.414 0-5.418 2.561-5.418 5.207 0 1.031.397 2.138.893 2.738.098.119.112.224.083.345l-.333 1.36c-.053.22-.174.267-.402.161-1.499-.698-2.436-2.889-2.436-4.649 0-3.785 2.75-7.262 7.929-7.262 4.163 0 7.398 2.967 7.398 6.931 0 4.136-2.607 7.464-6.227 7.464-1.216 0-2.359-.631-2.75-1.378l-.748 2.853c-.271 1.043-1.002 2.35-1.492 3.146 1.124.347 2.317.535 3.554.535 6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12z" />
								</svg>
							</ListItemIcon>
							{'Share on Pinterest'}
						</MenuItem>

						<MenuItem component='a' target={getBlankTarget()} href={this.getEmailURL()} onClick={this.handleMenuClose}>
							<ListItemIcon>
								<MailIcon />
							</ListItemIcon>
							{'Share over e-mail'}
						</MenuItem>

						{
							!process.env.REACT_APP_SERVER_SIDE &&
							// @ts-ignore							
							navigator.clipboard &&
							<MenuItem onClick={this.handleCopyLink}>
								<ListItemIcon>
									<LinkIcon />
								</ListItemIcon>
								{'Copy link'}
							</MenuItem>
						}
					</Menu >
				}
			</React.Fragment >
		);
	}
}

ShareButton.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	style: PropTypes.object,
	fullButton: PropTypes.bool,

	location: PropTypes.object,
	image: PropTypes.object,
	spotlist: PropTypes.object,

	popupOnly: PropTypes.bool,
	anchorEl: PropTypes.element,
	onClose: PropTypes.func,
};

/** @type {any} */
export default withStyles(styles)(ShareButton);
