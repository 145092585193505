//@ts-check
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';

import { Beforeunload } from 'react-beforeunload';
import { Prompt } from 'react-router';
import Navigation from '../navigation';

var initStarted;
var MarkdownEditor, setMarkdownEditor;
var loadingLong, setLoadingLong;

async function initEditor() {
	if (!initStarted) {
		initStarted = true;
		setTimeout(() => {
			// loading too long
			setLoadingLong(true);
		}, 150);
		if (!process.env.REACT_APP_SERVER_SIDE) {
			const ME = (await import('./Editor/MilkdownEditor')).MilkdownEditor;
			setMarkdownEditor(ME);
		}
	}
}

function Editor(props) {
	[MarkdownEditor, setMarkdownEditor] = useState(MarkdownEditor);
	[loadingLong, setLoadingLong] = useState(null);

	// Scroll the editor to view if needed
	const skeleton = useRef(null);
	const editor = useRef(null);
	const scrolled = useRef(false);
	useEffect(() => {
		if (!scrolled.current) {
			/** @type{any} */
			const ref = editor.current || skeleton.current;
			if (ref) {
				var block = null;
				if (ref.getBoundingClientRect().bottom > window.innerHeight || ref.getBoundingClientRect().top < 0) {
					block = 'start';
				}
				if (block) {
					ref.scrollIntoView({ behavior: 'smooth', block, inline: 'nearest' });
				}
				scrolled.current = true;
			}
		}
	});

	const preventNavigation = () => {
		return true;
	}

	const handleUnload = (event) => {
		if (preventNavigation())
			event.preventDefault();
	}

	const promptChange = (location) => {
		let current = Navigation.getPath();
		let i = current.indexOf('?');
		if (i >= 0)
			current = current.slice(0, i);
		let newPath = location.pathname;
		i = newPath.indexOf('?');
		if (i >= 0)
			newPath = newPath.slice(0, i);

		return current !== newPath ?
			'Changes you made are not saved. Do you want to leave?' :
			true;
	}

	initEditor();

	if (!MarkdownEditor) {
		if (loadingLong)
			return (
				<Skeleton height={'30vh'} variant='rect' animation='wave' ref={skeleton} />
			);
		else
			return null;
	}

	return (
		<>
			{!props.allowNavigation &&
				<>
					<Beforeunload onBeforeunload={handleUnload} />
					{preventNavigation() &&
						<Prompt
							message={promptChange}
						/>
					}
				</>
			}
			<MarkdownEditor placeholder='Start typing...' initialContent={props.initialValue} onChange={props.onChange} className={props.className} ref={editor}>
			</MarkdownEditor>
		</>
	);
}

Editor.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
	initialValue: PropTypes.string,
	onChange: PropTypes.func,
	allowNavigation: PropTypes.bool,
};

export default Editor;