import React from 'react';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import Navigation from '../navigation';
import { checkKeyMods } from '../tools';

function openTag(e) {
	if (checkKeyMods(e)) {
		const ds = e.currentTarget.dataset;
		Navigation.openTag(ds.id, ds.hier);
	}
}

export default function TagHierarchy(props) {
	const { hier } = props;

	if (hier) {
		const items = hier.split('\\');
		return (
			<Typography variant='h6' align='center' style={{ fontSize: 14 }}>
				{items.map((item, index) =>
					<React.Fragment key={item}>
						<Link
							href={Navigation.getTagURL(item, items.slice(0, index).join('\\'))}
							onClick={openTag}
							data-id={item}
							data-hier={items.slice(0, index).join('\\')}
						>
							{item}
						</Link>
						{' \u00a0»\u00a0 '}
					</React.Fragment>
				)}
			</Typography>
		)
	} else
		return null;
}