import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';

import EditIcon from '@material-ui/icons/Edit';

import TagItem from './TagItem';

import Collapse from '@material-ui/core/Collapse';

import Server from '../server';

const styles = theme => ({
	tagsEdit: {
		marginTop: theme.spacing(1.5),
		marginBottom: theme.spacing(1),
	},

	tags: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: 6,
		marginBottom: 4,
	},

	editButtonRoot: {
		padding: 8, // To show larger hover
		margin: -8,
		marginLeft: 0,
		marginTop: -6,
	},
});

class TagItems extends Component {
	state = {
		edit: false, // Locally started edit of tags
		editLocation: null, // What are we editing
	}
	currentEditValue = null;

	componentDidUpdate(prevProps) {
		if ((prevProps.location && prevProps.location.id) !== (this.props.location && this.props.location.id)) {
			if (this.state.edit)
				this.handleEditDone();
		}
	}

	componentWillUnmount() {
		if (this.state.edit)
			this.handleEditDone();
	}

	handleEdit = (event) => {
		if (!Server.checkLogin(event.currentTarget))
			return;

		this.setState({
			edit: true,
			editLocation: this.props.location,
		});
	}

	addNewValue(value) {
		if (value)
			this.props.onNewTag(value.trim());
	}

	handleEditDone = () => {
		this.setState({ edit: false });
		if (this.currentEditValue)
			this.addNewValue(this.currentEditValue);
		if (this.state.editLocation && this.state.editLocation.id > 0) // For new locations, we don't handle data submit
			Server.submitTags(this.state.editLocation);
	}

	addTagChange = (event) => {
		const value = event.target.value;
		var endPos = value.indexOf(',');
		if (endPos >= 0) {
			const newValue = value.slice(0, endPos);
			this.addNewValue(newValue);
			event.target.value = value.slice(endPos + 1);
		}
		this.currentEditValue = event.target.value;
	}

	handleTagDelete = (tag) => {
		this.props.onDeleteTag(tag);
	}

	handleTagRating = (tagName, value) => {
		this.props.onRateTag(tagName, value);
	}

	onInputKeyPress = (event) => {
		if (event.key === 'Enter') { // Save the new tag
			const value = event.target.value;
			this.currentEditValue = '';
			if (value)
				this.addNewValue(value);
			else
				this.handleEditDone(); // Close the editor
			event.target.value = '';
		}
	}

	anyTagAdded = () => {
		const tags = this.props.tags;

		if (!tags)
			return false;

		for (const tag of tags) {
			if (tag.isNew && !tag.auto)
				return true;
		}

		return false;
	}

	renderEditTags() {
		const { tags, classes } = this.props;

		if (tags && tags.length > 0) {
			return (
				<Grid container direction='row' className={classes.tagsEdit} style={{ alignItems: 'center' }}>
					{tags && tags.map((tag, index) => (
						<TagItem
							tag={tag}
							key={'tag' + index}
							edit={true /*edit*/}
							onDelete={this.handleTagDelete}
							onRating={this.handleTagRating}
						/>
					))}
				</Grid>
			);
		} else
			return null;
	}

	renderNoEdit() {
		const { tags } = this.props;

		return (
			<>
				{tags && tags.map((tag, index) => (
					<TagItem
						tag={tag}
						key={'tag' + index}
						edit={false}
						onDelete={this.handleTagDelete}
						onRating={this.handleTagRating}
					/>
				))}
			</>);
	}

	render() {
		const { tags, classes, isNew, location } = this.props;
		const canEdit = location;
		const hier = location && location.hier;
		var { edit } = this.state;
		if (isNew)
			edit = true;

		const hierArr = (hier && hier.split('\\')) || [];

		if (!this.props.editable)
			return this.renderNoEdit();

		return (
			<>
				<Collapse in={edit}>
					<Paper style={{ marginTop: 10, marginBottom: 10 }}>
						<Grid container direction='column' style={{ width: 'calc(100% - 20px)', marginLeft: 10, marginRight: 10 }}>
							{this.renderEditTags()}

							{/* Add Tags editline */}
							<Grid container direction='row' className={classes.tagsEdit} style={{ alignItems: 'baseline' }}>
								<Input
									placeholder='Add spot tags... (e.g. city, New York, river)'
									className={classes.input}
									inputProps={{
										'aria-label': 'Add tags',
									}}
									style={{ flexGrow: 1 }}
									onChange={this.addTagChange}
									onKeyPress={this.onInputKeyPress}
								/>

								{!isNew &&
									<Button variant='contained' color='secondary' style={{ marginLeft: 20 }} onClick={this.handleEditDone}>
										{'Done'}
									</Button>
								}
							</Grid>
						</Grid>
					</Paper>
				</Collapse>

				<Collapse in={!edit}>
					<div className={classes.tags} style={{ ...this.props.style }}>
						{tags && tags.map((tag, index) => {
							return (
								hierArr.indexOf(tag.tag) < 0 ?
									<TagItem
										tag={tag}
										key={'tag' + index}
										edit={false}
										onDelete={this.handleTagDelete}
										onRating={this.handleTagRating}
									/> : null
							);
						})}

						{canEdit &&
							<Tooltip title='Edit Tags' enterDelay={300}>
								<IconButton
									aria-label='Edit Tags'
									onClick={this.handleEdit}
									style={{ alignSelf: 'baseline' }}
									classes={{ root: classes.editButtonRoot }}
								>
									<EditIcon fontSize="small" />
								</IconButton>
							</Tooltip>
						}
					</div >
				</Collapse>
			</>
		);
	}
}

TagItems.propTypes = {
	classes: PropTypes.object.isRequired,
	style: PropTypes.object,
	tags: PropTypes.array,
	location: PropTypes.object,
	onNewTag: PropTypes.func,
	onDeleteTag: PropTypes.func,
	onRateTag: PropTypes.func,
	isNew: PropTypes.bool,
	editable: PropTypes.bool,
};

/** @type {any} */
export default withStyles(styles)(TagItems);