//@ts-check

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import MoveIcon from '@material-ui/icons/ArrowRightAlt';

import Paper from '@material-ui/core/Paper';
// import { Typography } from '@material-ui/core';

import Server from '../server';
import { Typography } from '@material-ui/core';

const style = theme => ({
	imgLine: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: 20,
		marginBottom: 10,

		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
	},

	imgRoot: {
		backgroundColor: 'white',
		padding: '6px 10px',
	},

	highlight: {
		fontWeight: 'bold',
	},

	img: {
		height: 150,
	},
});

class DialogPhotoMove extends Component {
	state = {
		/** @type{any} */
		title: null,
		image: null,
	}
	_terminating = false;

	componentDidMount() {
		this.fetchData();
	}


	componentWillUnmount() {
		this._terminating = true;
	}

	componentDidUpdate(prevProps) {
		if (this.props.image !== prevProps.image) {
			this.fetchData();
		}
	}

	async fetchData() {
		const image = this.props.image;

		if (image && image.newSpot && image.newSpot.idSpot) {
			const preview = await Server.getPointPreview(image.newSpot.idSpot);

			if (this._terminating)
				return;

			this.setState({
				title: preview.title,
				image: preview.image,
			});
		}
	}

	rate(up, event) {
		const { image } = this.props;
		if (image && image.newSpot) {
			Server.movePhotoRate(image.id, this.props.spot, image.newSpot.idSpot, event.currentTarget, up); // No waiting here
			this.props.onClose();
		}
	}

	rateUp = (event) => {
		this.rate(true, event);
	}

	rateDown = (event) => {
		this.rate(false, event);
	}

	render() {
		const { classes, image } = this.props;
		const newImage = this.state.image;

		return (
			<Dialog
				open={this.props.open}
				fullScreen={this.props.fullScreen}
				onClose={this.props.onClose}
				maxWidth='md'
				fullWidth
			>
				<DialogContent>
					<Typography variant='body1' align='center' style={{ marginBottom: 10 }}>
						{'There is a voting in progress whether the photograph should be moved to the spot titled '}
						<span className={classes.highlight}>{`${this.state.title}`}</span>
						{'.'}
					</Typography>

					<Typography variant='body1' align='center'>
						{'Do you agree with the proposed move?'}
					</Typography>

					<div className={classes.imgLine}>
						<Paper className={classes.imgRoot}>
							{image &&
								<img src={Server.getImageThumb(image)} className={classes.img} alt={image.title} />
							}
						</Paper>

						<MoveIcon fontSize='large' color='action' style={{ margin: 10 }} />

						<Paper className={classes.imgRoot}>
							{newImage &&
								<img src={Server.getImageThumb(newImage)} className={classes.img} alt={this.state.title} />
							}
						</Paper>
					</div>

				</DialogContent>

				<DialogActions>
					<Button variant='contained' color='primary' onClick={this.rateUp}>
						<ThumbUpIcon style={{ marginRight: 10 }} />
						{'Yes'}
					</Button>

					<Button variant='contained' color='primary' onClick={this.rateDown}>
						<ThumbDownIcon style={{ marginRight: 10 }} />
						{'No'}
					</Button>

					{/* Spacer */}
					<div style={{ flexGrow: 10 }} />

					<Button onClick={this.props.onClose} color='primary'>
						{'Close'}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

DialogPhotoMove.propTypes = {
	classes: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	fullScreen: PropTypes.bool.isRequired,

	spot: PropTypes.object,
	image: PropTypes.object,
};

// @ts-ignore
export default withMobileDialog({ breakpoint: 'sm' })(withStyles(style)(DialogPhotoMove));