// @ts-check
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { DateTimePicker } from '@material-ui/pickers';

import DateUploadIcon from '@material-ui/icons/CloudUpload';
import DateTakenIcon from '@material-ui/icons/AccessTime';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import ExposureIcon from '@material-ui/icons/ShutterSpeed';
import ApertureIcon from '@material-ui/icons/Camera';
import ISOIcon from '@material-ui/icons/Iso';
import FocalLengthIcon from '@material-ui/icons/FilterTiltShift';

import suncalc from '../tools/suncalc';
import { browserLocale, getLocTZOffset } from '../tools';

const styles = /*theme =>*/ ({
});

class Exif extends Component {
	state = {}

	constructor(props) {
		super(props);
		const { exif } = props;
		this.state = this.parseExif(exif);
		this.notifyChange(this.state);
	}

	parseExif(exif) {
		return {
			editCamera: this.formatCamera(exif) || '',
			editLens: exif.Lens || '',
			editDateTaken: this.getDateTaken(),
			editFocal: exif.Focal || '',
			editAperture: exif.FNumber || '',
			editExposure: this.formatExposure(exif) || '',
			editISO: exif.ISO || '',
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.exif !== this.props.exif) {
			setTimeout(() => {
				this.localSetState(this.parseExif(nextProps.exif));
			}, 0);
		}

		return (this.state !== nextState);
	}

	formatCamera(exif) {
		var res;

		if (exif.Model && exif.Make) {
			if (exif.Model.slice(0, exif.Make.length) === exif.Make || exif.Model.slice(0, 5) === 'NIKON') {
				res = exif.Model;
			} else
				res = exif.Make + ' ' + exif.Model;
		} else
			res = exif.Model || exif.Make;
		return res;
	}

	formatCameraLens(exif) {
		var res = this.formatCamera(exif);

		if (exif.Lens) {
			if (res)
				res += ' + ';
			res += exif.Lens;
		}

		return res;
	}

	formatDateTaken(image) {
		const date = this.getDateTaken();
		if (date && image) {
			const times = suncalc.getTimes(date.valueOf() - getLocTZOffset(image, date), image.lat, image.long);
			const riseDiff = times.sunrise && (date.valueOf() - date.getTimezoneOffset() * 60 * 1000 - (times.sunrise.valueOf() + getLocTZOffset(image, date))) / 1000;
			const setDiff = times.sunset && (date.valueOf() - date.getTimezoneOffset() * 60 * 1000 - (times.sunset.valueOf() + getLocTZOffset(image, date))) / 1000;
			var occur = '';
			var diff;
			if (Math.abs(riseDiff) < Math.abs(setDiff)) {
				diff = riseDiff;
				occur = 'sunrise';
			} else {
				diff = setDiff;
				occur = 'sunset';
			}

			const absDiff = Math.abs(diff);
			const occurStr = `(${absDiff > 60 * 60 * 2 ? Math.round(absDiff / (60 * 60)) + ' hours' : Math.round(absDiff / 60) + ' minutes'} ${diff < 0 ? 'before' : 'after'} ${occur})`;


			return date.toLocaleDateString(browserLocale(), { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }) + ' ' + occurStr;
		} else
			return null;
	}

	formatFocalLength(exif) {
		var res = exif.Focal + ' mm';
		if (exif.Focal35 && Number(exif.Focal35) !== Number(exif.Focal)) {
			res += ` (${exif.Focal35} mm full-frame)`;
		}
		return res;
	}

	formatExposure = (exif) => {
		var exposure = exif.Exposure;
		if (typeof exposure === 'string')
			return exposure;
		else {
			if (typeof exposure === 'number') {
				if (exposure > 1)
					return exposure;
				else {
					const inv = 1 / exposure;
					if (inv < 2)
						return '1/' + Math.round(inv * 10) / 10;
					else
						return '1/' + Math.round(inv);
				}
			}
		}
	}

	getDateTaken = () => {
		const { exif } = this.props;
		if (!exif.Date)
			return null;

		if (typeof exif.Date === 'string') {
			const m = exif.Date.match(/(\d+):(\d+):(\d+)\s(\d+):(\d+)/);
			if (m)
				return new Date(m[1], m[2] - 1, m[3], m[4], m[5]);
		} else
			return new Date(exif.Date); // Already numeric
	}

	_str2num(str) {
		try {
			return Number(str);
		} catch {
			return '';
		}
	}

	ExpToNumber(exp) {
		if (exp) {
			const m = String(exp).match(/(\d+)\s*\/\s*(\d+)/);
			if (m) {
				return Number(m[1]) / Number(m[2]);
			} else
				return this._str2num(exp);
		} else
			return null;
	}

	notifyChange(newState) {
		if (this.props.onChange) {
			const exif = {};
			exif.Model = newState.editCamera;
			exif.Lens = newState.editLens;
			exif.Date = newState.editDateTaken && newState.editDateTaken.valueOf();
			exif.Focal = this._str2num(newState.editFocal);
			exif.FNumber = this._str2num(newState.editAperture);
			exif.Exposure = this.ExpToNumber(newState.editExposure);
			exif.ISO = this._str2num(newState.editISO);

			var origExif = { ...this.props.exif };
			delete origExif.Make;
			if (origExif.Focal35) {
				try {
					var ratio = origExif.Focal35 / origExif.Focal;
					origExif.Focal35 = Math.round(Number(exif.Focal) * ratio);
				} catch {
					// we don't care if this conversion fails
				}
			}
			Object.assign(origExif, exif);

			this.props.onChange(origExif);
		}
	}

	localSetState(newState) {
		this.notifyChange(Object.assign({}, this.state, newState));
		this.setState(newState);
	}

	onDateTakenChange = (newDate) => {
		if (!newDate) {
			this.localSetState({ editDateTaken: null });
			return;  // Invalid date
		}

		this.localSetState({ editDateTaken: newDate });
	}

	showExifField = (icon, value, renderEdit, placeholder) => {
		const edit = this.props.edit;
		if (!value && !edit)
			return null;

		var renderEditFn;
		if (edit) {
			if (typeof renderEdit === 'function')
				renderEditFn = renderEdit;
			else {
				if (renderEdit) {
					renderEditFn = () => {
						return (
							<TextField
								value={this.state[renderEdit]}
								onChange={(e) => { var s = {}; s[renderEdit] = e.currentTarget.value; this.localSetState(s); }}
								margin='dense'
								style={{ marginRight: renderEdit === 'editISO' ? 0 : 30, width: 112 }}
								placeholder={placeholder}
							/>
						);
					};
				}
			}
		}

		return (
			<>
				<span style={{ whiteSpace: 'nowrap' }}>
					<Tooltip title={placeholder}>
						{React.createElement(
							icon,
							{ color: 'action', fontSize: 'small', style: { marginRight: 5, marginBottom: renderEditFn ? -7 : -4 } }
						)}
					</Tooltip>

					{edit && renderEditFn ?
						renderEditFn()
						:
						<Typography variant='body1' style={{ marginRight: 30 }} component='span'>
							{value}
						</Typography>
					}
				</span>

				{/* An element for new line wraps */}
				<span />
			</>
		);
	}

	renderDateTakenEdit = () => {
		return (
			<div style={{ display: 'inline-block' }}>
				<DateTimePicker
					value={this.state.editDateTaken}
					onChange={this.onDateTakenChange}
					style={{ marginTop: 3, /*opacity: this.state.editDateTaken ? 1 : 0.7*/ }}
					emptyLabel='Time Taken'
					clearable={true}
					format='Pp'
					className={this.state.editDateTaken ? '' : 'emptyField'}   // A hack to make the placeholder text more transparent (using App.css)
				/>
			</div>
		);
	}

	renderCameraLensEdit = () => {
		return (
			<span style={{ whiteSpace: 'normal' }}>
				<TextField
					value={this.state.editCamera}
					onChange={(e) => this.localSetState({ editCamera: e.currentTarget.value })}
					margin='dense'
					style={{ marginRight: 33, width: 280 }}
					placeholder='Camera'
				/>
				<TextField
					value={this.state.editLens}
					onChange={(e) => this.localSetState({ editLens: e.currentTarget.value })}
					margin='dense'
					style={{ marginLeft: 25, width: 280 }}
					placeholder='Lens'
				/>
			</span>
		);
	}

	render() {
		const { exif, image, style, edit } = this.props;
		const dateCreated = image && image.created && new Date(image.created).toLocaleDateString(browserLocale(), { month: 'numeric', day: 'numeric', year: 'numeric' });
		const camera = this.formatCameraLens(exif);
		const dateTaken = this.formatDateTaken(image);

		return (
			<div style={{ lineHeight: 2.1, ...style }}>
				{/* Date uploaded and Date taken */}
				<div style={{ overflow: edit ? 'initial' : 'hidden', textOverflow: 'ellipsis' }}>
					{dateCreated &&
						this.showExifField(DateUploadIcon, dateCreated, undefined, 'Time Uploaded')
					}

					{this.showExifField(DateTakenIcon, dateTaken, this.renderDateTakenEdit, 'Time Taken')}
				</div>

				{/* Camera */}
				<div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
					{this.showExifField(CameraIcon, camera, this.renderCameraLensEdit, 'Camera and Lens')}
				</div>

				{/* Exposure values */}
				<span style={{ whiteSpace: 'nowrap' }}>
					{this.showExifField(FocalLengthIcon, exif.Focal ? this.formatFocalLength(exif) : null, 'editFocal', 'Focal length')}

					{this.showExifField(ApertureIcon, exif.FNumber ? 'f/' + exif.FNumber : null, 'editAperture', 'Aperture')}

					<span style={{ whiteSpace: 'normal' }}> </span>

					{this.showExifField(ExposureIcon, exif.Exposure ? this.formatExposure(exif) + ' s' : null, 'editExposure', 'Exposure time')}

					{this.showExifField(ISOIcon, exif.ISO ? exif.ISO : null, 'editISO', 'ISO')}
				</span>
			</div>
		);
	}
}

Exif.propTypes = {
	classes: PropTypes.object.isRequired,
	style: PropTypes.object,
	exif: PropTypes.object.isRequired,
	image: PropTypes.object,
	edit: PropTypes.bool,
	onChange: PropTypes.func,
};

/** @type {any} */
// @ts-ignore
export default withStyles(styles)(Exif);
