//@ts-check
/* eslint-disable no-console */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import AppHeader from './AppHeader';
// import Images from './Images';
import TagItems from './TagItems';
import MapView from '../MapView';
import SpotsList from './SpotsList';

import SpotIcon from '@material-ui/icons/LocationOn';

import Navigation from '../navigation';
import { fetchMapSearch, setPageTitle } from '../tools';

const style = /*theme => */({
	sectionGroup: {
		display: 'flex',
		marginTop: 10,
		justifyContent: 'center',

		'@media (max-width: 700px)': {
			flexWrap: 'wrap',
		}
	},

	section: {
		margin: 10,
		padding: 10,
		maxWidth: '87%',
		overflow: 'hidden',
		width: 700,
	},

	sectionHeader: {
		marginBottom: 8,
	},

	address: {
		fontSize: 13,
		fontWeight: 400,
		lineHeight: 2,
		transition: 'all 0.3s ease',

		cursor: 'pointer',

		marginRight: 10,

		whiteSpace: 'nowrap',
		display: 'inline-block',
		textIndent: 0,
	}
});

class SearchPage extends Component {
	currentMarkdown = null;
	state = {
		tabIndex: 0,

		scroller: null,
		photos: null,
		/** @type{any} */
		spots: null,
		tags: null,
		/** @type{any} */
		addresses: null,
	}
	locs = null;

	updateTitle() {
		setPageTitle(`Search '${this.props.searchString}'`);
	}

	async updateAddresses() {
		const uniqTerms = [];
		this.setState({ addresses: null });
		const addresses = (await fetchMapSearch(this.props.searchString))
			.slice(0, 10) // Max 10 results
			.map(t => { // Perform some formatting to shorten long addresses
				var terms = t.display_name
					.split(',')
					.map(tt => tt.trim())
					.filter(tt => isNaN(tt)); // Remove numbers (like ZIP)
				if (terms.length > 3)
					terms.splice(1, terms.length - 3); // Remove where there's too many parts (e.g. street address, etc.)
				if (terms[1] === terms[2])
					terms.splice(1, 1);
				if (terms[0] === terms[1])
					terms.splice(0, 1);
				return {
					display_name: terms.join(', '),
					boundingbox: t.boundingbox,
				};
			})
			.filter(t => { // Publish only unique addresses
				const exists = uniqTerms.indexOf(t.display_name) >= 0;
				if (!exists)
					uniqTerms.push(t.display_name);
				return !exists;
			});
		this.setState({ addresses: addresses });
	}

	newSearchTerm() {
		this.updateTitle();
		this.updateAddresses();
	}

	componentDidMount() {
		this.newSearchTerm();
		this.updateResults();
	}

	componentDidUpdate(prevProps) {
		if (this.props.searchResults !== prevProps.searchResults)
			this.updateResults();

		if (this.props.searchString !== prevProps.searchString)
			this.newSearchTerm();
	}

	updateResults() {
		var locs = this.props.searchResults && this.props.searchResults.locs;
		var reducer = (s, i) => s + i.rating;
		if (locs) {
			locs.sort((l1, l2) => {
				var res = l2.q - l1.q;
				if (res === 0)
					res = (l2.descriptions.reduce(reducer, 0) + l2.images.reduce(reducer, 0)) - (l1.descriptions.reduce(reducer, 0) + l1.images.reduce(reducer, 0));
				return res;
			});
		}
		this.locs = locs;
		const tags = this.props.searchResults && this.props.searchResults.tags;
		if (!locs) {
			this.setState({
				photos: null,
				spots: null,
				tags: null,
			});
			return;
		}

		// Get All photos
		// const photos = locs.reduce((acc, loc) => {
		// 	for (const img of loc.images) // eslint-disable-line no-unused-vars
		// 		img.location = loc;
		// 	return acc.concat(loc.images);
		// }, []);

		// Get All spots
		// const spots = locs.reduce((acc, loc) => acc.concat([{ ...loc.images[0], location: loc }].filter(img => img.id)), []);
		const spots = locs;

		this.setState({
			// photos: photos,
			spots: spots,
			tags: tags.map(t => ({ tag: t })),
		});
	}

	handleTabChange = (event, value) => {
		this.setState({ tabIndex: value });
		if (value === 1) {
			Navigation.setFilter('fs', this.props.searchString, this.locs);
		}
	}

	onAddressClick = (event) => {
		event.preventDefault();
		event.stopPropagation();

		const index = event.currentTarget.dataset.id;
		Navigation.clearFilters();
		Navigation.openMapPage(false, this.state.addresses[index].boundingbox);
	}

	render() {
		const { classes } = this.props;

		return (
			<div
				style={{ width: '100%', overflowY: 'auto', overflowX: 'hidden' }}
				ref={el => !this.state.scroller && this.setState({ scroller: el })}
			>
				<AppHeader user={this.props.user} />
				<div>
					<div className={classes.sectionGroup}>
						{/* Addresses */}
						{this.state.addresses &&
							<Paper className={classes.section}>
								<Typography variant='h6' className={classes.sectionHeader}>
									{'Places'}
								</Typography>
								{this.state.addresses.map((addr, index) => {
									return (
										<a key={addr.display_name} className={classes.address} data-id={index} onClick={this.onAddressClick} href='/'> {/* TODO: href should get some real target */}
											<SpotIcon style={{ marginBottom: -5 }} />
											{addr.display_name}
										</a>
									);
								})}
							</Paper>
						}

						{/* Tags */}
						{this.state.tags &&
							<Paper className={classes.section}>
								<Typography variant='h6' className={classes.sectionHeader}>
									{'Tags'}
								</Typography>
								<TagItems
									tags={this.state.tags}
								/>
							</Paper>
						}
					</div>

					{/* Main tabs */}
					<Tabs
						value={this.state.tabIndex}
						onChange={this.handleTabChange}
						style={{ marginBottom: 10 }}
						centered={true}
					>
						<Tab label={'Spots' + (this.state.spots ? ` (${this.state.spots.length})` : '')} />
						{/* <Tab label={'Photos' + (this.state.photos ? ` (${this.state.photos.length})` : '')} /> */}
						<Tab label={'Map'} />
					</Tabs>

					{!this.state.spots &&
						<LinearProgress />
					}

					{/* Spots */}
					{this.state.tabIndex === 0 &&
						<SpotsList spots={this.state.spots} />
					}

					{/* Photos
					{this.state.tabIndex === 1 &&
						<Images
							images={this.state.photos}
							height={180}
							margins={5}
							scroller={this.state.scroller}
						/>
					} */}

					{/* Map */}
					{this.state.tabIndex === 1 &&
						<div style={{ height: '50vh', width: '100%', maxWidth: 1366, marginLeft: 'auto', marginRight: 'auto' }}>
							<MapView
								largeMap={false}
								mobile={this.props.mobile}
							/>
						</div>
					}
				</div>
			</div>
		);
	}
}

SearchPage.propTypes = {
	classes: PropTypes.object.isRequired,
	searchString: PropTypes.string,
	searchResults: PropTypes.object,
	mobile: PropTypes.bool.isRequired,

	user: PropTypes.object,
};

/** @type {any} */
// @ts-ignore (style problem)
export default withStyles(style)(SearchPage);