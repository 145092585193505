/* eslint-disable react/display-name */
import React from 'react';

export default function tos() {
	return (
		<div className='textContent'>
			<h1>Terms of Service</h1>
			<p>Phoide.com is a website dedicated to sharing the best photography locations around the
			world. We welcome you to participation to this community of photographers to share your knowledge with other
			people. The following terms are here to ensure that everything keeps running smoothly.</p>

			<h3>User obligations</h3>
			<p>To access some of the Phoide.com features you will need to register for an account and
			consent to these Terms. If you do not consent to these Terms, Phoide.com reserves the right to refuse, suspend or
			terminate your access to the service.</p>
			<p>Every user is expected to treat other users with respect. Any behavior that may lead to
			a negative community experience can lead to a temporary or permanent termination of the account.</p>


			<h2>Content Permissions, Restrictions, and Licensing</h2>

			<p>All materials displayed on Phoide.com, including but not limited to text, graphics,
			logos, photographs, images, software or source code, audio and video are the property of Phoide.com and/or third
			parties and are protected by international copyright laws.</p>



			<h3>Subscriber photographic content</h3>

			<p>By submitting a Photograph to Phoide.com, you are granting Phoide.com a worldwide,
			non-exclusive license to use the Photograph in connection with Phoide.com services. The Photograph can be
			shown at Phoide.com, its affiliate websites and promoted on various media, but
			always only with a proper attribution. Phoide.com may not resell or otherwise exploit the
			Photograph.</p>

			<p>You warrant that you are the owner of all rights to each submitted Photograph.
			</p>

			<p>Ownership or other intellectual property rights in the Photograph are retained by
			you.</p>



			<h3>Subscriber text content</h3>

			<p>You agree that locations, their description and tags that you provide to the
			Phoide.com
			(collectively, &quot;Subscriber Text Content&quot;), is perpetually and irrevocably licensed to Phoide.com on a
			worldwide, royalty-free, non-exclusive basis pursuant to Creative Commons licensing terms (
			<a href="https://creativecommons.org/licenses/by-sa/4.0/">CC-BY-SA</a>), and you grant Phoide.com the perpetual and irrevocable right and license to access, use, process,
			copy, distribute, export, display and to commercially exploit such Subscriber Text Content. This allows other
			users to make corrections and add updated information to such a content.
			</p>
		</div>
	);
}