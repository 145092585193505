//@ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { showHelp } from '../dialogs/Help';

const styles = {
};

class HelpIcon extends React.Component {
	onClick = () => {
		showHelp(this.props.id);
	}

	render() {
		return (
			<IconButton size={this.props.size || 'medium'} style={{ marginBottom: -7, marginTop: -7, opacity: 0.5, ...this.props.style }} onClick={this.onClick}>
				<HelpOutlineIcon fontSize='small' />
			</IconButton>
		);
	}
}

HelpIcon.propTypes = {
	classes: PropTypes.object.isRequired,
	style: PropTypes.object,

	id: PropTypes.string.isRequired,
	size: PropTypes.string,
};

/** @type {any} */
export default withStyles(styles)(HelpIcon);
