// @ts-check

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import { checkKeyMods, MD2React } from '../tools';
import Navigation from '../navigation';

const styles = theme => ({
	mobileFrame: {
		width: 'calc(100% + 24px)',
		marginLeft: -12,
		marginRight: -12,
	},

	baseFrame: {
		backgroundColor: 'inherit',
		overflow: 'hidden',
		marginBottom: 30,
		padding: 12,
		paddingBottom: 0,
		width: `calc(100% - ${2 * 12}px)`,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
});

function shortenArtictle(text) {
	if (!text)
		return text;

	text = text.split("\n").filter(l => l[1] !== '#').join('\n');

	const m = text.match(/[\S\s]*?^[^#`].*$\n\n/m);
	if (m && m[0])
		return m[0]
	else
		return text;
}

class Description extends PureComponent {
	openArticle = (e) => {
		if (checkKeyMods(e)) {
			const { article } = this.props;
			Navigation.openGuide(article.perma || article.title);
		}
	}

	render() {
		const { classes, mobile, article } = this.props;

		return (
			<div
				// ref={this.frameRef}
				className={mobile ? ' ' + classes.mobileFrame : ''}
			>
				<Paper
					elevation={3}
					className={'defText articleBrief ' + classes.baseFrame}
				>
					{article.title &&
						<a href={'/guide/' + (article.perma || article.title)} onClick={this.openArticle} className='noUnderline'>
							<h3 style={{ marginTop: 4,  }}>{article.title}</h3>
						</a>
					}
					{MD2React(shortenArtictle(article.text))}
					<p style={{ textAlign: 'right', marginTop: -26 }}>
						<a href={'/guide/' + (article.perma || article.title)} onClick={this.openArticle}>
							{'continue reading...'}
						</a>
					</p>
				</Paper>
			</div>
		);
	}

}

Description.propTypes = {
	classes: PropTypes.object.isRequired,
	mobile: PropTypes.bool,
	article: PropTypes.object,
};

/** @type {any} */
// @ts-ignore
export default withStyles(styles)(Description);
