// @ts-check
/* eslint-disable no-console */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// import ResizeObserver from 'resize-observer-polyfill';
import EmptyIcon from '@material-ui/icons/Theaters';

import Navigation from '../navigation';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation as SwiperNavigation, Scrollbar as SwiperScrollbar } from 'swiper';
import './Swiper/swiper-bundle.css';

// Install modules
SwiperCore.use([SwiperNavigation, SwiperScrollbar]);

const styles = theme => ({
	mainbox: {
		height: 206,
		margin: 0,
		maxWidth: '100%',
	},

	imgbox: {
		height: 206,
		cursor: 'pointer',
		position: 'relative',
		width: 'auto',
	},
	img: {
		height: 200,
		border: 'solid transparent 3px',
		transition: 'border-color 0.4s, filter 0.4s',
	},

	unselImage: {
		filter: 'brightness(.3)',
	},
	selected: {
		borderColor: theme.palette.secondary.main,
		borderRadius: 8,
	},

	ignored: {
		display: 'none',
		width: 0,
	},

	emptyImage: {
		color: theme.palette.action.active,
	},
});

class ImagesChooser extends Component {
	state = {
		selected: null,
		/** @type{any} */
		ignored: [],
		loadedURLs: {},
	}
	loadedURLs = {};
	_ignored = [];
	/** @type{any} */
	_swiper = null;
	idSwiper = Navigation.getUID();

	componentDidUpdate(prevProps) {
		if (this.props.images !== prevProps.images) {
			this._ignored = [];
			this.firstAutoSelect = false;
			this.setState({
				selected: null,
				ignored: this._ignored,
			});
		}

		if (this.nonIgnoredCount() === 1 && !this.state.selected && !this.firstAutoSelect) {
			this.firstAutoSelect = true;
			setTimeout(() => {
				if (this.nonIgnoredCount() === 1 && !this.state.selected) {
					const index = this.state.ignored.indexOf(false);
					this.toggleImg(index);
				}
			}, 200);
		}
	}

	toggleImg = (index) => {
		const newSelected = (this.state.selected === index) ? null : index;
		this.setState({ selected: newSelected });

		// this.swiper.slideTo(index - this.state.ignored.filter((ign, ind) => ign && ind < index && !this.loadedURLs[this.props.images[ind].url]).length);

		if (this.props.onSelect) {
			this.props.onSelect(newSelected);
		}
	}

	imgClick = (event) => {
		if (event.ctrlKey || event.altKey || event.shiftKey)
			return; // Open the link
		else
			event.preventDefault(); // Don't open the link, just select it

		const index = Number(event.currentTarget.dataset.index);
		this.toggleImg(index);
	}

	imgLoaded = (event) => {
		// console.log('loaded!');
		const el = event.currentTarget;
		const index = Number(el.dataset.index);
		const ignore = (el.naturalWidth < 200 || el.naturalHeight < 100);
		if (!ignore) {
			this.loadedURLs[this.props.images[index].url] = true;
			this.setState({ loadedURLs: { ...this.loadedURLs } });
			setTimeout(() => {
				if (this._swiper)
					this._swiper.update();
			}, 200);
		}
		this._ignored[index] = ignore;
		this.setState({ ignored: this._ignored });
	}

	renderImage = (img, index) => {
		const { classes } = this.props;
		const { selected } = this.state;
		const ignored = (this.state.ignored[index] !== false) && !this.loadedURLs[img.url];

		return (
			<SwiperSlide
				className={
					'swiper-slide ' +
					classes.imgbox
					+ (ignored ? ' ' + classes.ignored : '')
				}
				// href={this.props.sourceURL}
				// target={'_blank'}
				// rel='noopener noreferrer'
				data-index={index}
				onClick={this.imgClick}
				key={'image' + img.url}
			>
				<img
					src={img.url}
					className={
						classes.img +
						(selected === null ? '' : ' ' + (selected === index ? classes.selected : classes.unselImage))
					}
					data-index={index}
					alt={img.title}
					onLoad={this.imgLoaded}
				/>
			</SwiperSlide>
		);
	}

	goNext = () => {
		if (this._swiper) this._swiper.slideNext();
	}

	goPrev = () => {
		if (this._swiper) this._swiper.slidePrev();
	}

	nonIgnoredCount() {
		return this.state.ignored.filter((item, index) => (item === false) || (this.props.images && this.props.images[index] && this.loadedURLs[this.props.images[index].url])).length;
	}

	initSwiper = (swiper) => {
		this._swiper = swiper;
	}

	render() {
		const { images, classes } = this.props;

		return (
			<Swiper
				spaceBetween={1}
				slidesPerView={'auto'}
				slidesPerGroup={1}
				slidesPerGroupAuto={true}
				navigation={{}}
				scrollbar={{ draggable: true, hide: true }}
				className={classes.mainbox}
				onInit={this.initSwiper}
			>
				{!images &&
					<SwiperSlide>
						<EmptyIcon style={{ width: '100%', height: '100%' }} className={classes.emptyImage} />
					</SwiperSlide>
				}

				{images && images.map((img, index) =>
					this.renderImage(img, index)
				)}
			</Swiper>
		);
	}
}

ImagesChooser.propTypes = {
	classes: PropTypes.object.isRequired,
	style: PropTypes.object,
	sourceURL: PropTypes.string,
	images: PropTypes.array,
	onSelect: PropTypes.func,
};

/** @type {any} */
// @ts-ignore (some silly style check?)
export default withStyles(styles)(ImagesChooser);
