/* eslint-disable react/display-name */
import React from 'react';

import VideoPlayer from '../fragments/VideoPlayer';
import Button from '@material-ui/core/Button';
import { completeLocalURL, getBlankTarget } from '../tools.js';
// import IconButton from '@material-ui/core/IconButton';

import Navigation from '../navigation';

function login() {
	Navigation.login();
}

export default function features(props) {
	return (
		<div style={{ textAlign: 'center', maxWidth: 1200, marginRight: 'auto', marginLeft: 'auto', paddingLeft: 0, paddingRight: 0 }} className='textContent'>
			<h1>Features</h1>
			<div className='subText'>
				Phoide offers a lot of features for photographers and travellers, let's have a look...
			</div>

			<div className='feature small'>
				<div className='featImg'>
					<VideoPlayer video='map' webm alt='Map usage video' />
				</div>
				<div className='featText'>
					<h4>Map</h4>
					<p>Use Map to find new places. The most popular are shown as circular thumbnails, click them or place mouse on them to get more information. Or zoom in to find more local photography locations denoted by pins with a thumbnail.</p>
				</div>
			</div>

			<div className='feature small'>
				<div className='featImg'>
					<VideoPlayer video='mapLayers' webm alt='Switching map layers' />
				</div>
				<div className='featText'>
					<h4>Map Layers</h4>
					<p>Various layer for various purposes. Use the default in cities, topography for outdoor navigation, or aerial to better understand the location. Or open external maps to use their features, like 3D view, navigation, etc.</p>
				</div>
			</div>

			<div className='feature small'>
				<div className='featImg'>
					<VideoPlayer video='neist' webm alt='Video showing 3D view navigation' />
				</div>
				<div className='featText'>
					<h4>Visualization in 3D</h4>
					<p>Preview the location in 360° panorama. Check out how the scene looks through Lenses of various focal lengths.</p>
				</div>
			</div>

			<div className='feature small'>
				<div className='featImg'>
					<VideoPlayer video='photoMove' webm alt='How to move a photograph to another location' />
				</div>
				<div className='featText'>
					<h4>Fix Photograph location</h4>
					<p>Photograph in an incorrect place? You can easily suggest its move to another location and votes of other users confirm the action then.</p>
				</div>
			</div>


			<div className='feature small'>
				<div className='featImg'>
					<VideoPlayer video='charles' webm alt='Video showing photography guide' />
				</div>
				<div className='featText'>
					<h4>Location Guides</h4>
					<p>Study existing location guides to find more about possible places to visit and when to go there. If you have comments or tips, you can share them with the community.</p>
				</div>
			</div>

			<div className='feature small'>
				<div className='featImg'>
					<VideoPlayer video='plan' webm alt='Video showing how to plan which photography spots to visit' />
				</div>
				<div className='featText'>
					<h4>Sun and Moon in Map</h4>
					<p>Review light conditions thorought the year, Sun and Moon positions, Golden and Blue Hour times, shadows directions, nearby Webcams and more.</p>
				</div>
			</div>

			<div className='feature small'>
				<div className='featImg'>
					<VideoPlayer video='3DLightPlan' webm alt='Location plan in 3D' />
				</div>
				<div className='featText'>
					<h4>Sun and Moon in 3D</h4>
					<p>View the location in 3D, use Augmented Reality elements that show nearby locations, Sun and Moon positions thorough the day and year, shadows, Weather forecast and more.</p>
				</div>
			</div>

			<div className='feature small'>
				<div className='featImg'>
					<VideoPlayer video='publish' webm alt='New photograph upload' />
				</div>
				<div className='featText'>
					<h4>Publish your photographs</h4>
					<p>Upload photographs, place them in new or existing photography spots, create galleries. Exif metadata processed on import, together with GPS coordinates if present in the photograph.</p>
				</div>
			</div>


			{props.user ?
				<div className='subText' style={{ marginTop: 60, marginBottom: 10 }}>
					How to start? Check out the <a data-inlink href='/map'>Map</a>, <a data-inlink href='/explore/mostphoto'>Favourite Locations</a>, <a data-inlink href='/explore/nearby'>Nearby Spots</a>, or just Search for anything you's like to explore.
				</div>
				:
				<>
					<div className='subText' style={{ marginTop: 60, marginBottom: 10 }}>
						How to start? Just login or create a new account, it's super easy.
					</div>
					<div><Button variant='contained' color='secondary' size='large' classes={{ label: 'joinLabel' }} onClick={login}>Join Phoide</Button></div>
				</>
			}

			{!process.env.REACT_APP_CORDOVA &&
				<div className='feature' style={{ maxWidth: 830, marginTop: 60 }}>
					<div className='featImg' style={{ justifyContent: 'flex-start', maxWidth: '40%' }}>
						<picture>
							<source srcSet={completeLocalURL('/images/phone.webp')} type='image/webp' />
							<source srcSet={completeLocalURL('/images/phone.png')} type='image/png' />
							<img src={completeLocalURL('/images/phone.png')} alt='Phoide running on a mobile phone' style={{ width: 250, maxWidth: '100%' }} />
						</picture>
					</div>
					<div className='featText' style={{ textAlign: 'center' }}>
						<p className='mobSmall mobMTop'>
							Or install the Android mobile application. It gives you all the Phoide's features in a neat package ready to be used anywhere on the go.
					</p>

						<p>
							<a href='https://play.google.com/store/apps/details?id=com.phoide' target={getBlankTarget()} rel='noopener noreferrer'>
								<img src={completeLocalURL('/images/playstore.svg')} style={{ width: 160, cursor: 'pointer', margin: 10, maxWidth: '35vw' }} alt='Get it on Google Play' />
							</a>
						</p>

					</div>
				</div>
			}
		</div>
	);
}