//@ts-check
/* eslint-disable no-console */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import AppHeader from './AppHeader';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Footer from './Footer';

import Navigation from '../navigation.js';
import { mainNav, subNav } from '../navUI.js';
import { checkKeyMods, setPageTitle } from '../tools.js';

const style = /*theme => */({
	drawer: {

	},
});


class AboutPage extends Component {
	componentDidMount() {
		this.updateTitle();
	}

	componentDidUpdate() {
		this.updateTitle();
	}

	updateTitle() {
		const { menu2 } = this.getMenu();
		setPageTitle(menu2.title);
	}

	goMenu = (e) => {
		if (checkKeyMods(e)) {
			const data = e.currentTarget.dataset;
			Navigation.goPath(data.url || `/${this.props.page}/${data.id}`);
		}
	}

	getMenu() {
		var { page, tab } = this.props;
		/** @type {any} */
		const menu1 = mainNav.find(item => item.id === page);
		const menulist = subNav[page];
		if (!tab && menu1)
			tab = menu1.defTab;
		const menu2 = menulist.find(item => item.id === tab);
		return { menulist, menu1, menu2 };
	}

	render() {
		// const { classes } = this.props;
		var { page, tab } = this.props;
		const { menulist, menu1, menu2 } = this.getMenu();

		return (
			<div
				style={{ width: '100%', overflowY: 'auto' }}
			>
				{/* All but Footer */}
				<div className='allButFooter'>
					<AppHeader user={this.props.user} menuItem={page} subMenuItem={tab} />

					<div style={{ display: 'flex', overflow: 'hidden' }}>
						<div style={{ minWidth: 230, flexGrow: 3, flexBasis: 200 }} className='fullMainMenu'>
							<List
								component='nav'
								aria-label='Navigation'
								style={{ float: 'right', maxWidth: '100%', minWidth: 200, borderRight: 'solid 1px #e0e0e0', marginTop: 8, padding: 0, paddingRight: 8, marginRight: 20 }}
							>
								{menulist.map((item, index) => {
									if (item.id) {
										return (
											<ListItem
												button
												key={item.id}
												selected={tab === item.id}
												data-id={item.id}
												data-url={item.url}
												onClick={this.goMenu}
												component='a'
												href={`/${page}/${item.id}`}
											>
												<ListItemText primary={item.title} />
											</ListItem>
										);
									}
									else
										return (
											<Divider key={'d' + index} />
										);
								})}
							</List>
						</div>

						{/* Main content */}
						<div style={{ maxWidth: menu1.contentWidth, flexGrow: 3, flexBasis: menu1.contentWidth, paddingTop: 12 }}>
							{menu2 && menu2.render && React.createElement(menu2.render, { user: this.props.user })}
						</div>

						{/* Show this as a right side placeholder (when screen is wide enough) */}
						<div style={{ minWidth: 230, flexGrow: 3, flexBasis: 200, marginLeft: 20 }} className={menu1.sideClass || 'wideOnly'} />
					</div>
				</div>

				<Footer />
			</div >
		);
	}
}

AboutPage.propTypes = {
	page: PropTypes.string.isRequired,
	tab: PropTypes.string,
	classes: PropTypes.object.isRequired,
	mobile: PropTypes.bool.isRequired,
	user: PropTypes.object,
};

/** @type {any} */
// @ts-ignore (style problem)
export default withStyles(style)(AboutPage);