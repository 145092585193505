// @ts-check

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import UserAvatar from './UserAvatar';
import ShareButton from './ShareButton';
import AddToCollection from './AddToCollection';
import Editor from './Editor';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HelpIcon from './HelpIcon';
import Tooltip from '@material-ui/core/Tooltip';

import ThumbUpIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbDownIcon from '@material-ui/icons/ThumbDownOutlined';
import LocationIcon from '@material-ui/icons/LocationOn';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import NoFullscreenIcon from '@material-ui/icons/FullscreenExit';
import TrashIcon from '@material-ui/icons/Delete';

import Exif from './Exif';

import Server from '../server';
import Navigation from '../navigation.js';
import { MD2React, checkKeyMods, setPageTitle, adjustStatusBar, getBlankTarget } from '../tools';

import ResizeObserver from 'resize-observer-polyfill';
import { showHelp } from '../dialogs/Help';

const HIDE_TIMEOUT = 3000;
const BOTTOM_HEIGHT = 195;

const styles = theme => ({
	rootScroll: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		overflowY: 'auto',
		zIndex: 2000,
	},

	photoBg: {
		minHeight: '70vh',
		height: `calc(100vh - ${BOTTOM_HEIGHT}px)`,
		backgroundColor: '#212121',
		position: 'relative',
		boxSizing: 'border-box',
		transition: 'all 0.2s',
	},

	topBtn: {
		position: 'absolute',
		top: 0,
		marginTop: 5,
		transition: 'opacity 0.3s',
	},

	topBtnHidden: {
		opacity: 0,

		'&:hover': {
			opacity: 1,
		}
	},

	sticky: {
		position: 'sticky',
		top: `calc(-70vh + ${BOTTOM_HEIGHT}px)`,
		// overflow: 'hidden',
	},

	imgPlace: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		willChange: 'transform',
	},

	bgImg: {
		height: '100%',
		width: '100%',
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},

	icnButton: {
		color: '#e0e0e0',

		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.08)',
		}
	},

	fieldTitle: { margin: 0 },

	inputTitle: {
		fontSize: '1.5rem',
		fontWeight: 500,
	},

	LBRating: {
		// opacity: 0.7,
	},

	detailsContainer: {
		backgroundColor: theme.palette.background.default,
		position: 'relative', // for z-index to work
		zIndex: 10, // To be above photo, when it's moved
	},

	locationLink: {
		alignItems: 'center',
		marginTop: 8,
		cursor: 'pointer',
		width: 'min-content',
		whiteSpace: 'nowrap',
	},

	locationHover: {
		display: 'inline',

		transition: 'all 0.1s ease',
		':hover > &': {
			color: theme.palette.primary.dark,
		}
	}
});

class PhotoPage extends React.PureComponent {
	state = {
		fullscreen: false,
		hideBtns: false,
		edit: false,

		/** @type{any} */
		imgHeight: null,
	}
	/** @type{any} */
	_hideTimer = null;
	currentMarkdown = '';
	editedExif = {};

	setTitle() {
		const image = this.props.photoData || {};
		const user = image.user;

		if (this.getTitle() && user)
			setPageTitle(`${this.getTitle()} by ${image.author || user.displayName}`);
	}

	componentDidMount() {
		document.addEventListener('keydown', this.onKeyDown, true);
		this.checkImageSize();

		this.ro = new ResizeObserver(() => {
			if (this.scroller && this.photoBg)
				this.photoBg.style.width = `calc(100% - ${this.scroller.offsetWidth - this.scroller.clientWidth}px)`;
		});

		this.ro.observe(this.scroller);

		this.setTitle();

		adjustStatusBar('#212121', true);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.onKeyDown, true);
		if (this.scroller)
			this.scroller.removeEventListener('scroll', this.onScroll);
		clearTimeout(this._hideTimer);

		if (process.env.REACT_APP_CORDOVA) {
			if (this.state.fullscreen) {
				// @ts-ignore
				window.AndroidFullScreen.showSystemUI();
			}
			adjustStatusBar();
		}

		if (this.scroller)
			// @ts-ignore
			this.ro.unobserve(this.scroller);
		this.ro = undefined;
	}

	componentDidUpdate(prevProps) {
		if (this.props.photoData !== prevProps.photoData)
			this.checkImageSize();

		this.setTitle();
	}

	checkImageSize() {
		this.setState({ imgHeight: null });
		const photoData = this.props.photoData;

		if (photoData) {
			var img = new Image();
			img.onload = () => {
				if (photoData && img.src === this.getImgUrl(photoData.id))
					this.setState({ imgHeight: img.naturalHeight });
			};

			img.src = this.getImgUrl(photoData.id);
			if (img.naturalHeight)
				this.setState({ imgHeight: img.naturalHeight });
		}
	}

	setScroller = (el) => {
		this.scroller = el;
		if (el)
			el.addEventListener('scroll', this.onScroll);
	}

	setPhotoBg = (el) => {
		this.photoBg = el;
	}

	onScroll = () => {
		this.updateScrollTop(this.scroller.scrollTop);
	}

	updateScrollTop(scrollTop) {
		this._adjustScroll = scrollTop;
		if (!this._rafQueued) {
			this._rafQueued = true;
			window.requestAnimationFrame(() => {
				this._rafQueued = undefined;
				if (this.elPhoto) {
					const adjust = Math.round(Math.min(this._adjustScroll / 2, (window.innerHeight * 0.7 - BOTTOM_HEIGHT) / 2));
					this.elPhoto.style.transform = `translateY(${adjust}px)`;

					// Follows an attempt to make it smoother on mobile by having two images, one on the background (non-scrolling) and another in front (scrolling). It didn't help though.
					// var adjust = Math.round(this._adjustScroll / 2);
					// const maxSize = Math.round((window.innerHeight * 0.7 - BOTTOM_HEIGHT) / 2);
					// if (adjust > maxSize) {
					// 	adjust = maxSize;
					// 	this.elPhotoBg.style.opacity = '1';
					// 	this.elPhoto.style.transform = `translateY(${adjust}px)`;
					// } else {
					// 	this.elPhotoBg.style.opacity = '0';
					// 	this.elBackPhoto.style.transform = `translateY(${-adjust}px)`;
					// }
				}
			});
		}
	}

	getImgUrl = (photoID) => {
		return photoID && Server.getImageUrlFromID(photoID);
	}

	getImage() {
		return this.props.photoData;
	}

	getExif() {
		return JSON.parse((this.getImage() || {}).exif || '{}');
	}

	getTitle = () => {
		const image = this.getImage();
		return image && image.title;
	}

	closeLightbox = (event) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		if (this.props.onClose)
			this.props.onClose();
	}

	renderUserAvatar = (user) => {
		if (user)
			return (
				<UserAvatar user={user} style={{ marginRight: 20 }} />
			);
		else
			return null;
	}

	handleLocate = () => {
		if (this.props.photoData) {
			Navigation.goSpot(this.props.photoData.idLoc);
		}
	}

	getCurrentLBImage = () => {
		return this.props.photoData;
	}

	handleLBRatingUp = (event) => {
		Server.rateImgUp(null, this.getCurrentLBImage(), event.currentTarget);
	}

	handleLBRatingDown = (event) => {
		Server.rateImgDown(null, this.getCurrentLBImage(), event.currentTarget);
	}

	setFullState(full) {
		this.setState({
			fullscreen: full,
			hideBtns: full,
		});
	}

	onFullscreen = () => {
		const full = !this.state.fullscreen;

		if (full) {
			this.restoreScrollTop = this.scroller && this.scroller.scrollTop;
			this.updateScrollTop(0);
		}
		else {
			var cnt = 0;
			var restorer = () => {
				if (this.scroller) {
					this.scroller.scrollTop = this.restoreScrollTop;
					this.updateScrollTop(this.restoreScrollTop);
				}

				if (cnt++ < 20)
					requestAnimationFrame(restorer); // We keep repeating this so that 'scrollTop' can be properly restored (TODO: Make it time-based?)
			};

			restorer();
		}

		// TODO: The hiding of system UI is very distracting on my Samsung Galaxy S7, let's try to return it later

		// if (full !== this.state.fullscreen &&
		// 	this.scroller &&
		// 	this.props.mobile &&
		// 	window.matchMedia('only screen and (max-width: 760px) and (hover: none)').matches
		// ) {
		// 	if (full) {
		// 		this.scroller.requestFullscreen && this.scroller.requestFullscreen();

		// 		setTimeout(() => {
		// 			this.setFullState(full);
		// 		}, 100);
		// 	} else {
		// 		document.exitFullscreen && document.exitFullscreen().catch(() => { });
		// 		setTimeout(() => {
		// 			this.setFullState(full);
		// 		}, 100);
		// 	}
		// 	return;
		// }

		// TODO: Disable also this, due to the questionable hiding speed of the system UI and drawing artefacts?
		if (process.env.REACT_APP_CORDOVA) {
			if (full) {
				// @ts-ignore
				window.AndroidFullScreen.immersiveMode();

				setTimeout(() => {
					this.setFullState(full);
				}, 60);
			} else {
				// @ts-ignore
				window.AndroidFullScreen.showSystemUI();
				adjustStatusBar('#212121', true);
				this.setFullState(full);
			}
		} else {
			this.setFullState(full);
		}
	}

	onUserClick = (event) => {
		if (checkKeyMods(event))
			Navigation.showProfile(this.getImage().user.username);
	}

	onKeyDown = (e) => {
		if (e.keyCode === 27 /*escape*/) {
			if (this.state.fullscreen)
				this.onFullscreen();
			else
				this.closeLightbox();
		}

		if (e.key && e.key.toUpperCase() === 'L' && !this.state.edit)
			this.onFullscreen();
	}

	getLocation() {
		const { photoData } = this.props;
		return {
			id: photoData.idLoc,
			title: photoData.locTitle,
		};
	}

	getLocationURL() {
		return Navigation.getLocationURL(this.getLocation());
	}

	onLocationClick = (event) => {
		if (checkKeyMods(event))
			Navigation.goSpot(this.getLocation());
	}

	resetHideTimer() {
		clearTimeout(this._hideTimer);
		this._hideTimer = setTimeout(() => {
			if (this.state.fullscreen)
				this.setState({ hideBtns: true });
		}, HIDE_TIMEOUT);
	}

	changeTestShot = () => {
		this.setState({ editTestShot: !this.state.editTestShot });
	}

	onMouseMove = () => {
		this.setState({ hideBtns: false });
		if (this.state.fullscreen)
			this.resetHideTimer();
	}

	onMouseLeave = () => {
		if (this.state.fullscreen)
			this.setState({ hideBtns: true });
	}

	onCancel = () => {
		if (window.confirm('Do you really want to cancel the edit?'))
			this.setState({ edit: false });
	}

	onDelete = (event) => {
		if (window.prompt('Do you really want to permanently delete this photograph? The action is irreversible! Please type the word \'YES\' if you want to proceed.') === 'YES') {
			Server.deletePhoto(this.props.photoData.id, this.props.photoData.idLoc);
			this.closeLightbox(event);
		}
	}

	onEdit = () => {
		const photoData = (this.props.photoData || {});

		this.setState({
			edit: true,
			editTitle: this.getTitle(),
			editTestShot: photoData.imgType === 1,
		});
		this.currentMarkdown = photoData.description || '';
	}

	onSave = () => {
		this.setState({
			edit: false,
		});

		const image = this.props.photoData;
		if (!image)
			return;

		image.title = this.state.editTitle;
		image.description = this.currentMarkdown;
		image.exif = JSON.stringify(this.editedExif);
		image.imgType = this.state.editTestShot ? 1 : 0;

		const spot = this.getLocation();
		Server.updateImage(image, spot.id);
	}

	onExifChange = (exif) => {
		this.editedExif = exif;
	}

	onTestShotHelp = () => {
		showHelp('testshot');
	}

	render() {
		const { classes } = this.props;
		const { edit } = this.state;
		const image = this.props.photoData || {};

		const user = image.user;
		const btnClass = classes.topBtn + (this.state.hideBtns ? ' ' + classes.topBtnHidden : '');
		const canEdit = this.props.user && ((image.user && this.props.user.id === image.user.id) || (this.props.user.id === 1 /* TODO: just a hack for now */));

		const exif = image.exif ? JSON.parse(image.exif) : {};

		return (
			<>
				{/* Icons layer */}
				<div
					style={{ position: 'absolute', width: '100%' }}
					ref={this.setPhotoBg}
				>
					{/* Photo background (testing second version of non-scrolling image) */}
					{/* <div
						className={
							classes.photoBg + ' ' +
							(this.state.fullscreen ? classes.fullscreen : classes.sticky) +
							(this.props.mobile ? ' ' + classes.photoBgMobile : '')
						}
						style={{ width: '100%', zIndex: 100 }}
					>
						<div
							className={classes.imgPlace}
							ref={el => this.elBackPhoto = el}
						>
							<div
								className={classes.bgImg}
								style={{
									backgroundImage: `url(${this.getImgUrl(image.id)})`,
									maxHeight: this.state.imgHeight || 'auto',
								}}
							/>
						</div>
					</div> */}

					{/* Close button */}
					<div
						className={btnClass}
						style={{ left: 0, marginLeft: 5, zIndex: 2200 }}
						ref={el => this.elCloseBtn = el}
					>
						<IconButton
							aria-label='Close image'
							className={classes.icnButton}
							onClick={this.closeLightbox}
						>
							<CloseIcon />
						</IconButton>
					</div>


					{/* Fullscreen button */}
					<div
						className={btnClass}
						style={{ right: 0, marginRight: 5, zIndex: 2200 }}
						ref={el => this.elFullscreenBtn = el}
					>
						<IconButton
							aria-label='Fullscreen'
							className={classes.icnButton}
							onClick={this.onFullscreen}
						>
							{this.state.fullscreen ?
								<NoFullscreenIcon /> :
								<FullscreenIcon />
							}
						</IconButton>
					</div>
				</div>

				{/* Main scroller */}
				<div
					className={classes.rootScroll}
					ref={this.setScroller}
				>
					{/* Photo background */}
					<div
						className={
							classes.photoBg + ' ' +
							(this.state.fullscreen ? 'fullscreen' : classes.sticky)
						}
						style={{ width: '100%', zIndex: 2100 }}
						ref={el => this.elPhotoBg = el}
						onClick={this.onFullscreen}
						onMouseMove={this.onMouseMove}
						onMouseLeave={this.onMouseLeave}
					>
						{/* Hide overflown image */}
						<div className='photoBg2'>
							<div
								className={classes.imgPlace}
								ref={el => this.elPhoto = el}
							>
								{/* Photograph */}
								<div
									className={classes.bgImg}
									style={{
										backgroundImage: this.state.imgHeight ? `url(${this.getImgUrl(image.id)})` : '',
										maxHeight: this.state.imgHeight,
									}}
								/>
							</div>
						</div>

						{/* Smooth hiding of scrolling description */}
						<div style={{ position: 'absolute', bottom: -27, height: 27, left: 0, right: 0, background: 'linear-gradient(rgba(242, 242, 242, 0.85), rgba(242, 242, 242, 0.0))', zIndex: 4000 }} />
					</div>

					{/* Description (below the photograph) */}
					{user && !this.state.fullscreen &&
						<Paper className={classes.detailsContainer}>
							<div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: 1100, padding: 10, paddingTop: 30, overflow: 'hidden' }}>
								<div style={{ float: 'right', marginTop: -6 }}>
									<div>
										{/* Gallery */}
										{!edit &&
											<AddToCollection
												photo={image}
											// fullButton
											/>
										}

										{/* Share */}
										{!edit &&
											<ShareButton
												image={image}
											// fullButton
											// style={{ marginLeft: 10 }}
											/>
										}

										{edit &&
											<Button
												variant='outlined'
												color='primary'
												aria-label='Cancel'
												onClick={this.onCancel}
												style={{ marginLeft: 10 }}
											>
												<CloseIcon style={{ marginRight: 8 }} />
												{'Cancel'}
											</Button>
										}

										{/* Edit */}
										{!edit && canEdit &&
											<Tooltip title='Edit' enterDelay={300}>
												<IconButton
													color='primary'
													onClick={this.onEdit}
												>
													<EditIcon />
												</IconButton>
											</Tooltip>
										}

										{edit &&
											<Button
												variant='contained'
												color='secondary'
												aria-label='Save'
												onClick={this.onSave}
												style={{ marginLeft: 10 }}
											>
												<CheckIcon style={{ marginRight: 8 }} />
												{'Save'}
											</Button>
										}

										{edit &&
											<Button
												variant='outlined'
												color='primary'
												aria-label='Delete'
												onClick={this.onDelete}
												style={{ marginLeft: 10 }}
											>
												<TrashIcon style={{ marginRight: 8 }} />
												{'Delete'}
											</Button>
										}
									</div>

									{/* Rating */}
									{!edit &&
										<div style={{ whiteSpace: 'nowrap', textAlign: 'right', marginTop: 10, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
											<IconButton
												color='primary'
												aria-label='Thumb up'
												className={classes.LBRating}
												// style={{ marginLeft: 20 }}
												onClick={this.handleLBRatingUp}>
												<ThumbUpIcon />
											</IconButton>

											<Typography variant='h6' style={{ display: 'inline', fontSize: 28, opacity: 0.8, minWidth: 46, textAlign: 'center' }}>
												{image.rating}
											</Typography>

											<IconButton
												color='primary'
												aria-label='Thumb down'
												className={classes.LBRating}
												onClick={this.handleLBRatingDown}>
												<ThumbDownIcon />
											</IconButton>
										</div>
									}
								</div>

								{/* Title */}
								<div>
									{!edit &&
										<h1 style={{ display: 'inline', paddingRight: 2 }}>
											{this.getTitle()}
										</h1>
									}

									{!edit &&
										<Typography variant='h6' style={{ display: 'inline', whiteSpace: 'nowrap', fontWeight: 400 }}>
											{' by '}
											{image.authorUrl ?
												<a href={image.authorUrl} target={getBlankTarget()} rel='noopener noreferrer'>
													{image.author}
												</a> :
												<a href={Navigation.getUserURL(user.username)} onClick={this.onUserClick}>
													{user.displayName}
												</a>
											}
										</Typography>
									}

									{!edit && image.imgType === 1 &&
										<span style={{ paddingLeft: 30, whiteSpace: 'nowrap', cursor: 'help', opacity: 0.6 }} onClick={this.onTestShotHelp}>
											{'TEST SHOT'}
										</span>
									}

									{edit &&
										<div style={{ maxWidth: 450 }}>
											<TextField
												fullWidth={true}
												value={this.state.editTitle}
												onChange={(e) => this.setState({ editTitle: e.currentTarget.value })}
												margin='normal'
												placeholder='Title'
												InputProps={{
													classes: {
														root: classes.inputTitle
													}
												}}
												classes={{
													root: classes.fieldTitle
												}}
											/>
										</div>
									}
								</div>

								{/* Location */}
								{image.locTitle &&
									<div style={{ marginTop: 20, marginBottom: 10, overflow: 'hidden', textOverflow: 'ellipsis' }}>
										<a className={classes.locationLink} href={this.getLocationURL()} onClick={this.onLocationClick}>
											<LocationIcon color='action' fontSize='small' className={classes.locationHover} style={{ marginRight: 5, marginBottom: -3 }} />
											<Typography variant='subtitle1' className={classes.locationHover}>
												{image.locTitle}
											</Typography>
										</a>
									</div>
								}

								{edit &&
									<div style={{ marginTop: 20, marginBottom: -16 }}>
										<FormControlLabel
											checked={this.state.editTestShot}
											control={<Switch color='primary' />}
											label='Test shot'
											style={{ marginLeft: 25, marginRight: 5 }}
											labelPlacement='start'
											onChange={this.changeTestShot}
										/>
										<HelpIcon id='testshot' />
									</div>
								}

								<Exif
									exif={exif}
									image={image}
									edit={edit}
									onChange={this.onExifChange}
									style={{ marginBottom: 30, marginTop: '1em' }}
								/>

								<hr style={{ marginBottom: 30 }} />

								{/* Description */}
								{edit ?
									<Editor
										initialValue={this.currentMarkdown}
										onChange={(md) => this.currentMarkdown = md}
										style={{ width: '100%' }}
									/>
									:
									<div className='defText guide' style={{ marginLeft: 0 }}>
										{MD2React(image.description)}
									</div>
								}

								{/* Same empty space for better readability */}
								<div style={{ marginTop: 60 }} />
							</div>
						</Paper>
					}
				</div>
			</>
		);
	}
}

PhotoPage.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func,
	mobile: PropTypes.bool,
	user: PropTypes.object,

	photoData: PropTypes.object,
};

/** @type {any} */
// @ts-ignore (styles)
export default withStyles(styles)(PhotoPage);
