//@ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LoggedIcon from '@material-ui/icons/Person';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';

import Server from '../server';
import Navigation from '../navigation';

const styles = {
};

class LoginIcon extends React.Component {
	state = {
		auth: true,
		anchorEl: null,
		notImpDialog: false,
		userLogged: false,
		/** @type{any} */
		avatarUrl: null,
	};
	terminating = false;

	componentDidMount() {
		this.updateData();
	}

	componentWillUnmount() {
		this.terminating = true;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.user !== this.props.user)
			this.updateData();
	}

	updateData() {
		const { user } = this.props;

		this.setState({
			userLogged: Boolean(user),
		});

		Server.getAvatarURL(user).then(url => {
			if (this.terminating)
				return;

			this.setState({ avatarUrl: url });
		}).catch(() => {
			if (this.terminating)
				return;

			this.setState({ avatarUrl: null });
		});
	}

	handleMenu = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleMenuClose = () => {
		this.setState({ anchorEl: null });
	};

	handleLogin = () => {
		this.handleMenuClose();
		Navigation.login();
	};

	handleLogout = () => {
		this.handleMenuClose();
		Navigation.logout();
	};

	showProfile = () => {
		this.handleMenuClose();
		if (this.props.user)
			Navigation.showProfile(this.props.user.username);
	}

	showSpotlists = () => {
		this.handleMenuClose();
		if (this.props.user)
			Navigation.showProfile(this.props.user.username, 'galleries');
	}

	newArticle = () => {
		Navigation.goPath('/guide/?t=' + Navigation.getCurrentTag(true /* for UI */));
		this.handleMenuClose();
	}

	renderIcon() {
		if (this.state.userLogged) {
			return (
				<Avatar src={this.state.avatarUrl}>
					{/* Temporarily show an icon, until avatar is prepared/known */}
					{!this.state.avatarUrl ? <LoggedIcon /> : null}
				</Avatar>
			);
		} else {
			return (<AccountCircle />);
		}
	}

	render() {
		const { auth, anchorEl, userLogged } = this.state;
		const open = Boolean(anchorEl);

		return (
			auth && (
				<>
					<IconButton
						aria-owns={ /** @type{any}*/ (open ? 'menu-appbar' : null)}
						aria-haspopup='true'
						aria-label='User'
						onClick={this.handleMenu}
						style={{ padding: 0, ...this.props.style }}
					>
						{this.renderIcon()}
					</IconButton>

					<Menu
						id='menu-appbar'
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={open}
						onClose={this.handleMenuClose}
					>
						{userLogged && <MenuItem onClick={this.showProfile}>{'My Profile'}</MenuItem>}
						{userLogged && <MenuItem onClick={this.showSpotlists}>{'My Galleries'}</MenuItem>}
						{userLogged && <Divider />}
						{userLogged && <MenuItem onClick={this.newArticle}>{'New Article'}</MenuItem>}
						{userLogged && <Divider />}
						{userLogged && <MenuItem onClick={this.handleLogout}>{'Logout'}</MenuItem>}

						{!userLogged && <MenuItem onClick={this.handleLogin}>{'Login / Sign up'}</MenuItem>}
					</Menu >
				</>
			)
		);
	}
}

LoginIcon.propTypes = {
	classes: PropTypes.object.isRequired,
	style: PropTypes.object,
	user: PropTypes.object,
};

/** @type {any} */
export default withStyles(styles)(LoginIcon);
