//@ts-check
import React from 'react';

import Fab from '@material-ui/core/Fab';
import MoreIcon from '@material-ui/icons/MoreVert';

import Server from '../../server';

import { openPhotoPopup } from './PhotoPopup';
import { getBlankTarget } from '../../tools';
import Navigation from '../../navigation';

export default function Photo(props) {
	const { node, view, getPos, img, setImg, onEndEdit } = props;

	const url = Server.getThumbForID(img.id) || '';
	var size = (img.height || 1) - 1;
	if (size < 0) {
		size = -1 / (size + 1) + 1;
	}

	const handleChange = (newObj) => {
		setImg(newObj);
	}

	const handleClose = () => {
		onEndEdit();
	}

	const openMenu = (e) => {
		openPhotoPopup(e.currentTarget, { node, view, getPos }, handleChange, handleClose);
	}

	const style = { position: 'relative' };
	if (img.height)
		style.width = Math.round(img.height * 400);
	const alignCls = img.align === 'right' ? ' userImageRight' : (img.align === 'center' ? ' userImageCenter' : '');
	return (
		<span className={'userImage defText ' + alignCls}
			// @ts-ignore
			style={style}>
			{img.id ?
				<div style={{ textAlign: 'center', overflow: 'hidden' }}>
					<a href={`/photo/${img.id}`} data-photo={img.id}>
						<img src={url} alt='Photograph' />
					</a>
				</div>
				:
				<div style={{ width: '100%', height: 250, background: '#ccc' }} />
			}

			{(img.comment) &&
				<div className='uimgcomment'>
					{img.comment}
					{img.username && img.author &&
						<>
							{img.comment && !img.comment.endsWith(' ') && ' © '}
							{img.username.startsWith('http') ?
								<a href={img.username} target={getBlankTarget()} rel='noopener noreferrer'>
									{img.author}
								</a>
								:
								<a href={`/by/${img.username}`} onClick={() => { Navigation.showProfile(img.username) }}>
									{img.author}
								</a>
							}
						</>
					}
				</div>
			}

			{node &&
				<Fab color='primary' style={{ position: 'absolute', top: 15, right: 15 }} size='small' onClick={openMenu}>
					<MoreIcon />
				</Fab>
			}
		</span>
	);
}
