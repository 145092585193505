//@ts-check
/* eslint-disable no-console */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import AppHeader from './AppHeader';
import Images from '../fragments/Images';
import Editor from '../fragments/Editor';
import { MD2React, checkKeyMods, setPageTitle } from '../tools';
import MapView from '../MapView';

import Server from '../server';
import Navigation from '../navigation';

const style = theme => ({
	userAvatar: {
		margin: theme.spacing(3),
		width: theme.spacing(9),
		height: theme.spacing(9),
	},

	headerText: {
		marginLeft: theme.spacing(2),
		flexGrow: 10,
	},
	fame: {
		marginTop: theme.spacing(1.3),
	},

	divider: {
		marginTop: theme.spacing(2),
	},
	about: {
		marginTop: theme.spacing(1),
	},

	h4: theme.typography.h4,
	title: theme.typography.title,
	hyperlinkEdit: {
		opacity: 0.6,
		cursor: 'pointer',
		marginLeft: 10,
	},

	sectionTitle: {
		marginBottom: theme.spacing(2),
	},

	spotlists: {
		textAlign: 'center',
		padding: '1em',
		[theme.breakpoints.down('sm')]: {
			margin: 0,
			padding: 0,
		}
	},
	spotlist: {
		display: 'inline-block',
		margin: '2em',
		width: 300,
		[theme.breakpoints.down('md')]: {
			width: 250,
			margin: '1.6em',
		},
		[theme.breakpoints.down('sm')]: {
			width: '28vw',
			margin: '2vw',
		},
		[theme.breakpoints.down('xs')]: {
			width: '43vw',
			margin: '2vw',
		}
	},
	spotlistImg: {
		height: 200,
		[theme.breakpoints.down('md')]: {
			height: 165,
		},
		[theme.breakpoints.down('sm')]: {
			height: '19vw',
		},
		[theme.breakpoints.down('xs')]: {
			height: '27vw',
		}
	},
	spotlistTitle: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		fontSize: 18,
	}
});

class UserPage extends Component {
	currentMarkdown = null;
	state = {
		/** @type{any} */
		user: null,

		editName: false,
		editUserName: false,
		editAbout: false,

		displayName: '',
		username: '',

		photos: null,	// All photos submitted by this user
		/** @type{any} */
		userPhotos: null, // Only own photos (no links) of this user
		/** @type{any} */
		testPhotos: null, // Test Shots

		scroller: null,
		/** @type{any} */
		spotlists: null,
	}

	setupMap(userPhotos) {
		if (this.props.tab === 'map') {
			const loc = userPhotos.map(i => ({
				id: i.idLoc,
				lat: i.lat,
				long: i.long,
			}));
			Navigation.setFilter('fu', this.props.username, loc);
		}
	}

	/** @type{any} */
	_loadingPromise = null;

	loadTopPhotos() {
		const tab = this.props.tab;

		if ((tab === 'photos' || tab === 'testphotos' || tab === 'map' || !tab) && !this.state.photos) {
			if (this._loadingPromise)
				return;

			var promise;
			if (this.props.idUser)
				promise = Server.getUserStats(this.props.idUser);
			else
				promise = Server.getUsernameStats(this.props.username);
			this._loadingPromise = promise;

			promise.then(stats => {
				this._loadingPromise = null;
				const imgs = stats.topImages.map(i => ({
					...i,
					location: {
						id: i.idLoc,
						lat: i.lat,
						long: i.long,
					}
				}));
				const userPhotos = imgs.filter(i => !i.linkURL);
				const testPhotos = userPhotos.filter(i => i.imgType === 1);
				this.setState({
					photos: imgs,
					userPhotos: userPhotos.filter(i => i.imgType !== 1),
					testPhotos,
				});

				this.setupMap(userPhotos);
			}).catch(e => {
				console.log(e);
				this._loadingPromise = null;
			});
		} else {
			this.setupMap(this.state.userPhotos);
		}
	}

	spotlistLoading = false;

	async loadSpotlists() {
		if (this.props.tab === 'galleries' && this.state.user && !this.state.spotlistImages && !this.spotlistLoading) {
			this.spotlistLoading = true;
			const spotlists = await Server.getUserSpotlists(this.state.user);

			this.setState({
				spotlists: spotlists
			});
		}
	}

	setTitle() {
		if (this.state.user)
			setPageTitle(`${this.state.user.displayName}`);
	}

	getData() {
		var promise;
		if (this.props.idUser)
			promise = Server.getUser(this.props.idUser);
		else
			promise = Server.getUserByName(this.props.username);

		promise.then(user => {
			this.setState({
				user: user,
				displayName: user.displayName,
				username: user.username,
			});
			return Server.getAvatarURL(user);
		}).then(url => {
			this.setState({ avatarUrl: url });
		}).catch(() => {
			this.setState({ avatarUrl: null });
		});
	}

	componentDidMount() {
		this.getData();

		this.loadTopPhotos();
		this.loadSpotlists();
		this.setTitle();
	}

	componentDidUpdate(prevProps) {
		if (this.props.username !== prevProps.username) {
			this.getData();
			this.setState({
				spotlistImages: null,
				photos: null,
				userPhotos: null,
			}, () => {
				this.loadTopPhotos();
				this.loadSpotlists();
				this.setTitle();
			});
		} else {
			this.loadTopPhotos();
			this.loadSpotlists();
			this.setTitle();
		}
	}

	handleTabChange = (event, value) => { // eslint-disable-line no-unused-vars
		const tab = event.currentTarget.dataset.id;
		Navigation.showProfile(this.state.user.username, tab);
		this.loadSpotlists();
	}

	handleChangeName = (event) => {
		const newValue = event.target.value;
		this.setState({ displayName: newValue });
		if (newValue !== this.state.user.displayName)
			this.setState({ editName: true });
	}

	handleSaveName = () => {
		this.setState({
			user: Object.assign({}, this.state.user, { displayName: this.state.displayName }),
			editName: false,
		}, () => {
			Server.saveProfile(this.state.user);
		});
	}

	handleCancelName = () => {
		this.setState({
			editName: false,
			displayName: this.state.user.displayName,
		});
	}

	onChangeUserName = (event) => {
		const newValue = event.target.value;
		this.setState({ username: newValue });
		if (newValue !== this.state.user.username)
			this.setState({ editUserName: true });
	}

	handleSaveUserName = () => {
		this.setState({
			user: Object.assign({}, this.state.user, { username: this.state.username }),
			editUserName: false,
		}, () => {
			Server.saveProfile(this.state.user).then(res => {
				if (res.username) { // Username was updated (and possibly modified by server), reflect it here
					this.setState({
						username: res.username,
						user: Object.assign({}, this.state.user, { username: res.username }),
					});
					Navigation.changeUsername(res.username);
				}
			});
		});
	}

	handleCancelUserName = () => {
		this.setState({
			editUserName: false,
			username: this.state.user.username || '',
		});
	}

	editAbout = () => {
		this.currentMarkdown = this.state.user.about;
		this.setState({ editAbout: true });
	}

	handleSaveAbout = () => {
		this.setState({
			user: Object.assign({}, this.state.user, { about: this.currentMarkdown }),
			editAbout: false,
		}, () => {
			Server.saveProfile(this.state.user);
		});
	}

	handleCancelAbout = () => {
		this.setState({ editAbout: false });
	}

	handleAboutChange = (newMarkdown) => {
		this.currentMarkdown = newMarkdown;
	}

	handleEmailNews = (event, checked) => { // eslint-disable-line no-unused-vars
		this.setState({
			user: { ...this.state.user, emailNews: checked }
		}, () => {
			Server.saveProfile(this.state.user);
		});
	}

	onSpotlistClick = (event) => {
		if (checkKeyMods(event))
			Navigation.goSpotlist(this.props.username, event.currentTarget.dataset.id);
	}

	isUsernameOK = () => {
		const username = this.state.username;
		if (!username)
			return true;

		if (username.search(/[^a-zA-Z0-9\-_]/) >= 0)
			return false;
		else
			return true;
	}

	getUserError = () => {
		if (!this.isUsernameOK())
			return 'Please enter only alphanumeric characters, dash or underscore';
		else
			return null;
	}

	renderSpotlist(spotlist) {
		const { classes } = this.props;

		return (
			<a href={`/by/${this.props.username}/gallery/${spotlist.urlTitle}`} key={spotlist.id} data-id={spotlist.urlTitle} onClick={this.onSpotlistClick}>
				<Card className={classes.spotlist}>
					<CardActionArea>
						<CardMedia
							className={classes.spotlistImg}
							image={Server.getThumbForID(spotlist.image)}
							title={spotlist.title}
						/>
						<CardContent style={{ padding: 9 }}>
							<Typography variant="h6" component="h6" className={classes.spotlistTitle}>
								{spotlist.title}
							</Typography>
						</CardContent>
					</CardActionArea>
				</Card>
			</a>
		);
	}

	renderSpotlists() {
		const { spotlists } = this.state;
		const { classes } = this.props;

		return (
			<div className={classes.spotlists}>
				{spotlists && spotlists.map(spotlist => this.renderSpotlist(spotlist))}
			</div>
		);
	}

	render() {
		const { classes } = this.props;
		const { user, userPhotos, testPhotos } = this.state;
		const own = this.props.user && this.props.user.username === this.props.username;
		if (!own && this.props.tab === 'settings')
			Navigation.showProfile(this.props.username); // Get away from Settings

		if (!user)
			return null;

		return (
			<div style={{ width: '100%', overflowY: 'auto' }} ref={el => !this.state.scroller && this.setState({ scroller: el })}>
				<AppHeader user={this.props.user} />

				{/* Header */}
				<Grid container direction='row' wrap='nowrap' className={classes.popoverRoot} style={{ marginTop: 15 }}>
					<Avatar
						className={classes.userAvatar}
						src={this.state.avatarUrl ? this.state.avatarUrl : null}
					>
						{this.state.avatarUrl ? null : user.displayName}
					</Avatar>

					<div className={classes.headerText}>
						<div style={{ marginBottom: 12 }}>
							{/* Name */}
							<span className={classes.h4}>{user.displayName}</span>

							{/* Fame */}
							<Typography className={classes.fame} style={{ display: 'inline', marginLeft: 20 }}><b>Impact:</b> {user.impact}</Typography>
							{/* <Typography className={classes.badges}><b>Badges:</b> {this.formatBadges(user.badges)}</Typography> */}
						</div>

						{/* About */}
						<div className='defText compact' style={{ marginLeft: 0 }}>
							{user.about ?
								MD2React(user.about) :
								<p>{own ? 'Info about you will be shown here, once you enter it.' : ''}</p>
							}
						</div>
					</div>
				</Grid>

				<Divider className={classes.divider} />

				{/* Tabs */}
				<Tabs
					value={this.props.tab || 'photos'}
					onChange={this.handleTabChange}
					style={{ marginBottom: 5 }}
					centered={!this.props.mobile}
					variant={this.props.mobile ? 'scrollable' : 'standard'}
				>
					<Tab label={'Photos' + (this.state.photos ? ` (${userPhotos.length})` : '')} value={'photos'} data-id={'photos'} />
					{testPhotos && testPhotos.length &&
						<Tab label={`Test Shots (${testPhotos.length})`} value={'testphotos'} data-id={'testphotos'} />
					}
					<Tab label={'Map'} value={'map'} data-id={'map'} />
					<Tab label={'Galleries'} value={'galleries'} data-id={'galleries'} />
					{own && <Tab label='Settings' value={'settings'} data-id={'settings'} />}
				</Tabs>

				{/* Photos */}
				{(this.props.tab === 'photos' || !this.props.tab) && user &&
					<Images
						images={this.state.userPhotos}
						height={300}
						margins={5}
					/>
				}

				{this.props.tab === 'testphotos' &&
					<Images
						images={this.state.testPhotos}
						height={260}
						margins={5}
					/>
				}

				{/* Map */}
				{this.props.tab === 'map' && user &&
					<div style={{ height: '50vh', width: '100%', maxWidth: 1366, marginLeft: 'auto', marginRight: 'auto' }}>
						<MapView
							largeMap={false}
							mobile={this.props.mobile}
						/>
					</div>
				}

				{/* Spotlists */}
				{this.props.tab === 'galleries' && user &&
					this.renderSpotlists()
				}

				{/* Settings */}
				{this.props.tab === 'settings' && user &&
					<div style={{ maxWidth: 730, marginLeft: 'auto', marginRight: 'auto', padding: 10 }}>
						<form className={classes.container} noValidate>
							{/* Display Name */}
							<div style={{ display: 'flex', alignItems: 'baseline' }}>
								<TextField
									id='name'
									label='Full Name'
									value={this.state.displayName}
									onChange={this.handleChangeName}
									style={{ width: 300 }}
									margin='normal'
								/>
								<div style={{ flexGrow: 10 }}></div>
								{this.state.editName &&
									<Button onClick={this.handleCancelName}>
										{'Cancel'}
									</Button>
								}
								{this.state.editName &&
									<Button variant='contained' color='secondary' onClick={this.handleSaveName}>
										{'Save'}
									</Button>
								}
							</div>

							{/* User Name */}
							<div style={{ display: 'flex', alignItems: 'baseline' }}>
								<TextField
									label='Username'
									value={this.state.username}
									onChange={this.onChangeUserName}
									style={{ width: 300 }}
									margin='normal'
									error={Boolean(this.getUserError())}
									helperText={this.getUserError()}
								/>
								<div style={{ flexGrow: 10 }}></div>
								{this.state.editUserName &&
									<Button onClick={this.handleCancelUserName}>
										{'Cancel'}
									</Button>
								}
								{this.state.editUserName &&
									<Button variant='contained' color='secondary' onClick={this.handleSaveUserName} disabled={!this.isUsernameOK()}>
										{'Save'}
									</Button>
								}
							</div>

							{/* About */}
							<div style={{ marginTop: 30, display: 'flex', alignItems: 'center' }}>
								<span className={classes.title}>{'About'}</span>

								{!this.state.editAbout && own &&
									<span className={classes.hyperlinkEdit} onClick={this.editAbout}>{'edit'}</span>
								}

								{this.state.editAbout &&
									<div style={{ flexGrow: 10 }} />
								}

								{this.state.editAbout &&
									<Button onClick={this.handleCancelAbout} style={{ marginLeft: 20 }}>
										{'Cancel'}
									</Button>
								}
								{this.state.editAbout &&
									<Button variant='contained' color='secondary' onClick={this.handleSaveAbout}>
										{'Save'}
									</Button>
								}
							</div>

							<div className={classes.about} style={{ marginBottom: 30 }} >
								{!this.state.editAbout &&
									<Typography variant='body2'>
										{(user.about ?
											MD2React(user.about) :
											<p>{own ? 'Describe yourself here...' : ''}</p>
										)}
									</Typography>
								}

								{this.state.editAbout &&
									<Editor
										initialValue={this.currentMarkdown}
										onChange={this.handleAboutChange}
									/>
								}
							</div>

							<Divider />

							<Grid container direction='row' wrap='nowrap' style={{ width: '100%', marginTop: 10 }}>
								<div style={{ flexGrow: 10 }}>
									<Typography variant='body1'>{'E-mail Announcements'}</Typography>
									<Typography variant='body2'>{'Infrequent e-mails about news on this site.'}</Typography>
								</div>
								<Switch
									checked={Boolean(user.emailNews)}
									onChange={this.handleEmailNews}
									color='primary'
								/>
							</Grid>
						</form>
					</div>
				}
			</div>
		);
	}
}

UserPage.propTypes = {
	classes: PropTypes.object.isRequired,
	idUser: PropTypes.number,
	username: PropTypes.string,
	tab: PropTypes.string,
	mobile: PropTypes.bool,

	user: PropTypes.object, // Logged in user
};

/** @type {any} */
// @ts-ignore
export default withStyles(style)(UserPage);