// @ts-check
import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import './App.css';

import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import DialogLogin from './dialogs/Login';
import DialogHelp from './dialogs/Help';
import DialogUnsubscribe from './dialogs/Unsubscribe';

import SignupPopup from './fragments/SignupPopup';
import PhotoPage from './fragments/PhotoPage';
import Location from './fragments/Location';
import UserPage from './fragments/UserPage';
import TagPage from './fragments/TagPage';
import SearchPage from './fragments/SearchPage';
import SpotlistPage from './fragments/SpotlistPage';
import HomePage from './fragments/HomePage';
import AboutPage from './fragments/AboutPage';
import MainDrawer from './fragments/MainDrawer';

import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import PubSub from 'pubsub-js';
import Navigation from './navigation.js';
import { withRouter } from 'react-router';

import { listenDrawerToggle } from './tools';
import GuidePage from './fragments/GuidePage';

var PhotoImport;
if (process.env.NODE_ENV !== 'production') {
	import('./fragments/PhotoImport').then(pi => PhotoImport = pi.default);
}

function isTagPage(path) {
	return path && (path.startsWith('/tag/') || path.startsWith('/photo_location/'));
}

const ScrollToTop = (props) => {
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		return () => {
			if (history.action === 'PUSH' &&
				!(isTagPage(history.location.pathname) && isTagPage(location.pathname))) { // Don't scroll in tag page changes, so that the minimap doesn't move unnecessarily
				window.scrollTo(0, 0);
			}
		}
	}, [location, history]);

	return <> {props.children} </>
}

const styles = /*theme =>*/ ({
});

// So that we fit the whole viewport on a mobile device, when Address bar is hidden
function adjustFullHeight() {
	// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
	let vh = window.innerHeight * 0.01;
	// Then we set the value in the --vh custom property to the root of the document
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}

if (!process.env.REACT_APP_SERVER_SIDE) {
	window.addEventListener('resize', () => {
		adjustFullHeight();
	});

	adjustFullHeight();
}

class App extends Component {
	state = {
		drawerOpen: false,

		sidebarLarge: false,
		snackbarOpen: false,
		snackbarAutoHide: 10000,
		snackbarMessage: '',
		popUps: {},
		photoData: null,
	}

	constructor(props) {
		super(props);
		this.state.photoData = this.props.photoData;
		listenDrawerToggle(this.toggleDrawer);
	}

	componentDidUpdate(prevProps) {
		if (this.props.photoData !== prevProps.photoData && this.props.photoData) {
			// We keep photoData stored in the state, so that it's available during lightbox closing animation
			this.setState({ photoData: this.props.photoData });
		}
	}

	async componentDidMount() {
		PubSub.subscribe('SHOW_SNACKBAR', this.showSnackbar.bind(this));

		PubSub.subscribe('SHOW_POPUP', this.showPopup);
		PubSub.subscribe('CLOSE_POPUP', this.closePopup);

		PubSub.subscribe('SHOW_THANK_VOTE', () => {
			PubSub.publish('SHOW_SNACKBAR', {
				message: 'Thank you for your vote!',
				autoHide: 3000,
			});
		});

		if (!process.env.REACT_APP_SERVER_SIDE) {
			window.document.body.addEventListener('keydown', this.handleKeyDown);

			window.addEventListener('scroll', this.onScroll, { passive: false });
		}
	}

	onScroll = () => {
		PubSub.publishSync('MAIN_SCROLL', {
			scrollTop: window.scrollY,
			scrollHeight: window.document.scrollingElement && window.document.scrollingElement.scrollHeight,
			scrollerHeight: window.innerHeight,
		});
	}

	showPopup = (msg, data) => {
		const newObj = {};
		newObj[data.popUpID] = data.popUp;

		this.setState({
			popUps: Object.assign(newObj, this.state.popUps)
		});
	}

	closePopup = (msg, data) => {
		const newObj = Object.assign({}, this.state.popUps);
		delete newObj[data.popUpID];
		this.setState({ popUps: newObj });
	}

	showSnackbar(msg, data) { // eslint-disable-line no-unused-vars
		this.setState({
			snackbarOpen: true,
			snackbarAutoHide: data.autoHide || 10000,
			snackbarMessage: data.message,
			snackbarAction: data.action,
		});
	}

	handleSnackbarClose() {
		this.setState({ snackbarOpen: false });
	}

	handleExpandSidebar = () => {
		Navigation.showMap(false);
	}

	handleCollapseSidebar = () => {
		Navigation.showMap(true);
	}

	getSidebarClass = (landscape) => {
		const { classes } = this.props;
		if (this.props.point)
			return (landscape ?
				(!this.props.largeMap ? classes.rightSidebarLarge : classes.rightSidebarSmall) :
				(!this.props.largeMap ? classes.bottomSidebarLarge : classes.bottomSidebarSmall));
		else
			return (landscape ? classes.rightSidebarHidden : classes.bottomSidebarHidden);
	}

	handleDialogClose = () => {
		Navigation.closeDialogs();
		Navigation.go();
	}

	handleAddLocationClose = () => {
		Navigation.closeDialogs();
		Navigation.go();
	}

	handleKeyDown = (event) => {
		if (event.key === 'Escape' && !Navigation.isLightbox()) {
			if (Navigation.getSearch()) { // Will handle also search bar content
				Navigation.search('');
			}
		}
	}

	handleSnackbarAction = () => {
		this.setState({ snackbarOpen: false });
		this.state.snackbarAction && this.state.snackbarAction.fn && this.state.snackbarAction.fn();
	}

	renderSnackbarAction = (action) => {
		if (!action)
			return null;

		return (
			<Button color='secondary' size='small' onClick={this.handleSnackbarAction}>
				{action.title}
			</Button>
		);
	}

	lightboxClose = () => {
		const { photoData } = this.props;
		// if ((!point || !point.id) && photoData && photoData.idLoc)
		if (this.props.location.pathname.startsWith('/photo/') && photoData && photoData.idLoc)
			Navigation.goSpot({ id: photoData.idLoc, title: photoData.spotTitle }); // If this is a Photopage without another context, just go to its location
		else
			Navigation.closePhoto();
	}

	toggleDrawer = (e) => {
		this.setState({ drawerOpen: !this.state.drawerOpen });
		if (e) {
			e.stopPropagation();
			e.preventDefault();
		}
	}

	renderMainContent() {

		if (Navigation.isHome()) {
			return (
				<HomePage
					user={this.props.user}
					mobile={this.props.mobile}
				/>
			);
		}

		if (this.props.searchString)
			return (<SearchPage
				searchString={this.props.searchString}
				searchResults={this.props.searchResults}
				user={this.props.user}
				mobile={this.props.mobile}
			/>);

		return (
			<>
				<Switch>
					<Route path='/about/:tab?' render={({ match }) => (
						<AboutPage
							page='about'
							tab={match.params.tab}
							user={this.props.user}
							mobile={this.props.mobile}
						/>
					)} />
					<Route path='/explore/:tab?' render={({ match }) => (
						<AboutPage
							page='explore'
							tab={match.params.tab}
							user={this.props.user}
							mobile={this.props.mobile}
						/>
					)} />
					<Route path='/by/:username/gallery/:spotlist/:tab?' render={({ match }) => (
						<SpotlistPage
							username={match.params.username}
							spotlist={match.params.spotlist}
							tab={match.params.tab}
							user={this.props.user}
							mobile={this.props.mobile}
						/>
					)} />
					<Route path='/by/:username/spotlist/:spotlist/:tab?' render={({ match }) => (  /* TODO: Obsolete, remove sometimes*/
						<SpotlistPage
							username={match.params.username}
							spotlist={match.params.spotlist}
							tab={match.params.tab}
							user={this.props.user}
							mobile={this.props.mobile}
						/>
					)} />
					<Route path='/user/:idUser/:tab?' render={({ match }) => (
						<UserPage
							idUser={Number(match.params.idUser)}
							tab={match.params.tab}
							user={this.props.user}
							mobile={this.props.mobile}
						/>
					)} />
					<Route path='/by/:username/:tab?' render={({ match }) => (
						<UserPage
							username={match.params.username}
							tab={match.params.tab}
							user={this.props.user}
							mobile={this.props.mobile}
						/>
					)} />
					<Route path='/tag/:tag' render={() => (
						<TagPage
							user={this.props.user}
							editGuideID={this.props.editGuideID}
							tagData={this.props.tagData}
							mobile={this.props.mobile}
						/>
					)} />
					<Route path='/guide/:guide' render={() => (
						<GuidePage
							user={this.props.user}
							guideData={this.props.guideData}
							mobile={this.props.mobile}
						/>
					)} />
					<Route path='/guide' render={() => (
						<GuidePage
							user={this.props.user}
							guideData={this.props.guideData}
							mobile={this.props.mobile}
						/>
					)} />
					<Route path='/photo_location/:tag1/:tag2?/:tag3?/:tag4?' render={() => (
						<TagPage
							user={this.props.user}
							editGuideID={this.props.editGuideID}
							tagData={this.props.tagData}
							mobile={this.props.mobile}
						/>
					)} />
					<Route path='/map'>
						<Location
							point={null}
							largeMap={true}
							mobile={this.props.mobile}
							filter={this.props.filter}
							user={this.props.user}
							showGPS={this.props.showGPS}
						/>
					</Route>
					<Route path='/import'>
						{process.env.NODE_ENV !== 'production' && Boolean(PhotoImport) &&
							<PhotoImport
								point={this.props.point}
								search={this.props.search}
								editDescID={this.props.editDescID}
								fixPosition={this.props.fixPosition}
								fixPhoto={this.props.fixPhoto}
								largeMap={this.props.largeMap}
								mobile={this.props.mobile}
								filter={this.props.filter}
								user={this.props.user}
							/>
						}
					</Route>
					<Route path='/imports'>
						{process.env.NODE_ENV !== 'production' && Boolean(PhotoImport) &&
							<PhotoImport
								point={this.props.point}
								search={this.props.search}
								editDescID={this.props.editDescID}
								fixPosition={this.props.fixPosition}
								fixPhoto={this.props.fixPhoto}
								largeMap={this.props.largeMap}
								mobile={this.props.mobile}
								filter={this.props.filter}
								user={this.props.user}
								multi={true}
							/>
						}
					</Route>
					<Route path='/newPhoto'>
						<Location
							point={this.props.point}
							largeMap={this.props.largeMap}
							mobile={this.props.mobile}
							user={this.props.user}
							newPhoto={true}
						/>
					</Route>
					<Route path='/photo'>
						{/* Nothing here, just to catch Photo pages and don't unnecessarily render location data underneath */}
					</Route>
					<Route path='/'>
						<Location
							point={this.props.point}
							region={this.props.region}
							search={this.props.search}
							editDescID={this.props.editDescID}
							fixPosition={this.props.fixPosition}
							fixPhoto={this.props.fixPhoto}
							showGPS={this.props.showGPS}
							largeMap={this.props.largeMap}
							mobile={this.props.mobile}
							filter={this.props.filter}
							user={this.props.user}
						/>
					</Route>
				</Switch>
			</>
		);
	}

	onPhotoClosed = () => {
		this.setState({
			photoData: null
		});
	}

	render() {
		return (
			<>
				<Dialog
					fullScreen
					open={Boolean(this.props.photoData)}
					TransitionProps={{
						onExit: this.onPhotoClosed
					}}
				>
					<PhotoPage
						// imgChange={this.lightboxChange}
						onClose={this.lightboxClose}
						photoData={this.state.photoData}
						mobile={this.props.mobile}
						user={this.props.user}
					/>
				</Dialog>

				<DialogLogin />
				<DialogHelp />
				<DialogUnsubscribe />

				{/* Drawer */}
				<MainDrawer
					open={this.state.drawerOpen}
					onClose={this.toggleDrawer}
				/>

				<ScrollToTop />

				{this.renderMainContent()}

				<Snackbar
					open={this.state.snackbarOpen}
					autoHideDuration={this.state.snackbarAutoHide}
					onClose={this.handleSnackbarClose.bind(this)}
					message={this.state.snackbarMessage}
					action={this.renderSnackbarAction(this.state.snackbarAction)}
				/>

				<SignupPopup />

				{/* All Popups */}
				{Object.keys(this.state.popUps).map(popUpID =>
					this.state.popUps[popUpID]
				)}

				<Switch>
					<Route path='/' exact />
				</Switch>
			</>
		);
	}
}

App.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object,
	editDescID: PropTypes.number,
	search: PropTypes.string,

	point: PropTypes.object,
	region: PropTypes.object,
	fixPosition: PropTypes.bool,
	fixPhoto: PropTypes.string,
	showGPS: PropTypes.bool,

	searchResults: PropTypes.object,
	searchString: PropTypes.string,

	largeMap: PropTypes.bool,
	mobile: PropTypes.bool,
	filter: PropTypes.object,

	editGuideID: PropTypes.number,
	tagData: PropTypes.object,
	photoData: PropTypes.object,
	guideData: PropTypes.object,

	location: PropTypes.object.isRequired,
};

/** @type {any} */
// @ts-ignore
export default withStyles(styles)(withRouter(App));