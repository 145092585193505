//@ts-check

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import Typography from '@material-ui/core/Typography';
import Navigation from '../navigation';

const style = /*theme =>*/ ({
});

class DialogUnsubscribe extends Component {
	state = {
	}

	close = () => {
		Navigation.closeDialogs();
		this.forceUpdate();
	}

	render() {
		return (
			<Dialog
				open={Navigation.isUnsubscribeDialog()}
				onClose={this.close}
				maxWidth='sm'
			>
				<DialogContent>
					<Typography variant='h5' style={{ marginBottom: 20 }}>
						{'Done'}
					</Typography>

					<Typography variant='body1' style={{ marginBottom: 10 }}>
						{'You were successfully unsubscribed.'}
					</Typography>

					<Typography variant='body1' style={{ marginBottom: 10 }}>
						{'If this was by an error, you can subscribe again in your Profile page.'}
					</Typography>
				</DialogContent>

				<DialogActions>
					<Button onClick={this.close} color='secondary' variant='contained'>
						{'Close'}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

DialogUnsubscribe.propTypes = {
	classes: PropTypes.object.isRequired,
	fullScreen: PropTypes.bool.isRequired,
};

/** @type {any} */
// @ts-ignore
export default withMobileDialog({ breakpoint: 'sm' })(withStyles(style)(DialogUnsubscribe));