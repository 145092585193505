// @ts-check

// const tos = import()
import tos from './static/tos.js';
import privacy from './static/privacy.js';
import contact from './static/contact.js';
import popLoc from './fragments/PopularLocations.js';
import recent from './fragments/PhotosRecent.js';
import fav from './fragments/PhotosFav.js';
import most from './fragments/PhotosMost.js';
import nearby from './fragments/PhotosNearby.js';
import features from './static/features.js';

export const mainNav = [
	{ id: 'explore', title: 'Explore', defTab: 'locations', contentWidth: 1200, sideClass: 'veryWideOnly' },
	// { id: 'edit', title: 'Edit' },
	{ id: 'about', title: 'About', defTab: 'contacts', contentWidth: 900 },
];

export const subNav = {
	'explore': [
		{ id: 'locations', title: 'Popular Locations', render: popLoc },
		{ id: 'recent', title: 'Recent Uploads', render: recent },
		{ id: 'popular', title: 'Favorite Photos', render: fav },
		{ id: 'mostphoto', title: 'Most Photographed', render: most },
		{},
		{ id: 'nearby', title: 'Nearby Spots', render: nearby },
		{ id: 'map', title: 'World Map', url: '/map'},
	],
	'about': [
		// { id: 'phoide', title: 'About Phoide' },
		{ id: 'features', title: 'Features', render: features },
		{ id: 'contacts', title: 'Contacts', render: contact },
		{},
		{ id: 'tos', title: 'Terms of Service', render: tos },
		{ id: 'privacy', title: 'Privacy policy', render: privacy },
	]

};