import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Server from '../server';
import SpotsList from './SpotsList';
import TagItems from './TagItems';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MapView from '../MapView';
import Typography from '@material-ui/core/Typography';
import SpotsBar from './SpotsBar';

import CloseIcon from '@material-ui/icons/Close';
import ZoomIcon from '@material-ui/icons/ZoomIn';
import MapIcon from '@material-ui/icons/Map';
import IconExpand from '@material-ui/icons/ExpandLess';
import IconClose from '@material-ui/icons/Close';

import PubSub from 'pubsub-js';
import Navigation from '../navigation';
import { roundGPS, formatDistance } from '../tools';

const styles = /*theme => */({
	mapBase: {
		height: 220,
		width: 350,
		flexGrow: 0,
		flexBasis: 350,
		flexShrink: 0,
		marginLeft: 10,
		marginRight: -12,
	},
});

class Region extends Component {
	state = {
		spots: null,
		tags: null,
	};
	counter = 0;
	destroying = false;

	constructor(props) {
		super(props);
		this.fetchData(props);
	}

	componentWillUnmount() {
		this.destroying = true;
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.region !== this.props.region) {
			this.setState({ spots: null, tags: null });
			this.fetchData(nextProps);
			return true;
		}

		if (this.state !== nextState)
			return true;

		return false;
	}

	async fetchData(props) {
		const { region } = props;
		if (!region)
			return;

		const counter = ++this.counter;
		const spots = await Server.getRange(region.lat, region.long, region.dLat, region.dLong);
		// const spotsImgs = spots.reduce((acc, loc) => acc.concat([{ ...loc.images[0], location: loc }].filter(img => img.id)), []);
		if (counter === this.counter && !this.destroying) {
			// Get most used tags
			const tags = {};
			for (const spot of spots) {
				for (const tag of spot.tags) {
					tags[tag.tag] = (tags[tag.tag] || 0) + 1;
				}
			}

			const sortTags = [];
			for (const tag of Object.keys(tags))
				sortTags.push({ tag, cnt: tags[tag] });
			sortTags.sort((t1, t2) => t2.cnt - t1.cnt);

			let tIdx = 0;
			while (tIdx < sortTags.length && tIdx <= 5 &&
				(sortTags[tIdx].cnt > spots.length * 0.6 || sortTags[tIdx].cnt >= 10))
				tIdx++;
			sortTags.length = tIdx;

			this.setState({
				// spots: spotsImgs,
				spots,
				tags: sortTags,
			});
		}
	}

	onZoom = (e) => {
		e.preventDefault();
		e.stopPropagation();

		const r = this.props.region;
		if (r) {
			PubSub.publish('MAP_BOUNDING_BOX', { boundingBox: [r.lat - 1.15 * r.dLat, r.lat + 1.15 * r.dLat, r.long - 1.15 * r.dLong, r.long + 1.15 * r.dLong] });
			if (this.props.mobile)
				Navigation.showMap(true);
		}
	}

	onMap = () => {
		Navigation.showMap(true);
	}

	onClose = () => {
		Navigation.goRegion(); // Close region view
	}

	getDistance() {
		const { region } = this.props;

		return formatDistance({ lat: region.lat, long: region.long }, { lat: region.lat + region.dLat, long: region.long + region.dLong });
	}

	onCloseSpot = (e) => {
		e.preventDefault();
		e.stopPropagation();

		Navigation.goSpot(null);
	}

	renderFullTitle() {
		const { mobile, classes, region } = this.props;

		return (
			<div style={{
				display: 'flex',
				marginBottom: 20,
				paddingLeft: mobile ? 0 : 12,
				paddingRight: mobile ? 0 : 12,
			}}>
				{/* Text part */}
				<div style={{ flexGrow: 10, textAlign: 'center', paddingTop: 15, marginTop: mobile ? 0 : 15 }}>
					{this.state.spots &&
						<>
							<Typography variant='subtitle1'>
								{`${this.state.spots.length}${this.state.spots.length === 100 ? '+' : ''} spots within ${this.getDistance()} from (${roundGPS(region.lat)}, ${roundGPS(region.long)})`}
							</Typography>

							<div style={{ marginBottom: 10, marginTop: 10 }}>
								{mobile &&
									<Button onClick={this.onMap} variant='outlined' color='primary' style={{ marginRight: 10 }}>
										<MapIcon />
										{'Map'}
									</Button>
								}

								<Button onClick={this.onZoom} variant='outlined' color='primary'>
									<ZoomIcon />
									{'Zoom in'}
								</Button>
							</div>

							<TagItems
								tags={this.state.tags}
								editable={false}
							/>
						</>
					}
				</div>

				{/* Small map */}
				{!mobile &&
					<Paper
						elevation={7}
						className={(classes.mapBase)}
					>
						<MapView
							style={{ height: '100%', width: '100%' }}
							showGPS={this.props.showGPS}
							largeMap={false}
							mobile={this.props.mobile}
						/>
					</Paper>
				}
			</div>
		);
	}

	renderMiniTitle() {
		const { region } = this.props;
		const { spots } = this.state;

		return (
			<>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div style={{ flexGrow: 1, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
						<div style={{ marginTop: 0, marginLeft: 10 }}>
							{spots &&
								<Typography variant='subtitle1'>
									{`${spots.length}${spots.length === 100 ? '+' : ''} spots within ${this.getDistance()} from (${roundGPS(region.lat)}, ${roundGPS(region.long)})`}
								</Typography>
							}
						</div>
					</div>
					<IconButton onClick={this.onZoom}>
						<ZoomIcon />
					</IconButton>
					<IconButton onClick={this.onClose}>
						<CloseIcon />
					</IconButton>
				</div>
				<div style={{ textAlign: 'center', marginTop: -5, marginBottom: 10 }}>
					<TagItems
						tags={this.state.tags}
						editable={false}
					/>
				</div>
			</>
		);
	}

	renderMobile() {
		return (
			<>
				<Button color='primary' style={{ position: 'absolute', width: '100%', padding: 0, paddingBottom: 24 }}>
					<IconExpand />
				</Button>

				<div style={{ float: 'right' }}>
					<IconButton size='medium' style={{ display: 'inline-flex', marginLeft: -20 }} onClick={this.onZoom}>
						<ZoomIcon />
					</IconButton>

					<IconButton size='medium' style={{ float: 'right', display: 'inline-flex', marginLeft: 0 }} onClick={this.onCloseSpot}>
						<IconClose />
					</IconButton>
				</div>

				<Typography variant='subtitle2' noWrap style={{ marginTop: 4, marginBottom: 6, marginLeft: 4, minHeight: 21, opacity: 0.5 }}>
					{this.state.spots &&
						`${this.state.spots.length}${this.state.spots.length === 100 ? '+' : ''} spots`
					}
				</Typography>

				<Typography variant='subtitle2' noWrap style={{ marginTop: -3, fontSize: 12, opacity: 0.7, minHeight: 18 }}>
					{this.state.spots && this.state.tags && this.state.tags.map(tag => {
						return (
							<span key={tag.tag} style={{ marginRight: 10 }}>
								{'#' + tag.tag}
							</span>
						);
					})
					}
				</Typography>

				<SpotsBar
					spots={this.state.spots}
					openOnClick={true}
					singleLine={true}
					overlayScrollbar={true}
					height={120}
					margin={3}
				/>
			</>
		);
	}

	render() {
		const { large, mobile } = this.props;

		if (mobile && !large)
			return this.renderMobile();

		return (
			<div>
				{large ? this.renderFullTitle() : this.renderMiniTitle()}

				{/* {this.state.spots && `(${this.state.spots.length} Spots)`} */}
				<SpotsList spots={this.state.spots} flexibility={1.3} />
			</div>
		);
	}
}

Region.propTypes = {
	classes: PropTypes.object.isRequired,
	region: PropTypes.object.isRequired,
	large: PropTypes.bool,
	mobile: PropTypes.bool,
	showGPS: PropTypes.bool,
};

/** @type {any} */
// @ts-ignore
export default withStyles(styles)(Region);