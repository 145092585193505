import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import { register } from './serviceWorkerRegistration';
import { unregister } from './serviceWorkerRegistration';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { BrowserRouter, Route } from 'react-router-dom';
import NavRoute from './NavRoute';
import { CookiesProvider } from 'react-cookie';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import { COLORS } from './tools';
import Navigation from './navigation';

// Polyfills for old browsers, mainly needed for Google, since it currently uses Chromium 41 for search engine rendering
// More info: https://whatibroke.com/2018/12/09/fetch-as-google-error-reactjs/
// https://developers.google.com/search/docs/guides/rendering
require('es6-promise/auto');
if (!window.fetch)
	require('fetch-ie8');
require('es6-object-assign/auto');
if (!Array.prototype.find)
	require('array.prototype.find').shim();
if (!Array.from) Array.from = require('array-from');

const theme = createTheme({
	palette: {
		background: {
			default: '#ffffff',
		},
		secondary: { // Deep Orange A400
			light: COLORS.SECONDARY_LIGHT, // '#ff7539',
			main: COLORS.SECONDARY_MAIN, //'#ff3d00',
			dark: COLORS.SECONDARY_DARK, //'#c30000',
		},
		primary: {
			light: COLORS.PRIMARY_LIGHT,
			main: COLORS.PRIMARY_MAIN,
			dark: COLORS.PRIMARY_DARK,
		}
	},
	typography: {
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			'Open Sans',
			'Helvetica', 
			'Arial', 
			'sans-serif'
			// '-apple-system',
			// 'BlinkMacSystemFont',
		].join(','),
		body1: {
			fontSize: 14,
		},
	},
	overrides: {
		MuiPickersDay: {
			daySelected: {
				backgroundColor: COLORS.SECONDARY_MAIN,
				'&:hover': {
					backgroundColor: COLORS.SECONDARY_MAIN,
				}
			},
		},
		MuiPickersClockNumber: {
			clockNumberSelected: {
				backgroundColor: COLORS.SECONDARY_MAIN,
				'&:hover': {
					backgroundColor: COLORS.SECONDARY_MAIN,
				}
			},
		},
		MuiPickersClockPointer: {
			thumb: {
				borderColor: COLORS.SECONDARY_MAIN,
				'&:hover': {
					borderColor: COLORS.SECONDARY_MAIN,
				}
			},
		}
	}
});

var jssStyles;
var root;

function Main(props) {
	if (jssStyles && jssStyles.parentNode) {
		jssStyles.parentNode.removeChild(jssStyles);
	}

	const mobile = useMediaQuery('(max-width:720px)');
	window.isMobile = mobile;

	return <App {...props} mobile={mobile} />;
}

function setupOpenwith() {
	// Increase verbosity if you need more logs
	//cordova.openwith.setVerbosity(cordova.openwith.DEBUG);

	// Initialize the plugin
	if (window.cordova.openwith)
		window.cordova.openwith.init(initSuccess, initError);

	function initSuccess() { }
	function initError(err) { console.log('OpenWith init failed: ' + err); }

	// Define your file handler
	if (window.cordova.openwith)
		window.cordova.openwith.addHandler(myHandler);

	function myHandler(intent) {

		function dataURLtoFile(dataurl, filename, mime) {
			var
				bstr = atob(dataurl),
				n = bstr.length,
				u8arr = new Uint8Array(n);

			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}

			return new File([u8arr], filename, { type: mime });
		}

		// console.log('intent received');

		// console.log('  action: ' + intent.action); // type of action requested by the user
		// console.log('  exit: ' + intent.exit); // if true, you should exit the app after processing

		// for (var i = 0; i < intent.items.length; ++i) {
		// 	var item = intent.items[i];
		// 	console.log('  type: ', item.type);   // mime type
		// 	console.log('  uri:  ', item.uri);     // uri to the file, probably NOT a web uri

		// 	// some optional additional info
		// 	console.log('  text: ', item.text);   // text to share alongside the item, iOS only
		// 	console.log('  name: ', item.name);   // suggested name of the image, iOS 11+ only
		// 	console.log('  utis: ', item.utis);
		// 	console.log('  path: ', item.path);   // path on the device, generally undefined
		// }

		if (intent.items.length > 0) {
			const item = intent.items[0];
			if (item.uri && item.uri.match(/^https:\/\/.*firebaseapp.com\/__\/auth\/callback\?/i))
				return; // This is login related stuff
			if (item.uri && item.uri.match(/^https:\/\/app.phoide.com/i))
				return; // This is login related stuff
			window.cordova.openwith.load(item, function (data/*, item*/) {
				// data is a long base64 string with the content of the file
				const file = dataURLtoFile(data, item.path, item.type);
				Navigation.newPhoto(file, true /*mobile*/);  // path
				// uploadToServer(item, function() {
				//   if (intent.exit) { cordova.openwith.exit(); }
				// });
			});
		}
	}
}

function setupBackButton() {
	document.addEventListener('backbutton', () => {
		if (Navigation.isHome() || !Navigation.canGoBack)
			navigator.app.exitApp();
		else
			Navigation.goBack();
	}, false);
}

function startApp() {
	if (window.cordova) {
		setupOpenwith();
		setupBackButton();
		//adjustStatusBar();
		//window.open = window.cordova.InAppBrowser.open;
	}

	jssStyles = document.getElementById('jss-server-side');
	root = document.getElementById('root');

	const docData = <ThemeProvider theme={theme}>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<BrowserRouter>
				<CookiesProvider>
					<Route render={props =>
						<NavRoute
							location={props.location}
							history={props.history}
							cookies={props.cookies}
						>
							<Main />
						</NavRoute>
					} />
				</CookiesProvider>
			</BrowserRouter>
		</MuiPickersUtilsProvider>
	</ThemeProvider>;

	if (jssStyles)
		ReactDOM.hydrate(docData, root);
	else
		ReactDOM.render(docData, root);

	const body = document.getElementById('main');
	body.style.opacity = 1;

	// Temporarily don't use service worker, until it's clear how to prevent cache usage for /auth/google url, which shouldn't be cached
	// Let's try to enable it again
	// console.log('Current version: 2');
	// if (!process.env.REACT_APP_CORDOVA)
	// 	register({
	// 		onUpdate: registration => {
	// 			console.log(registration);
	// 			alert('New version available (2)!  Ready to update?');
	// 			if (registration && registration.waiting) {
	// 				registration.waiting.postMessage({ type: 'SKIP_WAITING' });
	// 			}
	// 			window.location.reload();
	// 		}
	// 	});

	// Removed again, needs more testing re. updates (and do we even need a worker? Would PWA be missed?)
	if (!process.env.REACT_APP_CORDOVA)
		unregister();
}

if (window.cordova) {
	document.addEventListener('deviceready', startApp, false);
} else {
	startApp();
}
