// @ts-check
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';
import HelpIcon from './HelpIcon';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slider from '@material-ui/core/Slider';

import UpIcon from '@material-ui/icons/ArrowUpward';
import NavigationIcon from '@material-ui/icons/Navigation';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import MoreIcon from '@material-ui/icons/MoreVert';
import PhotoSizeSelectActualIcon from '@material-ui/icons/PhotoSizeSelectActual';
import PlaceIcon from '@material-ui/icons/Place';
import HeightIcon from '@material-ui/icons/Height';

import { appendScript, getLocTZOffset, COLORS, getDestinationPoint, shallowCompare, bearingTo, getPinImageSVG, getPinSVG, locDistance, completeLocalURL } from '../tools';
import suncalc from '../tools/suncalc';
import PubSub from 'pubsub-js';
import Navigation from '../navigation';
import Server from '../server.js';

// Vectors for 3D transformations are stored as [forward, up, right]

const styles = /*theme =>*/ ({
	btn: {
		position: 'absolute',
		width: 40,
		zIndex: 1000,
	},

	canvas: {
		zIndex: 51,
		position: 'absolute',
		width: '100%',
		height: '100%',
		pointerEvents: 'none',
	},

	gPointer: {
		'& .widget-scene-canvas': {
			cursor: 'pointer',
		}
	}
});

const SEC_PER_DAY = 60 * 60 * 24;
const MS_PER_DAY = 1000 * SEC_PER_DAY;
const SPOT_WIDTH = 120;
const SPOT_HEIGHT = 225;

var G360, gSV;
var gScriptsLoading = false;
var gScriptsLoaded = false;
var gPano;
var e360;
var lastSpotShown;

var spotBackground;
function getPointImageSVG() {
	if (!spotBackground) {
		spotBackground = new Image();
		spotBackground.src = 'data:image/svg+xml,' + escape(getPinImageSVG());
	}
	return spotBackground;
}

var selSpotBackground;
function getSelPointImageSVG() {
	if (!selSpotBackground) {
		selSpotBackground = new Image();
		selSpotBackground.src = 'data:image/svg+xml,' + escape(getPinSVG(COLORS.SECONDARY_MAIN, COLORS.SECONDARY_VERY_LIGHT));
	}
	return selSpotBackground;
}

var hitCanvas;
// Checks whether the coordinates are over a spot icon
function hitTest(x, y) {
	var ctx;
	if (!hitCanvas) {
		hitCanvas = document.createElement('canvas');
		hitCanvas.width = SPOT_WIDTH;
		hitCanvas.height = SPOT_HEIGHT;
		ctx = hitCanvas.getContext('2d');
		if (ctx)
			ctx.drawImage(getPointImageSVG(), 0, 0);
	} else
		ctx = hitCanvas.getContext('2d');

	return ctx.getImageData(x, y, 1, 1).data[0] > 0;
}

// Image cache
var images = {}; // TODO: remove old images?

function getThumbForID(idSpot) {
	var img = images[idSpot];
	if (!img) {
		img = new Image();
		images[idSpot] = img;
		Server.fetchMiniThumb(idSpot).then(src => {
			img.src = src;
			imgLoaded();
		});
	}
	if (img.complete && img.height > 0 /* to check that it's really loaded and not 'broken' */)
		return img;
}

function imgLoaded() {
	if (G360)
		G360.onImgLoaded();  // TODO: notify only for images requested by the current 360 view (not some old ones)? Probably not a big deal...
}

// 3D related stuff
function onPovChange() {
	if (G360)
		G360.nowDraw(false);
}

function onStatusChange() {
	if (G360) {
		if (gPano.getPano())
			G360.setDefaultPov();
		else
			G360.setNearbySpot();  // No pano found/loaded, try to find another one nearby
	}
}

function onPositionChange() {
	if (G360)
		G360.onPosChange();
}

function rotateV(angle, c) {
	const a = angle / 180 * Math.PI;
	const sa = Math.sin(a);
	const ca = Math.cos(a);

	const r1 = ca * c[0] - sa * c[1];
	const r2 = sa * c[0] + ca * c[1];
	const r3 = c[2];

	return [r1, r2, r3];
}

function rotateH(angle, c) {
	const b = -angle / 180 * Math.PI;
	const sb = Math.sin(b);
	const cb = Math.cos(b);

	const r1 = cb * c[0] + sb * c[2];
	const r2 = c[1];
	const r3 = -sb * c[0] + cb * c[2];

	return [r1, r2, r3];
}

// eslint-disable-next-line no-unused-vars
function getHVangles(heading, pitch, oHeading, oPitch) {
	const p = rotateV(-pitch, rotateH(-heading, rotateH(oHeading, rotateV(oPitch, [1, 0, 0]))));
	return {
		h: Math.atan(p[2] / p[0]) / Math.PI * 180,
		v: Math.atan(p[1] / Math.sqrt(p[0] * p[0] + p[2] * p[2])) / Math.PI * 180,
	};
}

function projectV(c, v) {
	const p = rotateV(-c.pitch, rotateH(-c.heading, v));
	const dist = p[0] * Math.tan(Math.min(c.fov, 177) / 2 / 180 * Math.PI);
	if (dist > 0) {
		const xx = p[2] / dist;
		const yy = p[1] / dist;
		const maxX = 1;
		const maxY = maxX * c.height / c.width;
		const x = Math.round((xx / maxX + 1) / 2 * c.width);
		const y = Math.round((1 - (yy / maxY + 1) / 2) * c.height);
		return { x, y, show: true };
	} else
		return { show: false };

}

function project(c, oHeading, oPitch) {
	/** @type{any} */
	const res = projectV(c, rotateH(oHeading, rotateV(oPitch, [1, 0, 0])));
	return res;
}

function zoomToFOV(zoom) {
	if (zoom === 0)
		return 143;
	else if (zoom <= 0.1)
		return 126.6825514 - 29.13508891 * zoom;
	else if (zoom <= 1.70)
		return 128.3332069 - 38.408708 * zoom;
	else if (zoom <= 2.00)
		return 112.8358661 - 29.94406007 * zoom;
	else
		return 136.5594048 - 53.47559481 * zoom + 5.744283378 * zoom * zoom;
}

function FOVToZoom(fov) {
	if (fov >= 143)
		return 0;
	else if (fov > 126.6825514)
		return 0.0001;
	else if (fov > 123.7690425)
		return (126.6825514 - fov) / 29.13508891;
	else if (fov > 63.0384033)
		return (128.3332069 - fov) / 38.408708;
	else if (fov > 52.94774596)
		return (112.8358661 - fov) / 29.94406007;
	else
		return (7.79323024 - 1.241128369 * Math.sqrt(fov) + 0.06279105819 * fov);
}

function fovToMM(fov) {
	return 18 / Math.tan(fov / 180 * Math.PI / 2) / Navigation.getCropValue();
}

function getPole(c, direction, sun) {
	const UP = 2;
	const DOWN = -11;
	const SEGS = c.fov < 60 ? (c.fov < 30 ? 100 : 60) : 40;
	const POLE_DIAM = 0.009;

	const poleUp = rotateV(UP, [1, 0, 0]);
	const poleDown = rotateV(DOWN, [1, 0, 0]);
	const height = poleUp[1] - poleDown[1];

	// Pole
	const res = {
		tl: project(c, direction - 0.5, UP),
		bl: project(c, direction - 0.5, DOWN),
		tr: project(c, direction + 0.5, UP),
		br: project(c, direction + 0.5, DOWN),
		/** @type{any} */
		segs: [],
	};
	res.show = res.tl.show && res.bl.show && res.tr.show && res.br.show;

	// Segments
	const angleStep = 2 * Math.PI / SEGS;
	const angleStep2 = angleStep / 2;
	for (let iseg = 0; iseg < SEGS; iseg++) {
		const angle = iseg * angleStep + angleStep2;
		const cA1 = Math.cos(angle) * POLE_DIAM;
		const cA2 = Math.cos(angle + angleStep) * POLE_DIAM;
		const sA1 = Math.sin(angle) * POLE_DIAM;
		const sA2 = Math.sin(angle + angleStep) * POLE_DIAM;
		const seg = [
			projectV(c, rotateH(direction, [poleDown[0] + cA1, poleDown[1], poleDown[2] + sA1])),
			projectV(c, rotateH(direction, [poleDown[0] + cA2, poleDown[1], poleDown[2] + sA2])),
			projectV(c, rotateH(direction, [poleUp[0] + cA2, poleUp[1], poleUp[2] + sA2])),
			projectV(c, rotateH(direction, [poleUp[0] + cA1, poleUp[1], poleUp[2] + sA1])),
			Math.cos(angle - (sun.direction - direction) / 180 * Math.PI)
		];
		// @ts-ignore
		if (seg[0].x > seg[1].x) { // Segment is in front of camera plane?
			res.segs.push(seg);
		}
	}

	// Shadow
	const shLength = Math.pow(height / Math.tan(Math.max(sun.pitch, 1) / 180 * Math.PI) + 1, 0.6) - 1  /* otherwise the shadow appears too long */;
	const orig = rotateH(direction, poleDown);
	const sunDir = sun.direction / 180 * Math.PI;
	const poleSh = [orig[0] - Math.cos(sunDir) * shLength, orig[1], orig[2] - Math.sin(sunDir) * shLength];
	const shWidth = 0.009;
	const sunDirPer = sunDir + Math.PI / 2;
	const poleShL = [poleSh[0] - Math.cos(sunDirPer) * shWidth, poleSh[1], poleSh[2] - Math.sin(sunDirPer) * shWidth];
	const poleShR = [poleSh[0] + Math.cos(sunDirPer) * shWidth, poleSh[1], poleSh[2] + Math.sin(sunDirPer) * shWidth];
	res.shadowL = projectV(c, poleShL);
	res.shadowR = projectV(c, poleShR);
	res.shadowBL = projectV(c, [orig[0] - Math.cos(sunDirPer) * shWidth, orig[1], orig[2] - Math.sin(sunDirPer) * shWidth]);
	res.shadowBR = projectV(c, [orig[0] + Math.cos(sunDirPer) * shWidth, orig[1], orig[2] + Math.sin(sunDirPer) * shWidth]);

	return res;
}

// eslint-disable-next-line no-unused-vars
function renderPole(c, direction, sun, ctx) {
	/** @type{any} */
	const pole = getPole(c, direction, sun);
	if (pole.show) {
		if (sun.pitch > 0.1) {
			// Shadow
			ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
			ctx.beginPath();
			ctx.moveTo(pole.shadowBL.x, pole.shadowBL.y);
			ctx.lineTo(pole.shadowL.x, pole.shadowL.y);
			ctx.lineTo(pole.shadowR.x, pole.shadowR.y);
			ctx.lineTo(pole.shadowBR.x, pole.shadowBR.y);
			ctx.lineTo(pole.shadowBL.x, pole.shadowBL.y);
			ctx.fill();
		}

		// Pole		
		for (let seg of pole.segs) {
			const mult = Math.min(1, Math.max(0.3, (sun.pitch + 15) / 19));  // Darker pole when Sun is down
			const gcol = Math.round(0.8 * (145 + 110 * seg[4])) * mult;
			const rcol = gcol * (1 + 0.6 * (1 - Math.min(1, Math.abs(1 - sun.pitch) / 5))) /* redder pole when Sun is low*/;
			const bcol = Math.round(gcol * 0.6) * mult;
			// TODO: ctx.createLinearGradient(x0, y0, x1, y1);   to create smoother transitions?
			ctx.strokeStyle = `rgba(${rcol}, ${gcol}, ${bcol}, 1)`;
			ctx.fillStyle = `rgba(${rcol}, ${gcol}, ${bcol}, 1)`;
			ctx.beginPath();
			ctx.moveTo(seg[0].x, seg[0].y);
			ctx.lineTo(seg[1].x, seg[1].y);
			ctx.lineTo(seg[2].x, seg[2].y);
			ctx.lineTo(seg[3].x, seg[3].y);
			ctx.lineTo(seg[0].x, seg[0].y);
			ctx.stroke();
			ctx.fill();
		}
	}
}

// Curves

// given an array of x,y's, return distance between any two,
// note that i and j are indexes to the points, not directly into the array.
function dista(arr, i, j) {
	return Math.sqrt(Math.pow(arr[2 * i] - arr[2 * j], 2) + Math.pow(arr[2 * i + 1] - arr[2 * j + 1], 2));
}

// return vector from i to j where i and j are indexes pointing into an array of points.
function va(arr, i, j) {
	return [arr[2 * j] - arr[2 * i], arr[2 * j + 1] - arr[2 * i + 1]];
}

// @ts-ignore
// eslint-disable-next-line no-unused-vars
function ctlpts(x1, y1, x2, y2, x3, y3) {
	var t = 0.4; // Tension
	var v = va(arguments, 0, 2); // eslint-disable-line prefer-rest-params
	var d01 = dista(arguments, 0, 1); // eslint-disable-line prefer-rest-params
	var d12 = dista(arguments, 1, 2); // eslint-disable-line prefer-rest-params
	var d012 = d01 + d12;
	return [x2 - v[0] * t * d01 / d012, y2 - v[1] * t * d01 / d012, x2 + v[0] * t * d12 / d012, y2 + v[1] * t * d12 / d012];
}

function setLineStyle(above, ctx) {
	if (above) {
		ctx.setLineDash([]);
		ctx.globalAlpha = 1;
	} else { // Sun or Moon path below the horizon
		ctx.setLineDash([3, 15]);
		ctx.globalAlpha = 0.4;
	}
}

function drawCurvedPath(cps, pts, above, ctx) {
	var len = pts.length / 2; // number of points
	if (len < 2) return;
	setLineStyle(above[0], ctx);
	if (len === 2) {
		ctx.beginPath();
		ctx.moveTo(pts[0], pts[1]);
		ctx.lineTo(pts[2], pts[3]);
		ctx.stroke();
	}
	else {
		ctx.beginPath();
		ctx.moveTo(pts[0], pts[1]);
		// from point 0 to point 1 is a quadratic
		ctx.quadraticCurveTo(cps[0], cps[1], pts[2], pts[3]);
		if (above[0] !== above[1]) {
			ctx.stroke();
			ctx.beginPath();
			ctx.moveTo(pts[2], pts[3]);
		}
		// for all middle points, connect with bezier
		for (var i = 2; i < len - 1; i += 1) {
			ctx.bezierCurveTo(
				cps[(2 * (i - 1) - 1) * 2], cps[(2 * (i - 1) - 1) * 2 + 1],
				cps[(2 * (i - 1)) * 2], cps[(2 * (i - 1)) * 2 + 1],
				pts[i * 2], pts[i * 2 + 1]);
			if (above[i - 1] !== above[i]) {
				setLineStyle(above[i - 1], ctx);
				ctx.stroke();
				ctx.beginPath();
				ctx.moveTo(pts[i * 2], pts[i * 2 + 1]);
			}
		}
		ctx.quadraticCurveTo(
			cps[(2 * (i - 1) - 1) * 2], cps[(2 * (i - 1) - 1) * 2 + 1],
			pts[i * 2], pts[i * 2 + 1]);
		setLineStyle(above[i - 1], ctx);
		ctx.stroke();
	}
}

function drawPath(pts, above, ctx) {
	var cps = []; // There will be two control points for each "middle" point, 1 ... len-2e
	for (var i = 0; i < pts.length - 2; i += 1) {
		cps = cps.concat(ctlpts(pts[2 * i], pts[2 * i + 1],
			pts[2 * i + 2], pts[2 * i + 3],
			pts[2 * i + 4], pts[2 * i + 5]));
	}

	drawCurvedPath(cps, pts, above, ctx);
}

function drawObjPath(x, dayStart, getPos, spot, c, ctx) {
	const horizon = -1 / 180 * Math.PI;
	x.sort((t1, t2) => t1 - t2);
	const pts = [];
	const above = [];
	var lastAlt = null;
	for (let offset of x) {
		const pos = getPos(dayStart + offset, spot.lat, spot.long);
		const prj = project(c, pos.azimuth / Math.PI * 180 + 180, pos.altitude / Math.PI * 180);
		if (prj.show) {
			if (lastAlt !== null)
				above.push(Math.abs(pos.altitude - horizon) > Math.abs(lastAlt - horizon) ? pos.altitude - horizon > 0 : lastAlt - horizon > 0);
			pts.push(prj.x, prj.y);
			lastAlt = pos.altitude;
		} else {
			if (pts.length) {
				drawPath(pts, above, ctx);
				pts.length = 0;
				above.length = 0;
			}
			lastAlt = null;
		}
	}
	drawPath(pts, above, ctx);
}


if (!process.env.REACT_APP_SERVER_SIDE) {
	// @ts-ignore
	window.init360 = function () { // A global function to be called back by Google code
		gScriptsLoaded = true;
		if (G360)
			G360.whenGLoaded();
	};
}

class VR360 extends Component {
	state = {
		focMenuEl: null,
		moreMenuEl: null,
		dirMenuEl: null,

		fixDir: 'North',
		northSet: false,
		/** @type{any} */
		elevation: null,
	};

	_root;
	_sun;
	_moon;
	_subDate;
	_compass;
	forceDay;
	forceTime;
	willDraw = false;
	willDrawDOM = false;
	willDrawCanvas = false;
	lastHeading = 0;
	headingOffset = 0;
	pitchOffset = 0;
	fixNorth = false;
	setView = false;
	fixElevation = false;
	isAdjusted = false;
	drawnElements = [];
	/** @type{any} */
	_subLight;
	/** @type{any} */
	_downTime;
	/** @type{any} */
	sunPitch

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidMount() {
		G360 = this;
		this._subLight = PubSub.subscribe('LIGHT_PANEL_CHANGE', (msg, data) => { // eslint-disable-line no-unused-vars
			this.updateSunPosition();
			this.nowDraw(false);
		});

		this._subDate = PubSub.subscribe('FORCE_TIME', (msg, data) => { // eslint-disable-line no-unused-vars
			this.forceDay = data.newDay;
			this.forceTime = data.newTime;
			this.nowDraw(true);
		});
	}

	nowDraw(updateSun) {
		// Seems to look better when not in requestAnimationFrame()?  (While rotate/zooming)
		if (updateSun)
			this.updateSunPosition();
		this.onPovChange();
		return;

		// if (!this.willDraw) {
		// 	this.willDraw = true;
		// 	requestAnimationFrame(() => {
		// 		this.willDraw = false;
		// 		if (updateSun)
		// 			this.updateSunPosition();
		// 		this.onPovChange();
		// 	});
		// }
	}

	componentWillUnmount() {
		lastSpotShown = this.props.location;
		G360 = null;
		if (e360)
			e360.remove(); // TODO: Maybe use safer way, is this method supported everywhere we need it?
		PubSub.unsubscribe(this._subDate);
		PubSub.unsubscribe(this._subLight);
		if (this._root) {
			this._root.removeEventListener('mousemove', this.mouseMove, true);
			this._root.removeEventListener('mouseup', this.mouseUp, true);
			this._root.removeEventListener('mousedown', this.mouseDown, true);
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.location !== this.props.location || (nextProps.location && nextProps.location === this.props.location && nextProps.location.lat !== this.props.location.lat)) {
			this.updateSpot(nextProps);
			return false;
		}

		return shallowCompare(this, nextProps, nextState);
	}

	init() {
		if (!gScriptsLoaded && !gScriptsLoading) {
			gScriptsLoading = true;
			// if (process.env.NODE_ENV === 'production') // Google maps work well in a domain anyway (localhost has some CORS issues)
			appendScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyBgqety7nRiGBic9QfydAHDmcqgbnBzWrk&callback=init360&libraries=&v=weekly');
		}
		if (gScriptsLoaded)
			this.whenGLoaded();
	}

	/** Google scripts are loaded */
	whenGLoaded = () => {
		if (!this._root) {
			setTimeout(this.whenGLoaded, 5);
			return;
		}

		if (!e360) {
			e360 = document.createElement('div');
			e360.id = 'e360SV';
			e360.style.height = '100%';
		}
		this._root.append(e360);
		this._root.addEventListener('mousemove', this.mouseMove, true);
		this._root.addEventListener('mouseup', this.mouseUp, true);
		this._root.addEventListener('mousedown', this.mouseDown, true);
		if (!lastSpotShown || !this.props.location || lastSpotShown.id !== this.props.location.id)  // Don't update when returning to the same spot
			this.updateSpot();
		else
			this.onPovChange();
	}

	async getNewPano(fromLatLng, fromPano, radius, repeat) {
		if (!gSV) {
			// @ts-ignore
			// eslint-disable-next-line no-undef
			gSV = new google.maps.StreetViewService();
		}

		return await new Promise((res) => {
			gSV.getPanorama({ location: fromLatLng, radius, preference: 'nearest' }, async (data, status) => {
				// console.log(fromLatLng);
				// console.log(status);
				if (status === 'OK') {
					// console.log(data.location);
					if (data.location.pano !== fromPano) {
						return res(data);
					}
				} else if (status === 'ZERO_RESULTS') { // Our exact (1m) searches sometimes fail, even if set to ~5 meters, use larger radius then
					if (repeat) {
						return res(await this.getNewPano(fromLatLng, fromPano, 100));
					}
				}
				return res(null);
			});
		});
	}

	// Mouse operations
	mouseMove = (e) => {
		this._downTime = null;  // To not make click after mouse move
		const el = this.getElementXY(e.clientX, e.clientY);
		if (el) {
			PubSub.publish('MAP_MOUSE_MOVE', {
				idSpot: el.id,
				spotPixel: [e.clientX, e.clientY],
			});
			this.setState({ gPointer: true });
		} else {
			PubSub.publish('MAP_MOUSE_MOVE', {});
			this.setState({ gPointer: false });
		}
	}

	mouseDown = () => {
		this._downTime = Date.now();
	}

	mouseUp = (e) => {
		if (Date.now() - this._downTime < 500 && e.button === 0) { // Consider it to be a click
			const el = this.getElementXY(e.clientX, e.clientY);
			if (el) {
				Navigation.goSpot({ id: el.id });
				e.preventDefault(); // To not handle it by Google Pano
				e.stopPropagation();
			}
		}
	}

	async adjust3D(lat, lng) {
		this.adjLat = lat;
		this.adjLng = lng;
		const adj = await Server.get3DAdjust(lat, lng);
		if (adj && lat === this.adjLat && lng === this.adjLng) { // We are still at the same panorama
			this.headingOffset = adj.headingAdj;
			this.pitchOffset = adj.pitchAdj;
			const spot = this.props.location;
			if (this.isViewSet && !this.isAdjusted && spot.D3) {
				gPano.setZoom(FOVToZoom(spot.D3.fov));
				gPano.setPov({ heading: spot.D3.head - this.headingOffset, pitch: spot.D3.pitch - this.pitchOffset });
			}
			this.onPovChange();
			this.isAdjusted = true;
		}
	}

	setDefaultPov = () => {
		if (!this.isViewSet)
			gPano.setPov(gPano.getPhotographerPov());
		this.onPovChange();
	}

	getDirFix() {
		switch (this.state.fixDir) {
			case 'North': return 0;
			case 'East': return 90;
			case 'South': return 180;
			default: return 270;
		}
	}

	moveForward = async () => {
		const loc = gPano.getLocation().latLng;
		const pano = gPano.getPano();
		const heading = gPano.getPov().heading;
		for (let dist of [10, 30, 60, 120, 240, 500, 1000]) {
			const dest = getDestinationPoint({ lat: loc.lat(), long: loc.lng() }, heading, dist);
			const newPano = await this.getNewPano({ lat: dest.lat, lng: dest.lon }, pano, dist);
			if (newPano) {
				gPano.setPano(newPano.location.pano);
				break;
			}
		}
	}

	setNearbySpot = async () => {
		const loc = this.props.location;
		if (loc) {
			const newPano = await this.getNewPano({ lat: loc.lat, lng: loc.long }, null, 1000);
			if (newPano)
				gPano.setPano(newPano.location.pano);
		}
	}

	updateSunPosition(props) {
		if (!Navigation.getWeather())
			return;

		props = props || this.props;
		const spot = props.location;
		if (!spot || typeof spot.lat !== 'number')
			return;

		const tzOffset = getLocTZOffset(spot);
		const dayStart = this.forceDay ? this.forceDay.valueOf() : (Math.trunc((Date.now() + tzOffset) / MS_PER_DAY) * MS_PER_DAY);
		const useTime = (this.forceTime + dayStart) || Date.now();
		const sunPos = suncalc.getPosition(useTime, spot.lat, spot.long);
		this.sunPitch = sunPos.altitude / Math.PI * 180; // TODO: Convert it all to radians sometimes
		this.sunDir = sunPos.azimuth / Math.PI * 180 + 180;
		const times = suncalc.getTimes(dayStart - tzOffset + 10000000, spot.lat, spot.long);
		this.sunrise = times.sunrise;
		this.sunset = times.sunset;

		if (Navigation.getShowMoon()) {
			const moonPos = suncalc.getMoonPosition(useTime, spot.lat, spot.long);
			this.moonPitch = moonPos.altitude / Math.PI * 180; // TODO: Convert it all to radians sometimes
			this.moonDir = moonPos.azimuth / Math.PI * 180 + 180;
			const times = suncalc.getMoonTimes(dayStart - tzOffset, spot.lat, spot.long);
			/** @type {any} */
			this.moonrise = times.rise;
			/** @type {any} */
			this.moonset = times.set;
		}
	}

	getCtx(canvas) {
		const width = this._root.clientWidth;
		const height = this._root.clientHeight;

		if (canvas.width !== width || canvas.height !== height) {
			canvas.width = width;
			canvas.height = height;
		}
		var ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		return ctx;
	}

	headNorth = () => {
		if (gPano) {
			let pov = gPano.getPov();
			pov.heading = 0;
			gPano.setPov(pov);
		}
	}

	toggleFocalMenu = (e) => {
		if (this.state.focMenuEl)
			this.setState({ focMenuEl: null });
		else
			this.setState({ focMenuEl: e.currentTarget });
	}

	toggleMore = (e) => {
		if (this.state.moreMenuEl)
			this.setState({ moreMenuEl: null });
		else
			this.setState({ moreMenuEl: e.currentTarget });
	}

	catchEvent = (e) => {
		this._downTime = null;  // To not make click after a button click
	}

	scheduleDraw() {
		if (!this.willDrawCanvas) {
			this.willDrawCanvas = true;
			requestAnimationFrame(() => {
				this.onPovChange();
				this.willDrawCanvas = false;
			});
		}
	}

	onImgLoaded = () => {
		this.scheduleDraw();
	}

	onPosChange = () => {
		this.headingOffset = 0;
		this.pitchOffset = 0;
		this.setState({ northSet: false });
	}

	onPovChange = () => {
		if (!this._root)
			return;

		const spot = this.props.location;
		var pov, pos, zoom;
		if (gPano) {
			pov = Object.assign({}, gPano.getPov()); // To not use the internal structure from Google (which wasn't good)
			zoom = pov.zoom || gPano.getZoom(); // pov.zoom seems to be more reliable, getZoom() sometimes returns old values.
			const gpos = gPano.getPosition();
			if (gpos)
				pos = { lat: gpos.lat(), long: gpos.lng() };
		} else {
			pov = { heading: 180, pitch: 0 };
			zoom = 0;
		}
		if (!pos)
			pos = spot;
		const hAng = zoomToFOV(zoom);
		pov.heading += this.headingOffset;
		pov.pitch += this.pitchOffset;
		this.drawnElements.length = 0;

		if (!this.willDrawDOM) {
			this.willDrawDOM = true;
			requestAnimationFrame(() => {
				this.willDrawDOM = false;
				// Compass
				if (this._compass) {
					let diff = (pov.heading - this.lastHeading) % 360;
					while (diff > 180) diff -= 360;
					while (diff < -180) diff += 360;
					this.lastHeading += diff;
					this._compass.style.transform = `rotate(${-this.lastHeading}deg)`;
				}

				// Focal length
				if (this._mm) {
					this._mm.innerText = String(Math.round(fovToMM(hAng)));
				}
			});
		}

		// Canvas drawing
		const width = this._root.clientWidth;
		const height = this._root.clientHeight;

		// Clear ctx before doing anything else
		var ctx;
		var ctx1 = this.getCtx(this._canvas1);
		var ctx2 = this.getCtx(this._canvas2);

		const c = {
			heading: pov.heading,
			pitch: pov.pitch,
			fov: hAng,
			width,
			height
		};

		if (Navigation.getWeather()) {
			const sunSize = (31 / 60 /* angular size of sun and moon*/) / (hAng / width) * (Navigation.getEnlarge() ? 5 : 1) /* enlarge */;
			const sunSize2 = sunSize / 2;

			ctx = ctx1;

			if (Navigation.getPaths()) {
				// Sunpath
				const spot = this.props.location;
				const useDay = (this.forceDay ? this.forceDay.valueOf() : (Math.trunc((Date.now() + getLocTZOffset(spot)) / MS_PER_DAY) * MS_PER_DAY));
				const dayStart = useDay - getLocTZOffset(spot, useDay);
				ctx.strokeStyle = 'orange';
				ctx.lineWidth = 3;
				var x = Array.from({ length: 25 }, (_, i) => i * 60 * 60 * 1000);
				if (this.sunrise)
					x.push(this.sunrise - dayStart);
				if (this.sunset)
					x.push(this.sunset - dayStart);
				drawObjPath(x, dayStart, suncalc.getPosition, spot, c, ctx);

				// Moonpath
				if (Navigation.getShowMoon()) {
					ctx.strokeStyle = 'blue';
					x = Array.from({ length: 25 }, (_, i) => i * 60 * 60 * 1000);
					if (this.moonrise)
						x.push(this.moonrise - dayStart);
					if (this.moonset)
						x.push(this.moonset - dayStart);
					drawObjPath(x, dayStart, suncalc.getMoonPosition, spot, c, ctx);
				}
				ctx.lineWidth = 1;
				ctx.globalAlpha = 1;
				ctx.setLineDash([]);
			}

			ctx = ctx2;

			// Sun (red part - around horizon and below)
			/** @type{any} */
			const prjS = project(c, this.sunDir, this.sunPitch);
			const sunFade = Math.min(1, Math.max(0, (this.sunPitch - 1) / 8));
			if (this.sunPitch < 8) { // Red Sun (sunrise/sunset)
				let flareMult = 1.9;
				ctx.drawImage(this._sunR, prjS.x - sunSize2 * flareMult, prjS.y - sunSize2 * flareMult, sunSize * flareMult, sunSize * flareMult);
			}

			// Moon
			if (Navigation.getShowMoon()) {
				/** @type{any} */
				const prjM = project(c, this.moonDir, this.moonPitch);
				if (prjM.show) {
					ctx.drawImage(this._moon, prjM.x - sunSize2, prjM.y - sunSize2, sunSize, sunSize);
				}
			}

			// Hide (almost) things below horizon
			const horizon = Math.max(0, Math.min(height, project(c, c.heading, -0.833 /* sunrise/sunset start/end */).y));
			ctx.globalCompositeOperation = 'source-atop';
			ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
			ctx.fillRect(0, horizon, width, height - horizon);
			ctx.globalCompositeOperation = 'source-over';

			// Sun (white part - higher in the sky)
			if (this.sunPitch > 0) {
				let flareMult = 7; // Sunrays make this image larger
				ctx.globalAlpha = sunFade;
				ctx.drawImage(this._sun, prjS.x - sunSize2 * flareMult, prjS.y - sunSize2 * flareMult, sunSize * flareMult, sunSize * flareMult);
				ctx.globalAlpha = 1 - sunFade;
				ctx.globalCompositeOperation = 'source-atop';
				ctx.fillStyle = '#f76919';
				ctx.fillRect(prjS.x - sunSize2 * flareMult, prjS.y - sunSize2 * flareMult, sunSize * flareMult, sunSize * flareMult);
				ctx.globalAlpha = 1;
				ctx.globalCompositeOperation = 'source-over';
			}

			// Poles & Shadows
			if (Navigation.getShadows()) {
				// Render Poles
				const oSun = { direction: this.sunDir, pitch: this.sunPitch };
				for (let ang = 30; ang < 390; ang += 60)
					renderPole(c, ang, oSun, ctx);
			}
		}

		ctx = ctx2;

		if (Navigation.getShowNearby() && spot) {
			const elev = this.fixElevation ? this.state.elevation : spot.elev;
			// Nearby Spots
			for (let i = (spot.nearby || []).length - 1; i >= 0; i--) { // Go from the furthest spot to the nearest one (to properly draw visibility)
				const loc = spot.nearby[i];
				this.drawSpot(ctx, pos, loc, c, hAng, false, loc.elev - elev);
			}
			this.drawSpot(ctx, pos, spot, c, hAng, true /* selected */);
		}

		// Directions
		if (this.fixNorth) {
			const len = 54;
			ctx.strokeStyle = COLORS.SECONDARY_MAIN;
			ctx.lineWidth = 5;
			ctx.setLineDash([]);

			const center = project(c, this.getDirFix(), 0);
			ctx.beginPath();
			ctx.moveTo(center.x - len, center.y);
			ctx.lineTo(center.x + len, center.y);
			ctx.stroke();

			ctx.beginPath();
			ctx.moveTo(center.x, center.y - len);
			ctx.lineTo(center.x, center.y + len);
			ctx.stroke();

			// Variable size ?
			// const currUp = project(c, 0, 4);
			// const currDown = project(c, 0, -4);
			// if (currUp.show && currDown.show) {
			// 	ctx.beginPath();
			// 	ctx.moveTo(currUp.x, currUp.y);
			// 	ctx.lineTo(currDown.x, currDown.y);
			// 	ctx.stroke();
			// }

			// const currLeft = project(c, -4, 0);
			// const currRight = project(c, 4, 0);
			// if (currLeft.show && currRight.show) {
			// 	ctx.beginPath();
			// 	ctx.moveTo(currLeft.x, currLeft.y);
			// 	ctx.lineTo(currRight.x, currRight.y);
			// 	ctx.stroke();
			// }

			// New direction
			ctx.lineWidth = 3;
			ctx.setLineDash([8, 8]);
			ctx.beginPath();
			ctx.moveTo(width / 2 - len, height / 2);
			ctx.lineTo(width / 2 + len, height / 2);
			ctx.stroke();

			ctx.beginPath();
			ctx.moveTo(width / 2, height / 2 - len);
			ctx.lineTo(width / 2, height / 2 + len);
			ctx.stroke();
			ctx.setLineDash([]);
			ctx.lineWidth = 1;
		}
		this.drawnElements.reverse();
	}

	drawSpot(ctx, pos, loc, c, hAng, selected, elevDiff) {
		const dist = locDistance(pos, loc);
		/** @type{any} */
		const prj = projectV(c, rotateH(bearingTo(pos, loc), [dist / 10, selected ? -1 : -0.1 + (elevDiff / 10 || 0), 0]));
		if (prj.show) {
			var scale = Math.max(0.3, Math.min(2, 2000 / (dist + 1000) / hAng * 50 / devicePixelRatio));
			const x = prj.x - 60 * scale;
			const y = prj.y - (225 / 2) * scale;
			const w = SPOT_WIDTH * scale;
			const h = SPOT_HEIGHT * scale;
			ctx.drawImage(selected ? getSelPointImageSVG() : getPointImageSVG(), x, y, w, h);
			const img = getThumbForID(loc.id);
			if (img) {
				ctx.drawImage(img, prj.x - 30 * scale, prj.y - 202 / 2 * scale, 60 * scale, 60 * scale);
			}
			this.drawnElements.push({
				id: loc.id,
				x, y,
				x2: x + w,
				y2: y + h,
				w,
				h,
			});
		}
	}

	getElementXY(X, Y) {
		if (!this._root)
			return;

		const rect = this._root.getBoundingClientRect();
		const x = X - rect.left; //x position within the element.
		const y = Y - rect.top;  //y position within the element.
		for (const el of this.drawnElements) {
			if (el.x <= x && el.x2 >= x && el.y <= y && el.y2 >= y) {
				if (hitTest((x - el.x) / (el.w / SPOT_WIDTH), (y - el.y) / (el.h / SPOT_HEIGHT)))
					return el;
			}
		}
	}

	async updateSpot(props) {
		props = props || this.props;
		const spot = props.location;
		if (!gScriptsLoaded || !spot || typeof spot.lat !== 'number')
			return;
		var position;
		if (spot.D3 && typeof spot.D3.lat === 'number')
			position = { lat: spot.D3.lat, lng: spot.D3.lng };
		else
			position = { lat: spot.lat, lng: spot.long };
		this.isViewSet = false;
		this.isAdjusted = false;
		this.headingOffset = 0;
		this.pitchOffset = 0;
		this.updateSunPosition();

		const newPano = await this.getNewPano(position, null, spot.D3 ? 1 : 1000, true /* repeat on fail */);
		if (!newPano)
			return;

		const latLng = newPano.location.latLng;
		this.adjust3D(latLng.lat(), latLng.lng());

		if (gPano) {
			gPano.setPano(newPano.location.pano);
			if (spot.D3 && !this.isViewSet) {
				gPano.setZoom(FOVToZoom(spot.D3.fov));
				gPano.setPov({ heading: spot.D3.head - this.headingOffset, pitch: spot.D3.pitch - this.pitchOffset });
			}
		} else {
			const opts = {
				// position,
				pano: newPano.location.pano,
				fullscreenControl: false,
				addressControl: false,
				zoomControl: false,
				linksControl: false,
				clickToGo: true,
				panControl: false,
			};
			if (spot.D3 && !this.isViewSet) {
				opts.zoom = FOVToZoom(spot.D3.fov);
				opts.pov = { heading: spot.D3.head - this.headingOffset, pitch: spot.D3.pitch - this.pitchOffset };
			}
			// @ts-ignore
			// eslint-disable-next-line no-undef
			gPano = new google.maps.StreetViewPanorama(e360, opts);

			gPano.addListener('pov_changed', onPovChange);
			gPano.addListener('status_changed', onStatusChange);
			gPano.addListener('position_changed', onPositionChange);
		}
		this.isViewSet = true;
	}

	setFocalLengthValue = (value) => {
		if (gPano) {
			const f = value * Navigation.getCropValue();
			const fov = 2 * Math.atan(18 / f) / Math.PI * 180;
			gPano.setZoom(Math.max(0, FOVToZoom(fov)));
			this.onPovChange();
		}
	}

	setFocalLength = (e) => {
		this.setFocalLengthValue(Number(e.currentTarget.dataset.id));
		this.toggleFocalMenu();
	}

	changeCrop = (e) => {
		Navigation.setCropFactor(e.target.value);
		this.forceUpdate();
		this.onPovChange();
	}

	adjustLens(modFn) {
		if (gPano) {
			this.setFocalLengthValue(modFn(fovToMM(zoomToFOV(gPano.getPov().zoom))));
		}
	}

	lensPlus = () => {
		this.adjustLens(i => i + 1.7); // > 1 in order to make sure there's a change even after some rounding issues
	}

	lensMinus = () => {
		this.adjustLens(i => i - 1.7);
	}

	toggleNearby = () => {
		Navigation.toggleShowNearby();
		this.forceUpdate();
		this.onPovChange();
	}

	toggleFixNorth = () => {
		this.setState({ moreMenuEl: null });
		this.fixNorth = !this.fixNorth;
		this.onPovChange();
		this.forceUpdate();
	}

	toggleFixElevation = () => {
		this.setState({
			moreMenuEl: null,
			elevationBase: this.props.location && this.props.location.elev,
			elevation: this.props.location && this.props.location.elev,
		});
		this.fixElevation = !this.fixElevation;
		this.forceUpdate();
		this.onPovChange();
	}

	toggleSetView = () => {
		this.setState({ moreMenuEl: null });
		this.setView = !this.setView;
		this.forceUpdate();
	}

	submitNorth() {
		this.toggleFixNorth();
		if (gPano) {
			const loc = gPano.getLocation().latLng;
			Server.submit3DAdjust(loc.lat(), loc.lng(), this.headingOffset, this.pitchOffset);
			this.setState({ northSet: false });
		}
	}

	submitView() {
		this.toggleSetView();
		if (gPano) {
			const loc = gPano.getLocation().latLng;
			const pov = gPano.getPov();
			Server.set3DView(this.props.location, loc.lat(), loc.lng(), pov.heading + this.headingOffset, pov.pitch + this.pitchOffset, zoomToFOV(pov.zoom));
		}
	}

	submitElevation = (e) => {
		if (Server.checkLogin(e.currentTarget)) {
			Server.fixElevation(this.props.location, this.state.elevation);
			this.toggleFixElevation();
		}
	}

	submit = (e) => {
		if (Server.checkLogin(e.currentTarget)) {
			if (this.fixNorth)
				this.submitNorth();
			else
				this.submitView();
		}
	}

	setNewNorth = () => {
		if (gPano) {
			const pov = gPano.getPov();
			this.headingOffset = -(pov.heading - this.getDirFix());
			this.pitchOffset = -pov.pitch;
			this.onPovChange();
			this.setState({ northSet: true });
		}
	}

	closePopup = () => {
		if (this.fixNorth)
			this.toggleFixNorth();
		else
			this.toggleSetView();
	}

	toggleDirMenu = (e) => {
		if (this.state.dirMenuEl)
			this.setState({ dirMenuEl: null });
		else
			this.setState({ dirMenuEl: e.currentTarget });

		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
	}

	setFixDir = (e) => {
		this.setState({ fixDir: e.currentTarget.dataset.id });
		this.toggleDirMenu();
		requestAnimationFrame(() => { // So that state is updated
			this.onPovChange();
		});
	}

	onChangeElev = (e) => {
		try {
			const val = Number(e.currentTarget.value);
			if (!isNaN(val)) {
				this.setState({ elevation: val });
				this.scheduleDraw();
			}
		} catch (e) {
			// 
		}
	}

	onElevSliderChange = (e, value) => {
		this.setState({ elevation: this.state.elevationBase + value });
		this.scheduleDraw();
	}

	renderFixElevation() {
		var diff = (this.state.elevation - this.state.elevationBase) || 0;

		// const elev = this.props.location && this.props.location.elev;
		return (
			<div style={{ position: 'absolute', top: 8, left: 5, right: 5, marginLeft: 'auto', marginRight: 'auto', maxWidth: 'fit-content', zIndex: 5000 }}>
				<Paper style={{ opacity: 0.9, padding: 10 }}>
					<Typography variant='subtitle1' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<div style={{ marginBottom: 10 }}>
							{'Enter elevation of the Spot'}
							<IconButton onClick={this.toggleFixElevation} style={{ position: 'absolute', right: 4, top: 0 }}>
								<CloseIcon />
							</IconButton>
						</div>

						<div style={{ textAlign: 'center', width: '100%' }}>
							<TextField
								value={(this.state.elevation === undefined ? '' : this.state.elevation)}
								onChange={this.onChangeElev}
								margin='dense'
								style={{ marginLeft: 'auto', marginRight: 'auto', width: 100, marginTop: 0 }}
								placeholder='Elevation'
								variant='outlined'
								color='secondary'
								InputProps={{
									endAdornment: <InputAdornment position='end'><div style={{ width: '1em' }}>m</div></InputAdornment>,
									// style: { textAlign: 'right' },
								}}
							/>

							<Button
								style={{ position: 'absolute', right: 10 }}
								color='secondary'
								variant='contained'
								disabled={this.state.elevation === this.state.elevationBase}
								onClick={this.submitElevation}
							>
								{'Submit'}
							</Button>
						</div>

						<div style={{ fontSize: 13, marginBottom: -6, marginTop: 6 }}>
							{'Adjust by '}{diff === 0 ? '0' : diff > 0 ? '+' + diff : diff}	{' m'}
						</div>
						<Slider
							min={-300}
							max={300}
							step={1}
							track={false}
							value={diff}
							onChange={this.onElevSliderChange}
							style={{ width: 300, minWidth: '20vw' }}
						/>
					</Typography>
				</Paper>
			</div >
		);
	}

	renderFixNorth() {
		return (
			<div style={{ position: 'absolute', top: 8, left: 5, right: 5, marginLeft: 'auto', marginRight: 'auto', maxWidth: 'fit-content', zIndex: 5000 }}>
				<Paper style={{ opacity: 0.9, padding: 10 }}>
					<Typography variant='subtitle1'>
						<span style={{ marginLeft: 10, marginRight: 10 }}>
							{this.fixNorth ?
								<>
									{'Please rotate to the correct '}
									<a onClick={this.toggleDirMenu} style={{ cursor: 'pointer' }} href='/'>{this.state.fixDir}</a>
									{' orientation and confirm.'}
								</>
								:
								'Rotate and zoom to a typical composition from this Spot.'
							}
						</span>
						<HelpIcon style={{ marginLeft: -12, marginRight: 15 }} id={this.fixNorth ? 'fixNorth' : 'setView'} />
						{this.fixNorth &&
							<IconButton onClick={this.setNewNorth} color='secondary'>
								<CheckIcon />
							</IconButton>
						}
						<IconButton onClick={this.closePopup}>
							<CloseIcon />
						</IconButton>
					</Typography>

					<Menu
						anchorEl={this.state.dirMenuEl}
						open={this.state.dirMenuEl !== null}
						onClose={this.toggleDirMenu}
						variant='menu'
						anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
						transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
						getContentAnchorEl={null}
					>
						<MenuItem onClick={this.setFixDir} data-id='North'>{'North'}</MenuItem>
						<MenuItem onClick={this.setFixDir} data-id='East'>{'East'}</MenuItem>
						<MenuItem onClick={this.setFixDir} data-id='South'>{'South'}</MenuItem>
						<MenuItem onClick={this.setFixDir} data-id='West'>{'West'}</MenuItem>
					</Menu>

					<Collapse in={this.state.northSet || this.setView} mountOnEnter unmountOnExit>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Button color='secondary' variant='contained' onClick={this.submit}>{'Submit'}</Button>
						</div>
					</Collapse>
				</Paper>
			</div>
		);
	}

	renderMoreMenu() {
		return (
			<Menu
				anchorEl={this.state.moreMenuEl}
				open={this.state.moreMenuEl !== null}
				onClose={this.toggleMore}
				variant='menu'
				anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
				getContentAnchorEl={null}
			>
				<MenuItem onClick={this.toggleSetView}><ListItemIcon><PhotoSizeSelectActualIcon /></ListItemIcon>{'Set default view'}</MenuItem>
				<MenuItem onClick={this.toggleFixNorth}><ListItemIcon><NavigationIcon /></ListItemIcon>{'Fix North'}</MenuItem>
				<MenuItem onClick={this.toggleFixElevation}><ListItemIcon><HeightIcon /></ListItemIcon>{'Adjust Elevation'}</MenuItem>
				<Divider />
				<MenuItem onClick={this.toggleNearby} style={{ minWidth: 270 }}>
					<ListItemIcon><PlaceIcon /></ListItemIcon>
					{'Show Nearby Spots'}
					<ListItemSecondaryAction><Switch edge='end' size='small' onChange={this.toggleNearby} checked={Navigation.getShowNearby()} /></ListItemSecondaryAction>
				</MenuItem>
			</Menu>
		);
	}

	renderFocalMenu() {
		const afls = {
			'4/3': [7, 11, 14, 18, 22, 35, 50],
			'C APS-C': [10, 15, 18, 24, 35, 50, 70],
			'APS-C': [10, 16, 24, 35, 50, 70, 85],
			'FF': [15, 20, 24, 35, 50, 70, 85, 105],
			'MF': [23, 32, 45, 50, 64, 80, 110],
		};
		const fls = afls[Navigation.getCropFactor()];

		return (
			<Menu
				anchorEl={this.state.focMenuEl}
				open={this.state.focMenuEl !== null}
				onClose={this.toggleFocalMenu}
				variant='menu'
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
				getContentAnchorEl={null}
			>
				{/* Crop factor */}
				<FormControl style={{ paddingLeft: 16, paddingRight: 16, minWidth: 200, marginTop: 7 }}>
					<Select
						value={Navigation.getCropFactor()}
						onChange={this.changeCrop}
						variant='outlined'
					>
						<ListSubheader style={{ marginTop: -5, marginBottom: -5 }}>
							{'System/Crop factor'}
						</ListSubheader>
						<MenuItem value={'4/3'}>Four Thirds (2x)</MenuItem>
						<MenuItem value={'C APS-C'}>Canon APS-C (1.6x)</MenuItem>
						<MenuItem value={'APS-C'}>APS-C (1.5x)</MenuItem>
						<MenuItem value={'FF'}>35mm full frame (1.0x)</MenuItem>
						<MenuItem value={'MF'}>Medium format (0.79x)</MenuItem>
					</Select>
				</FormControl>

				{/* Lens header */}
				<ListSubheader style={{ marginTop: 10, marginBottom: -8, display: 'flex', alignItems: 'center' }}>
					<IconButton size='medium' onClick={this.lensMinus} style={{ width: 40, height: 40 }}>
						<RemoveIcon />
					</IconButton>
					<div style={{ flexGrow: 1 }} />
					{'Lens'}
					<div style={{ flexGrow: 1 }} />
					<IconButton size='medium' onClick={this.lensPlus} style={{ width: 40, height: 40 }}>
						<AddIcon />
					</IconButton>
				</ListSubheader>

				{/* Lens values */}
				{fls.map(fl =>
					<MenuItem onClick={this.setFocalLength} style={{ display: 'block', textAlign: 'center' }} data-id={fl} key={fl}>
						{fl + ' mm'}
					</MenuItem>
				)}
			</Menu>
		);
	}

	render() {
		const { classes } = this.props;

		return (
			<div
				className={this.state.gPointer ? classes.gPointer : ''}
				style={{ height: '100%', overflow: 'hidden', position: 'relative', backgroundColor: COLORS.PRIMARY_LIGHT, ...this.props.style }}
				ref={(ref) => this._root = ref}
			>
				<Fade in={this.fixNorth || this.setView} mountOnEnter unmountOnExit>
					{this.renderFixNorth()}
				</Fade>

				<Fade in={this.fixElevation} mountOnEnter unmountOnExit>
					{this.renderFixElevation()}
				</Fade>

				<Tooltip title='North' enterDelay={300} className={classes.btn} style={{ left: 8, top: 8 }}>
					<div className='ol-unselectable ol-control'>
						<button style={{ fill: 'white', outline: 'none' }} onClick={this.headNorth} onMouseDown={this.catchEvent}>
							<NavigationIcon style={{ transform: 'rotate(0deg)', transition: 'all 0.1s' }} ref={ref => this._compass = ref} />
						</button>
					</div>
				</Tooltip>

				<Tooltip title='Focal length' enterDelay={300} className={classes.btn} style={{ left: 54, top: 8 }}>
					<div className={'ol-unselectable ol-control ' + classes.btn} style={{ left: 54, top: 8 }}>
						<button style={{ fill: 'white', outline: 'none' }} onClick={this.toggleFocalMenu} onMouseDown={this.catchEvent}>
							<div style={{ fontSize: 18, marginBottom: 5, marginTop: -3 }} ref={ref => this._mm = ref}>{''}</div>
							<div style={{ fontSize: 10, marginBottom: -8 }}>{'mm'}</div>
						</button>
					</div>
				</Tooltip>

				<Tooltip title='More...' enterDelay={300} className={classes.btn} style={{ left: 8, top: 54 }}>
					<div className='ol-unselectable ol-control'>
						<button style={{ fill: 'white', outline: 'none' }} onClick={this.toggleMore} onMouseDown={this.catchEvent}>
							<MoreIcon />
						</button>
					</div>
				</Tooltip>

				{!this.fixNorth && !this.setView && !this.fixElevation &&
					<Tooltip title='Move this direction' enterDelay={300} className={classes.btn} style={{ left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto', top: 8 }}>
						<div className='ol-unselectable ol-control'>
							<button style={{ fill: 'white', outline: 'none' }} onClick={this.moveForward} onMouseDown={this.catchEvent}>
								<UpIcon />
							</button>
						</div>
					</Tooltip>
				}

				<div style={{ display: 'none' }}>
					<img src={completeLocalURL('/obj/moon.png')} alt='' ref={r => this._moon = r} />
					<img src={completeLocalURL('/obj/sun.png')} alt='' ref={r => this._sun = r} />
					<img src={completeLocalURL('/obj/sunR.png')} alt='' ref={r => this._sunR = r} />
				</div>
				<canvas className={classes.canvas} ref={r => this._canvas1 = r} />
				<canvas className={classes.canvas} ref={r => this._canvas2 = r} />

				{this.renderFocalMenu()}
				{this.renderMoreMenu()}
			</div>
		);
	}
}

VR360.propTypes = {
	classes: PropTypes.object.isRequired,
	style: PropTypes.object,
	location: PropTypes.object.isRequired,
};

/** @type {any} */
// @ts-ignore
export default withStyles(styles)(VR360);