//@ts-check
import React from 'react';

import ImageWithTitle from './ImageWithTitle';

import Server from '../server';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation as SwiperNavigation, Scrollbar as SwiperScrollbar, FreeMode as SwiperFreeMode } from 'swiper';
import './Swiper/swiper-bundle.css';

// Install modules
SwiperCore.use([SwiperNavigation, SwiperScrollbar, SwiperFreeMode]);

function TagsBar(props) {
	return (
		<div /*className={classes.tags}*/ style={{ padding: 10, overflow: 'hidden', width: 'calc(100% - 4px)', marginLeft: -16, marginRight: -10, marginTop: -20, marginBottom: -25, fontSize: 15, paddingBottom: 20 }}>
			<Swiper
				className='swiper-container swiper-container-horizontal'
				slidesPerView={'auto'}
				slidesPerGroup={1}
				slidesPerGroupAuto={true}
				updateOnImagesReady={true}
				preloadImages={true}
				freeMode={true}
				navigation={{}}
				scrollbar={{ draggable: true, hide: true }}
				style={{ width: '100%', overflow: 'visible' }}
			>
				{props.tags.map((tag, index) => {
					if (tag.photo)
						return (
							<SwiperSlide key={tag.tag}>
								<ImageWithTitle
									title={tag.tag}
									hierarchy={tag.locHier}
									imageURL={Server.getImageThumb({ id: tag.photo })}
									blurhash={tag.blurhash}
									style={{ marginRight: (index + 1 === props.tags.length) ? 0 : 15 }}
								/>
							</SwiperSlide>
						);
					else
						return null;
				})}
			</Swiper>
		</div>
	);
}

export default TagsBar;