// @ts-check
/* eslint-disable no-console */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Skeleton from '@material-ui/lab/Skeleton';

import Server from '../server';
import Navigation from '../navigation';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation as SwiperNavigation } from 'swiper';
import './Swiper/swiper-bundle.css';

// Install modules
SwiperCore.use([SwiperNavigation]);

const styles = /*theme =>*/ ({
	imgbox: {
		cursor: 'pointer',
		position: 'relative',
		width: 'auto',
	},
});

class ImagesSwiped extends Component {
	state = {
		image: null,
		maxImages: 5,
	}
	_swiper = null;

	componentWillUnmount() {
		this._swiper = undefined;
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.images !== this.props.images) {
			this.setState({ maxImages: 5 });
			return true;
		}

		if (nextState.maxImages !== this.state.maxImages)
			return true;

		return false;
	}

	componentDidUpdate() {
		if (this._swiper) {
			this._swiper.imagesLoaded = 0;
			this._swiper.preloadImages();
		}
	}

	getImageURL = (index) => {
		const image = this.getImages()[index];
		return Navigation.getPhotoURL(image);
	}

	getImages = () => {
		return this.props.images;
	}

	imgClick = (event) => {
		const index = event.currentTarget.dataset.index;
		const image = this.getImages()[index];
		if (this.props.openLocation) {
			// Open the location
			if (!image.location) {
				console.error('image location expected!');
				return;
			}

			Navigation.goSpot(image.location);
		} else {
			// Open the image
			Navigation.openImage(image);
		}

		event.preventDefault();
		event.stopPropagation();
	}

	getImageByID(idImg) {
		const images = this.getImages();
		return images.find(img => img.id === idImg);
	}

	swiperClick = (e) => {
		// Avoid bubbling click event up (from swiper navigation)
		e.stopPropagation();
		e.preventDefault();
	}

	onSlideChange = () => {
		if (this._swiper) {
			if (this._swiper.activeIndex + 5 > this.state.maxImages) {
				this.setState({ maxImages: this._swiper.activeIndex + 4 });
			}
		}
	}

	renderImage = (img, index) => {
		const { classes } = this.props;
		const height = this.props.height || 200;

		return (
			<SwiperSlide
				className={'swiper-slide ' + classes.imgbox}
				data-index={index}
				// tag='a'
				// href={this.getImageURL(index)}
				onClick={this.imgClick}
				key={'image' + img.id}
				style={{ height: height, pointerEvents: 'all' }}
			>
				<img
					src={Server.getImageThumb(img)}
					data-index={index}
					alt={img.title}
					style={{ height: height }}
				/>
			</SwiperSlide>
		);
	}

	render() {
		const height = this.props.height || 200;
		const allImages = this.getImages();
		let images = allImages;
		if (images)
			images = images.slice(0, this.state.maxImages);
		const dummies = new Array(Math.min(5, ((allImages || []).length || 5) - (images || []).length)).fill(null);

		return (
			<div onClick={this.swiperClick}>
				<Swiper
					className='swiper-container swiper-container-horizontal'
					spaceBetween={this.props.spacing || 1}
					slidesPerView={'auto'}
					slidesPerGroup={1}
					slidesPerGroupAuto={true}
					updateOnImagesReady={true}
					preloadImages={true}
					navigation={{}}
					style={{ width: '100%', ...this.props.style }}
					onSwiper={(swiper) => { this._swiper = swiper; }}
					onSlideChange={this.onSlideChange}
				>
					{images && images.map((img, index) =>
						this.renderImage(img, index)
					)}

					{dummies.map((i, index) => {
						return (
							<SwiperSlide
								className={'swiper-slide '}
								style={{ width: 1.5 * height }}
								key={'dummy' + index}
							>
								<Skeleton height={height} width={1.5 * height} variant='rect' animation='wave' />
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		);
	}
}

ImagesSwiped.propTypes = {
	classes: PropTypes.object.isRequired,
	style: PropTypes.object,
	images: PropTypes.array,
	height: PropTypes.number,
	spacing: PropTypes.number,
	location: PropTypes.object,
	openLocation: PropTypes.bool, // Open the location instead of the image	
};

/** @type {any} */
// @ts-ignore (some silly style check?)
export default withStyles(styles)(ImagesSwiped);
