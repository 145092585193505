// @ts-check

import qs from 'query-string';
import Server from './server';
import PubSub from 'pubsub-js';
import { titleForURL, string2LatLong, roundGPS, openNewTab } from './tools';

var history;
var cookies;
var path = '/';
var newPath;
var state = {};
/** @type{any} */
var hiddenState = { moon: true, shadows: true, large: true, paths: true, nearby: true };
var latitude;
var longitude;
var zoom;
var haveHistory; // Whether there was any navigation made in Phoide
var range;

var locID;
var searchValue;
var UID = 0; // Unique id to be used by components

var mapLayer = (!process.env.REACT_APP_SERVER_SIDE && localStorage.getItem('cfgLayer')) || 'default';

PubSub.subscribe('POINT_UPDATED', (msg, data) => {
	const title = data.point && data.point.title;
	if (title && path.match(/\/l\/_\//)) {
		newPath = '/l/' + titleForURL(title) + path.slice(4);
		Navigation.go(true); // Replace title in the URL
	}
});

class Navigation {
	static update(aLocation, aHistory, aCookies) {
		// console.log('New path: ' + aLocation.pathname);
		path = aLocation.pathname;
		newPath = path;
		const search = aLocation.search;
		state = qs.parse(search);
		history = aHistory;
		cookies = aCookies;

		locID = undefined;

		if (process.env.REACT_APP_CORDOVA && path.slice(-11) === '/index.html') {
			// Handle properly Cordova app home screen
			Navigation.goHome();
			return;
		}

		// Handle '/i/123' navigation
		var res = /^\/i\/(\d+)/.exec(path);
		if (res && res[1]) {
			locID = res[1];
			path = 'l/_/' + locID;
		}

		// Handle '/l/Title/123' navigation
		res = /^\/l\/.*\/(\d+)/.exec(path);
		if (res && res[1]) {
			locID = res[1];
		}

		// Handle '/login' navigation
		res = /^\/login/.exec(path);
		if (res) {
			newPath = '/';
			state.login = null;
			Navigation.go();
		}

		// Handle unsubscribe
		res = /^\/unsubscribe/.exec(path);
		if (res) {
			newPath = '/';
			hiddenState.unsubscribe = null;
			Navigation.go();
		}

		// Handle regions
		res = /^\/r\/([0-9-.]+)\/([0-9-.]+)\/([0-9-.]+)\/([0-9-.]+)/.exec(path);
		if (res && res[1])
			range = { lat: Number(res[1]), long: Number(res[2]), dLat: Number(res[3]), dLong: Number(res[4]) };
		else
			range = null;
		PubSub.publishSync('RANGE_CHANGE', range);

		// Handle '/photo/abc-456' navigation
		res = /^\/photo\/(.+)/.exec(path);
		if (res && res[1])
			state.photo = res[1];

		res = /^\/newPhoto/.exec(path);
		if (res) {
			locID = state.l;
		}

		// Parse Search
		res = /^\/search\/(.+)/.exec(path);
		if (res && res[1]) {
			searchValue = res[1];
		} else
			searchValue = undefined;

		if (state.welcome !== undefined) {
			delete state.welcome;
			setTimeout(() => { // Wait a little to make sure we are fully running.
				PubSub.publish('SHOW_SNACKBAR', {
					message: 'Welcome to Phoide.com! Feel free to update your profile or browse the best photo spots...',
					autoHide: 8000,
				});
			}, 500);
		}
	}

	static init(aLocation, aHistory, aCookies) {
		UID = 0; // To be consistent accross rendering
		Navigation.update(aLocation, aHistory, aCookies);
		if (cookies) {
			const auth = cookies.get('auth');
			if (auth)
				Server.setAuth(cookies.get('auth'));
		}
	}

	static getUID() {
		return UID++;
	}

	static go(replace) {
		const params = {
			pathname: newPath,
			search: qs.stringify(state),
		};

		if (replace)
			history.replace(params);
		else {
			history.push(params);

			// Google Analytics
			// @ts-ignore			
			// eslint-disable-next-line no-undef
			if (window.ga) {
				// @ts-ignore
				// eslint-disable-next-line no-undef
				ga('set', 'page', newPath);
				// @ts-ignore
				// eslint-disable-next-line no-undef
				ga('send', 'pageview');
			}
		}
		haveHistory = true;
	}

	static getPath() {
		return path + '?' + qs.stringify(state);
	}

	static isHome() {
		return (!path || path === '/') && !Navigation.getPointID();
	}

	static goHome() {
		newPath = '/';
		Navigation.go();
	}

	static login() {
		state.login = null;
		Navigation.go();
	}

	static isLoginDialog() {
		return (state.login !== undefined) || (path === '/login');
	}

	static closeLogin() {
		delete state.login;
		if (path === '/login')
			newPath = '/';
		Navigation.go();
	}

	static isUnsubscribeDialog() {
		return (hiddenState.unsubscribe !== undefined);
	}

	static goPath(aPath) {
		newPath = aPath;
		delete state.photo; // To make sure photo doesn't stay open while navigating somewhere
		Navigation.go();
	}

	static canGoBack() {
		return haveHistory;
	}

	static goBack() {
		history.go(-1);
	}

	static logout() {
		cookies.remove('auth');
		cookies.remove('authF');
		Server.logout();
	}

	static closeDialogs() {
		// TODO: Don't hardcode these?
		delete state.photo;
		delete state.userPhoto;
		delete state.newPhoto;
		delete hiddenState.unsubscribe;
	}

	static goSpot(spot/*, silent*/) {
		Navigation.fixMapPosition(false);
		if (spot)
			newPath = Navigation.getLocationURL(spot);
		else {
			newPath = '/map';
			delete state['360']; // Don't show VR when we don't have a spot selected
		}
		if (path === '/map') // If going from map, keep the large map
			state.map = null;
		delete state.l; // Only useful for new photo upload
		Navigation.closeDialogs();
		Navigation.go();
	}

	static setSpot(spot) {
		if (spot)
			state.l = spot.id;
		else {
			delete state.l;
			delete state['360'];
		}
		Navigation.go(true);
	}

	static goRegion(lat, long, dLat, dLong) {
		if (lat === undefined)
			newPath = '/map';
		else
			newPath = `/r/${roundGPS(lat)}/${roundGPS(long)}/${roundGPS(dLat)}/${roundGPS(dLong)}`;
		if (path === '/map') // If going from map, keep the large map
			state.map = null;
		Navigation.go();
	}

	static newPhoto(photoData) {
		Navigation.fixMapPosition(false);
		newPath = '/newPhoto';
		if (locID)
			state.l = locID;
		else
			delete state.l;
		// @ts-ignore
		if (window.isMobile)
			delete state.map;
		hiddenState.photoData = photoData;
		Navigation.closeDialogs();
		Navigation.go();
	}

	static getNewPhotoData() {
		const res = hiddenState.photoData;
		delete hiddenState.photoData; // Not needed anymore after the first retrieval by Photo Upload page
		return res;
	}

	static getPointID() {
		const num = Number(locID);
		return num >= 0 ? num : null;
	}

	static setMapPosition(lat, long, aZoom) {
		latitude = lat;
		longitude = long;
		zoom = aZoom;
	}

	static getMapPosition() {
		return {
			latitude,
			longitude,
			zoom,
		};
	}

	static getRange() {
		return range;
	}

	// ** **

	static getValue(name) {
		return state[name];
	}

	// ** Fix position **
	static showGPS(value) {
		if ((value && state.showGPS === null) ||
			(!value && state.showGPS === undefined))
			return;

		if (value)
			state.showGPS = null;
		else
			delete state.showGPS;

		Navigation.go(true);
	}

	static getShowGPS() {
		return (state.showGPS !== undefined);
	}

	static fixMapPosition(value) {
		if ((value && state.fixPosition === null) ||
			(!value && state.fixPosition === undefined))
			return;

		if (value)
			state.fixPosition = null;
		else
			delete state.fixPosition;

		Navigation.go(true);
	}

	static getFixMapPosition() {
		return (state.fixPosition !== undefined);
	}

	static isImport() {
		return path.startsWith('/import');
	}

	// ** Fix photo position
	static fixPhotoSpot(idPhoto) {
		if (idPhoto)
			state.fixPhoto = idPhoto;
		else
			delete state.fixPhoto;

		Navigation.go(true);
	}

	static getFixPhotoSpot() {
		return state.fixPhoto;
	}

	// ** Search **
	static search(value) {
		if (value) {
			const loc = string2LatLong(value);
			if (loc) {
				Navigation.openMapPage();
				PubSub.publish('CENTER_COORDS', { point: loc });
				return;
			}

			newPath = '/search/' + encodeURIComponent(value);
			Navigation.go();
		}
	}

	static cancelSearch() {
		if (path && path.match(/^\/search\//))
			Navigation.goBack();
	}

	static getSearch() {
		return searchValue;
	}

	// ** Lightbox **
	static openPhoto(idPhoto) {
		state.photo = idPhoto;
		Navigation.go();
	}

	static closePhoto(openName) {
		if (haveHistory)
			history.go(-1);
		else {
			delete state[openName || 'photo'];
			Navigation.go(true);
		}
	}

	static isFlickr(img) {
		return Boolean(img.owner);
	}

	static getPhotoURL(image) {
		if (image.linkURL && image.license !== 'unsplash')
			return image.linkURL;
		else if (Navigation.isFlickr(image)) {
			return `https://www.flickr.com/photos/${image.owner}/${image.id}`;
		} else {
			return `/photo/${image.id}`;
		}
	}

	static isOurImg(image) {
		if (!image)
			return false;
		return (!Navigation.isFlickr(image) && !(image.linkURL && image.license !== 'unsplash'));
	}

	static openImage(image) {
		if (!image)
			return;

		if (Navigation.isFlickr(image)) {
			openNewTab(`https://www.flickr.com/photos/${image.owner}/${image.id}`);
		} else {
			if (image.linkURL && image.license !== 'unsplash')
				openNewTab(image.linkURL);
			else
				Navigation.openPhoto(image.id);
		}
	}

	static isLightbox() {
		return Boolean(state.photo);
	}

	static getPhotoID() {
		return state.photo;
	}

	static isPhotoPage() {
		const res = /^\/photo\/(.+)/.exec(path);
		return Boolean(res && res[1]);
	}

	// **

	static showProfile(username, tab) {
		newPath = `/by/${username}${tab ? '/' + tab : ''}`;
		Navigation.closeDialogs();
		Navigation.go(tab ? true : false);
	}

	// *** Descriptions ***

	static editDescription(edit, id) {
		if (edit) {
			state.editd = id;
		} else
			delete state.editd;

		Navigation.go();
	}

	static addDescription() {
		Navigation.editDescription(true, -1);
	}

	static getEditDescID() {
		return state.editd && Number(state.editd);
	}

	// *** Guides ***

	static editGuide(edit, id) {
		if (edit) {
			state.editg = id;
		} else
			delete state.editg;

		Navigation.go();
	}

	static getEditGuideID() {
		return state.editg && Number(state.editg);
	}

	static openGuide(perma, replace) {
		newPath = '/guide/' + perma;
		Navigation.go(replace);
	}

	// *** Map ***

	static showMap(show) {
		if (show)
			state.map = null;
		else {
			delete state.map;
			if (Navigation.getWeather())
				Navigation.toggleWeather();
			if (Navigation.getSpotBar())
				Navigation.toggleSpotBar();
		}

		Navigation.go(true);
	}

	static isLargeMap() {
		return state.map !== undefined;
	}

	static ToggleMap() {
		const match = path.match(/^\/([^/]*)/);
		const nav = match && match[1];

		if (nav === 'map') {
			newPath = '/';
			delete state.map;
			Navigation.go();
		} else if (nav === 'tag' || nav === 'photo_location' || nav === 'search' || nav === 'by') {
			newPath = '/map';
			state.map = null;
			Navigation.go();
		} else
			Navigation.showMap(state.map === undefined);
	}

	static openMapPage(track, boundingBox) {
		newPath = '/map';
		if (track)
			state.track = null;
		else
			delete state.track;
		state.map = null;

		Navigation.go();

		if (boundingBox) {
			PubSub.publish('MAP_BOUNDING_BOX', { boundingBox });
		}
	}

	static getGeoTrack() {
		return (state.track === null);
	}

	static getMapLayer() {
		return mapLayer;
	}

	static setMapLayer(layer) {
		localStorage.setItem('cfgLayer', layer);
		mapLayer = layer;
	}

	// *** Tags ***

	static getTagURL(tagName, tagHierarchy) {
		var nav;
		if (typeof tagHierarchy === 'string') {
			tagHierarchy = tagHierarchy.replace('\\', '/');
			if (tagHierarchy.length && !tagHierarchy.endsWith('/'))
				tagHierarchy += '/';
			nav = 'photo_location';
		} else {
			tagHierarchy = '';
			nav = 'tag';
		}
		return tagName && `/${nav}/${(tagHierarchy + encodeURIComponent(tagName.replace(/\s/g, '_'))).replace(/\s/g, '_')}`;
	}

	static openTag(tagName, tagHierarchy) {
		newPath = Navigation.getTagURL(tagName, tagHierarchy);
		Navigation.go();
	}

	static getCurrentTag(forUI) {
		let m = /^\/tag\/([^/?]+)/.exec(path);
		let res = m && m[1];
		if (!res) {
			let m = /^\/photo_location\/(?:[^/?]+\/)*?(?:([^/?]+)\/)?([^/?]+)\/?$/.exec(path);
			if (m) {
				res = [m[1] || '', m[2]].join('$');
			}
		}
		if (res && forUI)
			res = res.replace(/_/g, ' ');
		return res;
	}

	static getCurrentGuide() {
		let m = /^\/guide\/([^/?]*)(?:\?t=(.*))?/.exec(path);
		if (!m)
			return null;
		return { name: m[1], tag: m[2] };
	}

	// *** Spots ***

	static getLocationURL(location) {
		return `/l/${titleForURL(location.title)}/${location.id}`;
	}

	// *** UI ***
	static getWeather() {
		return state.w !== undefined;
	}

	static toggleWeather() {
		if (Navigation.getWeather())
			delete state.w;
		else {
			state.w = null;
			if (!Navigation.isLargeMap())
				Navigation.showMap(true);
		}
		PubSub.publish('LIGHT_PANEL_CHANGE', { visible: Navigation.getWeather() });
		Navigation.go(true);
	}

	static get360() {
		return state['360'] !== undefined;
	}

	static toggle360() {
		if (Navigation.get360())
			delete state['360'];
		else {
			state['360'] = null;
			if (!Navigation.isLargeMap())
				Navigation.showMap(true);
		}
		// PubSub.publish('LIGHT_PANEL_CHANGE', { visible: Navigation.getWeather() });
		Navigation.go(true);
	}

	static getSpotBar() {
		return state.sb !== undefined;
	}

	static toggleSpotBar() {
		if (Navigation.getSpotBar())
			delete state.sb;
		else {
			state.sb = null;
			if (!Navigation.isLargeMap())
				Navigation.showMap(true);
		}
		Navigation.go(true);
	}

	static toggleMoon() {
		if (Navigation.getShowMoon())
			delete hiddenState.moon;
		else {
			hiddenState.moon = null;
		}
		PubSub.publish('LIGHT_PANEL_CHANGE', { visible: Navigation.getWeather() });
	}

	static getShowMoon() {
		return hiddenState.moon !== undefined;
	}

	static togglePaths() {
		if (Navigation.getPaths())
			delete hiddenState.paths;
		else {
			hiddenState.paths = null;
		}
		PubSub.publish('LIGHT_PANEL_CHANGE', { visible: Navigation.getWeather() });
	}

	static getPaths() {
		return hiddenState.paths !== undefined;
	}

	static toggleShowNearby() {
		if (Navigation.getShowNearby())
			delete hiddenState.nearby;
		else {
			hiddenState.nearby = null;
		}
	}

	static getShowNearby() {
		return hiddenState.nearby !== undefined;
	}

	static toggleEnlarge() {
		if (Navigation.getEnlarge())
			delete hiddenState.large;
		else {
			hiddenState.large = null;
		}
		PubSub.publish('LIGHT_PANEL_CHANGE', { visible: Navigation.getWeather() });
	}

	static getEnlarge() {
		return hiddenState.large !== undefined;
	}

	static toggleShadows() {
		if (Navigation.getShadows())
			delete hiddenState.shadows;
		else {
			hiddenState.shadows = null;
		}
		PubSub.publish('LIGHT_PANEL_CHANGE', { visible: Navigation.getWeather() });
	}

	static getShadows() {
		return hiddenState.shadows !== undefined;
	}

	static setCropFactor(value) {
		hiddenState.crop = value;
	}

	static getCropFactor() {
		return hiddenState.crop || 'FF';
	}

	static getCropValue() {
		const crops = { '4/3': 2, 'C APS-C': 1.6, 'APS-C': 1.5, 'FF': 1, 'MF': 0.79 };
		return crops[Navigation.getCropFactor()];
	}

	// *** Panels ***

	static setExpandedPanel(name, value) {
		const panels = cookies.get('panels') || {};
		panels[name] = value;
		cookies.set('panels', panels);
	}

	static getExpandedPanel(name) {
		if (process.env.REACT_APP_SERVER_SIDE)
			return; // TODO: Server side cookies handling?

		const panels = cookies.get('panels') || {};
		return panels[name];
	}

	static setExpandedNearbySpots(value) {
		Navigation.setExpandedPanel('nearbyspots', value);
	}

	static getExpandedNearbySpots(def) {
		const res = Navigation.getExpandedPanel('nearbyspots');
		return (res === undefined ? def : res);
	}

	static setExpandedNearbyWebcams(value) {
		Navigation.setExpandedPanel('nearbywebcams', value);
	}

	static getExpandedNearbyWebcams(def) {
		const res = Navigation.getExpandedPanel('nearbywebcams');
		return (res === undefined ? def : res);
	}

	static setDaylightWebcams(value) {
		Navigation.setExpandedPanel('daylight', value);
	}

	static getDaylightWebcams(def) {
		const res = Navigation.getExpandedPanel('daylight');
		return (res === undefined ? def : res);
	}

	static getExpandedMap(def) {
		const res = Navigation.getExpandedPanel('map');
		return (res === undefined ? def : res);
	}

	static setExpandedMap(value) {
		Navigation.setExpandedPanel('map', value);
	}

	// *** Spotlists ***
	static goSpotlist(username, spotlist, tab) {
		newPath = Navigation.hrefSpotlist(username, spotlist, tab);
		Navigation.go();
	}

	static hrefSpotlist(username, spotlist, tab) {
		if (!username)
			username = Server.getLoggedUser().username;

		return `/by/${username}/gallery/${spotlist}` + (tab ? `/${tab}` : '');
	}

	// *** Users ***
	static getUserURL(username) {
		return `/by/${username}`;
	}

	static changeUsername(newUsername) {
		newPath = path.replace(/\/by\/[^/]*/gi, `/by/${newUsername}`);
		Navigation.go(true);
	}

	// *** Filters ***
	static internalClearFilters() {
		delete state.ft; // Tag
		delete state.fu; // User
		delete state.fs; // Search
		delete state.fsl; // Spotlist
		delete state.fsu; // Spotlist user (creator)
	}

	static clearFilters() {
		Navigation.internalClearFilters();
		Navigation.go(true);
		PubSub.publish('MAP_SPOT_SET', { points: null });
	}

	static setFilter(filterType, filter, spots) {
		Navigation.internalClearFilters();
		state.map = null; // To open larger map on click
		state[filterType] = filter;
		PubSub.publish('MAP_SPOT_SET', { points: spots });
	}

	static setSpotlistFilter(spotlist, username, spots) {
		Navigation.setFilter('fsl', spotlist, spots);
		state.fsu = username;
	}

	static getFilter() {
		if (state.ft)
			return { ft: state.ft };
		if (state.fu)
			return { fu: state.fu };
		if (state.fs)
			return { fs: state.fs };
		if (state.fsl)
			return { fsl: state.fsl, fsu: state.fsu };

		return null;
	}
}

export default Navigation;