//@ts-check
/* eslint-disable no-console */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import MapView from '../MapView';
import IconButton from '@material-ui/core/IconButton';
import TagItems from './TagItems';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HelpIcon from './HelpIcon';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Collapse from '@material-ui/core/Collapse';

import LinkIcon from '@material-ui/icons/Code';
import RemoveIcon from '@material-ui/icons/Close';

import ImagesChooser from './ImagesChooser';
import Editor from './Editor';
import Exif from './Exif';
import SpotsBar from './SpotsBar';

import Server from '../server';
import PubSub from 'pubsub-js';

import Navigation from '../navigation';

import { roundGPS, getLocationTags, setPageTitle } from '../tools';
import SpotItem from './SpotItem';

const style = theme => ({
	uploadRoot: {
		paddingTop: theme.spacing(3),
	},
	imageTarget: {
		width: 230,
		height: 230,
		border: '2px solid ' + theme.palette.divider,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	image: {
		height: 230,
		maxWidth: 500,
	},

	spotBottom: {
		marginTop: 20,
	},
	spotRight: {
		flexGrow: 1,
		flexBasis: 300,
		marginLeft: 20,
		maxWidth: 'calc(50% - 10px)',
	},

	descHeader: theme.typography.caption,
	descComment: theme.typography.caption,

	spotCard: {
		width: 270,
		height: 193,
		marginRight: 15,
		display: 'inline-block',
		verticalAlign: 'middle',
	},

	spotCardWrap: {
		width: 'auto',
	},

	spotSelected: {
		borderWidth: 1.5,
		borderStyle: 'solid',
		borderColor: theme.palette.secondary.main,
	},

	mapBaseNew: {
		height: 400,
		width: 500,
		flexBasis: 500,
		flexShrink: 0,
	},

	textPaper: {
		width: '100%',
		marginTop: 20,
		paddingLeft: 10,
		paddingRight: 10,
		boxSizing: 'border-box',
	},

	lightLabel: {
		opacity: 0.6,
	},

	sectionHeader: {
		marginBottom: 7,
	},
});

var uploadInstance;
var _exifr = null;

PubSub.subscribe('POINT_MOVED', (msg, data) => {
	var process = function () {
		if (Navigation.getPath().startsWith('/newPhoto')) {
			if (!uploadInstance) {
				if (Navigation.isLargeMap())
					Navigation.showMap(false); // Hide large map when a spot was chosen
				setTimeout(process, 10);
				return;
			}

			if (!data.newSpot)
				uploadInstance.scrollToMap();
			uploadInstance.setNewGPS(data.lat, data.long);
			getLocationTags(uploadInstance.state.spot, data.lat, data.long);
		}
	};

	if (data.id === 0) {
		process();
	}
});

PubSub.subscribe('NEW_SPOT_SELECT', (msg, data) => {
	var process = function () {
		if (Navigation.getPath().startsWith('/newPhoto')) {
			if (!uploadInstance) {
				if (Navigation.isLargeMap())
					Navigation.showMap(false); // Hide large map when a spot was chosen
				setTimeout(process, 10);
				return;
			}

			uploadInstance.scrollToMap();
			uploadInstance.setExistingSpot(data.id, data.lat, data.long);
		}
	};

	process();
});

class NewPhotoUploadBase extends Component {
	/** @type{any} */
	upload = null; // Ref to the Browse files button
	currentMarkdown = '';
	state = {
		tab: 'upload',
		submitting: false,
		hasImage: false,

		imgURL: null,
		title: '',
		photoAuthor: '',
		imageID: null,

		showImgURL: false, // Show Link Image URL text field
		/** @type{any} */
		linkedImages: null,
		linkURL: '', // Url of the linked page
		linkImage: '', // Url of the image to link (from the linked page)

		nearby: [],
		/** @type{any} */
		selSpot: null,
		lat: null,
		long: null,
		exif: {},
		testShot: false,

		spot: {
			id: 0,
			tags: [],
		},
		spotTitle: '',
	}
	_editedExif = {};
	/** @type{any} */
	_tabRef = null;

	componentDidMount() {
		uploadInstance = this;
		setPageTitle('New Photograph');

		const { location } = this.props;
		if (location) {
			this.setExistingSpot(location.id, location.lat, location.long);
			// PubSub.publish('CENTER_COORDS', { point: location }); // Don't center selected spot, so that we don't lose the current map position
		}

		// Accept images while dropped anywhere
		document.addEventListener('dragover', this.handleDragOver);
		document.addEventListener('drop', this.handleDrop);

		this.getExifr();

		const newPhotoData = Navigation.getNewPhotoData();
		if (newPhotoData) {
			this.readFile(newPhotoData);
		}
	}

	componentWillUnmount() {
		uploadInstance = undefined;

		document.removeEventListener('dragover', this.handleDragOver);
		document.removeEventListener('drop', this.handleDrop);
	}

	checkHideMap() {
		if (this.props.mobile && this.props.large)
			Navigation.showMap(false); // Hide large map when a spot was chosen
	}

	scrollToMap() {
		setTimeout(() => {
			// @ts-ignore
			this._map.scrollIntoView(true, { behavior: 'smooth' });
		}, 30);
	}

	async setExistingSpot(id, lat, long) {
		if (!id)
			return;

		this.setState({ selSpot: { id: id, lat, long }, spotSelect: 'existing', mapMissing: false });
		const selPreview = await Server.getPointPreview(id);
		if (selPreview && this.state.selSpot && this.state.selSpot.id === selPreview.id) {
			this.setState({ selSpot: selPreview, mapMissing: false });
		}
		if (lat || long) {
			Server.getNearbySpots(lat, long).then((nearby) => {
				this.setState({ nearby: nearby.filter(s => Number(s.id) !== Number(id)) });
			});
		}
	}

	setUpload = (ref) => {
		this.upload = ref;

		if (this.props.browseNow) {
			setTimeout(() => { // Give browser time to render this dialog first (for Edge)
				this.upload.click();
			}, 10);
		}
	}

	handleDragOver = (e) => {
		e.stopPropagation();
		e.preventDefault();
		e.dataTransfer.dropEffect = this.state.hasImage ? 'none' : 'copy';
	}

	handleDrop = (e) => {
		e.stopPropagation();
		e.preventDefault();

		if (this.state.hasImage)
			return;

		let dt = e.dataTransfer;
		let files = dt.files;

		if (dt.files[0])
			this.readFile(files[0]);
	}

	handleFileBrowsed = () => {
		this.readFile(this.upload.files[0]);
	}

	async getExifr() {
		if (!_exifr)
			_exifr = await import('exifr');
		return _exifr;
	}

	readFile = (file) => {
		var reader = new FileReader();
		this.setState({ hasImage: true });

		reader.onload = (e) => {
			// @ts-ignore (e.target.result exists)
			this.setState({ imgURL: e.target.result });
			this.getExifr().then(exifr =>
				// @ts-ignore
				exifr.parse(e.target.result).then((exif) => {
					if (!exif)
						return;

					exif.Date = exif.DateTimeOriginal;
					exif.Exposure = exif.ExposureTime;
					exif.Focal = exif.FocalLength;
					exif.Focal35 = exif.FocalLengthIn35mmFilm || exif.FocalLengthIn35mmFormat;
					exif.Lens = exif.LensModel;
					this.setState({ exif });
					if (exif.latitude && exif.longitude)
						this.setNewGPS(exif.latitude, exif.longitude);
				})
			);
			// @ts-ignore
			reader.onload = undefined; // Necessary to properly unload the handler?
		};

		reader.readAsDataURL(file);

		var title = file.name || '';
		if (title === 'undefined')
			title = '';
		// Strip path
		var index = title.lastIndexOf('/');
		if (index >= 0)
			title = title.substr(index + 1);
		// Strip extension
		index = title.lastIndexOf('.');
		if (index >= 0)
			title = title.substr(0, index);
		if (this.state.title === '') {
			this.setState({ title: title });
		}

		if (this.upload)
			this.upload.value = null;

		// Perform the image upload
		Server.uploadImage(file).then(imageID => {
			this.setState({
				imageID: imageID,
				photoMissing: false,
			});
		}).catch(e => {
			console.log(`Failed upload: ${e}`);
			this.setState({ uploadError: e });
		});
	}

	onErrClose = () => {
		this.setState({ uploadError: null });
	}

	onRemoveImage = () => {
		this.setState({
			hasImage: false,
			imageID: null,
			imgURL: null,
		});
	}

	handleEditorChange = (newMarkdown) => {
		this.currentMarkdown = newMarkdown;
	}

	handleTitleChange = (event) => {
		this.setState({ title: event.currentTarget.value });
	}

	handleAuthorChange = (event) => {
		this.setState({ photoAuthor: event.currentTarget.value });
	}

	handleUpload = () => {
		if ((this.state.tab === 'upload' && this.state.imageID === null)) {
			this.setState({ photoMissing: true });
			this._tabRef && this._tabRef.scrollIntoView({ behavior: 'smooth' });
			return;
		}

		if (!(this.state.tab === 'link' && this.state.linkURL && this.state.linkImage)) {
			this.setState({ linkMissing: true });
			this._tabRef && this._tabRef.scrollIntoView({ behavior: 'smooth' });
		}

		if (!this.state.selSpot && this.state.lat === null) {
			this.setState({ mapMissing: true });
			return;
		}

		if (!this.state.selSpot && this.state.lat !== null && !this.state.spotTitle) {
			this.setState({ titleMissing: true, spotSelect: 'new' });
			return;
		}

		const spot = this.state.selSpot || { lat: this.state.lat, long: this.state.long, title: this.state.spotTitle, tags: this.state.spot.tags };
		if (this.props.onDone)
			this.props.onDone(true);

		if (this.state.tab === 'upload') {
			Server.newImage(
				spot,
				{
					id: this.state.imageID,
					title: this.state.title,
					description: this.currentMarkdown,
					exif: JSON.stringify(this._editedExif),
					imgType: this.state.testShot ? 1 : 0,
				}
			);
		} else { // Link image
			Server.newImage(
				spot,
				{
					title: this.state.title,
					description: this.currentMarkdown,
					author: this.state.photoAuthor,
					linkURL: this.state.linkURL,
					linkImgURL: this.state.linkImage,
					imgType: 0,
					license: this.state.license,
					authorUrl: this.state.authorUrl,
				}
			);
		}

		this.setState({ submitting: true });
	}

	handleTabChange = (event, value) => {
		this.setState({ tab: value });
	}

	handleLinkUrl = (event) => {
		const url = event.currentTarget.value;
		this.setState({ linkURL: url });
		this.setState({ linkedImages: null });
		Server.getURLImages(url).then((results) => {
			if (results[0])
				this.setState({ linkedImages: Server.fixHTTPImages(results) });
			else
				this.setState({ linkedImages: [] });
		});
	}

	handleLinkImageUrl = (event) => {
		const url = event.currentTarget.value;
		this.setState({
			imageURL: url,
			linkImage: url,
			linkedImages: url ?
				[{ url: Server.fixHTTPImage(url) }] :
				null
		});
	}

	onImgSelect = (index) => {
		var img;
		if (index >= 0)
			img = this.state.linkedImages[index];
		if (img) {
			this.setState({
				title: img.title || '',
				photoAuthor: img.author || '',
				linkImage: img.url,
				linkMissing: false,
				license: img.license,
				authorUrl: img.authorUrl,
			});
			if (img.description) {
				this.currentMarkdown = img.desc; // Currently useless, as the editor doesn't update after the initial content is set
			}
			if (img.lat || img.long) {
				PubSub.publish('CENTER_COORDS', { point: img, zoom: 16 });
			}
		} else
			this.setState({
				title: '',
				linkImage: null,
			});
	}

	toggleShowImgURL = () => {
		this.setState({ showImgURL: !this.state.showImgURL });
	}

	async setNewGPS(lat, long) {
		this.setState({ lat, long, spotSelect: 'new', selSpot: null, mapMissing: false });
		if (lat || long) {
			const nearby = await Server.getNearbySpots(lat, long);
			this.setState({ nearby });
		}
	}

	selectSpot = (spot) => {
		if (spot) {
			this.setExistingSpot(Number(spot.id), spot.lat, spot.long);
			Navigation.setSpot(spot);
		}
	}

	onCancel = () => {
		Navigation.goBack();
	}

	onExifChange = (newExif) => {
		this._editedExif = newExif;
	}

	handleSpotTitleChange = (e) => {
		this.setState({ spotTitle: e.currentTarget.value });
		if (e.currentTarget.value)
			this.setState({ titleMissing: false });
	}

	onSpotSelect = (event, value) => {
		this.setState({ spotSelect: value });
	}

	handleNewTag = (tagname) => {
		Server.addTag(this.state.spot, tagname);
	}

	handleDeleteTag = (tag) => {
		Server.deleteTag(this.state.spot, tag);
	}

	changeTestShot = () => {
		this.setState({ testShot: !this.state.testShot });
	}

	renderTitle() {
		return (
			<Paper className={this.props.classes.textPaper}>
				<TextField
					label='Photograph Title'
					fullWidth={true}
					value={this.state.title}
					onChange={this.handleTitleChange}
					margin='normal'
					style={{ marginTop: 8 }}
				/>
			</Paper>
		);
	}

	renderSpotSelection() {
		const { spotSelect, nearby, selSpot } = this.state;

		return (
			<>
				<Tabs
					value={spotSelect}
					onChange={this.onSpotSelect}
					style={{ marginBottom: 4, marginTop: 20 }}
					centered={true}
				>
					{this.state.lat !== null && !selSpot &&
						<Tab value={'new'} label={'New Spot'} style={{ textTransform: 'none' }} />
					}
					{selSpot &&
						<Tab value={'existing'} label={'Selected Spot'} style={{ textTransform: 'none' }} />
					}
					{nearby.length > 0 &&
						<Tab value={'near'} label={`Nearby Spots (${nearby.length})`} style={{ textTransform: 'none' }} />
					}
				</Tabs>

				{/* New Spot fields */}
				{spotSelect === 'new' &&
					<>
						<Paper className={this.props.classes.textPaper}>
							<TextField
								label='Spot Title'
								fullWidth={true}
								value={this.state.spotTitle}
								onChange={this.handleSpotTitleChange}
								margin='normal'
								style={{ marginTop: 8 }}
							/>
						</Paper>

						<Typography
							variant={'body1'}
							style={{ marginTop: 9, display: (this.state.titleMissing ? 'block' : 'none') }}
							className={this.state.titleMissing ? 'invalid' : ''}
							color='secondary'
						>
							{'Please enter title of the new Photography Spot.'}
						</Typography>

						{/* Tags */}
						<TagItems
							tags={this.state.spot.tags}
							location={this.state.spot}
							onNewTag={this.handleNewTag}
							onDeleteTag={this.handleDeleteTag}
							isNew={true}
							editable={true}
						/>
					</>
				}

				{/* Existing spot */}
				{spotSelect === 'existing' && selSpot && selSpot.image &&
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<SpotItem
							spot={selSpot}
							height={210}
							singleLine={true}
						/>
					</div>
				}

				{/* Nearby spots */}
				{spotSelect === 'near' &&
					<SpotsBar
						spots={nearby}
						onClick={this.selectSpot}
						baseSpot={selSpot || { lat: this.state.lat, long: this.state.long }}
						height={200}
						margin={6}
						style={{ marginTop: 10 }}
					/>
				}
			</>
		);
	}

	renderDescription() {
		const { classes, mobile } = this.props;
		const location = this.props.location || {};
		const largeMap = !this.props.large;
		const oneColumn = mobile || largeMap;
		const mapDescription =
			this.state.selSpot ?
				'Your photograph will be assigned to the selected Spot. Feel free to review other nearby Spots or create a new one in case your photograph wasn\'t taken here.' :
				this.state.lat !== null ?
					`Your photograph will be added to a new Spot at coordinates (${roundGPS(this.state.lat)}, ${roundGPS(this.state.long)}). If your photograph belongs rather to an existing nearby Spot, place it there please.` :
					'Place the pin at the exact spot where the photograph was taken, or select an existing spot.';

		return (
			<div style={{
				display: 'flex', width: '100%', marginTop: 20, flexDirection: (oneColumn) ? 'column' : 'row'
			}}>
				<div style={{ flexGrow: 1, flexBasis: 300 }}>
					{/* Decription label */}
					<FormLabel component='legend' classes={{ root: classes.sectionHeader }}>
						{'Description'}
					</FormLabel>

					{/* Editor */}
					<Editor
						initialValue={this.currentMarkdown}
						onChange={this.handleEditorChange}
						style={{ width: '100%', flexGrow: 1 }}
						className={'smallEditor'}
						allowNavigation={true /* handled by this page */}
					/>

					<div style={{ marginTop: 3, opacity: 0.7 }}>
						<span className={classes.descComment}>{'Please describe the conditions when the photo was taken, technical details, etc.'}</span>
					</div>

					{this.state.tab === 'upload' &&
						<>
							<FormLabel component='legend' style={{ marginTop: 25 }} classes={{ root: classes.sectionHeader }}>
								{'Image metadata'}
							</FormLabel>

							<Paper className={classes.textPaper} style={{ marginTop: 0, paddingTop: 10, paddingBottom: 12 }}>
								<div>
									<FormControlLabel
										checked={this.state.editTestShot}
										control={<Switch color='primary' />}
										label='Test shot'
										style={{ marginLeft: 25, marginRight: 3 }}
										labelPlacement='start'
										onChange={this.changeTestShot}
										classes={{ label: classes.lightLabel }}
									/>
									<HelpIcon id='testshot' />
								</div>
								<Exif
									exif={this.state.exif}
									edit={true}
									onChange={this.onExifChange}
								/>
							</Paper>
						</>
					}
				</div>

				{/* Small map */}
				<div className={oneColumn ? classes.spotBottom : classes.spotRight} ref={(ref) => { this._map = ref; }}>
					<FormLabel component='legend' classes={{ root: classes.sectionHeader }}>
						{'Photography Spot'} <HelpIcon id='photospot' size='small' />
					</FormLabel>

					{!largeMap &&
						<Paper
							elevation={7}
							className={classes.mapBaseNew}
							style={{ flexGrow: 1, height: 340, width: 'auto' }}
						>
							<MapView
								style={{ height: '100%', width: '100%' }}
								location={location}
								largeMap={false}
								mobile={this.props.mobile}
								newPhoto={true}
								centerLat={this.state.lat}
								centerLong={this.state.long}
							/>
						</Paper>
					}

					<div style={{ marginTop: 3, opacity: 0.7 }}>
						<span className={classes.descComment}>{mapDescription}</span>
					</div>

					<Typography
						variant={'body1'}
						style={{ marginTop: 9, display: (this.state.mapMissing ? 'block' : 'none') }}
						className={this.state.mapMissing ? 'invalid' : ''}
						color='secondary'
					>
						{'Please select position in the map, where the photograph was taken.'}
					</Typography>

					<Collapse in={Boolean(this.state.spotSelect)} style={{ overflow: 'hidden' }}>
						{this.renderSpotSelection()}
					</Collapse>
				</div>
			</div>
		);
	}

	renderUpload() {
		const { classes } = this.props;

		return (
			<Grid container direction='column' wrap='nowrap' alignItems='center' justifyContent='center' className={classes.uploadRoot}>

				{(this.state.imgURL === null ?
					<Paper
						style={{ alignSelf: 'stretch', minHeight: 200, flexGrow: 1, flexBasis: 250, display: 'flex', flexFlow: 'column', height: '100%', alignItems: 'center' }}
						className={this.state.imageMissing ? 'invalid' : ''}
					>
						<div style={{ display: 'flex', flexFlow: 'row', alignSelf: 'stretch', padding: 10 }}>
							<Typography variant={'body1'} style={{ opacity: 0.7 }}>
								{'Upload your beautiful photograph here.'}
							</Typography>
						</div>

						<div style={{ flexGrow: 10 }}></div>

						<div style={{ flexGrow: 10, textAlign: 'center' }}>
							<input type='file' accept='image/*'
								ref={(ref) => this.setUpload(ref)}
								style={{ display: 'none' }}
								onChange={this.handleFileBrowsed}
							/>
							<Button color='primary' variant='contained' onClick={() => this.upload.click()}>{'Select Image'}</Button>

							<Typography
								variant={'body1'}
								style={{ marginTop: 9, visibility: (this.state.photoMissing ? 'visible' : 'hidden') }}
								className={this.state.photoMissing ? 'invalid' : ''}
								color='secondary'
							>
								{'Please select a photograph to upload first.'}
							</Typography>
						</div>
					</Paper>

					:

					<React.Fragment>
						{/* Image */}
						<div style={{ position: 'relative' }}>
							<img src={this.state.imgURL} className={classes.image} alt='New Photograph' />
							<Fab size='small' color='primary' aria-label='remove' style={{ position: 'absolute', top: -19, right: -19 }} onClick={this.onRemoveImage}>
								<RemoveIcon />
							</Fab>
						</div>
					</React.Fragment>)
				}

				{this.renderTitle()}

				{this.renderDescription()}
			</Grid>
		);
	}

	renderLink() {

		return (
			<Grid container direction='column' wrap='nowrap' alignItems='center' justifyContent='center'>
				{/* URL */}
				<div style={{ display: 'flex', width: '100%', alignItems: 'flex-end' }}>
					<TextField
						label='Paste Webpage URL here'
						style={{ flexGrow: 1 }}
						value={this.state.linkURL}
						onChange={this.handleLinkUrl}
						margin='normal'
					/>

					<Tooltip title='Manually enter Image URL' enterDelay={300}>
						<IconButton onClick={this.toggleShowImgURL}>
							<LinkIcon />
						</IconButton>
					</Tooltip>
				</div>

				<Collapse in={this.state.showImgURL} mountOnEnter unmountOnExit style={{ width: '100%' }}>
					<TextField
						label='Paste Image URL here'
						fullWidth={true}
						style={{ flexGrow: 1 }}
						value={this.state.linkImage}
						onChange={this.handleLinkImageUrl}
						margin='normal'
					/>
				</Collapse>

				<ImagesChooser
					sourceURL={this.state.linkURL}
					images={this.state.linkedImages}
					onSelect={this.onImgSelect}
				/>

				<Typography
					variant={'body1'}
					style={{ marginTop: 9, visibility: (this.state.linkMissing ? 'visible' : 'hidden') }}
					className={this.state.linkMissing ? 'invalid' : ''}
					color='secondary'
				>
					{'Please select a photograph from the list of available photographs.'}
				</Typography>

				{/* Title */}
				{this.renderTitle()}

				{/* Author */}
				<Paper className={this.props.classes.textPaper}>
					<TextField
						label='Author'
						fullWidth={true}
						value={this.state.photoAuthor}
						onChange={this.handleAuthorChange}
						margin='normal'
						style={{ marginTop: 8 }}
					/>
				</Paper>

				{/* Description */}
				{this.renderDescription()}
			</Grid>
		);
	}

	render() {
		return (
			<div style={{ paddingLeft: 12, paddingRight: 12, minHeight: '100%', paddingBottom: 100 /* To make enough place to show Calendar */ }}>
				<div style={{ position: 'relative', paddingTop: 5 }}>
					<Tabs
						value={this.state.tab}
						onChange={this.handleTabChange}
						style={{ marginBottom: 4 }}
						centered={true}
						ref={ref => this._tabRef = ref}
					>
						<Tab value={'upload'} label={'Upload a Photograph'} style={{ textTransform: 'none' }} />
						<Tab value={'link'} label={'Link from Web'} style={{ textTransform: 'none' }} />
					</Tabs>

					{/* Upload progress */}
					{(
						(this.state.imgURL !== null && this.state.imageID === null) ||
						(this.state.linkURL && !this.state.linkedImages)
					)
						&&
						<CircularProgress color='secondary' size={30} style={{ position: 'absolute', top: 13, right: 13, zIndex: 2000 }} />}
				</div>

				{this.state.tab === 'upload' &&
					this.renderUpload()
				}

				{this.state.tab === 'link' &&
					this.renderLink()
				}

				<div style={{ display: 'flex', marginTop: 20, marginBottom: 10, justifyContent: 'flex-end' }}>
					<Button onClick={this.onCancel} color='primary'>
						{'Cancel'}
					</Button>

					{//showSubmit &&
						<Button onClick={this.handleUpload} variant='contained' color='secondary' disabled={this.state.submitting} style={{ marginLeft: 10 }}>
							{'Submit'}
						</Button>
					}
				</div>

				<Dialog
					open={Boolean(this.state.uploadError)}
					onClose={this.onErrClose}
				>
					<DialogTitle>{'Upload error'}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{'There was an issue with image upload. Don\'t you have a traffic blocker installed? '}<br /><br />
							{this.state.uploadError}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.onErrClose} color="primary" autoFocus>
							{'Close'}
						</Button>
					</DialogActions>
				</Dialog>
			</div >
		);
	}
}

function NewPhotoUpload(props) {
	const matches = useMediaQuery('(max-width:1024px)');
	const { mobile, ...other } = props;

	return (
		<NewPhotoUploadBase mobile={matches || mobile} {...other} />
	);
}

NewPhotoUploadBase.propTypes = {
	classes: PropTypes.object.isRequired,
	location: PropTypes.object,
	browseNow: PropTypes.bool,
	large: PropTypes.bool,
	mobile: PropTypes.bool,
	user: PropTypes.object,
	onDone: PropTypes.func,
};

/** @type {any} */
// @ts-ignore
export default withStyles(style)(NewPhotoUpload);