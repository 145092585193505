//@ts-check

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import MoreIcon from '@material-ui/icons/MoreHoriz';

import AppHeader from './AppHeader';
import ShareButton from './ShareButton';
import SpotsList from './SpotsList';
import MapView from '../MapView';
import Images from './Images';

import Server from '../server';
import Navigation from '../navigation';
import PubSub from 'pubsub-js';
import { MD2React, MD2HTML, escapeHtml, setPageTitle } from '../tools';

const style = theme => ({
	header: {
		height: '30vh',
		backgroundColor: theme.palette.primary.light,
		backgroundSize: 'cover',
		backgroundPosition: 'center 66%',
		position: 'relative',
	},

	dummyHeader: {
		height: 52,
		position: 'relative',
	},

	content: {
		backgroundColor: theme.palette.background.default,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: 8,
	},

	buttons: {
		[theme.breakpoints.up('md')]: {
			position: 'absolute',
			bottom: '1em',
			right: '1em',
			zIndex: 10,
		},
		[theme.breakpoints.down('sm')]: {
			marginBottom: '0.7em',
		}
	}
});

class SpotlistPage extends Component {
	currentMarkdown = null;
	state = {
		/** @type{any} */
		loggedUser: null,

		anchorEl: null,
		editDialog: false,

		imageMenuAnchor: null,

		/** @type{any} */
		user: null,
		/** @type{any} */
		spots: null,
		scroller: null,
		backImageURL: null,
		/** @type{any} */
		spotlist: null,
	}
	/** @type{any} */
	scrollSubscr = null;

	async getContent() {
		if (process.env.REACT_APP_SERVER_SIDE)
			return;

		const { user, spots, spotlist } = await Server.getSpotlist(this.props.username, this.props.spotlist);

		spotlist.username = this.props.username;

		// Get All photos
		const unqPhotoID = {};
		const unqPhotos = spots
			.reduce((acc, loc) => acc.concat([{ ...loc.images[0], location: loc }].filter(img => img.id)), []) // Extract images
			.filter(img => {
				const isUnique = !unqPhotoID[img.id];
				unqPhotoID[img.id] = true;
				return isUnique;
			});

		const unqSpotID = {};
		const unqSpots = spots.filter(img => {
			const isUnique = !unqSpotID[img.id];
			unqSpotID[img.id] = true;
			return isUnique;
		});

		this.setState({
			spots: unqPhotos,
			user: user,
			spotsData: unqSpots,
			spotlist: spotlist,
		});

		this.updateTitle(user, spotlist);

		// Get All photos to find the best one
		const photos = spots.reduce((acc, loc) => acc.concat(loc.images), []);

		// Get header photo
		const bestPhoto = photos.reduce((best, photo) => !Navigation.isFlickr(photo) && !photo.linkURL && photo.rating >= best.rating ? photo : best, { rating: null, owner: 'x' });
		if (bestPhoto.rating >= 0 && !Navigation.isFlickr(bestPhoto) && !bestPhoto.linkURL)
			this.setState({ backImageURL: Server.getImageUrl(bestPhoto) });
		else
			this.setState({ backImageURL: '-' });

		this.checkMap();
	}

	checkMap() {
		if (this.props.tab === 'map' && this.state.spotsData) {
			const loc = this.state.spotsData.map(i => ({
				id: i.id,
				lat: i.lat,
				long: i.long,
				p: i.p,
			}));
			Navigation.setSpotlistFilter(this.props.spotlist, this.props.username, loc);
		}
	}

	updateTitle(user, spotlist) {
		setPageTitle(`${spotlist && spotlist.title}: Gallery by ${user.displayName}`);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.username !== this.props.username || prevProps.spotlist !== this.props.spotlist) {
			this.getContent();
		} else
			this.checkMap();
	}

	async componentDidMount() {
		this.getContent();

		this.scrollSubscr = PubSub.subscribe('MAIN_SCROLL', (msg, data) => {
			this.updateScrollTop(data.scrollTop);
		});

		this.setState({
			loggedUser: await Server.getUser(),
		});
	}

	componentWillUnmount() {
		PubSub.unsubscribe(this.scrollSubscr);
	}

	updateScrollTop(scrollTop) {
		// window.requestAnimationFrame(() => {
		// 	if (this.header)
		// 		this.header.style.transform = `translateY(${Math.round(scrollTop / 2)}px)`;
		// });
	}

	onUserClick = (event) => {
		Navigation.showProfile(this.state.user.username);
		event.preventDefault();
		event.stopPropagation();
	}

	goTab(tab) {
		Navigation.goSpotlist(this.props.username, this.props.spotlist, tab);
	}

	goPhotos = () => {
		this.goTab('photos');
	}

	goSpots = () => {
		this.goTab('');
	}

	goMap = () => {
		this.goTab('map');
	}

	onShowMenu = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	}

	handleMenuClose = () => {
		this.setState({
			anchorEl: null,
			imageMenuAnchor: null,
		});
	}

	onEdit = () => {
		this.handleMenuClose();
		this.setState({
			editDialog: true,
			newTitle: this.state.spotlist.title,
			newDescription: this.state.spotlist.description,
		});
	}

	getKMLText() {
		const spots = this.state.spotsData;
		var spotlistStr = '';
		for (const spot of spots) { // eslint-disable-line no-unused-vars
			var desc = '';
			if (spot.images) {
				var imgIndex = 1;
				for (const img of spot.images.slice(0, 3)) { // eslint-disable-line no-unused-vars
					desc += `<img src="${Server.getImageThumb(img)}" height="200" width="auto" />`;
					desc += `Image ${imgIndex++}: <b>${img.title}</b><br/>`;
					if (img.description)
						desc += MD2HTML(img.description);
				}
			}
			desc += '<br/>';
			if (spot.descriptions) {
				for (const description of spot.descriptions.slice(0, 1)) { // eslint-disable-line no-unused-vars
					desc += MD2HTML(description.text) + '<br/>';
				}
			}
			desc = `<![CDATA[${desc}]]>`;

			spotlistStr +=
				`			<Placemark>
				<name>${escapeHtml(spot.title)}</name>
				<description>${desc}</description>
				<Point>
          <coordinates>
            ${spot.long},${spot.lat},0
          </coordinates>
				</Point>
			</Placemark>
`;
		}

		const { spotlist } = this.state;

		var res =
			`<?xml version="1.0" encoding="UTF-8"?>
<kml xmlns="http://www.opengis.net/kml/2.2">
	<Document>
	<name>${escapeHtml(spotlist.title)} (Phoide Gallery)</name>
	<description>${escapeHtml(spotlist.description || '')}</description>
	<Folder>
		<name>${escapeHtml(spotlist.title)}</name>
		${spotlistStr}
	</Folder>
</Document>
</kml>`;

		return res;
	}

	getGPXText() {
		const spots = this.state.spotsData;
		var spotlistStr = '';
		for (const spot of spots) { // eslint-disable-line no-unused-vars
			var desc = '';
			if (spot.images) {
				for (const img of spot.images.slice(0, 1)) {
					desc += `<link href="${Server.getImageThumb(img)}">`;
					if (img.description) {
						desc += `<text><![CDATA[${MD2HTML(img.description)}]]></text>`;
					}
					desc += '</link>';
				}
			}

			spotlistStr +=
				`  <wpt lat="${spot.lat}" lon="${spot.long}">
		<name>${escapeHtml(spot.title)}</name>${desc}
	</wpt>
	`;
		}

		const { spotlist } = this.state;

		var res =
			`<?xml version="1.0" encoding="UTF-8"?>
<gpx xmlns="http://www.topografix.com/GPX/1/1" version="1.1" creator="https://phoide.com/">
	<metadata>
		<name>${escapeHtml(spotlist.title)} (Phoide Gallery)</name>
		<desc>${escapeHtml(spotlist.description || '')}</desc>
	</metadata>
	${spotlistStr}
</gpx>`;

		return res;
	}

	downloadFile = (data, fname) => {
		const element = document.createElement('a');
		element.setAttribute('href', data);
		element.setAttribute('download', fname);

		element.style.display = 'none';
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
	}

	getKMLURI = () => {
		return `data:text/plain;charset=utf-16le,${encodeURIComponent(this.getKMLText())}`;
	}

	onKML = () => {
		this.handleMenuClose();
		this.downloadFile(this.getKMLURI(), this.state.spotlist.title + '.kml');
	}

	getGPXURI = () => {
		return `data:text/plain;charset=utf-16le,${encodeURIComponent(this.getGPXText())}`;
	}

	onGPX = () => {
		this.handleMenuClose();
		this.downloadFile(this.getGPXURI(), this.state.spotlist.title + '.gpx');
	}

	onDialogClose = (e, reason) => {
		if (
			(this.state.imgURL !== null || this.state.linkURL !== '') &&
			reason === 'backdropClick'
		)
			return;

		this.setState({ editDialog: false });
	}

	onDialogSave = () => {
		this.onDialogClose();

		const saveSpotlist = {
			...this.state.spotlist,
			title: this.state.newTitle,
			description: this.state.newDescription,
		};
		this.setState({ spotlist: saveSpotlist });
		Server.saveSpotlist(saveSpotlist);
	}

	onDialogDelete = async () => {
		if (window.confirm('Do you really want to delete this gallery?')) {
			this.onDialogClose();

			await Server.deleteSpotlist(this.state.spotlist.id);
			Navigation.showProfile(this.props.username, 'spotlists');
		}
	}

	onTitleChange = (event) => {
		this.setState({ newTitle: event.currentTarget.value });
	}

	onDescChange = (event) => {
		this.setState({ newDescription: event.currentTarget.value });
	}

	onOverflowClick = (e, index) => {
		this.setState({ imageMenuAnchor: e.currentTarget });
		this._menuImageIndex = index;
	}

	onRemoveImage = () => {
		Server.removePhotoFromSpotlist(this.state.spotlist.id, this.state.spotlist.urlTitle, this.state.spots[this._menuImageIndex].id);
		this.setState({ spots: this.state.spots.filter((e, i) => i !== this._menuImageIndex) });

		this.handleMenuClose();
	}

	render() {
		const { classes } = this.props;
		const tab = this.props.tab || 'spots';
		const isUser = this.state.loggedUser && this.props.username === this.state.loggedUser.username;

		if (!this.state.backImageURL)
			return null; // To avoid jittery output, don't render anything until we know whether we have the header image

		return (
			<>
				<AppHeader
					// style={{ position: 'absolute' }}
					user={this.props.user}
				/>

				{/* Header */}
				{this.state.backImageURL !== '-' &&
					<div
						className={classes.header}
						ref={el => this.header = el}
						style={{ backgroundImage: `linear-gradient(to bottom, transparent, rgba(0,0,0,0.3)), url(${this.state.backImageURL})`, zIndex: -1 }}
					/>
				}

				{/* Dummy header in case we don't have a usable header image */}
				{this.state.backImageURL === '-' &&
					<div className={classes.dummyHeader} />
				}

				<div className={classes.content}>
					<div style={{ position: 'relative', width: '100%', textAlign: 'center' }}>
						{/* Title */}
						<h1 style={{ marginBottom: 0 }}>
							{this.state.spotlist &&
								this.state.spotlist.title}
						</h1>

						{/* Author */}
						{this.state.user &&
							<Typography variant={'body1'} style={{ marginTop: 10, marginBottom: 20, marginLeft: 'auto', marginRight: 'auto', fontStyle: 'italic' }}>
								{'Gallery by '}
								<a href={Navigation.getUserURL(this.state.user.username)} onClick={this.onUserClick}>
									{this.state.user.displayName}
								</a>
							</Typography>
						}

						{/* Description */}
						{this.state.spotlist && this.state.spotlist.description &&
							<Typography variant={'body1'} style={{ marginTop: -5, marginBottom: 20, marginLeft: 'auto', marginRight: 'auto' }}>
								{MD2React(this.state.spotlist.description)}
							</Typography>
						}

						{/* Share and Edit Buttons */}
						<div className={classes.buttons}>
							<ShareButton
								spotlist={this.state.spotlist}
								fullButton={true}
							/>

							{isUser &&
								<Button
									onClick={this.onShowMenu}
									variant='outlined'
									color='primary'
									style={{ marginLeft: '0.7em' }}
								>
									<MoreIcon />
								</Button>
							}
						</div>

						{/* Tabs */}
						<Tabs
							value={tab}
							style={{ marginBottom: 10 }}
							centered={true}
						>
							<Tab value={'photos'} label={'Photos' + (this.state.spots ? ` (${this.state.spots.length})` : '')} onClick={this.goPhotos} />
							<Tab value={'spots'} label={'Spots' + (this.state.spots ? ` (${this.state.spotsData.length})` : '')} onClick={this.goSpots} />
							<Tab value={'map'} label={'Map'} onClick={this.goMap} />
						</Tabs>
					</div>

					{/* Photos */}
					{tab === 'photos' &&
						<Images images={this.state.spots} height={200} lastRow='center' onOverflowClick={this.onOverflowClick} />
					}

					{/* Spots */}
					{tab === 'spots' &&
						<SpotsList spots={this.state.spotsData} />
					}

					{/* Map */}
					{tab === 'map' &&
						<div style={{ height: '50vh', width: '100%', maxWidth: 1366, marginLeft: 'auto', marginRight: 'auto' }}>
							<MapView
								largeMap={false}
								mobile={this.props.mobile}
							/>
						</div>
					}
				</div>

				<Menu
					id='menu-edit'
					anchorEl={this.state.anchorEl}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					open={this.state.anchorEl !== null}
					onClose={this.handleMenuClose}
				>
					<MenuItem onClick={this.onEdit}>
						{'Edit Gallery Info'}
					</MenuItem>
					<MenuItem onClick={this.onKML}>
						{'Download as KML file'}
					</MenuItem>
					<MenuItem onClick={this.onGPX}>
						{'Download as GPX file'}
					</MenuItem>
				</Menu>

				<Menu
					id='menu-image'
					anchorEl={this.state.imageMenuAnchor}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={Boolean(this.state.imageMenuAnchor)}
					onClose={this.handleMenuClose}
				>
					<MenuItem onClick={this.onRemoveImage}>
						{'Remove from Gallery'}
					</MenuItem>
				</Menu>

				<Dialog
					open={this.state.editDialog}
					// fullScreen={this.props.fullScreen}
					onClose={this.onDialogClose}
					maxWidth='sm'
					fullWidth
				>
					<DialogTitle>
						{'Edit Gallery Info'}
					</DialogTitle>

					<DialogContent>
						<TextField
							label='Title'
							fullWidth={true}
							value={this.state.newTitle}
							onChange={this.onTitleChange}
							style={{ marginBottom: '1em' }}
						/>

						<TextField
							label='Description'
							fullWidth={true}
							multiline={true}
							rowsMax={10}
							value={this.state.newDescription}
							onChange={this.onDescChange}
						/>
					</DialogContent>

					<DialogActions style={{ padding: 15, marginTop: 25 }}>
						<Button onClick={this.onDialogDelete} color='primary'>
							{'Delete Gallery'}
						</Button>

						<div style={{ flexGrow: 1 }}></div>

						<Button onClick={this.onDialogClose} color='default'>
							{'Cancel'}
						</Button>

						<Button onClick={this.onDialogSave} variant='contained' color='secondary' style={{ marginRight: 10 }}>
							{'Save'}
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

SpotlistPage.propTypes = {
	classes: PropTypes.object.isRequired,
	spotlist: PropTypes.string,
	username: PropTypes.string,
	tab: PropTypes.string,
	mobile: PropTypes.bool,

	user: PropTypes.object,
};

/** @type {any} */
// @ts-ignore (style problem)
export default withStyles(style)(SpotlistPage);