//@ts-check
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
// @ts-ignore
import { renderToStaticMarkup } from 'react-dom/server'
import { convertNodeToElement } from 'react-html-parser';
import LatLon from 'geodesy/latlon-spherical';
import Navigation from './navigation';
import PubSub from 'pubsub-js';
import Photo from './fragments/Editor/Photo';
import { decodeBlurHash } from 'fast-blurhash';  // TODO: include in the main html so that it works right away on the first page load (before scripts are loaded)
// JH: For MD-It >= 14.x, but we need to upgrade react-scripts first
// import markdownit from 'markdown-it';
// const md = markdownit('default', { html: true });
const md = require('markdown-it')('default', { html: true });
const mila = require('markdown-it-link-attributes');
const { getUTCOffset, findTimeZone } = require('./tools/tzIndex-2000-2030.js');

md.use(mila, {
	pattern: /^https?:/, // Only open external links in a new window/tab
	attrs: {
		target: '_blank',
		rel: 'noopener',
	}
});

if (!process.env.REACT_APP_SERVER_SIDE) {
	document.body.addEventListener('click', (e) => {
		// @ts-ignore
		const anchor = e.target.closest('a');
		if (anchor) {
			const href = anchor.getAttribute('href');
			if (!href)
				return;

			if (process.env.REACT_APP_CORDOVA && href.match(/^https?:/i)) {
				window.open(anchor.href, '_system'); // To open Cordova links in the system Browser
				e.preventDefault();
				e.stopPropagation();
				return;
			}

			if (anchor.hasAttribute('data-inlink')) {
				Navigation.goPath(href);  // Generic internal handling of links (to avoid page reloads and fix navigation in Cordova)
				e.preventDefault();
				e.stopPropagation();
				return;
			}
		}
	}, true);
}

export function getBlankTarget() {
	return process.env.REACT_APP_CORDOVA ? '_system' : '_blank';
}

export function openNewTab(url) {
	window.open(url, getBlankTarget());
}

const CACHE_TIME = 60 * 60 * 1000;  // 1 hour
export const zooms = [0, 5, 7, 10];

export function checkKeyMods(event) {
	if (event.ctrlKey || event.altKey || event.shiftKey)
		return false;

	event.stopPropagation();
	event.preventDefault();

	return true;
}

const openLink = (event) => {
	if (event.ctrlKey || event.altKey || event.shiftKey)
		return;

	const data = event.currentTarget.dataset;
	if (data.photo)
		Navigation.openPhoto(data.photo);
	else {
		if (data.href.slice(0, 1) === '/')
			Navigation.goPath(data.href);
		else
			return; // Default link handling
	}

	event.stopPropagation();
	event.preventDefault();
};

export function parseImageCode(code) {
	const imgObj = {};
	const lines = code.match(/^.*$/gm) || [];
	for (const line of lines) {
		const m = line.match(/^\s*([^:]+)\s*:\s*(.*)\s*$/);
		if (m)
			imgObj[m[1]] = m[2];
	}
	return imgObj;
}

export function imgToCode(img) {
	const lines = [];
	for (const key of Object.keys(img)) {
		lines.push(`${key}: ${img[key]}`);
	}
	return lines.join('\n');
}

function getImageHTML(code) {
	const image = parseImageCode(code);
	const res = renderToStaticMarkup(
		<Photo
			img={image}
		/>
	);
	return '\n ' + res + '\n';
}

// Patch markdown-it render() to handle our custom images
const _mdRender = md.render.bind(md);
md.render = (mdtext, env, forReact) => {
	if (mdtext) {
		mdtext = mdtext.replace(/</g, '&#60;'); // Sanitize -- don't allow tags (only we inject custom html)
		const html = _mdRender(mdtext, env);
		return html.replace(/<pre>\s*<code[^>]*>([^<]*)<\/code>\s*<\/pre>/gms, (match, p1) => {
			return getImageHTML(p1);
		});
	} else
		return '';
};

export const COLORS = {
	DAY: '#dcdcdc',
	DAWN: '#9179ac',
	SUNRISE: '#e67b19',
	SUNSET: '#f76919',
	SUN: '#feb125',
	SUN_SHADE: '#ab6e00',
	SUN_SHADOW: '#553700',
	DUSK: '#584d57',
	MOON: '#3f51b5',
	MOONRISE: '#5e6ec7',
	MOONSET: '#132584',

	SECONDARY_VERY_LIGHT: '#ffd4c7',
	SECONDARY_LIGHT: '#ffad94',
	SECONDARY_MAIN: '#ff5f2e', //'#ff3d00',
	SECONDARY_DARK: '#c72e00',

	PRIMARY_LIGHT: '#8e8e8e',
	PRIMARY_MAIN: '#616161', //'#5C6BC0' /* Indigo 400 */
	PRIMARY_DARK: '#373737',
};

export function hexToRgb(hex) {
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	} : null;
}

export function getMarkdownIt() {
	return md;
}

// export function stripDescriptionTitle(text) {
// 	var parse = /(#+[^\n]*)/.exec(text);

// 	return parse ? text.slice(parse[1].length) : text;
// }

export function description2React(text) {
	return MD2React(text);
}

// export function description2Title(text) {
// 	var parse = /#+\s*([^\n]*)/.exec(text);

// 	return parse ? parse[1] : null;
// }

export function MD2HTML(mdtext) {
	// @ts-ignore (there's our own version of render() function)
	return md.render(mdtext, undefined, true /* our HTML version -- for react */);
}

export function MD2React(mdtext) {
	return ReactHtmlParser(MD2HTML(mdtext), {
		// @ts-ignore
		transform: (node, index, transform) => {
			if (node.name === 'a') {
				/** @type {any} */
				// @ts-ignore
				const el = convertNodeToElement(node, index, transform);
				return React.cloneElement(el, {
					onClick: openLink,
					// @ts-ignore
					'data-href': node.attribs.href,
				});
			}
		}
	});
}

export function getLevelFromZoom(zoom) {
	if (zoom < 5)
		return 0;
	else if (zoom < 7)
		return 1;
	else if (zoom < 10)
		return 2;
	else
		return 3;
}

function getXYFromLatLong(lat, long, zoom) {
	const pow = Math.pow(2, zoom);
	const lngStep = 360 / pow;
	const latStep = 2 * 85.0511 / pow;

	const x = Math.trunc((long + 180) / lngStep);
	const y = Math.trunc((85.0511 - lat) / latStep);

	return { x, y };
}

export function locDistance(loc1, loc2) {
	const p1 = new LatLon(loc1.lat, loc1.long);
	const p2 = new LatLon(loc2.lat, loc2.long);
	return p1.distanceTo(p2);
}

export function formatDistance(loc1, loc2) {
	const dist = Math.round(loc2 ? locDistance(loc1, loc2) : loc1);
	if (dist < 100)
		return dist + ' m';
	if (dist < 10000)
		return Math.round(dist / 100) / 10 + ' km';
	return Math.round(dist / 1000) + ' km';
}

export function roundGPS(value) {
	return Math.round(value * 1000000) / 1000000;
}

export function bearingTo(fromLoc, toLoc) {
	// @ts-ignore   // bearingTo does exist	
	return (new LatLon(fromLoc.lat, fromLoc.long)).bearingTo(new LatLon(toLoc.lat, toLoc.long));
}

export function getDestinationPoint(fromLoc, heading, distance) {
	return (new LatLon(fromLoc.lat, fromLoc.long)).destinationPoint(distance, heading);
}

export function escapeHtml(str) {
	return str.replace(/[&<>"'\\/]/g, function (s) {
		var entityMap = {
			'&': '&amp;',
			'<': '&lt;',
			'>': '&gt;',
			'"': '&quot;',
			'\'': '&#39;',
			'/': '&#x2F;'
		};

		return entityMap[s];
	});
}

function mixColors(c1, c2, p) {
	var res = '#';
	for (var i = 0; i < 3; i++) {
		const i1 = parseInt(c1.substr(1 + i * 2, 2), 16);
		const i2 = parseInt(c2.substr(1 + i * 2, 2), 16);
		res += Math.round(i1 + (i2 - i1) * p).toString(16).padStart(2, '0');
	}
	return res;
}

export function getGroupImageSVG(popularity) {

	const color = mixColors(COLORS.PRIMARY_MAIN, COLORS.SECONDARY_MAIN, popularity);

	// var photoPart = photoData ?
	// 	`<clipPath id="clipCircle">
	// 	  <circle cx="15" cy="10.4" r="7.2"/>
	// 	</clipPath>
	// 	<image href="data:image/jpeg;base64,${btoa(String.fromCharCode(...new Uint8Array(photoData)))}" 
	// 	height="14.5" width="14.5" x="7.8" y="3.2" clip-path="url(#clipCircle)"
	// 		style="opacity: 1"/>`
	// 	: '';

	const res = '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 30 30" enable-background="new 0 0 90 90" xml:space="preserve">' +
		// `<circle fill="${color}" style="opacity: 0.3" cx="15" cy="15" r="7.8"/></svg>`;
		'<circle cx="15" cy="15" r="7.3" stroke="none" fill="#ffffff" style="opacity: 0.3"/>' +
		`<circle cx="15" cy="15" r="${7.3 + 0.5 * popularity}" stroke="${color}" stroke-width='${0.8 + 0.7 * popularity}' fill="none" style="opacity: ${0.3 + popularity * 0.5}"/></svg>`;
	// + photoPart +
	// '</svg>';

	return res;
}


export function getPinImageSVG(popularity, /*photoData, color, colorInner*/) {
	const color = COLORS.PRIMARY_MAIN;
	// colorInner = colorInner || '#E8EAF6' /* Indigo 50 */;

	// var photoPart = photoData ?
	// 	`<clipPath id="clipCircle">
	// 		<circle fill="${colorInner}" cx="15" cy="10.4" r="7.2"/>
	// 	</clipPath>
	// 	<image href="data:image/jpeg;base64,${btoa(String.fromCharCode(...new Uint8Array(photoData)))}" 
	// 		height="14.5" width="14.5" x="7.8" y="3.2" clip-path="url(#clipCircle)"
	// 		style="opacity: 1"/>`
	// 	: '';
	const opacity = 0.3 + popularity * 0.6;

	const res = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="120px" height="225px" viewBox="0 0 30 56" enable-background="new 0 0 90 164" xml:space="preserve" opacity="${opacity}">` +
		// `<path fill="${color}" style="stroke:#ff5f2e;opacity:0.5;fill:#616161;fill-opacity:0.5;stroke-width:1.1" d="M22.906,10.438c0,4.367-6.281,14.312-7.906,17.031c-1.719-2.75-7.906-12.665-7.906-17.031S10.634,2.531,15,2.531S22.906,6.071,22.906,10.438z"/>` +
		`<path fill="${color}" style="opacity:${0.3 / opacity}" d="M22.906,10.438c0,4.367-6.281,14.312-7.906,17.031c-1.719-2.75-7.906-12.665-7.906-17.031S10.634,2.531,15,2.531S22.906,6.071,22.906,10.438z"/>` +
		(popularity >= 0.1 ?
			`<circle
		style="fill:none;stroke:#ff5f2e;stroke-width:${0.85 + 0.13 * popularity};opacity:${0.3 + 0.7 * opacity}"
		id="path1194"
		cx="15.007"
		cy="10.427"
		r="${7.4 + popularity * 0.2}" />` : '') +
		// photoPart +
		'</svg>';

	return res;
}

export function getPinSVG(color, colorInner, moved) {
	color = color || COLORS.PRIMARY_MAIN;
	colorInner = colorInner || '#E8EAF6' /* Indigo 50 */;

	// return '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="75px" viewBox="0 0 30 56" enable-background="new 0 0 30 56" xml:space="preserve">' +
	// 	`<path fill="${color}" d="M22.906,10.438c0,4.367-6.281,14.312-7.906,17.031c-1.719-2.75-7.906-12.665-7.906-17.031S10.634,2.531,15,2.531S22.906,6.071,22.906,10.438z"/>` +
	// 	(moved ?
	// 		`<g fill="${colorInner}" transform="scale(0.2 0.2) translate(44 31)"><path d="m30.2 2.1c-11.6.7-17.7 7.3-18.2 19.2h11.7c.1-4.1 2.5-7.2 6.7-7.7 4.2-.4 8.2.6 9.4 3.4 1.3 3.1-1.6 6.7-3 8.2-2.6 2.8-6.8 4.9-9 7.9-2.1 3-2.5 6.9-2.7 11.7h10.3c.1-3.1.3-6 1.7-7.9 2.3-3.1 5.7-4.5 8.5-7 2.7-2.3 5.6-5.1 6-9.5 1.7-12.9-8.9-19.1-21.4-18.3"/><ellipse cx="30.5" cy="55.6" rx="6.5" ry="6.4"/></g>` :
	// 		`<circle fill="${colorInner}" cx="15" cy="10.677" r="4"/>`) +
	// 	'</svg>';

	return '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="120px" height="225px" viewBox="0 0 30 56" enable-background="new 0 0 90 164" xml:space="preserve">' +
		`<path fill="${color}" style="opacity: 0.87" d="M22.906,10.438c0,4.367-6.281,14.312-7.906,17.031c-1.719-2.75-7.906-12.665-7.906-17.031S10.634,2.531,15,2.531S22.906,6.071,22.906,10.438z"/>` +
		(moved ?
			`<g fill="${colorInner}" transform="scale(0.2 0.2) translate(44 31)"><path d="m30.2 2.1c-11.6.7-17.7 7.3-18.2 19.2h11.7c.1-4.1 2.5-7.2 6.7-7.7 4.2-.4 8.2.6 9.4 3.4 1.3 3.1-1.6 6.7-3 8.2-2.6 2.8-6.8 4.9-9 7.9-2.1 3-2.5 6.9-2.7 11.7h10.3c.1-3.1.3-6 1.7-7.9 2.3-3.1 5.7-4.5 8.5-7 2.7-2.3 5.6-5.1 6-9.5 1.7-12.9-8.9-19.1-21.4-18.3"/><ellipse cx="30.5" cy="55.6" rx="6.5" ry="6.4"/></g>` :
			`
			`) +
		'</svg>';
}

export function cacheInvalidate(key) {
	key = '/api' + key;
	for (var i = 0; i < localStorage.length; i++) {
		if (localStorage.key(i) === key)
			localStorage.removeItem(key);
	}
}

export function cacheInvalidateSpot(id) {
	return cacheInvalidate(`/point/${id}`);
}

export function cacheInvalidateSpots(spots) {
	if (!spots)
		return;
	for (const spot of spots)
		cacheInvalidateSpot(spot.id);
}

export function cacheInvalidatePhoto(id) {
	return cacheInvalidate(`/photo/${id}`);
}

export function cacheInvalidateAll() {
	localStorage.clear();
}

export async function cacheAddToPoints(newPoint) {
	for (const z of zooms) {
		var path;
		if (z) {
			const { x, y } = getXYFromLatLong(newPoint.lat, newPoint.long, z);
			path = `/api/pspots/${z}/${x}/${y}`;
		} else
			path = '/api/pspots/1/1/1';

		const points = await fetchJson(path, { cachedOnly: true });
		if (points) { // Is in cache
			points.push(newPoint);
			saveToCache(path, points);
		}
	}
}

function cacheCleanUp() {
	for (var i = 0; i < localStorage.length; i++) {
		const key = localStorage.key(i);
		// @ts-ignore
		const deleteIt = (key.slice(0, 9) === '/api/tag/') || (key.slice(0, 12) === '/api/search/');
		if (deleteIt)
			// @ts-ignore
			localStorage.removeItem(key);
	}
}

export function saveToCache(path, json) {
	var string = JSON.stringify({
		timestamp: Date.now(),
		data: json,
	}, null, 0 /*to save some space*/);

	try {
		localStorage.setItem(path, string);
	} catch {
		cacheCleanUp();
		cacheTimeCheck();

		try {
			localStorage.setItem(path, string);
		} catch {
			//
		}
	}
}

function cacheTimeCheck() { // Remove all expired cache items
	for (var i = localStorage.length - 1; i >= 0; i--) {
		const key = localStorage.key(i) || '';
		if (key.startsWith('/api/')) {
			// @ts-ignore
			const item = JSON.parse(localStorage.getItem(key));
			if (item && Date.now() - item.timestamp >= CACHE_TIME)
				localStorage.removeItem(key);
		}
	}
}

if (!process.env.REACT_APP_SERVER_SIDE) {
	setInterval(() => {
		// @ts-ignore
		if (window.requestIdleCallback) {
			// @ts-ignore
			window.requestIdleCallback(cacheTimeCheck);
		} else
			cacheTimeCheck();
	}, 1000 * 60 * 10);  // Clean up cache every 10 minutes
}

export function completeURL(url) {
	if (process.env.REACT_APP_CORDOVA && url[0] === '/')
		return 'https://phoide.com' + url;
	else
		return url;
}

export function completeLocalURL(url) {
	// if (process.env.REACT_APP_CORDOVA)
	// 	return 'file:///android_asset/www' + url;
	// else
	return url;
}

export function fetchJson(path, options) {
	return new Promise((resolve, reject) => {
		var cacheIt;
		if (options) {
			if (options.cache || options.cachedOnly) {
				cacheIt = true;
				const cached = localStorage.getItem(path);
				if (cached) {
					const cachedJSON = JSON.parse(cached);
					if (Date.now() - cachedJSON.timestamp < CACHE_TIME && cachedJSON.data) {
						return resolve(cachedJSON.data);
					}
				}

				if (options.cachedOnly)
					return resolve(null); // Not found in cache - don't try to connect the server
			}
			delete options.cache; // Delete even if 'false'					
		}

		// Fetch API
		fetch(completeURL(path), options).then((result) => {
			try {
				if (!result.ok)
					reject(`Error: ${result.status}: ${result.statusText}`);
				else {
					return result.json().catch(() => {
						resolve(null); // Probably null returned, is there a nicer way to catch this?
					});
				}
			} catch (e) {
				reject(e);
			}
		}).then((json) => {
			if (cacheIt)
				saveToCache(path, json);

			resolve(json);
		}).catch(err => {
			console.log('Fetch error on fetching ' + path);
			console.log(err);
			reject(err);
		});
	});
}

export function fetchMapSearch(value) {
	return (fetchJson(`https://nominatim.openstreetmap.org/search?q=${value}&format=json`));
}

function fetchOSM(lat, long) {
	return (fetchJson(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${long}&accept-language=en&format=jsonv2`));
}

async function getLocationTagsFromOSM(spot, lat, long) {
	const OSM = await fetchOSM(lat, long);
	var tags = [];
	var address = '';

	if (OSM) {
		if (OSM.name)
			tags = tags.concat(OSM.name);
		address = OSM.display_name;
		if (OSM.address) {
			tags.push(OSM.address.attraction);
			tags.push(OSM.address.information);
			tags.push(OSM.address.village);
			tags.push(OSM.address.town);
			tags.push(OSM.address.city);
			tags.push(OSM.address.state);
			tags.push(OSM.address.country);
			if (OSM.address.postcode)
				address = address.replace(OSM.address.postcode + ', ', '');
		}
	}

	spot.address = address;

	var locTags = spot.tags || [];
	locTags = locTags.filter(tag => !tag.auto);
	for (const tag of tags) { // eslint-disable-line no-unused-vars
		if (tag && !locTags.find(locTag => locTag.tag.toLowerCase() === tag.toLowerCase()))
			locTags.push({
				tag,
				auto: true,
				isNew: true,
			});
	}
	return tags;
}

async function getLocationTagsFromOverpass(spot, lat, long) {
	const tags = [];
	try {
		const res = await fetchJson(`https://z.overpass-api.de/api/interpreter?data=[out:json];is_in(${lat},${long});out%20meta;`);
		if (res) {
			const els = res.elements;
			for (var el of els) {
				if (!el.tags)
					continue;
				if (el.tags.type === 'land_area' || el.tags.name === 'Contiguous United States' || el.tags.name === 'Creedon Republic' ||
					el.tags.landuse === 'industrial' || el.tags.name === 'British Empire' || el.tags['name:en'] === 'Japanese Isles')
					continue;
				if (el.tags.boundary !== 'administrative' && el.tags.boundary !== 'protected_area' && el.tags.boundary !== 'natural' &&
					el.tags.boundary !== 'national_park' && el.tags.boundary !== 'park' && el.tags.boundary !== 'political' && el.tags.boundary) {
					continue;
				}
				tags.push(el.tags['name:en'] || el.tags.name);
			}
		}
	} catch (e) {
		console.log(e);
	}

	return tags;
}

export async function getLocationTags(spot, lat, long, options) {
	spot.tags = (spot.tags || []).filter(tag => !tag.auto);

	var publishTags = function (tags) {
		for (let tag of tags) {
			if (tag && !spot.tags.find(locTag => locTag.tag.toLowerCase() === tag.toLowerCase()))
				spot.tags.push({
					tag,
					auto: true,
					isNew: true,
				});
		}
		PubSub.publish('POINT_UPDATED', {
			point: { ...spot },
		});
	}

	getLocationTagsFromOSM(spot, lat, long).then(tags => {
		spot.tags._OSM = true;
		publishTags(tags);
	});
	if (!options || !options.noOverpass) {
		getLocationTagsFromOverpass(spot, lat, long).then(tags => {
			spot.tags._Overpass = true;
			publishTags(tags);
		});
	}
}

export function browserLocale() {
	if (process.env.REACT_APP_SERVER_SIDE)
		return 'en-US';  // TODO: Get the proper user locale

	if (navigator.languages && navigator.languages.length) {
		// latest versions of Chrome and Firefox set this correctly
		return navigator.languages[0];
		//@ts-ignore
	} else if (navigator.userLanguage) {
		// IE only
		//@ts-ignore
		return navigator.userLanguage;
	} else {
		// latest versions of Chrome, Firefox, and Safari set this correctly
		return navigator.language;
	}
}

export function titleForURL(title) {
	return (title || '')
		.replace(/[\s/:?&]/g, '_')
		.replace(/\[/g, '(')
		.replace(/\]/g, ')')
		.replace(/[^a-zA-Z0-9$\-_.+!*'()]/g, '')  // Remove any unsafe character
		.replace(/([^a-zA-Z0-9])[^a-zA-Z0-9]+/g, '$1') // Remove duplicated non-letter characters
		.replace(/^[^a-zA-Z0-9]+/g, '') // Remove starting useless chars
		.replace(/[^a-zA-Z0-9]+$/g, '') // Remove trailing useless chars
		|| '_'; // To not return an empty title
}

export function getServerFromURL(url) {
	const m = url.match(/(?:https?:\/\/)?(?:www.)?([^/]+)/);
	return m && m[1];
}

export function setPageTitle(title) {
	if (!process.env.REACT_APP_SERVER_SIDE)
		document.title = title + ' @ phoide.com';
}

export function getLocTZOffset(spot, time) {
	if (spot && spot.tz) {
		var tz;
		try {
			tz = findTimeZone(spot.tz.tz);
		} catch (e) {
			console.log(`Timezone ${spot.tz.tz} not found:`);
			console.log(e);
		}
		if (tz)
			return -getUTCOffset(time || Date.now(), tz).offset * 60 * 1000;
	}

	return 0;
}

export function hasPermission(permission) {
	return new Promise((resolve, reject) => {
		// @ts-ignore
		window.cordova.plugins.permissions.checkPermission(permission, (status) => {
			resolve(status.hasPermission);
		}, (error) => { reject(error); });
	});
}

export function getPermission(permission) {
	return new Promise((resolve, reject) => {
		// @ts-ignore
		window.cordova.plugins.permissions.requestPermission(permission, (status) => {
			resolve(status.hasPermission);
		}, (error) => { reject(error); });
	});
}

export function string2LatLong(str) {
	if (!str)
		return null;

	const m = str.match(/\s*([+-]?\d+[.,]\d*)\s*[,;]\s*([+-]?\d+[.,]\d*)/);
	if (m) {
		try {
			return {
				long: Number(m[2]),
				lat: Number(m[1]),
			};
		} catch {
			return null;
		}
	}
}

export function sortByPopularity(f1, f2) {
	return (f2.get('p') - f1.get('p')) || (f2.get('id') - f1.get('id'));
}

// Shallow Equal code for React
var hasOwnProperty = Object.prototype.hasOwnProperty;

/**
 * inlined Object.is polyfill to avoid requiring consumers ship their own
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is
 */
function is(x, y) {
	// SameValue algorithm
	if (x === y) {
		// Steps 1-5, 7-10
		// Steps 6.b-6.e: +0 != -0
		// Added the nonzero y check to make Flow happy, but it is redundant
		return x !== 0 || y !== 0 || 1 / x === 1 / y;
	} else {
		// Step 6.a: NaN == NaN
		return x !== x && y !== y; // eslint-disable-line no-self-compare
	}
}

/**
 * Performs equality by iterating through keys on an object and returning false
 * when any key has values which are not strictly equal between the arguments.
 * Returns true when the values of all keys are strictly equal.
 */
export function shallowEqual(objA, objB) {
	if (is(objA, objB)) {
		return true;
	}

	if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
		return false;
	}

	var keysA = Object.keys(objA);
	var keysB = Object.keys(objB);

	if (keysA.length !== keysB.length) {
		return false;
	}

	// Test for A's keys different from B.
	for (var i = 0; i < keysA.length; i++) {
		if (!hasOwnProperty.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]])) {
			return false;
		}
	}

	return true;
}

/**
 * Does a shallow comparison for props and state.
 * See ReactComponentWithPureRenderMixin
 * See also https://facebook.github.io/react/docs/shallow-compare.html
 */
export function shallowCompare(instance, nextProps, nextState) {
	return (
		!shallowEqual(instance.props, nextProps) ||
		!shallowEqual(instance.state, nextState)
	);
}


/**
 * Loads a script
 * @param {String} scriptToAppend 
 */
export function appendScript(scriptToAppend) {
	if (!process.env.REACT_APP_SERVER_SIDE) {
		const script = document.createElement('script');
		script.src = scriptToAppend;
		script.async = true;
		document.body.appendChild(script);
	}
}

export function adjustStatusBar(color, dark) {
	if (process.env.REACT_APP_CORDOVA) {
		// @ts-ignore
		if (window.cordova) {
			// @ts-ignore
			StatusBar.overlaysWebView(false); // eslint-disable-line no-undef
			if (dark)
				// @ts-ignore
				StatusBar.styleLightContent(); // eslint-disable-line no-undef		
			else
				// @ts-ignore
				StatusBar.styleDefault(); // eslint-disable-line no-undef		
			// @ts-ignore
			StatusBar.backgroundColorByHexString(color || '#e7e7e7'); // eslint-disable-line no-undef
		}
	}
}

export function renderBlurhash(blurhash, canvas) {
	if (!blurhash)
		return;
	const pixels = decodeBlurHash(blurhash, 32, 32);

	if (canvas) {
		const ctx = canvas.getContext('2d');
		if (!ctx)
			return;
		const imageData = ctx.createImageData(32, 32);
		imageData.data.set(pixels);
		ctx.putImageData(imageData, 0, 0);
	}
}

var fnDrawerOpenFn;
export function toggleDrawer() {
	if (fnDrawerOpenFn)
		fnDrawerOpenFn();
}

export function listenDrawerToggle(fn) {
	fnDrawerOpenFn = fn;
}

export function mapCache(url) {
	const m = url.match(/^https:\/\/api\.maptiler\.com\/tiles\/v3\/(\d+)\/(\d+)\/(\d+)/);
	if (m && m[1] < 4) {
		return { url: `https://cdn.phoide.com/map/${m[1]}/${m[2]}/${m[3]}.pbf` };
	}
	return { url };
}