/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';

import SpotsList from './SpotsList';
import Server from '../server';

export default function PhotosMost() {
	const [spots, setSpots] = useState(null);

	const ref = useRef({});
	const loc = ref.current;

	useEffect(() => {
		Server.getPopularSpots().then(p => setSpots(p));
	}, [loc /* call only on mount/unmount */]);

	return (
		<SpotsList spots={spots}/>
	);
}