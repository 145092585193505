/* eslint-disable react/display-name */
import React from 'react';

import IconMail from '@material-ui/icons/Mail';
import IconFB from '@material-ui/icons/Facebook';
import IconTwitter from '@material-ui/icons/Twitter';
import IconInsta from '@material-ui/icons/Instagram';

import IconButton from '@material-ui/core/IconButton';

import { getBlankTarget } from '../tools.js';

var initDone;
// var fbEl;

// function mountFB(ref) {
// 	if (ref) {
// 		if (fbEl) {
// 			ref.replaceChildren(fbEl);
// 		} else
// 			fbEl = ref.children[0];
// 	}
// }

export default function contact() {
	if (!initDone) {
		initDone = true;
		const script = document.createElement('script');
		script.nonce = 'TTMvNMsv';
		script.crossorigin = 'anonymous';
		script.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0&appId=372019109952392';
		document.head.append(script);
	}

	return (
		<div className='textContent'>
			<h1>Contacts</h1>

			<p>We love hearing from you! Any feedback, suggestion or idea is appreciated!</p>

			<p>We'll respond your questions not only on email, but also Facebook, Twitter and Instagram:</p>

			<p>
				<IconButton href='mailto:jh@phoide.com' target={getBlankTarget()} rel='noopener noreferrer'><IconMail classes={{ root: 'lgIcon' }} /></IconButton>
				<IconButton href='https://www.facebook.com/phoidePhoto' target={getBlankTarget()} rel='noopener noreferrer'><IconFB classes={{ root: 'lgIcon' }} /></IconButton>
				<IconButton href='https://twitter.com/phoidePhoto' target={getBlankTarget()} rel='noopener noreferrer'><IconTwitter classes={{ root: 'lgIcon' }} /></IconButton>
				<IconButton href='https://www.instagram.com/phoidephoto/' target={getBlankTarget()} rel='noopener noreferrer'><IconInsta classes={{ root: 'lgIcon' }} /></IconButton>
			</p>

			{/* <h3>Email</h3>

			<p>Send it to Jiri: <a href='mailto:jh@phoide.com'>jh@phoide.com</a></p>

			<h3>Facebook</h3>

			<div ref={mountFB}>
				<div className="fb-page" data-href="https://www.facebook.com/phoideGuide" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
					<blockquote cite="https://www.facebook.com/phoideGuide" className="fb-xfbml-parse-ignore">
						<a href="https://www.facebook.com/phoideGuide">Phoide</a>
					</blockquote>
				</div>
			</div> */}
		</div>
	);
}