// @ts-check
/* eslint-disable no-console */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';

import Server from '../server';
import Navigation from '../navigation';
import PubSub from 'pubsub-js';

import IconDist from '@material-ui/icons/ArrowUpward';
import LocationIcon from '@material-ui/icons/LocationOn';

import { formatDistance, bearingTo, checkKeyMods, renderBlurhash } from '../tools';
import LazyImage from './LazyImage';

const HEIGHT = 240;

const styles = /*theme =>*/ ({
	spotCard: {
		display: 'inline-block',
		verticalAlign: 'middle',
		pointerEvents: 'auto',
		transition: 'opacity 0.5s',
	},

	spotCardWrap: {
		width: 'auto',
	},

	cardContent: {
		position: 'absolute',
		bottom: 0,
		height: '30%',
		backgroundImage: 'linear-gradient(to bottom, transparent, rgba(0,0,0,0.85))',
		lineHeight: '1.35em',
		zIndex: 20,
	},

	text: {
		display: '-webkit-box',
		WebkitLineClamp: 2,
		WebkitBoxOrient: 'vertical',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		// fontWeight: 'bold',
		fontSize: 14,
	},

	textDistance: {
		marginTop: 3,
	},

	textSingle: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		fontSize: 14,
		whiteSpace: 'nowrap',
		textAlign: 'center',
		// fontStyle: 'italic',
	},

	textGrid: {
		textAlign: 'center',
		fontSize: 16,
		lineHeight: '22px',
	},

	contentGrid: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		height: 58,
		padding: '0px 8px',
	},

	cardSg: {
		padding: 5,
		width: 'calc(100% - 10px)',
		'& div': {
			bottom: 5,
			left: 5,
			right: 5,
		},
	},
	cardDb: {
		padding: 8,
		width: 'calc(100% - 16px)',
		'& div': {
			bottom: 0,
			left: 5,
			right: 5,
		},
	},

	distance: {
		opacity: 0.87,
		display: 'inline-flex',
		alignItems: 'center',
		// marginTop: 4,
		fontSize: 12,
		minHeight: 20, // to make sure the whole content part of Card is filled and thus correctly drawn on mouse hover
	},

	imgContain: {
		backgroundSize: 'contain',
	},

	locIcon: {
		marginRight: 2,
		marginBottom: -4,
		marginLeft: -3,
		opacity: 0.4,
	},

	imgBlur: {
		filter: 'blur(4px)',
		position: 'absolute', top: 0, bottom: 0, left: 0, right: 0,
	},

	imgBase: {
		zIndex: 10,
		position: 'relative',
	},
});

const ARcache = {};
const fullSpotCache = {};
const allItems = [];

class SpotItem extends PureComponent {
	state = {
		fullSpot: null,
	}
	_done = false;
	_blurhash = '';

	componentDidMount() {
		allItems.push(this);
		this.updateBlurHash();
	}

	componentWillUnmount() {
		allItems.splice(allItems.indexOf(this), 1);
		this._done = true;
	}

	updateBlurHash = () => {
		const spot = this.getFullSpot();
		if (spot) {
			const image = spot.image || (spot.images && spot.images[0]);
			if (image) {
				if (this._blurhash !== image.blurhash && image.blurhash) {
					if (this._canvas) {
						this._blurhash = image.blurhash;
						renderBlurhash(image.blurhash, this._canvas);
					}
				}
			}
		}
	}

	refCanvas = (r) => {
		this._canvas = r;
		this.updateBlurHash();
	}

	getFullSpot() {
		var { spot } = this.props;

		if (!spot || (spot.image || spot.images))
			return spot;

		// if (this.state.fullSpot && this.state.fullSpot.id === spot)
		// 	return this.state.fullSpot;

		spot = fullSpotCache[this.props.spot];
		if (!spot) {
			fullSpotCache[spot] = {}; // In progress
			Server.getPointPreview(this.props.spot, true /* group requests*/).then(spot => {
				if (!spot)
					return;

				fullSpotCache[spot.id] = spot;
				for (const item of allItems) {
					if (item.props.spot === spot.id) {
						item.forceUpdate();
						item.updateBlurHash();
					}
				}
			});
		}

		return spot;
	}

	onSpotClick = (e) => {
		const spot = this.getFullSpot();

		if (this.props.openOnClick) {
			if (checkKeyMods(e))
				Navigation.goSpot(spot);
		} else {
			if (this.props.onClick && checkKeyMods(e))
				this.props.onClick(spot);
		}
	}

	showImg(img) {
		if (!img)
			return;
		const id = img.dataset.id;
		if (img.naturalHeight && id) {
			const AR = img.naturalWidth / img.naturalHeight;
			ARcache[id] = AR;
			if (!this._done)
				this.forceUpdate();
		}
	}

	onImgLoad = (e) => {
		this.showImg(e.currentTarget);
	}

	refImg = (el) => {
		// Memory cached images don't get onLoad called, so we have to use this onRef()
		this.showImg(el);
	}

	getHeight() {
		return this.props.height || HEIGHT;
	}

	onMouseOver = (e) => {
		if (e.buttons !== 0)
			return; // Ignore if there's a button pressed

		const spot = this.getFullSpot();
		if (spot)
			PubSub.publish('HIGHLIGHT_COORDS', { long: spot.long, lat: spot.lat });
	}

	onMouseOut = () => {
		// To cancel the hightlight
		PubSub.publish('HIGHLIGHT_COORDS', {});
	}

	onClick = (event) => {
		if (checkKeyMods(event))
			Navigation.goSpot(this.getFullSpot());
	}

	renderDummy() {
		const { classes, isLast } = this.props;
		const height = this.getHeight();

		return (
			<Card
				className={classes.spotCard}
				variant={this.props.variant || 'elevation'}
				style={{
					marginRight: (isLast) ? 0 : 15,
					height: height,
				}}
			>
				<Skeleton height={height} width={1.5 * height} variant='rect' animation='wave' />
			</Card>
		);
	}

	render() {
		const { classes, isLast, grid, baseSpot, large, flexibility, margin } = this.props;
		const singleLine = true;

		const spot = this.getFullSpot();
		if (!spot || (!spot.image && !spot.images)) {
			return this.renderDummy();
		}

		const image = spot.image || (spot.images && spot.images[0]);
		if (!image) {
			return null;
		}
		const AR = image.ar || ARcache[spot.id];
		const imageURL = image && Server.getImageThumb(image);

		const baseWidth = Math.max(Math.min((AR || 1), 2.2), 1) * (this.getHeight());

		const coverArea = true;

		this.updateBlurHash();

		return (
			<a
				href={Navigation.getLocationURL(spot)}
				onClick={this.onClick}
				style={{
					maxWidth: `min(100%, ${Math.round(baseWidth * 1.25)}px)`,
					flexBasis: Math.round(baseWidth * 0.9 / (flexibility || 1)),
					flexGrow: Math.round(baseWidth),
					marginRight: (margin || 0) / 2,
					marginLeft: (margin || 0) / 2,
					marginBottom: grid ? (margin || 20) : 0,
				}}
			>
				<Card
					className={classes.spotCard}
					onClick={this.onSpotClick}
					data-id={spot.id}
					variant={this.props.variant || 'elevation'}
					style={{
						height: this.getHeight(),
						width: grid ? '100%' : Math.round(Math.max(Math.min((AR || 1), 1.7), 1) * (this.getHeight() - (coverArea ? 25 : 50))),
						marginRight: isLast ? 0 : (margin || 20),
						opacity: AR ? 1 : 0,
						position: 'relative',
					}}
					onMouseEnter={this.onMouseOver}
					onMouseLeave={this.onMouseOut}
				>
					<CardActionArea style={{height: '100%'}}>
						<canvas
							ref={this.refCanvas}
							style={{
								height: this.getHeight(),
								width: '100%',
							}}
							width={32} height={32}
						/>

						<LazyImage src={imageURL} alt={spot.title} style={{ height: this.getHeight(), objectFit: AR <= 1 ? 'contain' : 'cover', position: 'absolute', width: '100%', left: 0 }} />

						<CardContent
							classes={{
								root: classes.cardContent + ' ' + (singleLine ? classes.cardSg : classes.cardDb)
							}}
						>
							<div
								className={(singleLine ? classes.textSingle : classes.text) + (large ? ' ' + classes.textGrid : '') + (baseSpot && large ? ' ' + classes.textDistance : '')}
								style={{
									color: coverArea ? '#ffffffe0' : 'inherit',
									position: 'absolute',
								}}
							>
								{baseSpot &&
									<div className={classes.distance} style={{ display: 'flex', justifyContent: 'right', textAlign: 'right' }}>
										<>
											<IconDist style={{ marginBottom: -2, transform: `rotate(${bearingTo(baseSpot, spot)}deg) scale(0.8)`, opacity: 0.8 }} />
											<span>{formatDistance(baseSpot, spot)}</span>
										</>
									</div>
								}

								<LocationIcon color='action' fontSize='small' className={classes.locIcon}
									style={{
										fill: coverArea ? 'white' : 'inherit',
										opacity: coverArea ? 0.8 : 'inherit',
									}} />
								{spot.title}
							</div>
						</CardContent>
					</CardActionArea>
				</Card>
			</a >
		);
	}
}

SpotItem.propTypes = {
	classes: PropTypes.object.isRequired,
	style: PropTypes.object,

	height: PropTypes.number,
	margin: PropTypes.number,
	variant: PropTypes.string, // elevation or outlined
	singleLine: PropTypes.bool, // single or double line title
	large: PropTypes.bool,
	flexibility: PropTypes.number, // 1 = default, >1 => allow smaller images in order to better fit the line

	spot: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
	baseSpot: PropTypes.object,
	openOnClick: PropTypes.bool,
	isLast: PropTypes.bool,
	lazyLoad: PropTypes.bool,
	grid: PropTypes.bool,

	onClick: PropTypes.func,
};

/** @type {any} */
// @ts-ignore (some silly style check?)
export default withStyles(styles)(SpotItem);
