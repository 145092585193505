//@ts-check
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';

import RegionIcon from '../icons/Signpost';
import PhotoIcon from '@material-ui/icons/Photo';

import AppHeader from './AppHeader';
import MapView from '../MapView';
import Images from './Images';
import SpotsList from './SpotsList';
import Footer from './Footer';
import TagsBar from './TagsBar';
import Editor from './Editor';

import Server from '../server';
import PubSub from 'pubsub-js';
import Navigation from '../navigation';

import { setPageTitle, checkKeyMods } from '../tools';
import Skeleton from '@material-ui/lab/Skeleton';
import ImageWithTitle from './ImageWithTitle';
import TagHierarchy from './TagHierarchy';
import { Icon, Link } from '@material-ui/core';
import MapOutline from './MapOutline';
import Masonry from '../ext/Masonry';
import ResponsiveMasonry from '../ext/ResponsiveMasonry';
import Description from './Description';
import ArticleBrief from './ArticleBrief';
import HeaderImage from './HeaderImage';
import Logo from '../icons/Logo';

const DEFAULT_IMG_OFFSET = 66;

const style = theme => ({
	header: {
		height: '28vh',
		maxHeight: '400px',  // For Google bot, as it has a bug that renders it (in Search Console) too tall (not respecing 'vh' units above)
		backgroundColor: theme.palette.primary.light,
		backgroundSize: 'cover',
		position: 'relative',
	},

	content: {
		backgroundColor: theme.palette.background.default,
		padding: 8,
		paddingBottom: 30,
	},

	title: {
		top: 'calc(50% - 53px)',
		left: 0,
		right: 0,
		position: 'absolute',
		textAlign: 'center',
		fontFamily: theme.typography.h1.fontFamily,
		fontWeight: theme.typography.h1.fontWeight,
		fontSize: theme.typography.h1.fontSize,
		color: theme.palette.background.default,
	},

	rootGuide: {
		maxWidth: 1700,
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '1em',
	},

	sectionHeader: {
		display: 'flex',
	},

	sectionIcon: {
		marginRight: 15,
		marginTop: 2,
		transform: 'scale(1.2)',
		overflow: 'visible',
	}
});

function renderImagesSkeleton() {
	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<Skeleton variant='rect' width={270} height={180} style={{ margin: 3 }} />
			<Skeleton variant='rect' width={270} height={180} style={{ margin: 3 }} />
			<Skeleton variant='rect' width={270} height={180} style={{ margin: 3 }} />
		</div>
	);
}

const TAB_GUIDE = 0;
const TAB_SPOTS = 1;
const TAB_PHOTOS = 2;
const TAB_MAP = 3;
const TAB_ADMIN = 4;

class TagPage extends Component {
	state = {
		tabIndex: 0,
		scroller: null,
		/** @type {any} */
		spots: null,
		/** @type {any} */
		photos: null,

		/* Admin */
		parentTag: '',
	}
	locs = null;
	/** @type {any} */
	newGuide = null;
	scrollSubsr = '';
	/** @type {any} */
	content = {
		guides: [],
		bestPhotoId: '',
		bestPhotoDisplayName: '',
	};
	_infoText = '';

	constructor(props) {
		super(props);
		this.prepareContent(this.props, true);
		this.updateTitle();
	}

	sortPhotoContent(photos) {
		const locs = {};

		for (const p of photos) {
			p.w = (p.pop || 0) + (p.rating || 0) + 1;
			if (Navigation.isFlickr(p))
				p.w = (p.rating || 0) / 5 + (p.pop || 0) / 1000 - 0.5;
		}

		let res = photos.sort((p1, p2) => p2.w - p1.w);

		for (const p of photos) {
			if (p.idLoc) {
				const loccnt = (locs[p.idLoc] + 1) || 1;
				locs[p.idLoc] = loccnt;
				p.w *= 1 / (loccnt); // Sort repetitive locations lower
			}
		}

		// Sort againg using the updated weights
		res = res.sort((p1, p2) => p2.w - p1.w);

		return res;
	}

	prepareContent(props, construct) {
		const content = {};
		this._showRect = null;

		// Set up location links
		var { tagData, mobile } = props;
		if (!tagData) {
			tagData = {
				guides: [],
			};
		}

		content.guides = tagData.guides;

		// Prepare best photos
		content.bestSpots = tagData.bestSpots;
		content.topPhotos = this.sortPhotoContent((tagData.bestPhotos || []).concat(tagData.guides.map(g => Object.assign(g, { guide: true, pop: 3 + 2 * g.rating }))));

		// Get header photo
		content.bestPhotoId = tagData.imgHead;
		content.bestPhotoDisplayName = tagData.imgDisplayName;
		if (!content.bestPhotoId) {
			const best = content.topPhotos.find(p => !p.guide && Navigation.isOurImg(p));
			content.bestPhotoId = best && best.id;
			content.bestPhotoDisplayName = best && (best.author || (best.user && best.displayName));
		}

		// Move the first Guide to one of the first positions
		const gIndex = content.topPhotos.findIndex(p => p.guide);
		if (gIndex >= 0) {
			const guides = content.topPhotos.splice(gIndex, 1);
			content.topPhotos.splice(mobile ? 2 : 4, 0, guides[0]);
		}

		if (tagData.bestTags)
			content.bestTags = tagData.bestTags.filter(t => t.idBestImg).map(t => Object.assign(t, { photo: t.imgSquare || t.idBestImg, blurhash: t.blurhashSquare || t.blurhashBest }));

		this.content = content;

		const hier = tagData.locHier;
		const items = hier && hier.split('\\');

		const state = {
			parentTag: (items && items[items.length - 1]) || '',
			idOSM: tagData.idOSM,
			osmType: tagData.osmType,
		};
		if (construct)
			Object.assign(this.state, state);
		else
			this.setState(state);
		this._infoText = tagData.infoText;
	}

	async getSpots() {
		if (!this._spotsInProgress) {
			this._spotsInProgress = true;
			let spots = await Server.getTagSpots(this.props.tagData.id);
			if (spots) {
				spots = spots.sort((s1, s2) => s2.p - s1.p);
				this.setState({ spots });
			}
			return spots;
		}
	}

	async getPhotos() {
		if (!this._photosInProgress) {
			this._photosInProgress = true;
			let photos = await Server.getTagPhotos(this.props.tagData.id);
			if (photos) {
				photos = photos.sort((s1, s2) => s2.rating - s1.rating);
				this.setState({ photos });
			}
		}
	}

	async updateMap() {
		const name = this.props.tagData.name;
		let spots = this.state.spots;
		if (!spots)
			spots = await Server.getTagSpots(this.props.tagData.id);
		Navigation.setFilter('ft', name, spots);
		// Navigation.go(true); // To enforce refresh  // Doesn't seem to be necessary anymore? And causes problems when switching to Map tab.
	}

	updateTitle() {
		if (this.props.tagData)
			setPageTitle(`${this.props.tagData.name}`);
	}

	componentDidMount() {
		this.scrollSubsr = PubSub.subscribe('MAIN_SCROLL', (msg, data) => {
			this.updateScrollTop(data.scrollTop);
		});
	}

	componentWillUnmount() {
		PubSub.unsubscribe(this.scrollSubsr);
	}

	updateScrollTop(scrollTop) {
		// window.requestAnimationFrame(() => {
		// 	if (this.header)
		// 		this.header.style.transform = `translateY(${Math.round(scrollTop / 2)}px)`;
		// });
	}

	componentDidUpdate(prevProps) {
		if (this.props.tagData !== prevProps.tagData) {
			this.updateTitle();
			this.prepareContent(this.props);
			this.setState({ spots: null, photos: null, tabIndex: 0 });
			this.forceUpdate();
			this._spotsInProgress = false;
			this._photosInProgress = false;
		}
	}

	handleTabChange = (event, value) => {
		this.setState({ tabIndex: value });
		if (value === TAB_SPOTS) {
			this.getSpots();
		}
		if (value === TAB_PHOTOS) {
			this.getPhotos();
		}
		if (value === TAB_MAP) {
			this.updateMap();
		}
	}

	openTag = (e) => {
		if (checkKeyMods(e)) {
			Navigation.openTag(e.currentTarget.dataset.id, '');
		}
	}

	updateTag = (e) => {
		Server.updateTag(this.props.tagData.id, this.props.tagData.tag, {
			parentTag: this.state.parentTag,
			imgSquare: this.getImgSquare(),
			imgHead: this.getImgHead(),
			imgHeadOffset: this.getImgHeadOffset(),
			idOSM: this.state.idOSM,
			osmType: this.state.osmType,
			infoText: this._infoText,
		});
	}

	deleteTag = (e) => {
		if (window.confirm('Really delete the tag?')) {
			Server.deleteTagAdmin(this.props.tagData.tag).then(() => { Navigation.goBack() });
		}
	}

	getImgSquare = () => {
		return this.state.imgSquare !== undefined ? this.state.imgSquare : this.props.tagData.imgSquare;
	}

	getImgHead = () => {
		return this.state.imgHead !== undefined ? this.state.imgHead : this.props.tagData.imgHead;
	}

	updateImgSquare = (e) => {
		const newValue = e.target.value;
		this.setState({ imgSquare: newValue });
	}

	updateImgHead = (e) => {
		const newValue = e.target.value;
		this.setState({ imgHead: newValue });
	}

	updateImgHeadOffset = (e, newValue) => {
		this.setState({ imgHeadOffset: newValue });
	}

	getImgHeadOffset = () => {
		return this.state.imgHeadOffset || this.props.tagData.imgHeadOffset || DEFAULT_IMG_OFFSET;
	}

	openOSM = () => {
		window.open(this.state.osmType && this.state.idOSM ? `https://www.openstreetmap.org/${this.state.osmType}/${this.state.idOSM}` : `https://www.openstreetmap.org/search?query=${this.props.tagData.tag}`, "_blank");
	}

	renderAdmin() {
		const { tagData } = this.props;
		return (
			<div style={{ maxWidth: 1024, marginLeft: 'auto', marginRight: 'auto', marginTop: 20 }}>
				<TextField variant='outlined' label='Parent Tag' fullWidth value={this.state.parentTag} onChange={(e) => this.setState({ parentTag: e.target.value })} margin='dense' />

				<TextField variant='outlined' label='Image Square ID' fullWidth value={this.getImgSquare()} onChange={this.updateImgSquare} margin='dense' />

				{this.getImgSquare() &&
					<div>
						<ImageWithTitle
							title={''}
							imageURL={Server.getImageThumb({ id: this.getImgSquare() })}
						/>
					</div>
				}

				<TextField variant='outlined' label='Image Header ID' fullWidth value={this.getImgHead()} onChange={this.updateImgHead} margin='dense' />

				{this.getImgHead() &&
					<>
						<div
							className={this.props.classes.header}
							style={{ width: '100%', backgroundImage: `url(${Server.getImageUrlFromID(this.getImgHead())})`, backgroundPosition: `center ${this.getImgHeadOffset()}%` }}
						/>
						<Slider
							min={1}
							max={100}
							value={this.getImgHeadOffset()}
							onChange={this.updateImgHeadOffset}
						/>
					</>
				}

				<div style={{ display: 'flex', alignItems: 'flex-start' }}>
					<TextField variant='outlined' label='OSM ID' value={this.state.idOSM} onChange={(e) => { this.setState({ idOSM: e.target.value }); }} margin='dense' />
					<TextField variant='outlined' label='OSM Type' value={this.state.osmType} onChange={(e) => { this.setState({ osmType: e.target.value }); }} margin='dense' />
					<Button variant='contained' color='primary' onClick={this.openOSM} style={{ marginTop: 8 }}>
						{'Open'}
					</Button>

					<MapOutline
						interactive={true}
						style={{ height: 260, width: 400, marginLeft: 10 }}
						id={tagData.id}
						data={{
							geom: tagData.geo,
							rect: tagData.rect,
							parentRect: tagData.parentRect,
							showRect: tagData.showRect,
						}}
						onMove={(a => this._showRect = a)}
						mobile={this.props.mobile}
					/>
					<Button
						variant='contained'
						color='primary'
						onClick={() => { if (this._showRect) Server.updateTagMapRect(tagData.id, tagData.tag, this._showRect) }}
						style={{ marginTop: 8 }}
					>
						{'Update'}
					</Button>
				</div>

				<br /><br />

				{/* @ts-ignore */}
				<Editor
					initialValue={this._infoText}
					onChange={(value) => { this._infoText = value; }}
					allowNavigation={true}
				/>

				<Button variant='contained' color='secondary' onClick={this.updateTag} style={{ marginTop: 20 }}>
					{'Submit'}
				</Button><br /><br /><br />

				<Button variant='contained' color='secondary' onClick={this.deleteTag}>
					{'Delete'}
				</Button>
			</div>
		);
	}

	openMap = (e) => {
		if (checkKeyMods(e)) {
			Navigation.goPath(`/map`);
			this.updateMap();
		}
	}

	renderGuides() {
		const { classes, tagData, mobile } = this.props;
		const { topPhotos, bestTags } = this.content;

		return (
			<div className={classes.rootGuide}>
				{/* Header with basic info and a mini-map */}
				{tagData.infoText &&
					<div className='tagInfo' style={{}}>
						<Link href={`/map?ft=${tagData.tag}&map`} onClick={(e) => this.openMap(e)}>
							<MapOutline
								mapId={'minimap'}
								id={tagData.id}
								data={{
									geom: tagData.geo,
									rect: tagData.rect,
									parentRect: tagData.parentRect,
									showRect: tagData.showRect,
								}}
								mobile={this.props.mobile}
							/>
						</Link>
						<p className='' style={{}}>
							{tagData.infoText}
						</p>
					</div>
				}

				<div style={{ margin: '0px 0.7em' }}>
					{/* Photography sub-regions of this tag */}
					{bestTags && bestTags.length > 1 &&
						<div style={{ marginBottom: 30 }}>
							<h4 className={classes.sectionHeader}>
								<Icon color='disabled' className={classes.sectionIcon}><RegionIcon /></Icon>
								{'Photography regions of ' + tagData.tag}
							</h4>
							<TagsBar tags={bestTags} />
						</div>
					}

					{topPhotos &&
						<>
							<h4 className={classes.sectionHeader}>
								{bestTags && bestTags.length > 1 && // Show the title only if the tags line is shown above
									<>
										<Icon color='disabled' className={classes.sectionIcon}><PhotoIcon /></Icon>
										{'Photography of ' + tagData.tag}
									</>
								}
							</h4>

							<ResponsiveMasonry columnsCountBreakPoints={{
								// @ts-ignore
								200: 1, 650: 2, 1200: 3
							}}
								defaultWidth={this.props.mobile ? 500 : 1400}
							>
								<Masonry gutter='0px 30px'>
									{topPhotos.map((photo, index) =>
										photo.guide ?
											<ArticleBrief
												article={photo}
												key={'G' + photo.id}
											/>
											:
											<Description
												key={photo.id}
												description={{ image: {}, location: { id: photo.idLoc, title: photo.locTitle }, ...photo }}
												showLocation={true}
												location={{ id: -1 }}
												important={mobile ? index < 2 : index < 6}
											/>
									)}
								</Masonry>
							</ResponsiveMasonry>

							{tagData.contribs && tagData.contribs.length &&
								<div className='thx'>
									<div>
										<Logo />
									</div>

									<p>
										{'Thanks to all Phoide contributors to '}
										<span>{tagData.tag}</span>
										{'!'}
										<br />
										<>
											{'Most notably '}
											{tagData.contribs.map((user, index) => {
												return (
													<React.Fragment key={user.u}>
														{index > 0 &&
															(index < tagData.contribs.length - 1 ?
																', ' :
																' and ')
														}
														<a href={Navigation.getUserURL(user.u)} onClick={(e) => { if (checkKeyMods(e)) Navigation.goPath(Navigation.getUserURL(user.u)); }}>
															{user.d && user.d.replaceAll(' ', '\u00a0')}
														</a>
													</React.Fragment>
												);
											})}
											{'.'}
										</>
									</p>
								</div>
							}
						</>
					}
				</div>
			</div>
		);
	}

	render() {
		const { classes, tagData, user } = this.props;
		const { spots, photos } = this.state;
		const { bestPhotoId } = this.content;

		if (!this.props.tagData)
			return null; // To avoid jittery output, don't render anything until we know whether we have the header image

		return (
			<>
				<div className='allButFooter'>
					{/* @ts-ignore */}
					<AppHeader
						// style={{ position: 'absolute' }}
						tag={this.props.tagData.name}
						user={this.props.user}
					/>

					<HeaderImage
						image={{ id: bestPhotoId }}
						imgOffset={tagData.imgHeadOffset}
						imgDisplayName={this.content.bestPhotoDisplayName}
						blurhash={tagData.blurhash}
					/>

					<div className={classes.content}>
						<div style={{ paddingTop: 15, marginBottom: -15 }}>
							<TagHierarchy hier={this.props.tagData.locHier} />
						</div>

						<h1 style={{ textAlign: 'center' }}>{tagData.name}</h1>

						<Tabs
							value={this.state.tabIndex}
							onChange={this.handleTabChange}
							style={{ marginBottom: 5 }}
							centered={true}
						>
							<Tab label={'Guide'} value={TAB_GUIDE} />
							<Tab label={'Spots' + (tagData || spots ? ` (${tagData.locCnt || (spots && spots.length) || '0'})` : '')} value={TAB_SPOTS} />
							<Tab label={'Photos' + (photos && photos.length ? ` (${photos.length})` : '')} value={TAB_PHOTOS} />
							{!tagData.infoText &&
								<Tab label={'Map'} value={TAB_MAP} />
							}
							{user && user.admin &&
								<Tab label={'Admin'} value={TAB_ADMIN} />
							}
						</Tabs>

						{/* Guide */}
						{this.state.tabIndex === TAB_GUIDE &&
							this.renderGuides()
						}

						{/* Spots */}
						{this.state.tabIndex === TAB_SPOTS &&
							(spots ?
								<SpotsList spots={spots} />
								:
								renderImagesSkeleton()
							)
						}

						{/* Photos */}
						{this.state.tabIndex === TAB_PHOTOS &&
							(photos ?
								// @ts-ignore 
								<Images
									images={photos}
									height={180}
									margins={5}
								/>
								:
								renderImagesSkeleton()
							)
						}

						{/* Map */}
						{this.state.tabIndex === TAB_MAP &&
							<div style={{ height: '50vh', width: '100%', maxWidth: 1366, marginLeft: 'auto', marginRight: 'auto' }}>
								{/* @ts-ignore */}
								<MapView
									largeMap={false}
									mobile={this.props.mobile}
								/>
							</div>
						}

						{/* Admin */}
						{this.state.tabIndex === TAB_ADMIN &&
							this.renderAdmin()
						}
					</div>
				</div>

				<Footer />
			</>
		);
	}
}

TagPage.propTypes = {
	classes: PropTypes.object.isRequired,
	editGuideID: PropTypes.number,

	user: PropTypes.object,
	tagData: PropTypes.object,
	mobile: PropTypes.bool,
};

/** @type {any} */
// @ts-ignore (style problem)
export default withStyles(style)(TagPage);