import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';

import AdjustIcon from '@material-ui/icons/Adjust';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

import Server from '../server.js';

const styles = theme => ({
	floatRight: {
		float: 'right',
	},
	leftIcons: {
		width: 'auto',
		marginRight: theme.spacing(1),
	},
	changeBody: theme.typography.body1,
});

class LocationChange extends Component {
	handleRatingUp = (event) => {
		Server.moveRateUp(this.props.location, event.currentTarget);
	};

	handleRatingDown = (event) => {
		Server.moveRateDown(this.props.location, event.currentTarget);
	};

	render() {
		const { classes } = this.props;
		const newLocation = this.props.location ? this.props.location.newLocation : null;

		if (newLocation) {
			return (
				<Paper style={{ marginTop: 5, marginBottom: 10, padding: 10 }}>
					<Grid container direction='row' wrap='nowrap' alignItems='center'>
						<div className={classes.leftIcons}>
							<Avatar>
								<AdjustIcon />
							</Avatar>
						</div>

						<span className={classes.changeBody} style={{ flexGrow: 10, opacity: 0.7 }}>
							{'There was a location change proposed. Do you agree with the new location (marked by a question mark in the Map)?'}
						</span>

						<Tooltip title='The new location is correct' enterDelay={300}>
							<IconButton aria-label='Thumb Up' onClick={this.handleRatingUp}>
								<ThumbUpIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title='The new location is incorrect' enterDelay={300}>
							<IconButton aria-label='Thumb Down' onClick={this.handleRatingDown}>
								<ThumbDownIcon />
							</IconButton>
						</Tooltip>
					</Grid>
				</Paper>
			);
		} else
			return null;
	}


}

LocationChange.propTypes = {
	classes: PropTypes.object.isRequired,
	location: PropTypes.object,
};

/** @type {any} */
export default withStyles(styles)(LocationChange);
