// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import AppHeader from './AppHeader';
import Footer from './Footer';
import { MD2React, setPageTitle } from '../tools';
import HeaderImage from './HeaderImage';
import TagHierarchy from './TagHierarchy';
import Navigation from '../navigation';
import IconButton from '@material-ui/core/IconButton';

import EditIcon from '@material-ui/icons/Edit';
import Editor from './Editor';
import { Button, TextField } from '@material-ui/core';
import Server from '../server';

const styles = theme => ({
	content: {
		backgroundColor: theme.palette.background.default,
		padding: 8,
		paddingBottom: 30,

		maxWidth: 1066,
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '1em',
	},

	editTitle: {
		fontSize: 25
	}
});

class GuidePage extends React.PureComponent {
	state = {
		edit: false,
		guideTitle: '',
		guideTag: '',

		submitting: false,
	}
	editValue = '';

	componentDidMount() {
		this.update();
	}

	componentDidUpdate(prevProps) {
		const { guideData } = this.props;
		if (guideData !== prevProps.guideData) {
			this.update();
		}
	}

	updateTitle() {
		if (this.props.guideData)
			setPageTitle(`${this.props.guideData.title || 'New Article'}`);
	}

	update() {
		const { guideData } = this.props;
		this.updateTitle();
		this.setState({ edit: false, submitting: false, guideTag: guideData && guideData.tag });
	}

	onEdit = () => {
		const { guideData } = this.props;
		this.editValue = guideData.text;
		this.setState({
			edit: true,
			guideTitle: guideData.title,
			guideTag: guideData.tag,
			submitting: false,
		});
	}

	handleEditorChange = (newMarkdown) => {
		this.editValue = newMarkdown;
	}

	titleChange = (e) => {
		this.setState({ guideTitle: e.target.value });
	}

	onCancel = () => {
		window.location.reload();  // To force show confirmation dialog on page reload
	}

	onSave = async () => {
		this.setState({ submitting: true });
		const { guideData } = this.props;
		Server.saveGuide(Object.assign({}, guideData, {
			id: guideData.id === -1 ? -2 : guideData.id,  // Change from -1 to prevent further edit state of this guide
			hier: this.state.guideTag === guideData.tag ? guideData.locHier : '',
			tag: this.state.guideTag,
			title: this.state.guideTitle,
			text: this.editValue,
		}));
	}

	renderEdit() {
		const { classes, guideData } = this.props;

		return (
			<>
				<div className='allButFooter'>
					<AppHeader
						user={this.props.user}
					/>

					<HeaderImage
						image={{ id: guideData.imgHead }}
						imgOffset={guideData.imgHeadOffset}
						imgDisplayName={guideData.imgDisplayName}
					/>

					<div className={classes.content}>
						<div style={{ marginBottom: 10, marginTop: 15, textAlign: 'right' }}>
							<Button style={{ marginRight: '1em', paddingLeft: '1em', paddingRight: '1em' }} onClick={this.onCancel}>
								{'Cancel'}
							</Button>
							<Button variant='contained' color='secondary' style={{ paddingLeft: '1.5em', paddingRight: '1.5em' }} onClick={this.onSave} disabled={this.state.submitting}>
								{'Save'}
							</Button>
						</div>

						{/* <div style={{ paddingTop: 15, marginBottom: -15 }}>
							<TagHierarchy hier={(guideData.locHier ? guideData.locHier + '\\' : '') + guideData.tag} />
						</div> */}

						<TextField
							label='Tag'
							value={this.state.guideTag || ''}
							onChange={e => { this.setState({ guideTag: e.target.value }); }}
						/>

						<h1 style={{ textAlign: 'center', marginTop: 40 }}>
							<TextField
								label='Article Title'
								value={this.state.guideTitle}
								fullWidth={true}
								onChange={this.titleChange}
								InputProps={{
									classes: {
										root: classes.editTitle
									}
								}}
							// InputLabelProps={{
							// 	classes: {
							// 		root: classes.editTitle
							// 	}
							// }}
							/>
						</h1>

						<div className='guide'>
							<Editor
								initialValue={this.editValue}
								onChange={this.handleEditorChange}
								allowNavigation={this.state.submitting}
							/>
						</div>

					</div>
				</div>

				<Footer />
			</>
		);
	}

	render() {
		const { classes, guideData, user } = this.props;

		if (!guideData || !guideData.id /* not fully loaded yet, avoid flickering */)
			return null;

		if (this.state.edit || guideData.id === -1)
			return this.renderEdit();

		return (
			<>
				<div className='allButFooter'>
					<AppHeader
						user={this.props.user}
					/>

					<HeaderImage
						image={{ id: guideData.imgHead }}
						imgOffset={guideData.imgHeadOffset}
						imgDisplayName={guideData.imgDisplayName}
					/>

					<div className={classes.content}>
						<div style={{ paddingTop: 15, marginBottom: -15 }}>
							<TagHierarchy hier={(guideData.locHier ? guideData.locHier + '\\' : '') + guideData.tag} />
						</div>

						<h1 style={{ textAlign: 'center' }}>{guideData.title}</h1>

						<div style={{ fontSize: 14, textAlign: 'center', marginTop: -10, marginBottom: 40 }}>
							{'by\u00a0'}
							<a href={Navigation.getUserURL(guideData.username)}>{guideData.displayName}</a>

							{user && guideData.idUser === user.id &&
								<IconButton size='small' onClick={this.onEdit}><EditIcon /></IconButton>
							}
						</div>

						<div className='guide'>

							<div className='defText'>
								{MD2React(guideData.text)}
							</div>
						</div>
					</div>
				</div>

				<Footer />
			</>
		);
	}
}

GuidePage.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object,
	guideData: PropTypes.object,
};

/** @type {any} */
// @ts-ignore (styles)
export default withStyles(styles)(GuidePage);
