// @ts-check
import React, { Component, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import Skeleton from '@material-ui/lab/Skeleton';
import { getDestinationPoint, mapCache, shallowEqual, locDistance } from '../tools';
import Server from '../server';
import mapStyle from './outlineStyle.json';

var maploading;
var maplibregl;

function decodePoly(poly) {
	const res = [];
	let lastc1, lastc2;
	let r1;
	const mult = poly[0];
	const arr = poly.slice(1);
	for (const coord of arr) {
		let c = coord / mult;
		if (typeof lastc2 === 'number') {
			c += lastc2;
		}
		if (r1) {
			res.push([r1, c]);
			r1 = undefined;
		} else
			r1 = c;
		lastc2 = lastc1;
		lastc1 = c;
	}
	return res;
}

function joinRects(r1, r2) {
	if (!r1)
		r1 = r2;
	return [
		[Math.min(r1[0][0], r2[0][0]), Math.min(r1[0][1], r2[0][1])],
		[Math.max(r1[1][0], r2[1][0]), Math.max(r1[1][1], r2[1][1])],
	];
}

function enlargeRect(r, delta) {
	return [
		[r[0][0] - delta[0], r[0][1] - delta[1]],
		[r[1][0] + delta[0], r[1][1] + delta[1]]
	];
}

function getRectSize(r) {
	return Math.max(r[1][0] - r[0][0], r[1][1] - r[0][1]);
}

function adjustRects(r1, r2) {
	const s1 = r1[1][0] - r1[0][0];
	const s2 = r1[1][1] - r1[0][1];
	// Add some padding
	r1 = enlargeRect(r1, [s1 * 0.2, s2 * 0.2]);

	// Merge rects
	const total = joinRects(r1, r2);

	const ps1 = total[1][0] - total[0][0];
	const ps2 = total[1][1] - total[0][1];
	if (s1 / ps1 < 0.05 && s2 / ps2 < 0.05) {
		// Too small within the parent rectangle
		return enlargeRect(r1, [Math.max(s1 * 3, ps1 * 0.05), Math.max(s2 * 3, ps2 * 0.05)]);
	}

	return total;
}

function toRange(v, r1, r2) {
	if (v < r1)
		return r1;
	if (v > r2)
		return r2;
	return Number.isFinite(v) ? v : 0;
}

function normalizeRect(r) {
	return [[toRange(r[0][0], -180, 180), toRange(r[0][1], -87, 87)], [toRange(r[1][0], -180, 180), toRange(r[1][1], -87, 87)]];
}

function isInRect(p, rect) {
	return (p[0] > rect[0][0] && p[0] < rect[1][0] && p[1] > rect[0][1] && p[1] < rect[1][1]);
}

const REGIONS = [
	[[-12, 34], [38, 60]], // Europe
	[[89, -12], [144, 18]], // Southeast Asia
	[[113, 16], [155, 50]], // Far East
	[[55, 6], [122, 30]], // South Asia
	[[29, 18], [74, 43]], // Near East
	[[-22, -38], [51, 39]],  // Africa
	[[-96, 2], [-60, 25]],  // Central America
	[[-142, 10], [-29, 57]],  // North America
	[[-106, -58], [-31, 14]],  // South America
	[[91, -50], [176, 2]],  // Australia
];
const WORLD = [[-170, -45], [175, 70]];

// Get the best rectangle that shows rect within its parentRect
function getRect(rect, parentRect) {
	// console.log(rect);
	// console.log(parentRect);
	if (!rect || !rect[0])
		return WORLD;

	if (!parentRect) {
		for (const r of REGIONS) {
			if (isInRect(rect[0], r))
				return normalizeRect(joinRects(rect, r));
		}
		return WORLD;
	}

	return normalizeRect(adjustRects(rect, parentRect));
}

const savedMaps = {};

function savedMapsCleaner() {
	setTimeout(() => {
		for (const idmap of Object.keys(savedMaps)) {
			const map = savedMaps[idmap];
			if (Date.now() - map.time > 2000) {
				map.map.remove();
				delete savedMaps[idmap];
			}
		}
	}, 2000);
}

class MapOutline extends Component {
	_map;
	mapEl;
	_shown = false;
	_refresh = true;
	_points = [];

	initComp = async () => {
		if (!maplibregl && !maploading) {
			if (process.env.REACT_APP_SERVER_SIDE)
				return false;

			maploading = true;
			maplibregl = (await import(/* webpackMode: "lazy" */'../tools/mapLibre')).default;
			maplibregl.prewarm();  // Keeps it loaded in memory, useful for SPA when re-rendering map on another page
			this.updateMap();
		}
	}

	componentDidMount() {
		this.initComp();
		this.updateMap();
	}

	componentWillUnmount() {
		if (this._map) {
			const { mapId } = this.props;
			if (mapId) { // Save map element for a reuse
				this.clearData();
				savedMaps[mapId] = {
					map: this._map,
					el: this.mapEl.lastElementChild,
					time: Date.now(),
				}
				delete this._map.component;
				savedMapsCleaner();
			} else {
				this._map.remove();
				this._map = undefined;
			}
		}
	}

	shouldComponentUpdate(nextProps) {
		this.updateMap(nextProps);
		return (nextProps.id !== this.props.id || nextProps.loading !== this.props.loading || !shallowEqual(nextProps.data, this.props.data));
	}

	calcRect(data) {
		return (data.showRect && normalizeRect(data.showRect))|| getRect(data.rect, data.parentRect);
	}

	clearData() {
		if (this._shown) {
			const map = this._map;
			if (map.getLayer('line'))
				map.removeLayer('line');
			if (map.getLayer('point'))
				map.removeLayer('point');
			if (map.getLayer('fov'))
				map.removeLayer('fov');
			if (map.getSource('region'))
				map.removeSource('region');
			if (map.getSource('fov'))
				map.removeSource('fov');
			for (const p of this._points) {
				p.remove();
			}
			this._points = [];
		}
		this._shown = false;
	}

	showData(props) {
		const map = this._map;
		if (!map || !this._refresh)
			return;
		this._refresh = false;

		this.clearData();
		const { data } = props || this.props;
		if (!data || !data.geom)
			return;
		this._shown = true;

		let geom = data.geom.map(p => decodePoly(p));

		// console.log('Show data');
		// console.log(data);
		map.fitBounds(this.calcRect(data));

		if (data.points) {
			for (const p of data.points) {
				var el = document.createElement('div');
				el.style.background = 'no-repeat center url(/images/mapPin.svg)';
				el.style.width = '70px';
				el.style.height = '70px';

				this._points.push(new maplibregl.Marker(el)
					.setLngLat(p)
					.addTo(map));
			}

			if (data.view) {
				const destByDir = (origin, direction) => {
					const p = getDestinationPoint({ lat: origin[1], long: origin[0] }, direction, 1000000 /* 1000km, ok for most cases? */);
					return [p.lon, p.lat];
				}

				const p = data.points[0];
				const head = data.view.head;
				const fov = data.view.fov;
				const d1 = (head + fov / 2);
				const d2 = (head - fov / 2);

				map.addSource('fov', {
					'type': 'geojson',
					'data': {
						'type': 'Feature',
						'properties': {},
						'geometry': {
							'type': 'LineString',
							'coordinates': [
								destByDir(p, d1),
								p,
								destByDir(p, d2),
							]
						}
					}
				});
				map.addLayer({
					'id': 'fov',
					'type': 'line',
					'source': 'fov',
					'layout': {
						'line-join': 'round',
						'line-cap': 'round'
					},
					'paint': {
						'line-color': '#c72e00',
						'line-width': 1,
						'line-dasharray': [2, 4],
					}
				});
			}
		}

		if (geom && geom.length === 1 && geom[0].length === 1) {
			map.addSource('region', {
				'type': 'geojson',
				'data': {
					'type': 'Feature',
					'geometry': {
						'type': 'Point',
						'coordinates': geom[0][0],
					}
				}
			});
		} else {
			map.addSource('region', {
				'type': 'geojson',
				'data': {
					'type': 'Feature',
					'geometry': {
						'type': 'MultiLineString',
						// 'type': 'Polygon',
						'coordinates': geom,
					}
				}
			});

			map.addLayer({
				'id': 'line',
				'type': 'line',
				'source': 'region',
				'layout': {
					'line-join': 'round',
					'line-cap': 'round'
				},
				'paint': {
					'line-color': '#c72e00',
					'line-width': 3,
				},
				// 'filter': ['==', '$type', 'MultiLineString']  // This filter doesn't work, Polygon required?
			});
		}

		map.addLayer({
			'id': 'point',
			'type': 'circle',
			'source': 'region',
			'paint': {
				'circle-radius': 6,
				'circle-color': '#c72e00'
			},
			'filter': ['==', '$type', 'Point']
		});
		// }, 100);

		// this._map.addLayer({
		// 	'id': 'region',
		// 	'type': 'fill',
		// 	// 'type': 'line',
		// 	'source': 'region',
		// 	'paint': {
		// 		'fill-color': '#088',
		// 		'fill-opacity': 0.8
		// 	}
		// });
	}

	_delayTimeout;
	delayedShowData() {
		if (this._delayTimeout) {
			clearTimeout(this._delayTimeout);
		}
		this._delayTimeout = setTimeout(() => {
			this.showData();
			this._delayTimeout = undefined;
		}, 10);
	}

	onClick = (e) => {
		const { data } = this.props;
		if (data) {
			const r = this.calcRect(data);
			PubSub.publish('MAP_BOUNDING_BOX', { boundingBox: [r[0][1], r[1][1], r[0][0], r[1][0]], duration: 0 });

			const p = data.points && data.points[0];
			if (p) {
				const dist = locDistance({ lat: r[0][1], long: r[0][0] }, { lat: r[1][1], long: r[1][0] });
				if (dist > 1000) {
					const ratio = 1000 / dist;
					PubSub.publish('MAP_BOUNDING_BOX', {
						boundingBox:
							[p[1] + ratio * (r[0][1] - p[1]), p[1] + ratio * (r[1][1] - p[1]),
							p[0] + ratio * (r[0][0] - p[0]), p[0] + ratio * (r[1][0] - p[0])],
						duration: 1000
					});
				}
			}
		}
	}

	updateMap(props) {
		props = props || this.props;
		if (!maplibregl || !this.mapEl || props.loading || this._map)
			return;

		const { mapId } = this.props;
		if (savedMaps[mapId]) {
			// console.log('Restoring outline map');
			this._map = savedMaps[mapId].map;
			this._map.component = this;
			this.mapEl.appendChild(savedMaps[mapId].el);
			delete savedMaps[mapId];
			this._refresh = true;
			this._map.resize();
			this.showData(props);
			return;
		}

		const mapRoot = document.createElement('div');
		mapRoot.style.width = '100%';
		mapRoot.style.height = '100%';
		mapRoot.style.position = 'absolute';
		mapRoot.style.top = '0px';
		this.mapEl.appendChild(mapRoot);

		// console.log('New outline map');

		this._map = new maplibregl.Map({
			container: mapRoot,
			style: mapStyle, //'https://api.maptiler.com/maps/dataviz-light/style.json?key=8utWyTa40GfrAT6dx1WH',
			// style: 'https://api.maptiler.com/maps/dataviz/style.json?key=8utWyTa40GfrAT6dx1WH',
			interactive: this.props.interactive || false,
			attributionControl: false,
			bounds: this.calcRect(props.data),
			transformRequest: mapCache,
			// center: [14.4140164, 50.075259],
			// zoom: 10
		});
		this._map.component = this;

		var scale = new maplibregl.ScaleControl({
			maxWidth: this.props.mobile ? 80 : 90,
			unit: 'metric'
		});
		this._map.addControl(scale);

		this._map.on('load', (e) => {
			const map = e.target;
			if (map && map.component)
				map.component.delayedShowData();
		});

		this._map.on('sourcedata', (e) => {
			const map = e.target;
			if (map && map.component && map.isStyleLoaded()) {
				map.component.delayedShowData();
			}
		});

		this._map.on('moveend', (e) => {
			const map = e.target;
			if (map && map.component && map.component.props.onMove)
				map.component.props.onMove(map.getBounds().toArray().map(a => a.map(n => Math.round(n * 1000000) / 1000000)));
		});

		this._map.on('resize', (e) => {
			const map = e.target;
			if (map && map.component)
				map.fitBounds(map.component.calcRect(map.component.props.data));
		});
	}

	render() {
		this._refresh = true;
		if (this._map && this._map.isStyleLoaded() && !this.props.loading) {
			this.delayedShowData();
		}

		return (
			<div style={{ ...this.props.style, position: 'relative' }} ref={el => this.mapEl = el} onClick={this.onClick}>
				{!this._map &&
					<Skeleton variant='rect' height='100%' />
				}
			</div>
		);
	}
}

MapOutline.propTypes = {
	style: PropTypes.object,
	location: PropTypes.object,
	data: PropTypes.object,
	interactive: PropTypes.bool,
	onMove: PropTypes.func,
	mobile: PropTypes.bool,
	loading: PropTypes.bool,
};

export function MapOutlineLoader(props) {
	const addProps = {};
	/** @type{any}*/
	const [tagsData, setTagsData] = useState(null);
	const gettingTags = useRef([]);

	let data = props.data;

	const tags = data && data.tags;
	if (tags) {
		if (tagsData) {
			data = { ...props.data, geom: tagsData[0] && tagsData[0].geo }
		} else
			addProps.loading = true;
	}

	// Calculate rectangle to show
	if (data /*&& !data.showRect*/ && tagsData) {
		let totalRect = null;
		for (const t of tagsData) {
			if (!t)
				continue;
			let r = t.rect;
			if (!r)
				continue;
			const size = getRectSize(r);
			// Too small tags get some more padding
			if (size < 0.001)
				r = enlargeRect(r, [size, size]);
			totalRect = joinRects(totalRect, r);
		}
		for (const p of data.points || []) {
			totalRect = joinRects(totalRect, [p, p]);
			// Make sure there's enough place _above_ the point, in order to render the marker
			const height = totalRect[1][1] - totalRect[0][1];
			const loc = (totalRect[1][1] - p[1]) / height;
			if (loc < 0.15)
				totalRect[1][1] += (0.15 - loc) * height;
		}
		if (totalRect) {
			const size = getRectSize(totalRect);
			data.showRect = enlargeRect(totalRect, [size * 0.1, size * 0.1]);
		}
	}

	// Load data, if needed
	useEffect(() => {
		// console.log(tags);
		// console.log(gettingTags.current);
		if (tags && tags.length && (!gettingTags.current.length || gettingTags.current[0] !== tags[0])) {
			// console.log('New effect');

			gettingTags.current = tags;
			let running = true;
			const promises = [];
			setTagsData(null);
			for (const t of tags) {
				if (t)
					promises.push(Server.getTag(t));
			}
			if (promises.length) {
				Promise.all(promises).then(data => {
					if (running) {
						// console.log('Set Tags data');
						// console.log(data);
						setTagsData(data);
					}
				});
			}
			// return () => { console.log('cancel effect'); running = false; }
			return () => { running = false; }
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(tags)] /* to compare _content_ of the array */);

	addProps.data = data;

	// console.log(data);

	return (
		<MapOutline {...Object.assign({}, props, addProps)} />
	);
}

export default MapOutline;