/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';

import SpotItem from './SpotItem';
import PubSub from 'pubsub-js';
import ResizeObserver from 'resize-observer-polyfill';

function getScrollProps(element) {
  while (element) {
    if (element.hasAttribute('data-scroll'))
      return {
        scrollTop: element.scrollTop,
        scrollHeight: element.scrollHeight,
        scrollerHeight: element.clientHeight,
      };
    element = element.parentElement;
  }
  return {
    scrollTop: window.scrollY,
    scrollHeight: window.document.scrollingElement.scrollHeight,
    scrollerHeight: window.innerHeight,
  };
}

export default function SpotsList(props) {
  const [maxImages, setMaxImages] = useState(20);

  const ref = useRef({});
  const refRoot = useRef();
  const loc = ref.current;
  const imgDelta = 20;

  const updateCount = function (scrollProps) {
    if (scrollProps.scrollTop + (1.5 * scrollProps.scrollerHeight) > scrollProps.scrollHeight &&
    scrollProps.scrollHeight > loc.scrollHeight &&
      Date.now() - loc.lastScroll > 250 /*don't load too often*/) {
      loc.scrollHeight = scrollProps.scrollHeight;
      setMaxImages(maxImages + imgDelta);
      loc.lastScroll = Date.now();
    }
  }

  useEffect(() => {
    loc.lastScroll = 0;
    loc.scrollHeight = 0;
  }, [loc /* call only on mount/unmount */]);

  useEffect(() => {
    var scrollSubscr = PubSub.subscribe('MAIN_SCROLL', (msg, data) => {
      updateCount(data);
    });
    var ro = new ResizeObserver(() => {
      updateCount(getScrollProps(refRoot.current));
    });
    var rootEl = refRoot.current;
    ro.observe(rootEl);
    return () => {
      PubSub.unsubscribe(scrollSubscr);
      ro.unobserve(rootEl);
      ro = undefined;
    };
  });

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: 'calc(100% + 12px)', marginLeft: -6, marginRight: -6, }} ref={refRoot}>
      {props.spots && props.spots.slice(0, maxImages).map(spot => {
        return (
          <SpotItem
            key={spot.id}
            spot={spot}
            height={230}
            margin={12}
            grid={true}
            large={true}
            baseSpot={props.baseSpot}
            flexibility={props.flexibility}
          />
        );
      })}
    </div>
  );
}