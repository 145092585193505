import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Server from '../server';
import Navigation from '../navigation';
import { MD2React } from '../tools';

const styles = theme => ({
	userAvatar: {
		margin: theme.spacing(1),
		float: 'right',
		cursor: 'pointer',
	},
	small: {
		width: theme.spacing(5),
		height: theme.spacing(5),
	},
	large: {
		width: theme.spacing(7),
		height: theme.spacing(7),
	},

	popoverRoot: {
		padding: theme.spacing(1),
		minWidth: 300,
		maxWidth: 350,
	},
	header: {
		padding: theme.spacing(1),
	},
	popoverAvatar: {
		margin: theme.spacing(1),
		width: theme.spacing(7),
		height: theme.spacing(7),
	},

	fame: {
		marginTop: theme.spacing(1.3),
	},
	badges: {
		marginTop: theme.spacing(1),
	},
	headerText: {
		marginLeft: theme.spacing(2),
	},
	popoverBottom: {
		marginTop: theme.spacing(1.5),
		marginBottom: theme.spacing(0.5),
	}
});

class UserAvatar extends Component {
	state = {
		anchorEl: null,
		avatarUrl: null,
	}

	componentDidMount() {
		Server.getAvatarURL(this.props.user).then(url => {
			this.setState({ avatarUrl: url });
		}).catch(() => {
			this.setState({ avatarUrl: null });
		});
	}

	handlePopoverClose = () => {
		this.setState({ anchorEl: null });
	}

	handleAvatarClick = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	}

	formatBadges = (badges) => {
		var res = '';

		if (badges) {
			res += badges[0] ? badges[0] + ' Bronze' : '';
			if (badges[1]) {
				res = res + (res ? ', ' : '') + badges[1] + ' Silver';
			}
			if (badges[2]) {
				res = res + (res ? ', ' : '') + badges[2] + ' Gold';
			}
		}

		if (!res)
			res = 'none yet';

		return res;
	}

	showFullProfile = () => {
		Navigation.showProfile(this.props.user.username);
		this.handlePopoverClose();
	}

	render() {
		const { classes, user } = this.props;
		const { anchorEl } = this.state;

		return (
			<div>
				<Avatar
					className={classes.userAvatar + ' ' + (this.props.large ? classes.large : classes.small)}
					onClick={this.handleAvatarClick}
					src={this.state.avatarUrl ? this.state.avatarUrl : null}
					style={this.props.style}
				>
					{this.state.avatarUrl ? null : user.displayName}
				</Avatar>

				<Popover
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
					onClose={this.handlePopoverClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<Grid container direction='column' className={classes.popoverRoot}>
						{/* Header */}
						<Grid container direction='row' wrap='nowrap' className={classes.header}>
							<Avatar
								className={classes.userAvatar + ' ' + classes.large}
								src={this.state.avatarUrl ? this.state.avatarUrl : null}
								onClick={this.showFullProfile}
							>
								{this.state.avatarUrl ? null : user.firstName}
							</Avatar>

							<div className={classes.headerText}>
								<Typography variant='h6'><span>{user.displayName}</span></Typography>
								<Typography className={classes.fame}><b>Impact:</b> {user.impact}</Typography>
								<Typography className={classes.badges}><b>Badges:</b> {this.formatBadges(user.badges)}</Typography>
							</div>
						</Grid>

						<Divider />

						{/* Bottom */}
						<div className={classes.popoverBottom}>
							<Typography>
								{user.about ?
									MD2React(user.about) :
									'No description entered yet.'
								}
							</Typography>
						</div>

						{/* Profile button */}
						<Button color='primary' style={{ placeSelf: 'flex-end' }} onClick={this.showFullProfile}>
							{'Show Profile'}
						</Button>
					</Grid>
				</Popover>
			</div>
		);
	}
}

UserAvatar.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	large: PropTypes.any,
	style: PropTypes.object,
};

/** @type {any} */
export default withStyles(styles)(UserAvatar);